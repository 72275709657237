import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment/moment';
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { CommonForm } from 'src/app/models/user/common-form.model';
import {
  ACTIVITY_TYPE,
  API,
  API_DRAFT,
  API_EDS_QUERY,
  API_OTP,
  API_SWS,
  COMPLIANCE_ABBR,
  DATE_FORMATS,
  EC_TypeOfWaste,
  EXEMPTED_PARAMS,
  FC_2_ABBR,
  FC_3_ABBR,
  FC_4_ABBR,
  FC_FACTSHEET_ABBR,
  FC_FORM_NAMES,
  PROJECT_CATEGORY,
  PROPONENT_APPLICATION_ID,
  PROPOSAL_DATA,
  SECTOR_DETAIL,
  SIR_ABBR,
  STIPULATED_CA_ABBR,
  WORKING_PERMISSION_ABBR,
} from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';
import { AppState } from '../state/app-state/app-reducer';
import { SharedActions } from '../state/shared-state/shared-action-types';
import {
  isDistrictLoaded,
  isStateLoaded,
  selectAllProposalByProposalId,
  selectDistrict,
  selectState,
} from '../state/shared-state/shared.selector';
import { ClearenceService } from './clearence.service';
import { DigitalSignatureService } from './digital-signature/digital-signature.service';
import { EdsQueryService } from './eds-query.service';
import { LoaderService } from './loader.service';
import { MessageService } from './message.service';
import { EnvionmentClearancePartBService } from './organisation/envionment-clearance-part-b.service';
import { FcBService } from './organisation/fc-form-b.service';
import { ForestClearancesService } from './organisation/forest-clearances.service';
import { ProponentApplicationService } from './organisation/proponent-applications.service';
import { SessionStorageService } from './session-storage.service';
import { UserService } from './user.service';
import { tap } from 'rxjs/operators';
import { OKMService } from './okm.services';
import { FcSiteInspectionReportService } from './organisation/fc-site-inspection-report.service';

@Injectable({
  providedIn: 'root',
})
export class SharedServiceService {
  private _loaderService!: LoaderService;
  existedProposalsById: any;

  hidePendencyFor: number[] = [43, 45, 8, 561317, 4530821];

  SIAProposalsAllowedStates: number[] = [
    17, 21, 5, 18, 30, 33, 4, 1, 6, 38, 16, 23, 14, 11, 3, 2, 7, 28, 22, 24, 12,
    9, 19, 27, 34, 32, 10, 8, 15, 35, 31, 37, 36, 13, 29,
  ];
  signCertificateResponse: any;
  isStateAvailable: boolean = false;
  isDistrictAvailable: boolean = false;
  environment = environment;

  response: any[] = [];
  private _id: any;
  refId: any;
  constructor(
    private http: HttpClient,
    private location: Location,
    private ecBService: EnvionmentClearancePartBService,
    private fcAService: ForestClearancesService,
    private fcBService: FcBService,
    private userService: UserService,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private translateService: TranslateService,
    private edsQueryService: EdsQueryService,
    private injector: Injector,
    private msgService: MessageService,
    private sessionStorageService: SessionStorageService,
    private proponentApplicationService: ProponentApplicationService,
    private clearenceService: ClearenceService,
    private digitalSignatureService: DigitalSignatureService,
    private store: Store<AppState>,
    private okmService: OKMService,
    private fcSiteInspectionService : FcSiteInspectionReportService
  ) {
    this.store.pipe(select(isStateLoaded)).subscribe({
      next: (isStateLoaded) => (this.isStateAvailable = isStateLoaded),
    });
    this.store.pipe(select(isDistrictLoaded)).subscribe({
      next: (isDistrictLoaded) => (this.isDistrictAvailable = isDistrictLoaded),
    });
    this.store.pipe(select(selectAllProposalByProposalId)).subscribe({
      next: (response) =>
        (this.existedProposalsById = JSON.parse(JSON.stringify(response))),
    });
  }

  getStates(): Observable<any> {
    if (this.isStateAvailable) return this.store.pipe(select(selectState));
    this.store.dispatch(SharedActions.loadAllStates());

    return this.http.get<any>(`${environment.baseURL}${API.GET_STATE}`);
  }

  getDistrict(): Observable<any> {
    if (this.isDistrictAvailable)
      return this.store.pipe(select(selectDistrict));
    this.store.dispatch(SharedActions.loadAllDistrict());
    return this.http.get<any>(`${environment.baseURL}${API.GET_DISTRICT}`);
  }

  transformHtml = (value: any, isCertificate?: boolean, isString?: boolean) => {
    // value = this.sanitized.bypassSecurityTrustHtml(
    //   value
    //     ?.replaceAll(
    //       '<tr>',
    //       '<tr class="tr-border" style="border: 1px solid black;">'
    //     )
    //     ?.replaceAll(
    //       '<td ',
    //       '<td class="td-border" style="border: 1px solid black;"'
    //     )
    //     ?.replaceAll(
    //       '<td> ',
    //       '<td class="td-border" style="border: 1px solid black;">'
    //     )
    // );
    // if (isString) return value?.toString();
    return value;
  };

  commonDetailForm: CommonForm = new CommonForm();
  private commonDetail = new BehaviorSubject<CommonForm>(this.commonDetailForm);
  updatedCommonDetail = this.commonDetail.asObservable();
  enclosureSubject: Subject<boolean> = new Subject<boolean>();
  userSubject: Subject<any> = new Subject<any>();
  AirportSubject: Subject<boolean> = new Subject<boolean>();
  MiningSubject: Subject<boolean> = new Subject<boolean>();
  IrrigationHydel: Subject<boolean> = new Subject<boolean>();
  selectMultipleDropdownList$: Subject<any> = new Subject<any>();
  MiningMineralOil$: Subject<boolean> = new Subject<boolean>();
  getDataFromDraft: Subject<{ isCall: boolean; responseData: any }> =
    new Subject<{ isCall: boolean; responseData: any }>();
  saveDraftData: Subject<any> = new Subject<any>();
  eventsSubject: Subject<string> = new Subject<string>();
  proposalDataSubject: Subject<any> = new Subject<any>();
  submitCrzConditions: Subject<boolean> = new Subject<boolean>();

  fc2Abbr: string[] = FC_2_ABBR;
  fc3Abbr: string[] = FC_3_ABBR;
  fc4Abbr: string[] = FC_4_ABBR;
  sir: string[] = SIR_ABBR;
  complianceAbbr: string[] = COMPLIANCE_ABBR;
  workingPermissionAbbr: string[] = WORKING_PERMISSION_ABBR;
  factsheetAbbr: string[] = FC_FACTSHEET_ABBR;
  STIPULATED_CA_ABBR: string[] = STIPULATED_CA_ABBR;

  fcFormNames: string[] = FC_FORM_NAMES;

  public get loaderService(): LoaderService {
    if (!this._loaderService) {
      this._loaderService = this.injector.get(LoaderService);
    }
    return this._loaderService;
  }

  isLoading() {
    return this.loaderService.isLoading;
  }

  updateCommonDetail(detail: CommonForm) {
    this.commonDetail.next(detail);
  }

  //Openkm Start
  // getDocumentTypeByModuleID(moduleID: number, step: number) : Observable<GenericResponse>{
  // 	let param: HttpParams = new HttpParams();
  // 	param = param.append('Module Id', moduleID);
  // 	// param = param.append('Step', step);
  // 	var url = environment.baseURLWLC + API.GETDOCUMENT_BY_MODULEID;
  // 	return this.http.get<any>(url, { params: param });
  // }
  // publicDocument(data: string | Blob, obj: { documentId: any; displayName: any; keyword_details: { refDocumentId: number; keywords: any; }[]; validFrom: string; }) {
  // 	const header: any = this._global.getHeaderMultiPart();
  // 	let formParams = new FormData();
  // 	formParams.append('file', data);
  // 	formParams.append('refDocDetails', JSON.stringify(obj));
  // 	var url =environment.baseURLKMLUpload + API.ADD_DOCUMENT_DETAILS;
  //   return this.http.post(url, formParams, { headers: header });
  // }

  getCountryCode(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_COUNTRY_CODE;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getProjectCategory(): Observable<any> {
    let url = environment.baseURLMis + API.GET_FC_PROJECT_CATEGORY;
    let param = new HttpParams().append('isactive', true);
    return this.http.get<any>(url, { params: param });
  }

  getExoticForm1Data(
    id: string,
    regId: string,
    process: string
  ): Observable<GenericResponse> {
    let url = `${environment.wlcBaseURL}parivesh/wlpas/getform1?id=${id}&regId=${regId}&process=${process}`;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getExoticForm1Pdf(
    id: string,
    regId: string,
    process: string
  ): Observable<GenericResponse> {
    let url = `${environment.wlcBaseURL}parivesh/wlpas/getform1download?id=${id}&regId=${regId}&process=${process}&conditions=test`;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getAdditionalInformation(ref_id: number): Observable<any> {
    let url =
      environment.baseURL +
      API.GET_ADDITIONAL_INFORMATION +
      '?ref_id=' +
      ref_id +
      '&is_special_document=' +
      false;
    // let param = new HttpParams();
    // param = param.append('ref_id', ref_id);
    // param = param.append('is_special_document', false);
    // console.log('PARAMS -> ', param);
    return this.http.post<any>(url, {});
  }

  downloadFile(
    uuid: string,
    refId: string,
    refType: string,
    docTypemappingId: string,
    version: any
  ) {
    let url = `${environment.baseURLKMLUpload}/okm/downloadDocument`;
    let param: HttpParams = new HttpParams();
    param = param.append('docTypemappingId', docTypemappingId);
    param = param.append('refId', refId);
    param = param.append('refType', refType);
    param = param.append('uuid', uuid);
    param = param.append('version', version);
    return this.http.get(url, { params: param, responseType: 'blob' });
  }

  getExoticForm1Doc(id: string): Observable<GenericResponse> {
    let url = `${environment.wlcBaseURL}parivesh/wlpas/getUploadedDoc?id=${id}`;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getDuplicateEmail(email: any, entType: string): Observable<GenericResponse> {
    let url = environment.baseURL + API.CHECK_DUPLICATE_EMAIL;
    const param = new HttpParams()
      .append('email', email.trim())
      .append('entityType', entType.trim());
    return this.http
      .get<GenericResponse>(url, { params: param })
      .pipe(catchError(this.handleError));
  }

  getStatePendancy(): Observable<GenericResponse> {
    let param: HttpParams = new HttpParams().append('days', 150);
    let url = environment.baseURLProcessEngine + API.STATE_REPORT;
    return this.http.post<any>(url, {}, { params: param });
  }

  getReportingReason(): Observable<GenericResponse> {
    let url = environment.wlcBaseURL + 'parivesh/wlpa-Common/reporting-reasons';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getAcqutionPurpose(): Observable<GenericResponse> {
    let url = environment.wlcBaseURL + 'parivesh/wlpa-Common/acquisition-modes';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }
  getScienceName(): Observable<GenericResponse> {
    let url = environment.wlcBaseURL + 'parivesh/wlpa-Common/master-exotic';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getModeOfAcquisition(): Observable<GenericResponse> {
    let url =
      environment.wlcBaseURL +
      'parivesh/wlpa-Common/species-aquisition-purpose';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getStateDepartment(code: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_STATEDEPARTMENT;
    return this.http
      .post<GenericResponse>(url, {}, { params: { code } })
      .pipe(catchError(this.handleError));
  }

  uploadDocument(proposalNo: any, body: any): Observable<GenericResponse> {
    const formData: FormData = new FormData();
    formData.append('multipartFile', body);

    let param: HttpParams = new HttpParams().append('proposalNo', proposalNo);
    let url = environment.baseURLAgendaMom + API.UPLOAD_DOC;
    return this.http.post<any>(url, formData, { params: param });
  }
  migrationDataSave(body: any): Observable<GenericResponse> {
    let url = environment.baseUrlLegacy + API.MIGRATION_DATA_SAVE;
    return this.http.post<any>(url, body);
  }

  saveImportantDocument(): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_IMPORTANT_DOCUMENT;
    return this.http
      .post<GenericResponse>(url, {})
      .pipe(catchError(this.handleError));
  }

  getDistrictsByStateCode(stateCode: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_DISTRICT_BY_CODE;
    let param = { state_code: stateCode };
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getIroStates(): Observable<GenericResponse> {
    return this.http
      .post<GenericResponse>(environment.baseURL + API.GET_IRO_STATE, {})
      .pipe(catchError(this.handleError));
  }

  getSubDistrict(code: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_SUB_DISTRICT;
    return this.http
      .post<GenericResponse>(
        url,
        {},
        {
          params: { district_code: code },
        }
      )
      .pipe(catchError(this.handleError));
  }

  getVillage(code: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_VILLAGE;
    return this.http
      .post<GenericResponse>(
        url,
        {},
        {
          params: { sub_district_code: code },
        }
      )
      .pipe(catchError(this.handleError));
  }

  getVillageBySubDistrictCode(body: number[]): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_VILLAGES;
    return this.http
      .post<GenericResponse>(url, body)
      .pipe(catchError(this.handleError));
  }

  getRegistrationType(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_REGISTRATION_TYPE;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getEntityType(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_ENTITY_TYPE;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getActivation(token: string): Observable<GenericResponse> {
    let url = environment.baseURL + `${API.VERIFY_EMAIL}?token=${token}`;
    return this.http.get<GenericResponse>(url);
  }

  private handleError(err: HttpErrorResponse) {
    let errMsg: string = '';
    if (err.error instanceof Error) {
      errMsg = err.error.message;
    } else if (typeof err.error === 'string') {
      errMsg = 'A connection to back end can not be established.';
    } else {
      errMsg = err.error.message;
    }
    return throwError(errMsg);
  }

  getCafDetailsByProposalNo(
    proposal_no: string,
    searchBy?: string
  ): Observable<any> {
    if (this.existedProposalsById[proposal_no])
      return of({ data: this.existedProposalsById[proposal_no] });
    let param;
    if (searchBy == 'file_no') {
      param = { file_no: proposal_no };
    } else {
      param = { proposal_no };
    }
    return this.http
      .post<GenericResponse>(
        environment.baseURL + API.GET_CAF_DETAIL_BY_PROPOSAL_NO,
        {},
        { params: param }
      )
      .pipe(
        tap((response) =>
          this.store.dispatch(
            SharedActions.addProposalByProposalIdNumber({
              proposal: response?.data,
            })
          )
        )
      );
  }

  getApplicationName(clearence: string): Observable<GenericResponse> {
    let url = environment.baseURL + API.APPLICATION_NAME;
    let param = new HttpParams().append('Id', clearence);
    return this.http.post<any>(url, {}, { params: param });
  }

  getProposalDocument(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_PROPOSAL_DOCUMENT;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, {}, { params: param });
  }

  saveDraft(body: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.SAVE_DRAFT,
      body
    );
  }

  getFormDataForCompare(formId: any, applicationId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_FORM_DATA_FOR_COMPARE,
      {},
      { params: { formId, applicationId } }
    );
  }

  getFormDataForCompareWithStep(formId: any, applicationId: any, stepId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_FORM_DATA_FOR_COMPARE_WITH_STEP,
      {},
      { params: { formId, applicationId, stepId } }
    );
  }

  getCurrentVersion(param: {
    applicationId: any;
    cafID: string;
    formId: string;
  }) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_CURRENT_VERSION,
      {},
      { params: param }
    );
  }

  getAllVersion(param: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_ALL_VERSION,
      {},
      { params: param }
    );
  }

  sendOtp(mobile: number, email: any, messageType?: any) {
    return this.http.post<GenericResponse>(
      environment.baseURLEngine + API_OTP.SEND_OTP,
      {},
      {
        params: {
          mobile,
          email,
          type: messageType || 'CHAIRMAN_OTP',
        },
      }
    );
  }

  updateUserMobile(mobile: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_OTP.UPDATE_USER_MOBILE,
      {
        mobile,
      }
    );
  }

  verifyOtp(mobile: number, otp: number) {
    return this.http.post<GenericResponse>(
      environment.baseURLEngine + API_OTP.VERIFY_OTP,
      {},
      { params: { mobile, otp } }
    );
  }

  getSectorDetails(ecId: any) {
    return new Promise<string>((resolve) => {
      this.ecBService.getFormDetails(ecId).subscribe({
        next: (response) => {
          let sectorEntity: any[] = [];
          response.data.environmentClearanceProjectActivityDetails.forEach(
            (item: any, index: number) => {
              if (item?.activity_type === ACTIVITY_TYPE.MAJOR) {
                sectorEntity =
                  response.data.environmentClearanceProjectActivityDetails[
                    index
                  ].activities.ecSectorFormDetails;
                if (sectorEntity && sectorEntity.length) {
                  switch (sectorEntity[0].form) {
                    case SECTOR_DETAIL.AIRPORT:
                      {
                        resolve('/airport-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CONSTRUCTION:
                      {
                        resolve('/construction-details');
                      }
                      break;

                    case SECTOR_DETAIL.MINING:
                      {
                        resolve('/mining-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.INDUSTRY:
                      {
                        resolve('/industry-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.TDSF:
                      {
                        resolve('/tsdf-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CBWTF:
                      {
                        resolve('/cbwtf-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CEPT:
                      {
                        resolve('/cept-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CMSWMF:
                      {
                        resolve('/cmswmf-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.BUILDING:
                      {
                        resolve('/building-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.RIVER:
                      {
                        resolve('/river-valley-proposals');
                      }
                      break;
                  }
                } else {
                  resolve('/enclosures');
                }
              }
            }
          );
        },
      });
    });
  }

  getFcSectorDetails(fcId: any) {
    return new Promise<string>((resolve) => {
      this.fcAService.getFcDetails(fcId).subscribe({
        next: (response: any) => {
          let projectCategory = response.data.project_activity_id;
          switch (projectCategory) {
            case PROJECT_CATEGORY.AIRPORT:
              {
                resolve('/enclosures');
              }
              break;

            case PROJECT_CATEGORY.MINING:
              {
                resolve('/enclosures');
              }
              break;

            case PROJECT_CATEGORY.IRRIGATION:
              {
                resolve('/enclosures');
              }
              break;

            case PROJECT_CATEGORY.HYDEL:
              {
                resolve('/enclosures');
              }
              break;
            case PROJECT_CATEGORY.DRINKING_WATER:
              {
                resolve('/enclosures');
              }
              break;
            case PROJECT_CATEGORY.MINING_MINERAL_OIL:
              {
                resolve('/enclosures');
              }
              break;
            default: {
              resolve('/additional-information');
            }
          }
        },
      });
    });
  }

  getLoggerData() {
    return new Promise<any>((resolve) => {
      this.userService.getLoggedInUserAPI().subscribe({
        next: (response: any) => {
          resolve(response);
        },
        error: () => resolve(false),
      });
    });
  }

  getFcBSectorDetails(fcId: any) {
    return new Promise<string>((resolve) => {
      this.fcBService.getFcFormbProjectDetails(fcId, 5).subscribe({
        next: (response: any) => {
          let projectCategory = response.data?.project_category_code;
          switch (projectCategory) {
            case PROJECT_CATEGORY.MINING:
              {
                resolve('/category-specific-details');
              }
              break;
            default: {
              resolve('/additional-information');
            }
          }
        },
      });
    });
  }

  getTextWithoutHtml(textWithHtml: string) {
    let div: HTMLDivElement = document.createElement('div');
    div.innerHTML = textWithHtml;
    return div.textContent || div.innerText || '';
  }

  copyToClipBoard(textWithHtml: string) {
    let div: HTMLDivElement = document.createElement('div');
    div.innerHTML = textWithHtml;

    this.clipboard.copy(div.textContent || div.innerText || '');
    this.messageService.showSuccess(
      this.translateService.instant('generic.copy_to_clipboard')
    );
  }

  back() {
    if (window.history.length == 1) {
      window.close();
    } else {
      this.location.back();
    }
  }

  getOldProposalBySession() {
    return sessionStorage.getItem('is_for_old_proposal');
  }

  getObjectDifference = (a: any, b: any) => {
    let diff = this.isArray(a) ? [] : {};
    this.compare(a, b, diff);
    return diff;
  };

  compare = (a: any, b: any, node: any) => {
    for (let prop in a) {
      if (typeof b[prop] == 'undefined') {
        this.addNode(prop, 'REMOVED', node);
      } else if (JSON.stringify(a[prop]) != JSON.stringify(b[prop])) {
        if (typeof b[prop] != 'object' || b[prop] == null) {
          this.addNode(prop, b[prop], node);
        } else {
          if (this.isArray(b[prop])) {
            this.addNode(prop, [], node);
            this.compare(a[prop], b[prop], node[prop]);
          } else {
            this.addNode(prop, {}, node);
            this.compare(a[prop], b[prop], node[prop]);
          }
        }
      }
    }
  };

  addNode = (prop: any, value: any, parent: any) => {
    parent[prop] = value;
  };

  isArray = (obj: any) => {
    return Object.prototype.toString.call(obj) === 'Array';
  };

  getIPAddress() {
    return this.http.get<any>('http://api.ipify.org/?format=json');
  }

  getAllSectors(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_LIST_OF_SECTOR;
    return this.http.get<GenericResponse>(url);
  }

  //crz
  getProposalHistory(applicationId: any): Observable<any> {
    // let params = new HttpParams().append('application_id', applicationId);
    var url = `${environment.baseURLProcessEngine}/process/getProcessHis?application_id=${applicationId}`;
    return this.http.post<any>(url, {});
  }

  getCafDetailsByProposalId(proposalId: string): Observable<any> {
    var url = `${environment.baseURL}/proponentApplicant/getCafDataByProposalNo?proposal_id=${proposalId}`;
    // let param = new HttpParams().append('proposal_id', proposalId);
    return this.http.post<any>(url, {});
  }

  getFcApprovalDates(proposalId: string): Observable<any> {
    var url = `${environment.baseURL}/proponentApplicant/getFcApprovalDates?proposalNo=${proposalId}`;
    // let param = new HttpParams().append('proposal_id', proposalId);
    return this.http.post<any>(url, {});
  }

  getParentApplicationByProposalId(proposalId: string): Observable<any> {
    // var url = `${environment.baseURL}/proponentApplicant/getCafDataByProposalNo?proposal_id=${proposalId}`;
    let url = environment.baseURL + API.GET_PARENT_APPLICATON_BY_ID;
    // let param = new HttpParams().append('proposal_id', proposalId);
    return this.http.post<any>(url, {}, { params: { proposalId } });
  }

  saveHistoryWise(body: any): Observable<any> {
    var url = `${environment.baseURLProcessEngine}/process/getHistoryWise`;
    return this.http.post(url, body);
  }

  updateApplicationStatusForEDS(
    app_history_id: any,
    action: string,
    app_id: any
  ): Observable<any> {
    var url = `${environment.baseURL}/crz/updateApplicationStatusForEDS?application_id=${app_id}&app_history_id=${app_history_id}&status=${action}`;
    return this.http.post<any>(url, {});
  }

  raiseEds(body: any, clearance_id: any) {
    var url = `${environment.baseURL}/edsV2/saveEDS?clearance_id=${clearance_id}`;
    return this.http.post(url, body);
  }

  process(body: any): Observable<any> {
    var url = `${environment.baseURLProcessEngine}/process/addHistory`;
    return this.http.post(url, body);
  }

  getEDSQueriesForCRZ(applicationId: any): Observable<any> {
    var url = `${environment.baseURL}/edsV2/getQueries?application_id=${applicationId}`;
    return this.http.post(url, {});
  }

  submitEdsQueries(body: any, clearance_id: string) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.SUBMIT_EDS_QUERIES,
      body,
      { params: { clearance_id } }
    );
  }

  getProposalsForNodalFC(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_NODAL_PROPOSAL_FC;
    return this.http.post<GenericResponse>(url, {});
  }

  getProposalsForNodal(filterData?: any): Observable<GenericResponse> {
    let url = environment.baseURLProcessEngine + API.GET_NODAL_PROPOSAL;
    let param;
    if (filterData == true || filterData == 'true') {
      param = new HttpParams().append('filter', true);
    }
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  getDfaHistory(proposal_id: any, clearenceId: any, type: any) {
    return this.http.get<GenericResponse>(
      environment.baseURL + API.GET_DFA_VERSIONS,
      {
        params: {
          proposal_id,
          clearenceId,
          type,
        },
      }
    );
  }

  viewSpecificCertificate(certId: any, clearenceId: any, type: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.GET_DFA_SPECIFIC_CERTIFICATE,
      {},
      {
        params: {
          certId,
          clearenceId,
          type,
        },
      }
    );
  }

  getFcProposals(response: any, historyData: any[], proposal?: any) {
    return new Promise<any[]>((resolve) => {
      
      let divisionData: any[] = [];
      let data = [...response?.data];
      console.log("response",data);
      data?.forEach((outerItem: any, index: number) => {
        //console.log("OuterItem",outerItem,index);
        
        outerItem?.historyWiseWithApplications?.forEach((innerItem: any) => {
          if (this.fc2Abbr?.includes(innerItem?.abbr)) {
            
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Part II',
              order: 2,
            });
  
            if (!divisionData.length) {
              divisionData.push({
                ...this.getData(outerItem, innerItem),
                name: 'Part II',
                order: 2,
                isDivisionData: true,
              });
            } else {
              if (
                divisionData.some(
                  (item: any) =>
                    item.office_id == innerItem.office_id &&
                    item.office_name == innerItem.office_name
                )
              ) {
                divisionData.forEach((item: any, index: number) => {
                  if (
                    new Date(item.date_of_filling).getTime() -
                    new Date(innerItem.date_of_filling).getTime() >
                    0 &&
                    item.office_id == innerItem.office_id &&
                    item.office_name == innerItem.office_name
                  ) {
                    divisionData.splice(index, 1, {
                      ...this.getData(outerItem, item),
                      name: 'Part II',
                      order: 2,
                      isDivisionData: true,
                    });
                  } else if (
                    item.office_id == innerItem.office_id &&
                    item.office_name == innerItem.office_name
                  ) {
                    divisionData.splice(index, 1, {
                      ...this.getData(outerItem, innerItem),
                      name: 'Part II',
                      order: 2,
                      isDivisionData: true,
                    });
                  }
                });
              } else {
                divisionData.push({
                  ...this.getData(outerItem, innerItem),
                  name: 'Part II',
                  order: 2,
                  isDivisionData: true,
                });
              }
            }
          } else if (this.fc3Abbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Part III',
              order: 3,
            });
          } else if (this.fc4Abbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Part IV',
              order: 4,
            });
          } else if (this.sir?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'SIR',
              order: 5,
            });
          } else if (this.factsheetAbbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Factsheet',
              order: 7,
            });
          } else if (this.complianceAbbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Compliance',
              order: 8,
            });
          } else if (this.workingPermissionAbbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Working Permission',
              order: 9,
            });
          } else if (this.STIPULATED_CA_ABBR?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Stipulated CA',
              order: 9,
            });
          }
        });
        
        if (data?.length == index + 1) {
          this.sortData(historyData);
          let result = historyData.reduce(
            (accumulator: any, currentValue: any) => {
              accumulator[currentValue.name] =
                accumulator[currentValue.name] || [];
              accumulator[currentValue.name].push(currentValue);
              return accumulator;
            },
            Object.create(null)
          );

          let final: any[] = [];

          for (let item in result) {
            final.push({
              name: item,
              items: result[item],
            });
          }

          historyData = [];

          final.forEach((item: any, index: number) => {
            if (item?.items?.length) historyData[index] = item?.items[0];
          });

          this.fcFormNames.forEach((item: any, index: number) => {            
            if (item != historyData[index]?.name) {
              historyData.splice(index, 0, { name: item });
            }
          });

          let edsQueryTabExists = historyData.find(
            (item: any) => item?.name == 'Eds Query History'
          );
          if (!edsQueryTabExists) {
            historyData.push({
              name: 'Eds Query History',
              proposal_no: 'EDS',
              filled_by: null,
              last_submission_date: null,
              order: 10,
            });
          }

          if (divisionData?.length > 0) {
            historyData.splice(1, 1, ...divisionData);
          }

          this.sortData(historyData);

          resolve(historyData);
        }
      });
    });
  }

  getEdsQueryData(proposal: any) {
    return new Promise<boolean>((resolve) => {
      this.edsQueryService
        .getQueries(proposal?.proponentApplications?.id)
        .subscribe({
          next: (response) => {
            resolve(!!response?.data?.length);
          },
          error: () => resolve(false),
        });
    });
  }

  docToBase64(data: any = {}) {
    return new Promise((resolve, reject) => {
      this.okmService
        .downloadDocument(
          data.uuid,
          data.version,
          data.document_mapping_id,
          data.ref_id,
          data.type
        )
        .subscribe((res: any) => {
          let result: any = '';
          let reader = new FileReader();
          reader.readAsDataURL(res);

          reader.onloadend = () => {
            result = reader.result;
          };

          console.log('Result', res, '????????', result);


          resolve(true);
          return result;
        });
    });
  }

  fileToBase64(array: any[]) {
    return new Promise((resolve) => {

      resolve('')

      
      // if (!array?.length) resolve('');

      // array
      //   .map((item: any, index: number) => ({ ...item, file: index + 1 }))
      //   .forEach((item: any, index: number) => {
      //     if (item?.uuid) {
      //       this.okmService
      //       .downloadDocument(
      //         item.uuid,
      //         item.version,
      //         item.document_mapping_id,
      //         item.ref_id,
      //         item.type
      //       )
      //       .subscribe((res: any) => {
      //         let response_object: any = {};
      //         let reader = new FileReader();
      //         reader.readAsDataURL(res);
      //         reader.onloadend = () => {
      //           response_object.file = item.file;
      //           response_object.base64 = reader.result;
      //           this.response.push(response_object);
      //         };
      //         if (index === array.length - 1) {
      //           resolve(this.response);
      //         }
      //       });
      //     }
      //   });
    });
  }

  sortData(historyData: any) {
    return historyData.sort((a: any, b: any) => a.order - b.order);
  }

  getData(outerItem: any, innerItem: any) {
    return {
      ...innerItem,
      proposal_no: outerItem?.proposal_no,
      filled_by: innerItem?.role_name,
      last_submission_date: this.getDisplayDateFormat(
        innerItem?.date_of_filling
      ),
    };
  }

  getDisplayDateFormat(date: string | Date): string {
    return date ? moment(date).format(DATE_FORMATS.DISPLAY_FORMAT) : '';
  }

  saveRecommendedArea(body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_RECOMMENDED_AREA;
    return this.http.post<GenericResponse>(url, body);
  }

  getRecommendedArea(fcId: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_RECOMMENDED_AREA;
    let param = new HttpParams().append('fc_id', fcId);
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  saveDocumentDetails(body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_PROPOSAL_DOCUMENT;
    return this.http.post<GenericResponse>(url, body);
  }

  deleteDocumentDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_PROPOSAL_DOCUMENT;
    return this.http.post<GenericResponse>(url, {}, { params: { Id: id } });
  }

  exportPDF(string: any): any {
    let url = 'http://stgdev.parivesh.nic.in/pdfutil/pdf/generateHtmlPdf';
    let param = new HttpParams().append('title', 'AAAAAAAAAaa');
    return this.http.post(url, string, { params: param, responseType: 'blob' });
  }

  addClassToTable(element: any, classValue: string) {
    let ele = document.createElement('div');
    ele.innerHTML = element;
    let tables: any = ele.getElementsByTagName('table');
    for (var i = 0; i < tables.length; i++) {
      tables[i].className = tables[i].className + classValue;
    }
    return ele.outerHTML;
  }

  updateSws(req_id: any, application_id: any, sws_id: any) {
    return this.http.post(
      environment.baseURL + API_SWS.UPDATE_APPLICATION,
      {},
      {
        params: { req_id, application_id, sws_id },
      }
    );
  }

  processHistoryFallBack(
    proposalNo: any,
    status: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.PROCESS_HISTORY_FALL_BACK;
    return this.http.post<GenericResponse>(
      url,
      {},
      { params: { proposalNo, status } }
    );
  }

  setProponentApplicationId(value: any) {
    this.sessionStorageService.set(PROPONENT_APPLICATION_ID, value);
  }

  checkSeiaaStatus(state: any) {
    return this.http.post(
      environment.baseURL + API.CHECK_SIEAA_STATUS,
      {},
      {
        params: { state },
      }
    );
  }

  processHistoryFall(proposalNo: any, status: string) {
    this.processHistoryFallBack(proposalNo, status).subscribe({
      next: (res: any) => {
        this.msgService.showError(
          this.translateService.instant('generic.fall_back')
        );
      },
      error: (err) => {
        this.msgService.showError(
          this.translateService.instant('generic.error')
        );
      },
    });
  }

  async esignFormDocument(refId: any, refType: any, file: any) {
    //console.log("param",refId,refType);
    
    try {
      const data: any = await firstValueFrom(
        this.proponentApplicationService.updateFormDocument(
          refId,
          refType,
          file
        )
      );
      //console.log("redirectUrl",window.location.href);

      const url = `${environment.frontendURL}#/aadhar-sign/sign?file_path=${data.data}&ref_id=${refId}&ref_type=${refType}&module=PP_FORM&redirect_url=${window.location.href}`;

      window.location.href = url;
    } catch (error) {
      console.log('error', error);
    }
  }

  async setESignedSirPath(refId: any, refType: any, file: any,siteInspectionBasicDetailsId:any){
    this._id = siteInspectionBasicDetailsId;
    this.refId = refId;
    try{
      const filePath : any = await firstValueFrom(
        this.proponentApplicationService.updateFormDocument(
          refId, refType,file
        )
      );
      console.log("file path",filePath);

      const url = `${environment.frontendURL}#/aadhar-sign/sign?file_path=${filePath.data}&ref_id=${refId}&ref_type=${refType}&sir_id=${siteInspectionBasicDetailsId}&module=PP_FORM&redirect_url=${window.location.href}`;
      //console.log("URL",url);
      
      window.location.href =  url;
      console.log("after esign1");
      
      let data =  await firstValueFrom(this.fcSiteInspectionService.getData());
      console.log("check data", data);
      
      // let response : any =  await firstValueFrom(this.fcSiteInspectionService.setEsignedPath(data,refId,siteInspectionBasicDetailsId))
      // console.log("response",response);
      
      
      
    }catch(error){
      console.log('error',error)
    }

  }

  async setData(data:any){
    await this.fcSiteInspectionService.setData(data);
    let response : any =  await firstValueFrom(this.fcSiteInspectionService.setEsignedPath(data,this.refId,this._id))
    console.log("response",response);
      }

  downloadDocumentByRef(path: any) {
    let data = this.fcSiteInspectionService.getData();
    console.log("data",data);
    
    this.clearenceService.downloadDocumentByPath(path).subscribe({
      next: (response) => {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = path;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      error: (err) => {
        console.log('error', err);
        this.messageService.showError(
          'Something went wrong while downloading the file for e-sign.'
        );
      },
    });
  }

  async identifyExemptedScenario(
    area: any,
    isLinear: any,
    exemptedCategory: any,
    isViolation: any
  ) {
    try {
      if (
        isViolation.toLowerCase() == 'no' &&
        (exemptedCategory == EXEMPTED_PARAMS.EXEMPTED_CATEGORY.INFRA ||
          exemptedCategory ==
          EXEMPTED_PARAMS.EXEMPTED_CATEGORY.SECURITY_RELATED_INFRA)
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.DEF_SCENARIO;
      } else if (
        (exemptedCategory ==
          EXEMPTED_PARAMS.EXEMPTED_CATEGORY.DEFENCE_RELATED_INFRA_LWE_PUBLIC ||
          exemptedCategory ==
          EXEMPTED_PARAMS.EXEMPTED_CATEGORY
            .DEFENCE_RELATED_INFRA_LWE_DEFENCE) &&
        area <= 5 &&
        isViolation?.toLowerCase() == 'no'
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.DEF_SCENARIO;
      } else if (
        exemptedCategory ==
        EXEMPTED_PARAMS.EXEMPTED_CATEGORY.ROAL_RAIL_AMINITIES &&
        area <= 0.1 &&
        isLinear?.toLowerCase() == 'yes' &&
        isViolation?.toLowerCase() == 'no'
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.SCENARIO_1_C;
      }
      return '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  async eSignNoteWithDSC() {
    try {
      let response: any = '';
      // Check if dsc is live
      await this.digitalSignatureService.checkDigitalSignatureIsLive();

      // Check if dsc is already registered in the system
      const { body } = await firstValueFrom(
        this.digitalSignatureService.getUserCertificateList()
      );

      // if (isEmpty(body?.data)) {
      //Get list of certificates
      const certificateList = await firstValueFrom(
        this.digitalSignatureService.checkCertificateList()
      );

      const digitalSignerCeriticateList = await firstValueFrom(
        this.digitalSignatureService.getDigitalSignerCertificateList({
          transactionId: null,
          serialNumber: null,
          filePath: null,
          xmlResponse: certificateList,
        })
      );

      const digitalSignerCeriticate = digitalSignerCeriticateList[0];

      // Register certificate
      const registeredCertificate = await firstValueFrom(
        this.digitalSignatureService.registerDigitalSignature({
          transactionId: null,
          serialNumber: digitalSignerCeriticate?.serialNumber,
          filePath: null,
          xmlResponse: certificateList,
        })
      );

      if (registeredCertificate.status == '1') {
        // Sign certificate
        await this.getSignedCertificate(registeredCertificate);

        // Verify the registered certificate
        const verifiedCertificate = await firstValueFrom(
          this.digitalSignatureService.verifyRegister({
            transactionId: registeredCertificate?.transactionId,
            serialNumber: null,
            filePath: null,
            xmlResponse: this.signCertificateResponse,
          })
        );

        if (verifiedCertificate?.status == '1') {
          this.messageService.showSuccess(verifiedCertificate?.msg);
          const { body } = await firstValueFrom(
            this.digitalSignatureService.getUserCertificateList()
          );

          response = body?.data?.find((item: any) => item.active == true);
          return response;
        } else {
          throw verifiedCertificate;
        }
      } else {
        throw registeredCertificate;
      }
      // } else {
      //   //Get list of certificates
      //   const certificateList = await firstValueFrom(
      //     this.digitalSignatureService.checkCertificateList()
      //   );

      //   response = body?.data?.find((item: any) => item.active == true);
      //   return response;
      // }
    } catch (error) {
      this.msgService.showError(
        this.translateService.instant(
          'DSC Sign Failed. Please try after sometime. Make sure the DSC device is connected properly.'
        )
      );
      console.log(error);
      return '';
    }
  }

  getSignedCertificate(registeredCertificate: any) {
    return new Promise<any>((resolve) => {
      this.digitalSignatureService
        .signerService(registeredCertificate.xmlResponse)
        .subscribe({
          next: (res) => {
            this.signCertificateResponse = res;
            resolve(res);
          },
          error: (err) => resolve(err),
        });
    });
  }

  getselectedrole(role: any) {
    let roleName = ['Project Proponent', 'Employee', 'Consultant'];
    return !roleName.includes(role);
  }

  async identifySurveyExemptedScenario(
    isSurveyingProposal: any,
    noOfTreesFelling: any,
    noOfBoreholes: any,
    boreholesDiameter: any,
    noOfShortBoreholes: any,
    shortBoreholesDiameter: any
  ) {
    try {
      if (
        isSurveyingProposal?.toLowerCase() == 'yes' &&
        noOfTreesFelling <= 100 &&
        noOfBoreholes <= 25 &&
        boreholesDiameter <= 4 &&
        noOfShortBoreholes <= 80 &&
        shortBoreholesDiameter <= 6.5
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.FORM_C_DEF_SCENARIO;
      } else if (
        isSurveyingProposal?.toLowerCase() == 'yes' &&
        ((noOfBoreholes <= 25 && boreholesDiameter <= 4) ||
          (noOfShortBoreholes <= 80 && shortBoreholesDiameter <= 6.5))
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.SCENARIO_1_A;
      }
      return '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  htmlCodeCleaner(html: any, isCertificate?: boolean) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, 'text/html');

    let body = doc.querySelectorAll('body');
    body.forEach(function (b, i) {
      b.innerHTML = b.innerHTML.replace(/&nbsp;/g, ' ');
      b.setAttribute('style', 'word-break: break-all !important;');
    });

    if (!isCertificate) {
      let images = doc.getElementsByTagName('img');
      let l = images.length;
      for (let i = 0; i < l; i++) {
        images[0].parentNode!.removeChild(images[0]);
      }
    }

    let temp_node = parser.parseFromString(html, 'text/html');
    let editor = doc.querySelectorAll('app-quill-editor');
    editor.forEach(function (g, i) {
      g.innerHTML = g.innerHTML
        .replace(/[^\x00-\x7F]/g, '')
        .replace(/\s+/g, '')
        .replace(/(?! )\s/g, '')
        .replace(/&nbsp;/g, '')
        .trim();
      let all_div = g.querySelectorAll('div');
      all_div.forEach(function (e, i) {
        e.setAttribute(
          'style',
          e.getAttribute('style') + 'word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
      let all_p = g.querySelectorAll('p');
      all_p.forEach(function (e, i) {
        e.setAttribute(
          'style',
          e.getAttribute('style') + 'word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });

      let all_ul = g.querySelectorAll('ul');
      all_ul.forEach(function (e, i) {
        e.setAttribute('style', 'word-break: break-all !important;');
        e.innerHTML = e.innerHTML.trim();
      });
      let all_li = g.querySelectorAll('li');
      all_li.forEach(function (e, i) {
        e.setAttribute('style', 'word-break: break-all !important;');
        e.innerHTML = e.innerHTML.trim();
      });
      let editorDiv = document.createElement('div');
      editorDiv.setAttribute('class', 'app-quill-editor');
      editorDiv.innerHTML = g.innerHTML
        .replace(/[^\x00-\x7F]/g, '')
        .replace(/\s+/g, '')
        .replace(/(?! )\s/g, '')
        .replace(/&nbsp;/g, '')
        .trim();
      let val = g.getAttribute('style');
      editorDiv.setAttribute(
        'style',
        (val == null ? '' : val) +
        'width:100% !important;word-break: break-all !important;'
      );
      var parent = g.parentNode;
      if (parent != null) {
        parent.replaceChild(editorDiv, g);
      }
    });

    let all_figure = doc.querySelectorAll('figure');
    all_figure.forEach((f, i) => {
      this.addBorderToTables(doc, isCertificate);

      let newNode = document.createElement('div');
      newNode.innerHTML = f.innerHTML;
      let val = f.getAttribute('style');
      newNode.setAttribute('style', val == null ? '' : val);
      let parent = f.parentNode;
      if (parent != null) {
        parent.replaceChild(newNode, f);
      }
    });

    if (!all_figure?.length) this.addBorderToTables(doc, isCertificate);
    // let body_element = doc.getElementsByTagName('body')[0];
    // return body_element.innerHTML; //doc.documentElement.innerHTML;
    let str: any = doc.documentElement.innerHTML.toString();
    return str
      ?.replaceAll('<p style="margin:0px"> </p>', '<br>')
      ?.replaceAll('<p> </p>', '<br>')
      ?.replaceAll('<p>&nbsp;</p>', '<br>')
      ?.replaceAll('&lt;', '<')
      ?.replaceAll('&gt;', '>');
  }

  addBorderToTables = (doc: any, isCertificate?: boolean) => {
    if (!isCertificate) {
      let all_table = doc.querySelectorAll('table');
      all_table.forEach((e: any) => {
        e.setAttribute('border', '1px');
        e.setAttribute('cellspacing', '0');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; border-collapse: collapse !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });

      let all_tr = doc.querySelectorAll('tr');
      all_tr.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; margin: 3px !important'
        );
        e.innerHTML = e.innerHTML.trim();
      });

      let all_th = doc.querySelectorAll('th');
      all_th.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
      let all_td = doc.querySelectorAll('td');
      all_td.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
    } else {
      let all_th = doc.querySelectorAll('.table-border th');
      all_th.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; padding: 3px !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
      let all_td = doc.querySelectorAll('.table-border td');
      all_td.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; padding: 3px !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
    }
  };

  getStateName = (states: any[], code: number, key?: string): string =>
    states.find((item) => item.value == code)?.label;

  getDistrictName = (
    districtByState: any[],
    code: number,
    key?: string
  ): string => districtByState.find((item) => item.value == code)?.label;

  chnageKMLplotsArray(data: any = []) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        'Toposheet No': ele?.toposheet_no,
        'State/UT': ele?.state,
        District: ele?.district,
        'Sub District': ele?.sub_District,
        Village: ele?.village,
        'Plot/Survey/Khasra No.': [ele?.plot_no],
      });
    });
    return array;
  }

  changePartAProductDetails(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        NameOfProduct: ele?.product_name,
        ProductOrByProduct: ele?.product_by,
        QuantityCapacity: ele?.proposed_quantity,
        Unit: ele?.unit,
        ModeOfTransportTransmission: ele?.transport_mode,
        Remarks: ele?.remarks,
      });
    });
    return array;
  }

  changeConstructionArray(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        NameOfProduct: ele?.product_name,
        ProductOrByProduct: ele?.product_by,
        QuantityCapacity: ele?.proposed_quantity,
        Unit: ele?.unit,
        ModeOfTransportTransmission: ele?.transport_mode,
        Remarks: ele?.remarks,
      });
    });
    return array;
  }

  changeStramCrossing(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        temporarily: ele?.temporary_permanent,
        permanently: ele?.temporary_permanent,
        remarks: ele?.remarks,
      });
    });
    return array;
  }

  changePollutionDetailsSource(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        source: ele?.pollution_source,
        pollutants: ele?.pollutant,
        mitigationMeasures: ele?.mitigation_measures,
      });
    });
    return array;
  }

  changeWasteGenerationType(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        name: ele?.name,
        source: ele?.source,
        quantityTPA: ele?.quantity,
        modeOfDisposal: ele?.mode_of_disposal,
        modeOfTransport: ele?.mode_of_transport,
      });
    });
    return array;
  }

  chnagePublicHearingDetails(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        Venue: 'NULL',
        DateOfAdvertisementOfPH: ele?.date_of_advertisment,
        State: ele?.state_name,
        District: ele?.district_name,
        DistanceFromProjectBoundaryInKm: ele?.distance,
        CopyOfProceedings: ele?.public_hearing_copy?.document_name,
        NumberOfPeopleAttended: ele?.no_of_people_attended,
        DesignationOfPresidingOfficer: ele?.designation_of_presiding,
      });
    });
    return array;
  }

  changeMajorIssuesRaised(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        Issue: ele?.major_issues_raised,
        Response: ele?.pp_response,
        AddressedInFinalEIA_EMP: ele?.is_final_eia_addressed,
        ReferenceOfFinalEIA_EMP: ele?.final_eia_reference,
      });
    });
    return array;
  }

  changeToArray(data: any = [], type: string = '') {
    if (type == 'ACTIVITY')
      data.map((ele: any) => {
        ele['activityName'] =
          ele['activities']['item_no'] + ' ' + ele['activities']['name'];
        ele['subActivityName'] = ele['subActivities']['name'];
      });

    console.log('data', data);
    return data;
  }

  filteredArray(data: any = [], propoerty: any = '', value: any = '') {
    let fltrData = [];
    // if (type == 'EC_RESOURCE') {
    fltrData = data.filter((ele: any) => ele[propoerty] == value);
    // }

    console.log('???????', value);
    console.log('fltr Data', fltrData, '????????', data);

    return fltrData;
  }

  processArrayValues(data: any = []) {
    return data;
  }

  showData(data: any = [], fields: string[]) {
    return data.map((item: { [x: string]: string; }) => {
      const processedItem: any = {};
      fields.forEach(field => {
        processedItem[field] = item[field] || '';  // Default to '' if undefined or null
      });
      console.log("Processed DAta", processedItem);
      return processedItem;
    });
  }

  getJsonParsedValue = (value: string) => value ? JSON.parse(value) : null;

  mapper(data: any, type: string = '') {
    console.log('?????????', data);

    let body: any;

    let CAF: any = {
      'Name of the Project': data?.cafData?.project_name,
      'Project Proposal For': data?.cafData?.proposal_for,
      'Whether proposal expansion is made under 7 (ii) (a)?':
        data?.cafData?.seven_to_a,
      'Project ID (single Window Number)': data?.cafData?.project_sw_no,
      'Description of Project': data?.cafData?.project_description,
      'Legal Status of the Company/Organization/User Agency':
        data?.cafData?.organization_legal_status,
      'Name of the Company/Organization/User Agency':
        data?.cafData?.organization_name,
      'Address': data?.cafData?.organization_street,
      'Village /Town / City': data?.cafData?.organization_city,
      'State': data?.cafData?.organisationState?.name,
      'District': data?.cafData?.organization_district,
      'Pin Code': data?.cafData?.organization_pincode,
      'Landmarks': data?.cafData?.organization_landmark,
      'E-mail address': data?.cafData?.organization_email,
      'Landline Number': data?.cafData?.organization_landline_no,
      'Mobile number': data?.cafData?.organization_mobile_no,

      'Name' : data?.cafData?.applicant_name,
      'Designation': data?.cafData?.applicant_designation,
      'Address (Correspondence address)': data?.cafData?.applicant_street,
      'Village /Town / City (Correspondence address)':
        data?.cafData?.applicant_city,
      'State (Correspondence address)': data?.cafData?.applicantState?.name,
      'District (Correspondence address)': data?.cafData?.applicantDistrict?.name,
      'Pin Code (Correspondence address)': data?.cafData?.applicant_pincode,
      'Landmarks (Correspondence address)': data?.cafData?.applicant_landmark,
      'E-mail address (Correspondence address)': data?.cafData?.applicant_email,
      'Landline Number (Correspondence address)':
        data?.cafData?.applicant_landline_no,
      'Mobile number (Correspondence address)':
        data?.cafData?.applicant_mobile_no,
      'Upload KML': data?.cafData?.cafKML[0]?.caf_kml,
      'Whether the project/activity falling in the state/UT sharing international borders':
        data?.cafData?.cafLocationOfKml?.falling_border_in_states,
      'Aerial Distance from the International Border/LOC/LAC in km':
        data?.cafData?.cafLocationOfKml?.international_border_distance,
      'Shape of the Project': data?.cafData?.cafLocationOfKml?.shape_of_project,
      'Land Requirement (in Ha) of the project or activity': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Nature of Land involved in (Ha)',
            value: 'nature',
          },
          {
            label: 'Area Existing in Ha [X]',
            value: 'existing_area',
          },
          {
            label: 'Additional Area Proposed in Ha [Y]',
            value: 'additonal_area',
          },
          {
            label: 'Total Area required after expansion in Ha [X+Y]',
            value: 'total_area',
          },
        ],
        data: [
          {
            nature: 'Non-Forest Land [A]',
            existing_area:
              data?.cafData?.cafLocationOfKml?.existing_forest_land,
            additonal_area:
              data?.cafData?.cafLocationOfKml?.existing_non_forest_land,
            total_area: data?.cafData?.cafLocationOfKml?.existing_total_land,
          },
          {
            nature: 'Forest Land [B]',
            existing_area:
              data?.cafData?.cafLocationOfKml?.additional_forest_land,
            additonal_area:
              data?.cafData?.cafLocationOfKml?.additional_non_forest_land,
            total_area: data?.cafData?.cafLocationOfKml?.additional_total_forest_land,
          },
          {
            nature: 'Total Land [A+B]',
            existing_area: data?.cafData?.cafLocationOfKml?.total_forest_land,
            additonal_area: data?.cafData?.cafLocationOfKml?.total_forest_land,
            total_ares: data?.cafData?.cafLocationOfKml?.total_land,
          },
        ],
      },
      projectCost: {
        'Total Cost of the Project at current price level (in Lakhs)':
          data?.cafData?.cafProjectActivityCost?.total_cost,
      },
      'Cost of Existing Project at current price level (in lakhs)[A]':
        data?.cafData?.cafProjectActivityCost?.total_existing_cost,
      'Cost of the proposed expansion/modernization of Project at current price level (in Lakhs) [B]':
        data?.cafData?.cafProjectActivityCost?.total_expension_cost,
      'No. of permanent employment (No.s)[A]':
        data?.cafData?.cafProjectActivityCost?.cp_permanent_employment,
      'Period of employment (No of Days)[B]':
        data?.cafData?.cafProjectActivityCost?.cp_employment_period,
      'Temporary/Contractual employment (No of Man Days)[Y]':
        data?.cafData?.cafProjectActivityCost?.cp_temp_man_of_days,
      'During operational phase': {
        type: 'RELATIONAL_DATA',
        headers: [
          { label: '', value: 'params' },
          { label: 'Existing', value: 'existing' },
          { label: 'Proposed', value: 'proposed' },
        ],
        data: [
          {
            params: 'No. of permanent employment (No.s) [A]',
            existing:
              data?.cafData?.cafProjectActivityCost?.existing_forest_land,
            proposed:
              data?.cafData?.cafProjectActivityCost?.proposed_forest_land,
          },
          {
            params: 'Period of employment (No. of days) [B]',
            existing:
              data?.cafData?.cafProjectActivityCost
                ?.op_existing_employment_period,
            proposed:
              data?.cafData?.cafProjectActivityCost
                ?.op_proposed_employment_period,
          },
          {
            params: 'Temporary / Contractual employment (No. of Man days) [Y]',
            existing:
              data?.cafData?.cafProjectActivityCost
                ?.op_existing_temp_man_in_days,
            proposed:
              data?.cafData?.cafProjectActivityCost
                ?.op_proposed_temp_man_in_days,
          },
        ],
      },
      'No. of Villages ': data?.cafData?.cafOthers?.no_of_villages,
      'No. of Project Displaced Families ':
        data?.cafData?.cafOthers?.no_of_project_displaced_families,
      'No. of Project Affected Families ':
        data?.cafData?.cafOthers?.no_of_project_affected_families,
      'Status of Rehabilitation & Resettlement ':
        data?.cafData?.cafOthers?.rnr_status,
      'Whether project area involves shifting of watercourse/road/rail/Transmission line/water pipeline, etc. required? ':
        data?.cafData?.cafOthers?.is_required_warehouse_shifting,
      Details: data?.cafData?.cafOthers?.warehouse_shifting_description,
      'Brief justification on selected site particularly highlighting environmental considerations':
        data?.cafData?.cafOthers?.cafViolations
          ?.enviroment_consideration_justification,
      'Upload copy of note containing justification for locating in the selected site ':
        data?.cafData?.cafOthers?.alternative_site_order_copy,
      'Whether there is any Government Order or Policy/ Court order relevant or restricting to the site? ':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          { label: 'Details of Order/Policy ', value: 'detail_of_order' },
          { label: 'Upload copy', value: 'government_order_copy' },
        ],
        data: data?.cafData?.cafOthers?.cafGovernmentOrders,
      },
      'Whether there is any litigation pending against the project and/or land in which the project is proposed to be set up?':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          { label: 'Court Name', value: 'court_name' },
          { label: 'Bench ', value: 'court_bench_name' },
          { label: 'Case Category ', value: 'case_category' },
          { label: 'Case status', value: 'case_status' },
          { label: 'Order Direction', value: 'court_direction_description' },
          { label: 'Order Copy', value: 'litigation_order_copy' },
        ],
        data: data?.cafData?.cafOthers?.cafLitigations,
      },
      'Whether the proposal involves violation of Act/Rule/Regulation/Notification of Central/State Government? ':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          { label: 'Act', value: 'violation_act' },
          { label: 'From', value: 'violation_year' },
          { label: 'To', value: 'violation_year_TO' },
          { label: 'Direction Issued By	', value: 'direction_issued_by' },
          { label: 'Direction Details	', value: 'direction_details' },
          { label: 'Direction Copy', value: 'violation_direction_copy' },
          { label: 'Summary', value: 'violation_summary' },
          { label: 'Report', value: 'violation_report' },
        ],
        data: data?.cafData?.cafOthers?.cafViolations,
      },
      
    };

    if (type == 'CRZ_FRESH_PROPOSAL_FORM') {
      body = {
        CAF,
        crzFreshProposalForm: {
          'CRZ Classification of Project Location': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'CRZ Classification of Project Location',
                value: 'project_location',
              },
              { label: 'Type of Project', value: 'type_of_projects' },
            ],
            data: data?.crzFresh?.crzLocationOfProjects,
          },

          'Type of Coast': {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Type Of Coast', value: 'project_located_in' }],
            data: data?.crzFresh?.crzLocationDetails,
          },

          'Clause of CRZ notification under which the project is a permissible/ regulated activity':
            data?.crzFresh?.crzProjectDetails?.clause_of_crz_notification,
          'Whether CRZ Map Indicating HTL, LTL Demarcation in 1:4000 Scale Prepared?':
            data?.crzFresh?.crzProjectDetails?.crz_map_indicating,
          'Upload CRZ map indicating HTL, LTL demarcation in 1:4000 scale prepared':
            data?.crzFresh?.crzProjectDetails?.upload_crz_map_HTL,
          'Reason (Crz Map Indicating Reason)':
            data?.crzFresh?.crzProjectDetails?.crz_map_indicating_reason,
          'Whether Project Layout Superimposed on CRZ Map 1:4000 Scale?':
            data?.crzFresh?.crzProjectDetails
              ?.project_layout_superimposed_on_crz,
          'Upload project layout superimposed on CRZ map 1:4000 scale':
            data?.crzFresh?.crzProjectDetails?.upload_project_superimposed_crz,
          'Reason (Project Layout)':
            data?.crzFresh?.crzProjectDetails
              ?.project_layout_superimposed_on_crz_reason,
          'Whether CRZ Map 1:25000 Covering 7 km Radius Around Project Site Prepared?':
            data?.crzFresh?.crzProjectDetails?.crz_map,
          'Upload CRZ Map 1:25000 covering 7 km radius around project site prepared ':
            data?.crzFresh?.crzProjectDetails?.crz_map_site_project,
          'Reason (CRZ Map)': data?.crzFresh?.crzProjectDetails?.crz_map_reason,
          'Whether CRZ Map Indicating CRZ-I, II, III and IV Including Other Notified ESAs Prepared?':
            data?.crzFresh?.crzProjectDetails?.crz_map_indicating_crz,
          'Upload CRZ map indicating CRZ-I, II, III and IV including other notified ESAs prepared':
            data?.crzFresh?.crzProjectDetails?.upload_crz_map_notified_esa,
          'Reason (CRZ Map Indicating CRZ Reason)':
            data?.crzFresh?.crzProjectDetails?.crz_map_indicating_crz_reason,
          'Distance of project (in meters) from HTL':
            data?.crzFresh?.crzProjectDetails?.distance_of_project,
          'Agency prepared CRZ maps':
            data?.crzFresh?.crzProjectDetails?.agency_crz_map,
          'Date of report':
            data?.crzFresh?.crzProjectDetails?.agency_date_of_report,
          'Upload report of authorized agencies on CRZ mapping':
            data?.crzFresh?.crzProjectDetails
              ?.agency_upload_report_authorised_agency,
          'Details of forest/ Mangroves Land Involved, if Any':
            data?.crzFresh?.crzProjectDetails?.details_of_forest,
          'No. of Trees Cut for the Project':
            data?.crzFresh?.crzProjectDetails?.details_of_tree_cutting,
          'Details of tree cutting':
            data?.crzFresh?.crzProjectDetails?.details_of_tree_cutting_copy,
          'Compensatory afforestation plan':
            data?.crzFresh?.crzProjectDetails?.compensatory_afforestation_plan,
          'Distance of Proposed Project from ESA/Marine Park (in KM)':
            data?.crzFresh?.crzProjectDetails?.distance_of_proposed_project,
          'NOC from State Pollution Control Boards Obtained':
            data?.crzFresh?.crzProjectDetails?.noc_from_state_pollution,
          'Copy of NOC': data?.crzFresh?.crzProjectDetails?.copy_of_noc,
          'Compliance of the conditions imposed in the NOC':
            data?.crzFresh?.crzProjectDetails?.compliance_of_condition_copy,
          'Reason (Noc from State Pollution Board)':
            data?.crzFresh?.crzProjectDetails?.noc_from_state_pollution_reason,

          'Whether EIA (Terrestrial) Studies carried out?':
            data?.crzFresh?.crzOtherDetails?.eia_studies_carried_out,
          'Summary Details of EIA (Terrestrial) Studies':
            data?.crzFresh?.crzOtherDetails?.summary_details_of_eia,
          'Upload Recommendation made in EIAs':
            data?.crzFresh?.crzOtherDetails?.upload_eias,
          'Period of Study from (EIA Terrestrial)':
            data?.crzFresh?.crzOtherDetails?.period_of_study_from_eia,
          'Period of Study to (EIA Terrestrial)':
            data?.crzFresh?.crzOtherDetails?.period_of_study_to_eia,
          'Reason thereof (Not Carried Out)':
            data?.crzFresh?.crzOtherDetails?.give_reason_not_carried_out,
          'Reason thereof (Not Applicable)':
            data?.crzFresh?.crzOtherDetails?.give_reason_not_applicable,
          'Whether EIA (Marine)Studies carried out?':
            data?.crzFresh?.crzOtherDetails?.eia_marine_studies_carried_out,
          'Summary Details of EIA (Marine) Studies':
            data?.crzFresh?.crzOtherDetails?.summary_details_of_eia_marine,
          'Upload Recommendation made in EIAs (Marine)':
            data?.crzFresh?.crzOtherDetails?.upload_eias_marine,
          'Period of Study from (EIA Marine)':
            data?.crzFresh?.crzOtherDetails?.period_of_study_from_marine_eia,
          'Period of Study to (EIA Marine)':
            data?.crzFresh?.crzOtherDetails?.period_of_study_to_marine_eia,
          'Reason thereof (Not Carried Out (Marine))':
            data?.crzFresh?.crzOtherDetails?.give_reason_marine_not_carried_out,
          'Reason thereof (Not Applicable (Marine))':
            data?.crzFresh?.crzOtherDetails?.give_reason_marine_not_applicable,
          'Whether Disaster Management Plan/Risk Assessment Report/National Oil Spill Disaster Contingency Plan is Prepared?':
            data?.crzFresh?.crzOtherDetails?.disaster_management_plan,
          'Upload Disaster Management Plan/National Oil Spill Disaster Contingency Plan':
            data?.crzFresh?.crzOtherDetails?.upload_disaster_management_plan,
          'Reason thereof (Disaster Management Plan)':
            data?.crzFresh?.crzOtherDetails?.upload_reason_thereof,
          'Whether Project involving discharge of liquid effluents?':
            data?.crzFresh?.crzOtherDetails?.discharge_liquid_effluents,
          'Capacity of Sewage Treatment Plant (STP) (in kilo litre per day)':
            data?.crzFresh?.crzOtherDetails?.capacity_of_stp,
          'Quantity of effluent generated (in kilo litre)':
            data?.crzFresh?.crzOtherDetails?.quantity_of_effluent_generated,
          'Quantity of effluent treated (in kilo litre)':
            data?.crzFresh?.crzOtherDetails?.quantity_of_effluent_treated,
          'Method of Treatment and Disposal':
            data?.crzFresh?.crzOtherDetails?.method_of_treatment_and_disposal,
          'Whether project involving discharge of solid waste?':
            data?.crzFresh?.crzOtherDetails?.discharge_solid_waste,

          'CRZ Solid Wastes': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Type of Waste', value: 'type_of_waste' },
              {
                label: 'Quantity of Solid Waste generated (Kg/day)',
                value: 'quantity_of_solid_waste_generated',
              },
              { label: 'Mode of Disposal', value: 'mode_of_disposal' },
            ],
            data: data?.crzFresh?.crzSolidWastes,
          },

          'Water Requirements': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Source', value: 'source' },
              { label: 'Quantity', value: 'quantity' },
            ],
            data: data?.crzFresh?.crzWaterRequirements,
          },

          'Whether project involves water treatment and/ or recycling?':
            data?.crzFresh?.crzOtherDetails?.project_water_treatment,
          'Quantity of Waste Water Generated (KLD)':
            data?.crzFresh?.crzOtherDetails?.quantity_of_waste_water,
          'Treatment Capacity (KLD)':
            data?.crzFresh?.crzOtherDetails?.treatment_capacity,
          'Quantity of Treated Water Used in Recycling/Reuse (KLD)':
            data?.crzFresh?.crzOtherDetails?.quantity_of_treated_water,
          'Quantity of effluent to be disposed (KLD)':
            data?.crzFresh?.crzOtherDetails?.quantity_of_treated_water,

          'Mode of Disposal': {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Mode of Disposal', value: 'mode_of_disposal' }],
            data: data?.crzFresh?.crzDisposals,
          },

          'Whether rainwater harvesting system proposed/ planned?':
            data?.crzFresh?.crzOtherDetails?.project_rainwater_harvest,
          'No. of Storage': data?.crzFresh?.crzOtherDetails?.no_of_storage,
          'Capacity (KLD)': data?.crzFresh?.crzOtherDetails?.capacity_storage,
          'No. of Recharge Pits':
            data?.crzFresh?.crzOtherDetails?.number_of_recharge_pits,
          'Capacity (Recharge Pits (KLD))':
            data?.crzFresh?.crzOtherDetails?.capacity_recharge,
          'Whether Ground water withdrawal approval from CGWA/ SGWA obtained':
            data?.crzFresh?.crzOtherDetails?.ground_water_withdrawal,
          'Whether Ground Water Intersection will be there?':
            data?.crzFresh?.crzOtherDetails?.ground_water_intersection,
          'Copy of CGWA/ SGWA Letter':
            data?.crzFresh?.crzOtherDetails?.copy_of_central_ground_water,
          'Measures Proposed':
            data?.crzFresh?.crzOtherDetails?.measures_proposed,
          'percentage of Saving':
            data?.crzFresh?.crzOtherDetails?.energy_saving_percentage,
          'SCZMA recommendations available?':
            data?.crzFresh?.crzOtherDetails?.is_sczma_recommendation_available,
          'Copy of recommendation':
            data?.crzFresh?.crzOtherDetails?.copy_of_sczma,
          'Action plan on conditions imposed by SCZMA':
            data?.crzFresh?.crzOtherDetails?.action_plan_consitions_SCZMA,
          'Whether proposal attracts EIA Notification, 2006':
            data?.crzFresh?.crzOtherDetails?.proposal_attracts_eia_notification,
          'Status of proposal for EC':
            data?.crzFresh?.crzOtherDetails?.status_of_proposal_for_ec,
          'Social and environmental issues and mitigations measures suggested including but not limited to R&R, Water, Air, Hazardous Wastes, Ecological aspects, etc. (Brief Details to be Provided)':
            data?.crzFresh?.crzOtherDetails?.social_environmental_issues,
          'Benefits of the project':
            data?.crzFresh?.crzOtherDetails?.benefits_of_the_project,
          // 'Additional Documents': {
          //   type: 'RELATIONAL_DATA',
          //   headers: [
          //     { label: 'Document Name', value: 'document_name' },
          //     { label: 'Remarks', value: 'remarks_additional_information' },
          //     { label: 'Document', value: 'upload_additional_information' },
          //   ],
          //   data: data?.additionalDocuments,
          // },
        },
      };
      console.log(data)
      console.log("Name of Project CAF", data?.cafData?.project_name);
      console.log("Sate data",data?.cafData?.organisationState?.name,data?.cafData?.organization_district,data?.cafData?.organization_pincode);
      
      
      return body;
    }

    if (type == 'CRZ_TRANSFER_PROPOSAL_FORM') {
      body = {
        CAF,
        crzTransferProposalForm: {
          'Proposal No.': data?.crzTransfer?.proposal_no,
          'Category of the Project': data?.crzTransfer?.project_category,
          'CRZ Area Classification': data?.crzTransfer?.crz_classification,
          'Present status of the project':
            data?.crzTransfer?.present_status_project,
          'Whether project involve discharge of effluent, solid waste, sewage, and others':
            data?.crzTransfer,
          'Name of the Company / Organization/User agency ':
            data?.crzTransfer?.company_name,
          'Upload copy of state/UT pollution control board NOC ':
            data?.crzTransfer?.upload_pollution_control_board,
          'Upload copy of latest consent order ':
            data?.crzTransfer?.upload_cto_applicable,
          'Door/ House No, Street Number and Name':
            data?.crzTransfer?.company_house,
          'Village /Town / City ': data?.crzTransfer?.company_village,
          State: data?.crzTransfer?.company_state,
          District: data?.crzTransfer?.company_district,
          'Pin Code': data?.crzTransfer?.company_pincode,
          Landmarks: data?.crzTransfer?.company_landmark,
          'E-mail address': data?.crzTransfer?.company_email,
          'Landline Number': data?.crzTransfer?.company_landline,
          'Mobile Number': data?.crzTransfer?.company_mobile,
          'Legal Status of the Company / Organisation / User Agency ':
            data?.crzTransfer?.company_legal_status,
          'Name of the Company / Organization/User agency':
            data?.crzTransfer?.transferer_company_name,
          'Door/ House No, Street Number and Name ':
            data?.crzTransfer?.transferer_company_house,
          'Village /Town / City': data?.crzTransfer?.transferer_company_village,
          'State(Transferee)': data?.crzTransfer?.transferer_company_state,
          'District(Transferee)':
            data?.crzTransfer?.transferer_company_district,
          'Pin Code(Transferee)': data?.crzTransfer?.transferer_company_pincode,
          'Landmarks(Transferee)':
            data?.crzTransfer?.transferer_company_landmark,
          'E-mail address(Transferee)':
            data?.crzTransfer?.transferer_company_email,
          'Landline Number(Transferee)':
            data?.crzTransfer?.transferer_company_landline,
          'Mobile Number(Transferee)':
            data?.crzTransfer?.transferer_company_mobile,
          'Legal Status of the Company / Organisation / User Agency':
            data?.crzTransfer?.transferer_company_legal_status,
          'Name of the applicant ': data?.crzTransfer?.applicant_name,
          Designation: data?.crzTransfer?.applicant_designation,
          'Door/ House No, Street Number and Name(Applicant)':
            data?.crzTransfer?.applicant_house,
          'Village /Town / City(Applicant)':
            data?.crzTransfer?.applicant_village,
          'State(Applicant)': data?.crzTransfer?.applicant_state,
          'District(Applicant)': data?.crzTransfer?.applicant_district,
          'Pin Code(Applicant)': data?.crzTransfer?.applicant_pincode,
          'Landmarks(Applicant)': data?.crzTransfer?.applicant_landmark,
          'E-mail address(Applicant)': data?.crzTransfer?.applicant_email,
          'Landline Number(Applicant)': data?.crzTransfer?.applicant_landline,
          'Mobile Number(Applicant)': data?.crzTransfer?.applicant_mobile,
          'Upload KML': data?.crzTransfer?.crzCafKML?.caf_kml,
          'Plot / Survey / Khasra No. ': data?.crzTransfer?.organization_plot,
          Pincode: data?.crzTransfer?.organization_pincode,
          'File No.': data?.crzTransfer?.crzTransferProposalDetails?.fileNo,
          'Pincode(Organization)': data?.crzTransfer?.organization_pincode,
          'File No.(Organization)':
            data?.crzTransfer?.crzTransferProposalDetails?.fileNo,
          'Date of grant of Crz Clearance ':
            data?.crzTransfer?.crzTransferProposalDetails?.grantDate,
          'Date of end of validity of Crz Clearance ':
            data?.crzTransfer?.crzTransferProposalDetails?.validityEndDate,
          'Upload CRZ Clearance letter':
            data?.crzTransfer?.crzTransferProposalDetails?.grantLetter,
          'Copy of Affidavit by Transferor':
            data?.crzTransfer?.crzTransferEnclosureDetailsV2
              ?.transferorAffidavitCopy,
          'Copy of Affidavit by Transferee':
            data?.crzTransfer?.crzTransferEnclosureDetailsV2
              ?.transfereeAffidavitCopy,
          'Reason for Transfer (Attach Copy of Justification)':
            data?.crzTransfer?.crzTransferEnclosureDetailsV2?.transferReason,
          // 'Details of Additional information': {
          //   type: 'RELATIONAL_DATA',
          //   headers: [
          //     { label: 'Document Name', value: 'document_name' },
          //     { label: 'Remarks', value: 'remarks_additional_information' },
          //     { label: 'Document', value: 'upload_additional_information' },
          //   ],
          //   data: data?.additionalDocuments,
          // },
        },
      };

      return body;
    }

    if (type == 'CRZ_VALIDITY_EXTENTION') {
      body = {
        CAF,
        crzValidityEXtentionForm: {
          'Proposal No.': data?.crzValidityExtention?.proponentApplications?.proposal_no,
          'Project Name': data?.crzValidityExtention?.project_name,
          'Reference File No. of SCZMA': data?.crzValidityExtention?.file_number_sczma,
          'Reference File No. of MoEFCC/ SEIAA': data?.crzValidityExtention?.file_number_moefcc,
          'Reason for seeking extension of validity of the CRZ Clearance ': data?.crzValidityExtention?.reason_seeking_extension_validity,
          'Upload substantiating the reasons of the delay in implementation, if any': data?.crzValidityExtention?.delay_in_implemetation_document,
          'Date of issue of Prior CRZ clearance': data?.crzValidityExtention?.date_of_issue_crz_clearence,
          'Validity Up to ': data?.crzValidityExtention?.validity_up_to,
          'Issuing Authority ': data?.crzValidityExtention?.issuing_authority,
          'Copy of CRZ letter ': data?.crzValidityExtention?.copy_of_crz_letter,
          'Upload latest six-monthly monitoring compliance report': data?.crzValidityExtention?.compliance_report,
          'CRZ Classification of Project Location': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'CRZ Classification of Project Location',
                value: 'project_location',
              },
              { label: 'Type of Project', value: 'type_of_projects' },
            ],
            data: data?.crzValidityExtention?.crzLocationOfProjects,
          },

          'Type of Coast': {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Type Of Coast', value: 'project_located_in' }],
            data: data?.crzValidityExtention?.crzLocationDetails,
          },
          'Clause of CRZ notification under which the project is a permissible/ regulated activity': data?.crzValidityExtention?.proponentApplications?.clause_of_crz_notification,
          'Whether CRZ Map Indicating HTL, LTL Demarcation in 1:4000 Scale Prepared? ': data?.crzValidityExtention?.crzImplementation?.crz_map_indicating,
          'Upload CRZ map indicating HTL, LTL demarcation in 1:4000 scale prepared ': data?.crzValidityExtention?.crzImplementation?.upload_crz_map_HTL,
          'Whether Project Layout Superimposed on CRZ Map 1:4000 Scale? ': data?.crzValidityExtention?.crzImplementation?.clause_of_crz_notification,
          'Upload project layout superimposed on CRZ map 1:4000 scale': data?.crzValidityExtention?.crzImplementation?.clause_of_crz_notification,
          'Whether CRZ Map Indicating CRZ-I, II, III and IV Including Other Notified ESAs Prepared? ': data?.crzValidityExtention?.crzImplementation?.crz_map_indicating_crz,
          'Upload CRZ map indicating CRZ-I, II, III and IV including other notified ESAs prepared': data?.crzValidityExtention?.crzImplementation?.crz_map_site_project,
          'Distance of project (in meters) from HTL': data?.crzValidityExtention?.crzImplementation?.distance_of_project,
          'Agency prepared CRZ maps ': data?.crzValidityExtention?.crzImplementation?.agency_crz_map,
          'Date of report': data?.crzValidityExtention?.crzImplementation?.agency_date_of_report,
          'Upload report of authorized agencies on CRZ mapping': data?.crzValidityExtention?.crzImplementation?.agency_upload_report_authorised_agency,
          'Details of forest/ Mangroves Land Involved, if Any': data?.crzValidityExtention?.crzImplementation?.details_of_forest,
          'No. of Trees Cut for the Project': data?.crzValidityExtention?.crzImplementation?.details_of_tree_cutting,
          'Details of tree cutting ': data?.crzValidityExtention?.crzImplementation?.details_of_tree_cutting_copy,
          'Compensatory afforestation plan ': data?.crzValidityExtention?.crzImplementation?.distance_of_project,
          'Distance of Proposed Project from ESA/Marine Park (in KM)': data?.crzValidityExtention?.crzImplementation?.distance_of_proposed_project,
          'Copy of NOC ': data?.crzValidityExtention?.crzImplementation?.copy_of_noc,
          'Compliance of the conditions imposed in the NOC ': data?.crzValidityExtention?.crzImplementation?.compliance_of_condition_copy,
          'Whether EIA/ EMP studies has been carried out? ': data?.crzValidityExtention?.crzImplementation?.eia_emp_studies_carried_out,
          'Upload full EIA/ EMP report ': data?.crzValidityExtention?.crzImplementation?.upload_eias_emp,
          'Whether EIA (Terrestrial) Studies carried out?': data?.crzValidityExtention?.crzImplementation?.eia_studies_carried_out,
          'Summary Details of EIA (Terrestrial) Studies': data?.crzValidityExtention?.crzImplementation?.summary_details_of_eia,
          'Upload Recommendation made in EIAs ': data?.crzValidityExtention?.crzImplementation?.upload_eias,
          'Period of Study from (EIA Terrestrial)': data?.crzValidityExtention?.crzImplementation?.period_of_study_from_eia,
          'Period of Study to (EIA Terrestrial)': data?.crzValidityExtention?.crzImplementation?.period_of_study_to_eia,
          'Whether EIA (Marine)Studies carried out? ': data?.crzValidityExtention?.crzImplementation?.eia_marine_studies_carried_out,
          'Summary Details of EIA (Marine) Studies': data?.crzValidityExtention?.crzImplementation?.summary_details_of_eia_marine,
          'Upload Recommendation made in EIAs': data?.crzValidityExtention?.crzImplementation?.upload_eias_marine,
          'Period of Study from (EIA Marine)': data?.crzValidityExtention?.crzImplementation?.period_of_study_from_marine_eia,
          'Period of Study to (EIA Marine)': data?.crzValidityExtention?.crzImplementation?.period_of_study_to_marine_eia,
          'Whether Disaster Management Plan/Risk Assessment Report/National Oil Spill Disaster Contingency Plan is Prepared? ': data?.crzValidityExtention?.crzImplementation?.disaster_management_plan,
          'Upload Disaster Management Plan/National Oil Spill Disaster Contingency Plan ': data?.crzValidityExtention?.crzImplementation?.upload_disaster_management_plan,
          'Whether Project involving discharge of liquid effluents? ': data?.crzValidityExtention?.crzImplementation?.discharge_liquid_effluents,
          'Capacity of Sewage Treatment Plant (STP) (in KLD)': data?.crzValidityExtention?.crzImplementation?.capacity_of_stp,
          'Quantity of effluent generated (in Kilo litre)': data?.crzValidityExtention?.crzImplementation?.quantity_of_effluent_generated,
          'Quantity of Effluent Treated (in KLD)': data?.crzValidityExtention?.crzImplementation?.quantity_of_effluent_treated,
          'Method of Treatment and Disposal': data?.crzValidityExtention?.crzImplementation?.method_of_treatment_and_disposal,
          'Whether project involving discharge of solid waste?': data?.crzValidityExtention?.crzImplementation?.discharge_solid_waste,
          'Discharge of solid waste?': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Type of Waste ',
                value: 'type_of_projects'
              },
              {
                label: 'Quantity of Solid Waste generated (Kg/day)',
                value: 'quantity_of_solid_waste_generated',
              },
              {
                label: 'Mode of Disposal',
                value: 'mode_of_disposal',
              },
            ],
            data: data?.crzValidityExtention?.crzSolidWastes,
          },
          'Details of Water Requirement': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Source',
                value: 'source'
              },
              {
                label: 'Quality (KLD)',
                value: 'quantity',
              },
            ],
            data: data?.crzValidityExtention?.crzWaterRequirements,
          },
          'Whether project involves water treatment and/ or recycling? ': data?.crzValidityExtention?.crzImplementation?.project_water_treatment,
          'Quantity of Waste Water Generated (KLD)': data?.crzValidityExtention?.crzImplementation?.quantity_of_waste_water,
          'Treatment Capacity (KLD)': data?.crzValidityExtention?.crzImplementation?.treatment_capacity,
          'Quantity of Treated Water Used in Recycling/Reuse (KLD)': data?.crzValidityExtention?.crzImplementation?.quantity_of_treated_water,
          'Quantity of effluent to be disposed (KLD)': data?.crzValidityExtention?.crzImplementation?.quantity_of_treated_water,
          'Mode of Disposal': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Mode of Disposal',
                value: 'mode_of_disposal'
              }
            ],
            data: data?.crzValidityExtention?.crzDisposals,
          },
          'Whether rainwater harvesting system proposed/ planned? ': data?.crzValidityExtention?.crzImplementation?.project_rainwater_harvest,
          'No. of Storage': data?.crzValidityExtention?.crzImplementation?.no_of_storage,
          'Capacity Storage (KLD)': data?.crzValidityExtention?.crzImplementation?.capacity_storage,
          'No. of Recharge Pits': data?.crzValidityExtention?.crzImplementation?.number_of_recharge_pits,
          'Capacity Recharge (KLD)': data?.crzValidityExtention?.crzImplementation?.capacity_recharge,
          'Whether Ground water withdrawal approval from CGWA/ SGWA obtained ': data?.crzValidityExtention?.crzImplementation?.ground_water_withdrawal,
          'Whether Ground Water Intersection will be there?': data?.crzValidityExtention?.crzImplementation?.ground_water_intersection,
          'Copy of CGWA/ SGWA Letter': data?.crzValidityExtention?.crzImplementation?.copy_of_central_ground_water,
          'Measures Proposed ': data?.crzValidityExtention?.crzImplementation?.measures_proposed,
          'Percentage of Saving': data?.crzValidityExtention?.crzImplementation?.energy_saving_percentage,
          'SCZMA recommendations available? ': data?.crzValidityExtention?.crzImplementation?.is_sczma_recommendation_available,
          'Copy of recommendation ': data?.crzValidityExtention?.crzImplementation?.copy_of_sczma,
          'Action plan on conditions imposed by SCZMA ': data?.crzValidityExtention?.crzImplementation?.action_plan_consitions_SCZMA,
          'Whether proposal attracts EIA Notification, 2006 ': data?.crzValidityExtention?.crzImplementation?.proposal_attracts_eia_notification,
          'Status of proposal for EC ': data?.crzValidityExtention?.crzImplementation?.status_of_proposal_for_ec,
          'Social and environmental issues and mitigations measures suggested including but not limited to R&R, Water, Air, Hazardous Wastes, Ecological aspects, etc. (Brief Details to be Provided) ': data?.crzValidityExtention?.crzImplementation?.social_environmental_issues,
          'Benefits of the project ': data?.crzValidityExtention?.crzImplementation?.benefits_of_the_project,
          // 'Details of Additional information': {
          //   type: 'RELATIONAL_DATA',
          //   headers: [
          //     { label: 'Document Name', value: 'document_name' },
          //     { label: 'Remarks', value: 'remarks_additional_information' },
          //     { label: 'Document', value: 'upload_additional_information' },
          //   ],
          //   data: data?.additionalDocuments,
          // },
        },
      };

      return body;
    }

    if (type == 'FC_FORM_A') {
      //   const hepProjectConfig = data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json
      // ? JSON.parse(data.fcFormA.forestClearanceRiverValleyProject.hep_project_configuration_json)
      // : null;

      const hepProjectConfig = this.getJsonParsedValue(data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json);
      const hepProjectCapacity = this.getJsonParsedValue(data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json);
      const irrigationProjectConfig = this.getJsonParsedValue(data?.fcFormA?.forestClearanceRiverValleyProject?.irrigation_project_configuration_json);

      body = {
        CAF,
        fcFormA: {
          'State': data?.fcFormA?.proponentApplications?.state,
          'Upload a copy of note containing justification for locating the Project in forest land':
            data?.fcFormA?.locating_project_justification,
          'Project Category': data?.fcFormA?.project_category_id,
          'Exempted Category': data?.fcFormA?.exempted_category,
          'Is Related to Encroachment?': data?.fcFormA?.is_encrochment,
          'Whether any proposal seeking prior approval of Central Government under the Van (Sanrakshan Evam Samvardhan) Adhiniyam, 1980 for diversion of forest land required for this project has been submitted in the past?':
            data?.fcFormA?.is_any_prior_approval,
          'Status of the proposal': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Status of the proposal', value: 'proposal_status' },
              { label: 'Proposal No./ SW No. / CDN No ', value: 'proposalNo' },
              { label: 'Project Name  ', value: 'proposalName' },
              { label: 'MoEFCC File No. ', value: 'moefccFileNo' },
              {
                label: 'Area proposed for Diversion (ha.) ',
                value: 'proposedDiversionArea',
              },
              {
                label: 'Area Diverted (Ha) ',
                value: 'divertedArea',
              },
              {
                label: 'Area recommended for Diversion (ha.)',
                value: 'divertedArea',
              },
              {
                label: 'Date of in-principle approval',
                value: 'inPrincipleApprovalDate',
              },
              { label: 'Date of final approval', value: 'approvalDate' },
              { label: 'Date of Application', value: 'applicationDate' },
            ],
            data: data?.fcFormA?.priorApprovals,
          },

          'Underground Component':
            data?.fcFormA?.forestProposedLand?.underground_component,
          'Total area of forestland proposed for diversion (ha.)':
            data?.fcFormA?.forestProposedLand?.total_proposed_diversion_area,
          'Total area of non- forestland required for this project (ha.)':
            data?.fcFormA?.forestProposedLand
              ?.total_non_forestland_area_required,
          'Legal Status of forest land proposed for diversion': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Legal Status of Forest Land ',
                value: 'forest_land_legal_status',
              },
              { label: 'Forest Area (ha)', value: 'forest_land' },
            ],
            data: data?.fcFormA?.forestProposedLand?.forestClearanceLegalStatus,
          },
          'Total Area (ha)' : data?.fcFormA?.forestProposedLand?.total_area_of_kmls,
          'Total period for which the forestland is proposed to be diverted (No. of years)':
            data?.fcFormA?.forestProposedLand?.total_proposed_diversion_period,
          'Details of forestland proposed for diversion': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Division', value: 'division' },
              { label: 'Upload KML ', value: 'kml' },
              { label: 'Number of patches ', value: 'total_patches' },
            ],
            data: data?.fcFormA?.forestClearanceProposedDiversions,
          },

          'Total Forest Land Area (ha) Patch-wise': //undefined
            data?.fcFormA?.forestClearanceProposedDiversions[0]
              ?.forestClearanceDivisionPatchDetails[0]?.area,
          'Total Non Forest Land Area (ha) Patch-wise':
            data?.fcFormA?.forestClearanceProposedDiversions[0]
              ?.forestClearanceDivisionPatchDetails[0]?.nfl_area,
          'Total Forest Land Area (ha) KML-wise': // uindefined
            data?.fcFormA?.forestClearanceProposedDiversions[0]
              ?.total_forest_land_for_division,
          'Total Non Forest Land Area (ha) KML-wise':
            data?.fcFormA?.forestClearanceProposedDiversions[0]
              ?.total_non_forest_land_for_division,
            'Total Area' : data?.fcFormA?.total_area_of_kmls,
          'Component Wise Break Up': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Component', value: 'underground_component' },
              {
                label: 'Forest Land Proposed for Diversion (ha)',
                value: 'total_forest_land',
              },
              { label: 'Non-forest Land (ha)', value: 'total_non_forest_land' },
            ],
            data: data?.fcFormA?.forestProposedLand,
          },
          'Upload map of the forest land proposed to be diverted prepared by using DGPS or Total Station (pdf only) ':
            data?.fcFormA?.forestProposedLand?.geo_referenced_map,

          'Whether Compensatory Afforestation is applicable or not?':
            data?.fcFormA?.fcAforestationDetails
              ?.is_applicable_compensatory_afforestation,
          'Type of Compensatory Afforestation':
            data?.fcFormA?.fcAforestationDetails?.comp_afforestation_type,
          'Whether Non-forest land is available?':
            data?.fcFormA?.fcAforestationDetails?.is_non_forest_land,
           // 'Patch wise details': data?.fcFormA?.forestClearancePatchKmls, // getting object object
          'Patch Wise Details' : data?.fcFormA?.forestClearancePatchKmls?.forestClearancePatchKmlDetails,
          'Number of patches':
            data?.fcFormA?.fcAforestationDetails?.total_patches,
          'No. of districts involved for raising Compensatory Afforestation':
            data?.fcFormA?.fcAforestationDetails
              ?.total_districts_involved_in_ca,
          'Total CA Land': data?.fcFormA?.fcAforestationDetails?.ca_land,
          'Upload a scanned copy of the map of the land identified for creation of Compensatory Afforestation prepared by using GPS or Total Station (pdf only)':
          data?.fcFormA?.fcAforestationDetails?.identified_land_for_compensatory_afforestaion,
          'Whether the area of non-forest land or Revenue forest land required to be provided by User Agency for raising Compensatory Afforestation is less than area of forest land proposed to be diverted?':
            data?.fcFormA?.fcAforestationDetails
              ?.is_ua_land_smaller_from_proposed,
          'Reasons thereof':
            data?.fcFormA?.fcAforestationDetails?.ua_land_smaller_reason,
          'Area of non-forest or Revenue forest land required to be provided by User Agency for raising Compensatory Afforestation (in ha)':
            data?.fcFormA?.fcAforestationDetails?.ua_land_area,
          'Whether NFL is free from Non- Encumbrance':
            data?.fcFormA?.fcAforestationDetails?.is_nfl_free,
          'Whether the present owner of NFL is different from the User Agency':
            data?.fcFormA?.fcAforestationDetails?.present_owner_nfl,
          'Upload MoU/agreement executed between the present owner and the user agency':
            data?.fcFormA?.fcAforestationDetails?.copy_of_mou,

          'Whether Cost-Benefit analysis for the Project has been made?':
            data?.fcFormA?.fcOthersDetail?.is_cost_analyzed,
          'Cost-Benefit Reasons thereof':
            data?.fcFormA?.fcOthersDetail?.cost_not_analyzed_reason,
          'Total Loss (Against the proposed forest land Diversion in crore)':
            data?.fcFormA?.fcOthersDetail?.total_loss,
          'Estimated Benefits of Forest Diversion (in crore)':
            data?.fcFormA?.fcOthersDetail?.total_benefit,
          'Cost Benefit Ratio': // by default return 1
            data?.fcFormA?.fcOthersDetail?.cost_benefit_ratio,
          'Upload a copy of Cost-Benefit analysis':
            data?.fcFormA?.fcOthersDetail?.cost_benefit_report,
          'Whether the Project requires Clearance under the Environment (Protection) Act 1986 (Environmental clearance)?':
            data?.fcFormA?.fcOthersDetail?.is_ec_required,
          'Status of Environmental Clearance':
            data?.fcFormA?.fcOthersDetail?.ec_application_status,
          'Proposal No.': data?.fcFormA?.fcOthersDetail?.ec_proposal_no,
          'Date of issue of environmental clearance':
            this.getDisplayDateFormat(data?.fcFormA?.fcOthersDetail?.ec_issued_date),
          'MoEFCC / SEIAA File Number':
            data?.fcFormA?.fcOthersDetail?.ec_moefcc_file_no,
          'Upload EC Letter (in pdf)': data?.fcFormA?.fcOthersDetail?.ec_letter,
          'Date of application':
            this.getDisplayDateFormat(data?.fcFormA?.fcOthersDetail?.ec_submission_date),
          'Status of application [Submitted recently / ToR Granted / PH Conducted / Under appraisal]':
            data?.fcFormA?.fcOthersDetail?.ec_application_sub_status,
          'Application yet to be submitted Reasons thereof':
            data?.fcFormA?.fcOthersDetail?.ec_application_sub_status,
          'Whether the Project or a part thereof is located in any Protected Area or their Eco sensitive zone?':
            data?.fcFormA?.fcOthersDetail?.is_pa_esz_involved,
          'Whether the Project or a part thereof is located within a Protected Area?':
            data?.fcFormA?.fcOthersDetail?.protected_area_type,
          'Whether approval of the Standing Committee of National Board for the Wildlife(NBWL) required?':
            data?.fcFormA?.fcOthersDetail?.esz_nbwl_approval_required,
          'NBWL Approval Reasons thereof':
            data?.fcFormA?.fcOthersDetail?.esz_non_nbwl_approval_reason,
          'Area located outside the protected area':
            data?.fcFormA?.fcOthersDetail?.area_outside_protected_area,
          'Is Area located in the Protected Area?':
            data?.fcFormA?.fcOthersDetail?.is_located_in_protected_area,
          'Area falls within the Protected Area':
            data?.fcFormA?.fcOthersDetail?.area_within_protected_area,
          'Distance from the nearest Protected area (km)':
            data?.fcFormA?.fcOthersDetail?.distance_from_nearest_protected_area,
          'Distance from the nearest Eco-Sensitive Zone (km)':
            data?.fcFormA?.fcOthersDetail?.distance_from_nearest_eco_area,
          'Status of approval of the Standing Committee of National Board for Wildlife (NBWL) to the Project':
            data?.fcFormA?.fcOthersDetail?.esz_approval_status,
          'ESZ Proposal No.': data?.fcFormA?.fcOthersDetail?.esz_proposal_no,
          'ESZ Date of approval':
            this.getDisplayDateFormat(data?.fcFormA?.fcOthersDetail?.esz_approval_date),
          'MoEF File No.': data?.fcFormA?.fcOthersDetail?.esz_reference_no,
          'Upload copy of approval': data?.fcFormA?.fcOthersDetail?.esz_report,
          'ESZ Date of application':
            this.getDisplayDateFormat(data?.fcFormA?.fcOthersDetail?.esz_application_date),
          'Upload copy of application':
            data?.fcFormA?.fcOthersDetail?.esz_report,
          'ESZ Application yet to be submitted Reasons thereof':
            data?.fcFormA?.fcOthersDetail?.esz_non_submission_reason,

          //category of mining
          'Copy of approved mining plan along with approval letter':
            data?.fcFormA?.fcEnclosures?.copy_approved_mining_plan,
          'Copy of map of the outer boundary of mining lease area (pdf only)':
            data?.fcFormA?.fcEnclosures?.copy_map_outer_boundary,
          'Copy of the detailed land use plan in 1:4,000 scale prepared by using DGPS or Total Station (PDF only)':
            data?.fcFormA?.fcEnclosures?.copy_detailed_land_use_plan,
          'Copy of the prospecting licence and extension of the prospecting licence, if any':
            data?.fcFormA?.fcEnclosures?.copy_perspecting_licence,
          'Copy of the approval accorded for MoEFCC for prospecting':
            data?.fcFormA?.fcEnclosures?.copy_approval_accorded_moefcc,
          'Copy of note containing details of the plan for the transportation of the minerals proposed to be raised from the mining lease (pdf only)':
            data?.fcFormA?.fcEnclosures?.copy_transportation_minerals_proposed,
          'validity of Mining Plan From':
            this.getDisplayDateFormat(data?.fcFormA?.forestClearanceMiningProposals?.from_mining_plan),
          'validity of Mining Plan To':
          this.getDisplayDateFormat(data?.fcFormA?.forestClearanceMiningProposals?.to_mining_plan),
          'Period of Mining Lease(Years)':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.period_of_mining_lease,
          'Name of approving authority for the Mining plan':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.approving_authority_name,
          'Whether detailed prospecting to assess mineral reserves in the lease has been undertaken':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.is_mineral_reserves_access,
          'Designation of authority responsible for grant of the original prospecting licence':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.authority_designation,
          'Date of grant of prospecting licence':
            this.getDisplayDateFormat(data?.fcFormA?.forestClearanceMiningProposals?.date_of_grant_pl),
          'validity of extension prospecting licence From':
          this.getDisplayDateFormat(data?.fcFormA?.forestClearanceMiningProposals?.from_extension_pl),
          'validity of extension prospecting licence To':
          this.getDisplayDateFormat(data?.fcFormA?.forestClearanceMiningProposals?.to_extension_pl),
          'Forest Land No. of Bore holes':
            data?.fcFormA?.forestClearanceMiningProposals?.fl_no_bore_holes,
          'Diameters(Inch)':
            data?.fcFormA?.forestClearanceMiningProposals?.fl_diameter,
          'Non-Forest Land No. of Bore holes':
            data?.fcFormA?.forestClearanceMiningProposals?.nfl_no_bore_holes,
          'Non-Forest Land Diameters(Inch)':
            data?.fcFormA?.forestClearanceMiningProposals?.nfl_diameter,
          "Mineral Reserves": {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Name of the Mineral', value: 'mineral_name' },
            { label: 'Proved Reserves (Million Ton)', value: 'proved_reserves' },
            { label: 'Indicated Reserves (Million Ton)', value: 'indicated_reserves' },
            { label: 'Inferred Reserves (Million Ton)', value: 'inferred_reserves' },
            { label: 'Mineable Reserves (Million Ton)', value: 'mineable_reserves' },
            { label: 'Remarks', value: 'remarks' },
            ],
            data: data?.fcFormA?.forestClearanceMiningProposals
              ?.miningMineralReserves,
          },

          "Estimated reserves in Forest / Non-Forest land if any": {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Name of the Mineral', value: 'estimated_reserves_name' },
            { label: 'Estimated Reserve in Forest Land', value: 'estimated_reserves_fl' },
            { label: 'Estimated Reserve in Non-Forest Land', value: 'estimated_reserves_nfl' },
            { label: 'Remarks', value: 'estimated_reserves_remarks' },
            ],
            data: data?.fcFormA?.forestClearanceMiningProposals?.estimatedReserveMinerals,
          },

          'Date of issue of LoI/Vesting order/Minning Lease':
            this.getDisplayDateFormat(data?.fcFormA?.forestClearanceMiningProposals?.date_of_issue),
          'Period of validity of LoI / Vesting order (years and months)':
            data?.fcFormA?.forestClearanceMiningProposals?.date_of_validity,
          'Period of lease (years and months)':
            data?.fcFormA?.forestClearanceMiningProposals?.lease_period,
          'Date of expiry of lease':
           this.getDisplayDateFormat(data?.fcFormA?.forestClearanceMiningProposals?.date_of_expiry),
          'Lease area (in ha) as per LoI/Vesting order/Mining Lease':
            data?.fcFormA?.forestClearanceMiningProposals?.lease_area,
          'Production capacity (in MTPA) as per LoI / Vesting Order / Mining lease, if any prescribed':
            data?.fcFormA?.forestClearanceMiningProposals?.production_capacity,
          'Details of Lease renewal(s), if any':
            data?.fcFormA?.forestClearanceMiningProposals?.detail_of_lease,
          'Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals?.other_info,
          'Status of approval of Mining plan':
            data?.fcFormA?.forestClearanceMiningProposals?.status_of_approval,

          'Minerals to be mined': {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Name of the Mineral to be mined', value: 'mineral_name' },
            { label: 'Classification of mineral', value: 'mineral_classification' },
            { label: 'Production capacity in MTPA', value: 'production_capacity' },
            { label: 'Remarks', value: 'remarks' },
            ],
            data: data?.fcFormA?.forestClearanceMiningProposals?.miningMineralsMineds,
          },

          'Total excavation in MTPA':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.total_excavation_mtpa,
          'Total Excavation in M.Cu.m/Annum':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.total_excavation_annum,
          'Stripping Ratio':
            data?.fcFormA?.forestClearanceMiningProposals?.stripping_ratio,
          'Excavation Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.excavation_other_info,
          'Life of the mine as per approved mining plan (in years and months)':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.approved_life_of_mine,
          'Life of the mine as per total estimated reserves, if any (in years and months)':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.estimate_life_of_mine,
          'Life of mine Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.estimate_life_of_mine,
          'Type of mining':
            data?.fcFormA?.forestClearanceMiningProposals?.type_of_mining,
          'Method of mining':
            data?.fcFormA?.forestClearanceMiningProposals?.method_of_mining,
          'Type of mining Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.type_of_mining_other_info,
          'Type of blasting':
            data?.fcFormA?.forestClearanceMiningProposals?.type_of_blasting,
          'Mitigation measures for control of blast induced vibrations':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.mitigation_blast_control,
          'Type of blasting Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals?.blasting_other_info,
          'Whether it is proposed to install beneficiation plant/Coal washery within the mining lease area?':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.proposed_to_install_coal,
          'Capacity of beneficiation/washery (in MTPA)':
            data?.fcFormA?.forestClearanceMiningProposals?.coal_capacity,
          'Proposed process':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.coal_proposed_process,
          'Beneficiation/Washing Technology':
            data?.fcFormA?.forestClearanceMiningProposals?.coal_beneficiation,
          'coal Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals?.coal_other_info,
          'Whether it is proposed to install crusher within the mining lease areaa?':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.proposed_to_install_crusher,
          'No. Of crushers':
            data?.fcFormA?.forestClearanceMiningProposals?.number_of_crusher,
          //it is in form of table
            'Capacity of crushers':
            data?.fcFormA?.forestClearanceMiningProposals?.capacity_of_crusher,
          'Capacity of crushers unit':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.capacity_of_crusher_unit,
          'Total capacity of crushers':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.total_capacity_of_crusher,
          'Total capacity of crushers unit':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.total_capacity_of_crusher_unit,
          // it is in table form
          'External dump Area in (Ha)':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.external_dumping_area,
          'External dump Area in m':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.external_dumping_maximum_area,
          'External dump Remarks':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.external_dumping_remarks,
          'Internal dump Area in (Ha)':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.internal_dumping_area,
          'Internal dump Area in m':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.internal_dumping_maximum_area,
          'Internal dump Remarks':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.internal_dumping_remarks,
          'Topsoil dump/ storage Area in (Ha)':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.toposoil_dumping_area,
          'Topsoil dump/ storage Area in m':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.toposoil_dumping_maximum_area,
          'Topsoil dump/ storage Remarks':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.toposoil_dumping_remarks,
          'Total Topsoil excavated during the entire life of the mine (mm3)':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.total_topsoil_excavated,
          'Utilization strategy of topsoil':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.topsoil_utilization_strategy,
          'Topsoil Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals?.topsoil_other_info,
          'Total Quarry Area (ha.)':
            data?.fcFormA?.forestClearanceMiningProposals?.total_quarry_area,
          'Area of final void (ha.)':
            data?.fcFormA?.forestClearanceMiningProposals?.final_void_area,
          'Maximum Depth of final void (m)':
            data?.fcFormA?.forestClearanceMiningProposals?.final_void_max_depth,
          'Quarry Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals?.quarry_other_info,
          'Mode of transportation upto pit head':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.transportation_mode_up_pithead,
          'Mode of transportation from pit head to siding/loading':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.transportation_mode_from_pithead,
          'Mode of transport from loading point to consumers':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.transportation_mode_from_loading,
          'Transportation Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.transportation_mode_other_info,
          'Plantation area (ha.)':
            data?.fcFormA?.forestClearanceMiningProposals?.plantation_area,
          'Water body (ha.)':
            data?.fcFormA?.forestClearanceMiningProposals?.water_body,
          'Public use (ha.)':
            data?.fcFormA?.forestClearanceMiningProposals?.public_use,
          'Other uses (ha.)':
            data?.fcFormA?.forestClearanceMiningProposals?.other_use,

          //mining 
          'Whether approved DSR available':
            data?.fcFormA?.forestClearanceMiningProposals?.approved_dsr,
          'Whether the instant proposal is part of cluster':
            data?.fcFormA?.forestClearanceMiningProposals?.instant_proposal,
          'Total ML area (in ha) of the cluster':
            data?.fcFormA?.forestClearanceMiningProposals?.total_ml_area,
          'No. of Mines falling in the cluster':
            data?.fcFormA?.forestClearanceMiningProposals?.no_of_mines_falling,
          'Category of the cluster':
            data?.fcFormA?.forestClearanceMiningProposals?.category_of_cluster,
          'Replenishment Study in case of Sand/bajri mining':
            data?.fcFormA?.forestClearanceMiningProposals
              ?.replenishment_study_case,

          //hydel/irrigation
          "Hydel": data?.fcFormA?.forestClearanceRiverValleyProject?.river_valley_project,
          "Whether project involves Hydroelectric power project? ": data?.fcFormA?.forestClearanceRiverValleyProject?.is_hydroelectric_project,
          "Production Capacity (MW)": data?.fcFormA?.forestClearanceRiverValleyProject?.hydroelectric_production_capacity,
          "Whether cumulative impact assesment study to access the carrying capacity of the river basing has been done?": data?.fcFormA?.forestClearanceRiverValleyProject?.is_study_done,
          "Whether project involves construction of Dam/Barrage/Weir? ": data?.fcFormA?.forestClearanceRiverValleyProject?.involves_construction_of,
          "Name of the River (source)": data?.fcFormA?.forestClearanceRiverValleyProject?.river_name,
          // hydel
          'Type of Dam Configuration': hepProjectConfig?.type_of_dam_configuration_HEP,
          'Type of Dam  Remarks': hepProjectConfig?.type_of_dam_remarks_HEP,
          'Dam height from the deepest foundation level (m)  Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.dam_height_configuration_HEP,
          'Dam height from the deepest foundation level (m) Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.dam_height_remarks_HEP,
          'Length of the Dam (m) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.dam_length_configuration_HEP,
          'Length of the Dam (m) Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.dam_length_remarks_HEP,
          'Maximum Height of the Embankment (m) Configuration ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.max_height_configuration_HEP,
          'Maximum Height of the Embankment (m) Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.max_height_remarks_HEP,
          'Type of Spillways Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.type_of_spillway_configuration_HEP,
          'Type of Spillways Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.type_of_spillway_remarks_HEP,
          'No. of Spillways Configuration ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.no_of_spillway_configuration_HEP,
          'No. of Spillways Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.no_of_spillway_remarks_HEP,
          'Head Race Tunnel (HRT) (diameter) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.head_race_tunnel_config,
          'Head Race Tunnel (HRT) (diameter) Remarks ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.head_race_tunnel_remark,
          'Head Race Tunnel (HRT) (length (m)) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.head_race_tunnel_length_config,
          'Head Race Tunnel (HRT) (length (m)) Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.head_race_tunnel_length_remarks,
          'Surge shaft (diameter (m)) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.shaft_configuration,
          'Surge shaft (diameter (m)) Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.shaft_remark,
          'Surge shaft (height (m)) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.shaft_height_config,
          'Surge shaft (height (m)) Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.shaft_height_remark,
          'Pressure Shaft (Nos)& Penstock Configuration ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.pshaft_config,
          'Pressure Shaft (Nos)& Penstock Remarks ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_configuration_json?.pshaft_remark,
          'Powerhouse No. of Turbine Generating unit (MW) Configuration': hepProjectCapacity?.no_of_turbine,
          'Powerhouse No. of Turbine Generating unit (MW) Remarks': hepProjectCapacity?.no_of_turbine_remark,
          'Powerhouse Capacity of each turbine (MW) Configuration ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.capacity_of_each_turbine,
          'Powerhouse Capacity of each turbine (MW) Remarks ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.capacity_of_each_turbine_remark,
          'Powerhouse Size of the Power House Cavern (m3) (L*W*H) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.size_of_village,
          'Poerhouse Size of the Power House Cavern (m3) (L*W*H) Remarks ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.size_of_village_remark,
          'Tail Race Tunnel (TRT) (diameter)(m) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.trt,
          'Tail Race Tunnel (TRT) (diameter)(m) Remarks ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.trt_remark,
          'Tail Race Tunnel (TRT) (length)(m) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.trt_length,
          'Tail Race Tunnel (TRT) (length)(m) Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.trt_length_remark,
          'Total design discharge (Cumec)  Configuration ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.total_design_discharge,
          'Total design discharge (Cumec) Remarks ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.total_design_discharge_remark,
          'Other information, if any ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.hep_project_capacity_json?.other_please_specify_hep,
          'Other information, if any Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.other_configuration_hep,
          'Other information, if any Remarks': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.other_remarks_hep,
          'Installed Capacity (MW)': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.installed_capacity,
          'Whether installed power generation capacity of the project is greater than 10 MW ': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.is_greater_power_generation,
          'Copy of the approval of competent authority to the Catchment Area Treatment Plan (CAT Plan) (pdf)': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.copy_approval_competent_cat,
          'Copy of the approved CAT plan (pdf)': data?.fcFormA?.forestClearanceRiverValleyProject?.hep_project_capacity_json?.copy_approved_CAT,
          'catchement_area': data?.fcFormA?.forestClearanceRiverValleyProject?.catchement_area,
          'Average rainfall (mm)': data?.fcFormA?.forestClearanceRiverValleyProject?.average_rainfall,
          'Water availability (MCM) (approx.)': data?.fcFormA?.forestClearanceRiverValleyProject?.water_availability,
          'Submergence Area (ha), if any': {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Area submerged', value: 'area_submerged' },
            { label: 'Area (ha)', value: 'area' },

            ],
            data: data?.fcFormA?.fcSubmergedArea,
          },
          'Upload Muck Restoration Plan': data?.fcFormA?.forestClearanceRiverValleyProject?.upload_muck_restoration_plan,
          'Presence of Schedule I species within the study area ': data?.fcFormA?.forestClearanceRiverValleyProject?.schedule_1_spieces,
          'Brief on E flow': data?.fcFormA?.forestClearanceRiverValleyProject?.brief_on_e_flow,
          'Provision of fish pass envisaged': data?.fcFormA?.forestClearanceRiverValleyProject?.fish_pass_envisaged_provision,

          //irrigation
          'Length of Main Canal (in m) (for Irrigation project) Configuration': irrigationProjectConfig?.length_of_main_canal_configuration,
          'Length of Main Canal (in m) (for Irrigation project) Remarks': irrigationProjectConfig?.length_of_main_canal_remarks,
          'Length of Distributaries (in m) (for Irrigation project) Configuration': irrigationProjectConfig?.length_of_distributaries_configuration,
          'Length of Distributaries (in m) (for Irrigation project) Remarks': irrigationProjectConfig?.length_of_distributaries_remarks,
          'Cultural Command Area (ha.) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.irrigation_project_capacity_cultural_command,

          'Irrigable Command Area (ha.) Configuration': data?.fcFormA?.forestClearanceRiverValleyProject?.irrigation_project_capacity_irrigable_command,

          'Type of Irrigation': data?.fcFormA?.forestClearanceRiverValleyProject?.irrigation_project_capacity_type_of_irrigation_value,

          'Total number of villages to be benefitted in the district': {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'State', value: 'state' },
            { label: 'District', value: 'district' },
            { label: 'Village', value: 'village' },
            { label: 'Area benefited (ha)', value: 'area_benefited' },

            ],
            data: data?.fcFormA?.fcIrrigationProjectCapacityVillage,
          },
          'Cropping pattern': {
            type: 'RELATIONAL_DATA',
            headers: [{ label: 'Crop', value: 'crop' },
            { label: 'Existing Area (ha.)', value: 'existing_area' },
            { label: 'Existing Productivity (tonnes/ha.)', value: 'existing_productivity' },
            { label: 'Proposed Area (ha.)', value: 'proposed_area' },
            { label: 'Proposed Productivity', value: 'proposed_productivity' },
            { label: 'Total Production', value: 'total_production' },

            ],
            data: data?.fcFormA?.irrigationFcCroppingPattern,
          },

          //Mining and oil
          'Period of validity of Mineral-Oil Exploration/Mining Licence/Lease Form': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_validity_from,
          'Period of validity of Mineral-Oil Exploration/Mining Licence/Lease To': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_validity_to,
          'Name of approving authority of Mineral-Oil Exploration/Mining Licence/Lease ': data?.fcFormA?.miningMineralOilProposal?.approving_authority_name,
          'Copy of approved Mineral-Oil Exploration/Mining Licence/Lease along with approval letter': data?.fcFormA?.miningMineralOilProposal?.copy_approved_mineral_oil_plan,
          'Copy of map of the outer boundary of mining lease area (pdf only) ': data?.fcFormA?.miningMineralOilProposal?.copy_map_outer_boundary_mineral_oil,
          'Copy of the detailed land use plan in 1:4,000 scale prepared by using DGPS or Total Station (PDF only) ': data?.fcFormA?.miningMineralOilProposal?.copy_detailed_land_use_plan_mineral_oil,
          'Whether detailed exploration assessmineral oil reserve in the lease has been undertaken': data?.fcFormA?.miningMineralOilProposal?.fc_wlc_rofr_certification?.is_mineral_reserves_exploration,
          'Designation of authority responsible for grant of the original Mineral-Oil Exploration Licence. ': data?.fcFormA?.miningMineralOilProposal?.fc_wlc_rofr_certification?.mineral_oil_authority_designation,
          'Date of grant of Mineral-Oil Exploration Licence ': this.getDisplayDateFormat(data?.fcFormA?.miningMineralOilProposal?.fc_wlc_rofr_certification?.mineral_oil_grant_date),
          'Period of validity of extension of Mineral-Oil Exploration Licence From ': data?.fcFormA?.miningMineralOilProposal?.fc_wlc_rofr_certification?.mineral_oil_extension_validity_from,
          'Period of validity of extension of Mineral-Oil Exploration Licence To ': data?.fcFormA?.miningMineralOilProposal?.fc_wlc_rofr_certification?.mineral_oil_extension_validity_to,
          'Presence of any previously drilled wells in the proposed PML Area.': data?.fcFormA?.miningMineralOilProposal?.fc_wlc_rofr_certification?.is_previously_drilled,
          'FC/WLC/RoFR certification ': data?.fcFormA?.miningMineralOilProposal?.fc_wlc_rofr_certification,

          'Mineral-Oil Reserves/Resources': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Type of the Mineral-Oil', value: 'mineral_oil_type' },
              { label: 'Proved Reserves/Resources (Million Ton) ', value: 'proved_reserves_resources' },
              { label: 'Proved plus Probable plus Possible Reserves/Resources (Million Ton) ', value: 'proved_probable_reserves' },
              { label: 'Remarks', value: 'mineral_oil_reserves_remarks' },
            ],
            data: data?.fcFormA?.miningMineralOilProposal,
          },

          'Estimated reserves in Forest/ Non- Forest land if any': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Type of the Mineral-Oil', value: 'estimated_reserves_mineral_oil_type' },
              { label: 'Estimated Reserve in Forest Land (ha.)', value: 'estimated_reserves_mineral_oil_fl' },
              { label: 'Estimated Reserve in Non-Forest Land (ha.)', value: 'estimated_reserves_mineral_oil_nfl' },
              { label: 'Remarks', value: 'estimated_reserves_mineral_oil_remarks' },
            ],
            data: data?.fcFormA?.miningMineralOilProposal?.miningMineralOilEstimatedReserve,

          },

          'Copy of the Mineral-Oil Exploration licence and extension the Mineral-Oil Exploration licence if any': data?.fcFormA?.miningMineralOilProposal?.copy_mineral_oil_exploration_licence,
          'Copy of note containing details of the plan for the transportation of the mineral oil proposed to be raised from the Mineral-Oil Mining lease (pdf only)': data?.fcFormA?.miningMineralOilProposal?.copy_mineral_oil_transportation_note_,
          'Status of approval of Exploration License/Mining Lease for Mineral-Oil. ': data?.fcFormA?.miningMineralOilProposal?.status_of_approval_mineral_oil,
          'Mineral-Oil to be extracted': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Type of the Mineral-Oil to be extracted', value: 'mineral_oil_extracted' },
              { label: 'Classification of mineral', value: 'mineral_oil_classification' },
              { label: 'Estimated/Envisaged Production in MTPA', value: 'mineral_oil_mtpa' },
              { label: 'Estimated/Envisaged Production in MMSCMD', value: 'mineral_oil_mmscmd' },
            ],
            data: data?.fcFormA?.miningMineralOilProposal?.miningMineralOilExtracted,
          },

          'Drill Cuttings (cubic meter)': data?.fcFormA?.miningMineralOilProposal?.drill_cutting_excavation,
          'Other information, if (any)': data?.fcFormA?.miningMineralOilProposal?.excavation_other_information,
          'Life of the Mineral Oil Reservoir as per total estimated reserves, if any  Year': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_reservoir_total_life_year,
          'Life of the Mineral Oil Reservoir as per total estimated reserves, if any  Month': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_reservoir_total_life_month,
          'Life of the Mineral Oil Reservoir as per approved Field Development plan Year': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_reservoir_approved_plan_year,
          'Life of the Mineral Oil Reservoir as per approved Field Development plan Month': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_reservoir_approved_plan_month,
          'Expected life of the Mineral Oil Reservoir ': data?.fcFormA?.miningMineralOilProposal?.copy_mineral_oil_reservoir_life,
          'Other information,if any': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_reservoir_other_information,
          'Phase of Operation ': data?.fcFormA?.miningMineralOilProposal?.mineral_oil_extraction_phase,
          'Other information,if any ': data?.fcFormA?.miningMineralOilProposal?.extraction_mineral_oil_other_information,
          'Type of blasting, if any, to be adopted': data?.fcFormA?.miningMineralOilProposal?.is_type_of_blasting_adopted,
          'Type of blasting ': data?.fcFormA?.miningMineralOilProposal?.blasting_type,
          'Mitigation measures for control of blast induced vibrations ': data?.fcFormA?.miningMineralOilProposal?.blast_vibration_mitigation,
          'Other information,if any  ': data?.fcFormA?.miningMineralOilProposal?.blasting_adopted_other_information,
          'Whether it is proposed to install production facility (e.g.:EPS/QPS/OCS/GGS/GCS etc.) within the Mineral-Oil Mining Lease area ': data?.fcFormA?.miningMineralOilProposal?.is_proposal_install_production,
          'Capacity of the same (in MTPA/MMSCMD)': data?.fcFormA?.miningMineralOilProposal?.capacity_same_mtpa_mmscmd,
          'Other information , if any': data?.fcFormA?.miningMineralOilProposal?.production_facility_other_information,
          'Total drill cuttings excavated & wastewater management during the entire life of the petroleum activity': data?.fcFormA?.miningMineralOilProposal?.total_drill_cutting_excavated,
          'Other information,if (any)': data?.fcFormA?.miningMineralOilProposal?.drill_cuttings_other_information,
          'Estimated volume of the waste management pit for Drill cuttings & Wastewater ': data?.fcFormA?.miningMineralOilProposal?.waste_estimated_volume,
          'Other information,if (any) ': data?.fcFormA?.miningMineralOilProposal?.waste_pit_other_information,
          'Mode of transportation up to Separator': data?.fcFormA?.miningMineralOilProposal?.mode_of_transportation_seperator,
          'Mode of transportation from Separator to loading point/GGS ': data?.fcFormA?.miningMineralOilProposal?.mode_of_transportation_loading_point,
          'Mode of transport from loading point/GGS to consumers ': data?.fcFormA?.miningMineralOilProposal?.mode_of_transportation_consumer,
          'Other information, if (any) ': data?.fcFormA?.miningMineralOilProposal?.transportation_extracted_other_information,
          'Plantation area (in Ha)': data?.fcFormA?.miningMineralOilProposal?.plantation_area_after_cessation,
          'Public use (in Ha)': data?.fcFormA?.miningMineralOilProposal?.public_use_after_cessation,
          'Other information,if any plantation': data?.fcFormA?.miningMineralOilProposal?.other_use_after_cessation,
          'Field Development Plan for Mineral Oil extraction': data?.fcFormA?.miningMineralOilProposal?.fc_development_plan_mineral_oil_extraction,
          'Whether the proposal involves Production details for Expansion/ Modernization': data?.fcFormA?.miningMineralOilProposal?.is_proposal_involves_expansion,
          'Year of commencement of extraction of Mineral Oil operations': data?.fcFormA?.miningMineralOilProposal?.commencement_extraction_year,
          'Production Details since inception of mine': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Financial Year Range ', value: 'production_detail_fin_year' },
              { label: 'Sanctioned Capacity (MTPA) as per EC', value: 'production_details_sactioned_ec' },
              { label: 'Sanctioned Capacity (MTPA) as per CTO', value: 'production_details_sactioned_cto' },
              { label: 'Exploration License/Mining Lease', value: 'production_details_sactioned_licence' },
              { label: 'Actual Production ', value: 'actual_production' },
              { label: 'Excess Production beyond EC/ CTO/ Expl License-Mining Lease Vs. Sanctioned Capacity (MTPA)', value: 'excess_production' },
            ],
            data: data?.fcFormA?.miningMineralOilProposal?.miningMineralOilProposal?.miningMineralOilProductionDetail,

          },

          'DSR Other information, if any':
            data?.fcFormA?.forestClearanceMiningProposals?.dsr_other_info,
          // 'additionalDocuments': {
          //   type: 'RELATIONAL_DATA',
          //   headers: [
          //     { label: 'Document Name', value: 'document_name' },
          //     { label: 'Remarks', value: 'remarks_additional_information' },
          //     { label: 'Document', value: 'upload_additional_information' },
          //   ],
          //   data: data?.additionalDocuments,
          // },
        },
      };
      console.log('Body', body);
      console.log('Type of dam remark', hepProjectConfig?.type_of_dam_remarks_HEP,);
      console.log('Type of dam configuration', hepProjectConfig?.type_of_dam_configuration_HEP);
      console.log('Irrigation channel length', irrigationProjectConfig?.length_of_main_canal_configuration,);
      console.log("Cost benefit ratio",data?.fcFormA?.fcOthersDetail?.cost_benefit_ratio);

      console.log('Form A Details', data?.fcFormA);

      console.log("Patch wise ",data?.fcFormA?.forestClearanceProposedDiversions[0]
        ?.forestClearanceDivisionPatchDetails[0]?.area,);

      console.log("KML DEtail", data?.fcFormA?.forestClearanceProposedDiversions[0]
        ?.total_forest_land_for_division);
      
      return body;
    }

    if (type == 'FC_FORM_B') {
      body = {
        CAF,
        fcFormB: {
          'State': data?.fcFormB?.proponentApplications?.state,
          'Project Category': data?.fcFormB?.project_category_id,
          'Details of approvals under the Forest (Conservation) Act , 1980 accorded in the past': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Proposal No.', value: 'proposal_no' },
              { label: 'MoEFCC File No.', value: 'moef_file_no' },
              { label: ' Area of forest land Diverted (ha)', value: 'forest_land_area' },
              { label: 'Date of approval', value: 'approval_date' }
            ],
            //array(0) is passed
            data: data?.fcFormB[0]?.data?.fcFormBPriorProposals
          },
          'Status of compliance to conditions stipulated in the previous approvals': {
            type: 'RELATIONAL_DATA',
            headers: [
              {label: 'Proposal No.', value: 'proposal_no'},
               {label: 'MoEFCC File No.', value: 'moef_file_no'}, 
               {label: 'Conditions stipulated by the MoEFCC', value: 'moef_condition'}, 
               {label: 'Details of the compliance ', value: 'compliance_details'}
              ],
              //empty
            data: data?.fcFormB[1]?.data?.fcFormBComplianceDetails,
          },
          'Details of payments made in respect of the approval under the Forest (Conservation) Act , 1980 accorded in the past': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Proposal No.', value: 'proposal_no' },
              { label: 'MoEFCC File No.', value: 'moef_file_no' },
              { label: 'Item/Nature', value: 'item_nature' },
              { label: 'Amount Paid (in Rupees)', value: 'amount_paid' },
              { label: 'Date of Payment', value: 'payment_date' }
            ],
            data : data?.fcFormB[1]?.data?.fcFormBPaymentDetails, //empty
           
          },
          //'Whether a part of the forest land diverted in favour of the user agency has been returned to the State Forest Department or any other Authority?' : ,
          'No. of patches of the forest land returned / surrendered' : data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.patch_count, //null
          'Area of forest land returned / surrendered (ha)' : data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.forest_area,
          'Upload single KML of all the patches' : data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.all_patch_kml,
          'Patch Wise Details':{
            type: 'RELATIONAL_DATA',
            headers: [
              {label: "Area of Patch (ha)", value: 'area'},
              {label: "Copy of the map of the patch prepared by using GPS or Total Station", value: 'gps_patch'}, 
              {label: 'Date of return', value: 'return_date'}, 
              {label: 'Authority to whom the forest land has been returned', value: 'authority_name'}, 
              {label: 'Status of reclamation of land proposed to be returned /surrendered', value: 'land_status'}, 
              {label: 'Copy of documentary proof in support of the return/surrender of the forest land', value: 'documentary_proof_copy'}
            ],
            data: data?.fcFormB[1]?.data?.fcFormBPatchAreaDetails, //empty
          },
          'Name of the original lessee agency at the time of grant/previous renewal of the lease': data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.lease_agency_name,
          'Address of the original lessee agency at the time of grant/previous renewal of the lease': data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.lease_agency_address,
          'Whether the lease was transferred from the original lessee agency at the time of grant/previous renewal of the lease to any other firm/person': data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.is_lease_transferred,
          'Name of the firm or person in whose favour the lease was transferred': data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.firm_name,
          'Address of the firm or person in whose favour the lease was transferred': data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.firm_address,
          'Date of transfer of lease': data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.lease_transfer_date,
          'Copy of the transfer deed': data?.fcFormB[1]?.data?.fcFormBApprovalDetails?.transfer_deed_copy,
          'Whether the approval under the forest (Conservation) Act, 1980 for transfer of lease has been obtained ': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: "MoEF File No.", value: 'moef_file_no' },
              { label: "Date of approval", value: 'approval_date' },
              { label: "Copy of the MoEF approval", value: 'approval_copy' }
            ],
            data: data?.fcFormB[1]?.data?.fcFormBLeaseDetails, //Array(0)
          },
          'Total area of forestland proposed for renewal (ha.)': data?.fcFormB[2]?.data?.formBProposedLands?.total_proposed_diversion_area,
          'Total area of non-forestland proposed for renewal (ha.)': data?.fcFormB[2]?.data?.formBProposedLands?.total_non_forestland_area_required,
          'Legal Status of forest land proposed for diversion': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Legal Status of Forest Land', value: 'legal_status_of_forest_land' },
              { label: 'Forest Area (ha)', value: 'area' }
            ],
            data: data?.fcFormB[2]?.data?.formBProposedLands?.formBLegalStatus,

          },
          'Details of forestland proposed for renewal': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: "Division", value: 'division' },
              { label: "Upload single KML of all the patches/ segments for the selected division", value: 'kml' },
              { label: "Number of patches", value: 'total_patches' }
            ],
            data : data?.fcFormB[2]?.data?.fcFormBProposedDiversions
          },
          'Total Forest Land Area (ha)': data?.fcFormB[2]?.data?.fcFormBProposedDiversions?.total_forest_land_for_division,
          'Total Non Forest Land Area (ha)': data?.fcFormB[2]?.data?.fcFormBProposedDiversions?.total_non_forest_land_for_division,
          'Component Wise Break Up':{
            type: 'RELATIONAL_DATA',
            headers: [
              {label: 'Component', value: 'component'},
              {label: 'Forest Land Proposed for Renewal (ha)', value: 'forest_area_total'},
              {label: 'Non-forest Land (ha)', value: 'non_forest_area_total'}
            ],
            data: data?.fcFormB[2]?.data?.fcFormBComponentDetails,

          },
          'Upload map of the forest land proposed to be diverted prepared by using DGPS or Total Station (pdf only) ': data?.fcFormB[2]?.data?.formBProposedLands?.geo_referenced_map,
          'Whether Compensatory Afforestation was raised in the past?': data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.is_applicable_compensatory_afforestation, //null

          
          'Type of Compensatory Afforestation': data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.comp_afforestation_type,
          'Whether Non-forest land is available?' : data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.is_non_forest_land,

          'Number of patches.': data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.total_patches,
          'No. of districts involved for raising Compensatory Afforestation':  data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.total_districts_involved_in_ca,
          'Total CA Land' : data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.ca_land,
          'Upload a scanned copy of the map of the land identified for creation of Compensatory Afforestation prepared by using GPS or Total Station (pdf only)': data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.identified_land_for_compensatory_afforestaion,
          'Whether Cost-Benefit analysis for the Project has been made?' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.is_cost_analyzed , //null
          'Total Loss (Against the proposed forest land Diversion in crore) ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.total_loss,
          'Estimated Benefits of Forest Diversion (in crore)' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.total_benefit,
          'Cost Benefit Ratio' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.cost_benefit_ratio,
          'Upload a copy of Cost-Benefit analysis':data?.fcFormB[4]?.data?.fcFormBOthersDetails?.cost_benefit_report ,
          'Reasons thereof' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.cost_not_analyzed_reason,
          'Whether the Project requires Clearance under the Environment (Protection) Act 1986 (Environmental clearance)?' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.is_ec_required,
          'Status of Environmental Clearance ' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_application_status,
          'Proposal No.' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_proposal_no,
          'Date of application' : this.getDisplayDateFormat(data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_submission_date),
          'Date of issue of environmental clearance' : this.getDisplayDateFormat(data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_issued_date),
          'MoEFCC / SEIAA File Number ' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_moefcc_file_no,
          'Upload EC Letter (in pdf) ' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_letter,
          'Status of application [Submitted recently / ToR Granted / PH Conducted / Under appraisal] ' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_application_sub_status ,
          'Reasons thereof ' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.ec_non_submission_reason,
          'Whether the Project or a part thereof is located in any Protected Area or their Eco sensitive zone? ' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.is_pa_esz_involved,
          'Whether the Project or a part thereof is located within a Protected Area? ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.protected_area_type,
          'Whether approval of the Standing Committee of National Board for the Wildlife(NBWL) required? ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_nbwl_approval_required, 
          'Reasons thereof (Whether approval of the Standing Committee)' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_non_nbwl_approval_reason,
          'Distance from the nearest Protected area (km) ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.distance_from_nearest_protected_area,
          'Distance from the nearest Eco-Sensitive Zone (km)' :data?.fcFormB[4]?.data?.fcFormBOthersDetails?.distance_from_nearest_eco_area ,
          'Status of approval of the Standing Committee of National Board for Wildlife (NBWL) to the Project ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_approval_status,
          'Reasons thereof (esz)' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_non_submission_reason,
          'Proposal No. ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_proposal_no,
          'Date of approval ' : this.getDisplayDateFormat(data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_approval_date),
          'MoEF File No. ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_reference_no,
          'Upload copy of approval ' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_report,    
          'Date of application ' : this.getDisplayDateFormat(data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_application_date),
          'Upload copy of application' : data?.fcFormB[4]?.data?.fcFormBOthersDetails?.esz_report,

          //mining/Quering
          'validity of Mining Plan From':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.from_mining_plan, //null
          'validity of Mining Plan To':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.to_mining_plan,
          'Period of Mining Lease(Years)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.period_of_mining_lease,
          'Name of approving authority for the Mining plan':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.approving_authority_name,
          'Copy of approved mining plan along with approval letter': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.copy_approved_mining_plan,

          'Copy of map of the outer boundary of mining lease area (pdf only) ': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.copy_map_outer_boundary,
          'Copy of the detailed land use plan in 1:4,000 scale prepared by using DGPS or Total Station (PDF only) ': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.copy_detailed_land_use_plan,
          'Whether detailed prospecting to assess mineral reserves in the lease has been undertaken': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.is_mineral_reserves_access,
          'Designation of authority responsible for grant of the original prospecting licence ': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.authority_designation,
          'Date of grant of prospecting licence': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.a,
          'Period of validity of extension prospecting licence From': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.from_extension_pl,
          'Period of validity of extension prospecting licence To': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.to_extension_pl,
          'Details of bore holes drilled for prospecting Forest Land No. of Bore holes': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.fl_no_bore_holes,
          'Details of bore holes drilled for prospecting Forest Land Diameters(Inch)': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.fl_diameter,
          'Details of bore holes drilled for prospecting Non-Forest Land No. of Bore holes': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.nfl_no_bore_holes,
          'Details of bore holes drilled for prospecting Non-Forest Land Diameters(Inch)': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.nfl_diameter,
          'Mineral Reserves':{
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Name of the Mineral', value: 'mineral_name' },
              { label: 'Proved Reserves (Million Ton)', value: 'proved_reserves' },
              { label: 'Indicated Reserves (Million Ton)', value: 'indicated_reserves' },
              { label: 'Inferred Reserves (Million Ton)', value: 'inferred_reserves' },
              { label: 'Mineable Reserves (Million Ton)', value: 'mineable_reserves' },
              { label: 'Remarks', value: 'remarks' },
            ],
            data: data?.fcFormB[5]?.data?.fcFormBMiningProposals?.fcFormBMiningMineralReserves,
          },

          'Estimated reserves in Forest / Non-Forest land if any': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Name of the Mineral', value: 'estimated_reserves_name' },
              { label: 'Estimated Reserve in Forest Land', value: 'estimated_reserves_fl' },
              { label: 'Estimated Reserve in Non-Forest Land', value: 'estimated_reserves_nfl' },
              { label: 'Remarks', value: 'estimated_reserves_remarks' },
            ],
            data: data?.fcFormB[5]?.data?.fcFormBMiningProposals?.fcFormBEstimatedReserveMinerals,
          },

          'Copy of the prospecting licence and extension of the prospecting licence, if any': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.copy_perspecting_licence,
          'Copy of the approval accorded for MoEFCC for prospecting': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.copy_approval_accorded_moefcc,

          'Copy of note containing details of the plan for the transportation of the minerals proposed to be raised from the mining lease (pdf only)': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.copy_transportation_minerals_proposed,
          'Date of issue of LoI/Vesting order/Minning Lease ': this.getDisplayDateFormat(data?.fcFormB[5]?.data?.fcFormBMiningProposals?.date_of_issue),
          'Period of validity of LoI / Vesting order (years and months) ': this.getDisplayDateFormat(data?.fcFormB[5]?.data?.fcFormBMiningProposals?.date_of_validity),
          'Period of lease (years and months)': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.lease_period,
          'Date of expiry of lease ': this.getDisplayDateFormat(data?.fcFormB[5]?.data?.fcFormBMiningProposals?.date_of_expiry),
          'Lease area (in ha) as per LoI/Vesting order/Mining Lease': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.lease_area,
          'Production capacity (in MTPA) as per LoI/Vesting order/Mining Lease , if any prescribed': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.production_capacity,
          'Details of Lease renewal(s), if any': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.detail_of_lease,
          'Other information, if any': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.other_info,
          'Status of approval of Mining plan': data?.fcFormB[5]?.data?.fcFormBMiningProposals?.status_of_approval,
          'Minerals to be mined': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Name of the Mineral to be mined', value: 'mineral_name' },
              { label: 'Classification of mineral', value: 'mineral_classification' },
              { label: 'Production capacity in MTPA', value: 'production_capacity' },
              { label: 'Remarks', value: 'remarks' },
            ],
            data: data?.fcFormB[5]?.data?.fcFormBMiningProposals?.miningMineralsMineds,
          },
          'Total excavation in MTPA':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.total_excavation_mtpa,
          'Total Excavation in M.Cu.m/Annum':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.total_excavation_annum,
          'Stripping Ratio':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.stripping_ratio,
          'Excavation Other information, if any':
            data?.fcFormB?.fcFormBMiningProposals
              ?.excavation_other_info,
          'Life of the mine as per approved mining plan (in years and months)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.approved_life_of_mine,
          'Life of the mine as per total estimated reserves, if any (in years and months)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.estimate_life_of_mine,
          'Life of mine Other information, if any':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.estimate_life_of_mine,
          'Type of mining':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.type_of_mining,
          'Method of mining':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.method_of_mining,
          'Type of mining Other information, if any':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.type_of_mining_other_info,
          'Type of blasting':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.type_of_blasting,
          'Mitigation measures for control of blast induced vibrations':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.mitigation_blast_control,
          'Type of blasting Other information, if any':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.blasting_other_info,
          'Whether it is proposed to install beneficiation plant/Coal washery within the mining lease area?':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.proposed_to_install_coal,
          'Capacity of beneficiation/washery (in MTPA)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.coal_capacity,
          'Proposed process':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.coal_proposed_process,
          'Beneficiation/Washing Technology':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.coal_beneficiation,
          'coal Other information, if any':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.coal_other_info,
          'Whether it is proposed to install crusher within the mining lease areaa?':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.proposed_to_install_crusher,
          'No. Of crushers':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.number_of_crusher,
          'Capacity of crushers':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.capacity_of_crusher,
          'Capacity of crushers unit':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.capacity_of_crusher_unit,
          'Total capacity of crushers':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.total_capacity_of_crusher,
          'Total capacity of crushers unit':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.total_capacity_of_crusher_unit,
          'External dump Area in (Ha)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.external_dumping_area,
          'External dump Area in m':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.external_dumping_maximum_area,
          'External dump Remarks':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.external_dumping_remarks,
          'Internal dump Area in (Ha)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.internal_dumping_area,
          'Internal dump Area in m':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.internal_dumping_maximum_area,
          'Internal dump Remarks':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.internal_dumping_remarks,
          'Topsoil dump/ storage Area in (Ha)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.toposoil_dumping_area,
          'Topsoil dump/ storage Area in m':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.toposoil_dumping_maximum_area,
          'Topsoil dump/ storage Remarks':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.toposoil_dumping_remarks,
          'Total Topsoil excavated during the entire life of the mine (mm3)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.total_topsoil_excavated,
          'Utilization strategy of topsoil':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.topsoil_utilization_strategy,
          'Topsoil Other information, if any':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.topsoil_other_info,
          'Total Quarry Area (ha.)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.total_quarry_area,
          'Area of final void (ha.)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.final_void_area,
          'Maximum Depth of final void (m)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.final_void_max_depth,
          'Quarry Other information, if any':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.quarry_other_info,
          'Mode of transportation upto pit head':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.transportation_mode_up_pithead,
          'Mode of transportation from pit head to siding/loading':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.transportation_mode_from_pithead,
          'Mode of transport from loading point to consumers':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.transportation_mode_from_loading,
          'Transportation Other information, if any':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.transportation_mode_other_info,
          'Plantation area (ha.)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.plantation_area,
          'Water body (ha.)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.water_body,
          'Public use (ha.)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.public_use,
          'Other uses (ha.)':
            data?.fcFormB[5]?.data?.fcFormBMiningProposals?.other_use,
          'Production details for expansion/ modernization proposals': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Financial Year Range ', value: 'financial_year' },
              { label: 'Santioned as per EC (MTPA)', value: 'sanctioned_ec' },
              { label: 'Santioned as per CTO (MTPA)', value: 'sanctioned_cto' },
              { label: 'Santioned as per Approved Mining Plan (MTPA)', value: 'sanctioned_approved_plan' },
              { label: 'Actual Production', value: 'actual_production' },
              { label: 'Exces Production beyond the EC/CTO/Mining Plan Vs. Sanctioned Capacity (MTPA)', value: 'excess_production' }
            ], //null
            data: data?.fcFormB[5]?.data?.fcFormBMiningProposals?.fcFormBMiningProductionDetails,
          },

          // 'Details of Additional information': {
          //   type: 'RELATIONAL_DATA',
          //   headers: [
          //     { label: 'Document Name', value: 'document_name' },
          //     { label: 'Remarks', value: 'remarks_additional_information' },
          //     { label: 'Document', value: 'upload_additional_information' },
          //   ],
          //   data: data?.additionalDocuments,
          // },

        },
      };
      console.log('Body', body);
      console.log("Mining Data from service", data?.fcFormB[5]?.data?.fcFormBMiningProposals?.fcFormBMiningProductionDetails);
      console.log("NEw",data?.fcFormB[3]?.data?.fcFormBAfforestationDetails?.comp_afforestation_type);
      
      console.log('Form B Details', data?.fcFormB);

      return body;
    }

    if (type == 'FC_FORM_C') {
      body = {
        CAF,
        fcFormC: {
          'Whether the project area falls within protected area or within 1 km of distance from the boundary of PA in the Eco sensitive zone': data?.fcFormC?.is_project_falls_within_protected_area,
          'State ': data?.fcFormC?.proponentApplications?.state,
          'Whether the proposal falls into the exempted category of Surveying Forest Area ': data?.fcFormC?.isSurveyingProposal,
          'Purpose of Survey ': data?.fcFormC?.datasurveyPurpose,
          'Total area of the prospecting lease (ha.)': data?.fcFormC?.total_prospecting_lease_area,
          'Non-Forest Land (ha.)': data?.fcFormC?.non_forest_proposed_exploration_area,
          'Forest Land': data?.fcFormC?.forest_proposed_exploration_area,
          'Date of issue of the License / Letter of Intent (LOI) for grant of prospecting lease/ Proposed project ': data?.fcFormC?.grant_prospecting_lease_date,
          'Upload a copy of the License / Letter of Intent (LOI) (pdf only)': data?.fcFormC?.letter_of_intent_copy,

          'Whether any proposal seeking prior approval of Central Government under the Van (Sanrakshan Evam Samvardhan) Adhiniyam, 1980 for diversion of forest land required for this project has been submitted in the past? ': data?.fcFormC?.is_proposal_seeking_prior_approval,
          'Status of the proposal': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Status of the proposal', value: 'status_of_proposal' },
              { label: 'Proposal No./ SW No. / CDN No ', value: 'proposal_no' },
              { label: 'Proposal Name  ', value: 'proposal_name' },
              { label: 'MoEFCC File No. ', value: 'moefcc_file_no' },
              {
                label: 'Area proposed for Diversion (ha.) ',
                value: 'area_proposed_diversion',
              },
              {
                label: 'Area recommended for Diversion (ha.)',
                value: 'recommended_diversion_area',
              },
              {
                label: 'Date of in-principle approval',
                value: 'in_principal_approval_date',
              },
              { label: 'Date of final approval', value: 'final_approval' },
              { label: 'Date of Application', value: 'application_date' },
            ],
            data: data?.fcFormC?.fcFormCPriorApprovals,
          },
          // from getExploredForestLand
          'Details of Forestland proposed to be explored/ surveyed (Village / Division / District Wise Breakup)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Legal Status of Forest Land ',
                value: 'legal_status_of_forest_land',
              },
              { label: 'Forest Area (ha)', value: 'legal_status_forest_land_area' },
            ],
            //data: data?.fcFormC?.exposedForestLand ,
            //data : this.showData(data?.fcFormC?.exposedForestLand)
            data: this.processArrayValues(data?.fcFormC?.exposedForestLand).map((item: { legal_status_of_forest_land: any; legal_status_forest_land_area: any; }) => ({
              legal_status_of_forest_land: item.legal_status_of_forest_land || '',
              legal_status_forest_land_area: item.legal_status_forest_land_area || ''
            }))

          },
          'Total period for which the forestland is proposed to be excecuted to be explored/ surveyed (No. of years)': data?.fcFormC?.fcFormCProposedLand?.total_proposed_diversion_period,
          //picking it from getProposedDiversion
          "Details of forestland proposed for exploration/ survey": {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: "Division", value: 'division' },
              { label: "Upload single KML of all the patches/ segments for the selected division", value: 'kml' },
              { label: "Number of patches/Segments", value: 'total_patches' }],
            data: data?.proposedDiversionData,
          },
          //
          'Total Forest Land Area (ha)': data?.fcFormC?.fcFormCProposedLand?.total_forest_land,
          'Total Non Forest Land Area (ha)': data?.fcFormC?.fcFormCProposedLand?.total_non_forest_land,
          
          'Whether the Project requires Clearance under the Environment (Protection) Act 1986 (Environmental clearance)?' : data?.fcFormC?.fcFormCOtherDetails?.is_ec_required,
          'Status of Environmental Clearance ' : data?.fcFormC?.fcFormCOtherDetails?.ec_application_status,
          'Proposal No.' : data?.fcFormC?.fcFormCOtherDetails?.ec_proposal_no,
          'Proposal Name ' : data?.fcFormC?.fcFormCOtherDetails?.ec_proposal_name,
          'MoEFCC File No. ' : data?.fcFormC?.fcFormCOtherDetails?.ec_moefcc_file_no,
          'Area proposed for Diversion (ha.) ' : data?.fcFormC?.fcFormCOtherDetails?.ec_area_proposed_diversion,
          'Date of application' : this.getDisplayDateFormat(data?.fcFormC?.fcFormCOtherDetails?.ec_submission_date),
          'Date of issue of environmental clearance' : this.getDisplayDateFormat(data?.fcFormC?.fcFormCOtherDetails?.ec_issued_date),
          'MoEFCC / SEIAA File Number ' : data?.fcFormC?.fcFormCOtherDetails?.ec_moefcc_file_no,
          'Upload EC Letter (in pdf) ' : data?.fcFormC?.fcFormCOtherDetails?.ec_letter,
          'Status of application [Submitted recently / ToR Granted / PH Conducted / Under appraisal] ' : data?.fcFormC?.fcFormCOtherDetails?.ec_application_sub_status ,
          
          // getSurfaceSampling is calling for this
          'Details of activities proposed to be undertaken in forest land': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Surface sampling', value: 'surface_sampling' },
              { label: 'Area (ha)', value: 'area' }],
            // data : data?.fcFormC?.surfaceSamplingDropDown?.surfaceSampling ,
            //data : data?.fcFormC?.surfaceSamplingDropDown ,
            //data : this.showData(data?.fcFormC?.surfaceSamplingDropDown)
          },

          'Numbers of Pits[N}': data?.fcFormC?.fcFormCActivitiesDetails?.no_of_pits,
          'Width of Pits (km) [W]': data?.fcFormC?.fcFormCActivitiesDetails?.width_of_pit,
          'Depth of Pits (km) [D]': data?.fcFormC?.fcFormCActivitiesDetails?.depth_of_pit,

          'Length of Pits (km) [L]': data?.fcFormC?.fcFormCActivitiesDetails?.length_of_pit,
          'Number of Boreholes per 10 sq.': data?.fcFormC?.fcFormCActivitiesDetails?.no_of_drills,
          'Diameter (inches) [D]': data?.fcFormC?.fcFormCActivitiesDetails?.diameter,
          'Depth of boreholes (metres)': data?.fcFormC?.fcFormCActivitiesDetails?.depth_of_boreholes,
          'Area of forest land likely to be experience temporary change in land used at each borehole site (ha) ': data?.fcFormC?.fcFormCActivitiesDetails?.forest_area_temporary_change,
          'Area of forest land likely to be experience permanent change of land used due to drilling or  borehole  (ha) ': data?.fcFormC?.fcFormCActivitiesDetails?.forest_area_permanent_change,

          'No of Shot holes per sq. KM ': data?.fcFormC?.fcFormCActivitiesDetails?.no_of_shot_holes,
          'Diameter (inches) [D] ': data?.fcFormC?.fcFormCActivitiesDetails?.shot_holes_diameter,
          'Construction of roads or paths involving temporary change in land use Length (km) [L] ': data?.fcFormC?.fcFormCActivitiesDetails?.length_temporary_change,
          'Construction of roads or paths involving temporary change in land use Width (km) [W] ': data?.fcFormC?.fcFormCActivitiesDetails?.width_in_temporary_change,
          'Construction of roads or paths involving permanent change in land use (km) [L]': data?.fcFormC?.fcFormCActivitiesDetails?.length_permanent_change,
          'Construction of roads or paths involving permanent change in land use (km) [W]': data?.fcFormC?.fcFormCActivitiesDetails?.width_in_permanent_change,
          'Whether any other activity involving temporary changes in land use in proposed to be undertaken?  ': data?.fcFormC?.fcFormCActivitiesDetails?.ec_letter,
          'Please specify temporary ': data?.fcFormC?.fcFormCActivitiesDetails?.activity_on_temporary_change,
          'Area involved (ha) temporary': data?.fcFormC?.fcFormCActivitiesDetails?.activity_area_on_temporary_change,
          'Whether any other activity involving permanent changes in land use in proposed to be undertaken?  ': data?.fcFormC?.fcFormCActivitiesDetails?.is_activity_involved_on_permanent_change,
          'Please specify permanent ': data?.fcFormC?.fcFormCActivitiesDetails?.activity_on_permanent_change,
          'Area involved (ha) permanent': data?.fcFormC?.fcFormCActivitiesDetails?.activity_area_on_permanent_change,


          'Surface sampling (ha)': data?.fcFormC?.fcFormCLandDetailsN?.surface_sampling_of_temporary_change,
          'Pitting or trenching (ha) ': data?.fcFormC?.fcFormCLandDetailsN?.pitting_of_temporary_change,
          'Drilling of bore holes (ha) temporary change': data?.fcFormC?.fcFormCLandDetailsN?.drilling_of_boreholes_of_temporary_change,
          'No. of Shot holes per sq. KM temporary change': data?.fcFormC?.fcFormCLandDetailsN
            ?.no_of_shot_holes_temporary_change,
          'Construction of roads or paths (ha) temporary change': data?.fcFormC?.fcFormCLandDetailsN
            ?.construction_of_roads_temporary_change,
          'Other activity (pl .Specify) temporary change': data?.fcFormC?.fcFormCLandDetailsN
            ?.other_activity_temporary_change,
          'Drilling of bore holes (ha)  Permanent change': data?.fcFormC?.fcFormCLandDetailsN
            ?.drilling_of_boreholes_of_permanent_change,
          'No. of Shot holes per sq. KM Permanent change': data?.fcFormC?.fcFormCLandDetailsN
            ?.no_of_shot_holes_permanent_change,
          'Construction of roads or paths (ha) Permanent change': data?.fcFormC?.fcFormCLandDetailsN
            ?.construction_of_roads_permanent_change,
          'Other activity (pl .Specify) Permanent change': data?.fcFormC?.fcFormCLandDetailsN
            ?.other_activity_permanent_change,
          'Details of machinery or equipment to be deployed for prospecting': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Name of equipment or machinery', value: 'name_equipment' },
              { label: 'Mode of traction', value: 'mode_of_traction' },
              { label: 'Size (L X B X H) (Cubic Meters) ', value: 'size' },
              { label: 'Estimated deployment (machine hours)', value: 'estimated_deployment' },
              { label: 'Maximum noise level (decibel)', value: 'maximum_noise' },
            ],
            //data : data?.fcFormC?.machinery,
            data: this.showData(data?.fcFormC?.machinery, ["name_equipment", "mode_of_traction", "size", "estimated_deployment", "maximum_noise"]),
          },

          'Details of existing paths/roads in the proposed to be utilized for transport of the equipment or machines in the forest land' : 
          data?.fcFormC?.fcFormCLandDetailsN?.details_of_existing_path_copy,
          
          'No. of person' : data?.fcFormC?.fcFormCLandDetailsN?.no_of_person,
          'Duration (days) ' : data?.fcFormC?.fcFormCLandDetailsN?.duration_of_person,
          'Details of sample' : data?.fcFormC?.fcFormCLandDetailsN?.details_of_sample,
          'Qty. proposed to be collected (Metric Ton)' : data?.fcFormC?.fcFormCLandDetailsN?.quantity_proposed_sample,
          'Estimated accuracy level for mineral reserve assessment (%)' : data?.fcFormC?.fcFormCLandDetailsN?.estimated_accuracy_level_mineral_reserve,
          'Estimated confidence level for mineral reserve assessment (%)' : data?.fcFormC?.fcFormCLandDetailsN?.estimated_confidence_mineral_reserve,
          'Accuracy' : data?.fcFormC?.fcFormCLandDetailsN?.estimated_accuracy_level_boreholes,
          'Confidence level' : data?.fcFormC?.fcFormCLandDetailsN?.estimated_confidence_boreholes,
          'Proposal No. ' : data?.fcFormC?.fcFormCLandDetailsN?.proposal_no,
          'Date of the approval accorded ' : this.getDisplayDateFormat(data?.fcFormC?.fcFormCLandDetailsN?.date_of_approval),
          'Area of forest land permitted for prospecting (ha)' : data?.fcFormC?.fcFormCLandDetailsN?.permitted_forest_land_area,
          'Validity (From) period of approval' : data?.fcFormC?.fcFormCLandDetailsN?.validity_from,
          'Validity (To) period of approval' : data?.fcFormC?.fcFormCLandDetailsN?.validity_to,
          
          //
          'Status of compliance of conditions stipulated in approval granted in the past': {

            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Condition stipulated', value: 'compliance_condition' },
              { label: 'Status of compliance', value: 'compliance_status' }],

            // data : data?.fcFormC?.surfaceSamplingDropDown ,
            data: this.showData(data?.fcFormC?.complienceReportDetail, ["compliance_condition", "compliance_status"]),

          },

          //
          'Details of violation(s), if any committed': data?.fcFormC?.fcFormCLandDetailsN?.details_of_violation,
          'Justification for extension of permission accorded for prospecting': data?.fcFormC?.fcFormCLandDetailsN?.justification_for_extension_copy,
          'Upload a copy of note containing details of prospecting activities undertaken and sample collected, so far (pdf only)': data?.fcFormC?.fcFormCLandDetailsN?.note_containing_details_copy,
          'Whether CA land is applicable or not?': data?.fcFormC?.fcFormCAfforestationDetails?.is_applicable_compensatory_afforestation,
          //Non forest land
          'Type of Compensatory Afforestation': data?.fcFormC?.fcFormCAfforestationDetails?.comp_afforestation_type,
          // form getFcFormCPatchKmls
          'Upload single KML of all the patches for the CA land': data?.patch_kml,
          'Total CA Land': data?.fcFormC?.fcFormCAfforestationDetails?.ca_land,
          'Number of patches': data?.fcFormC?.fcFormCAfforestationDetails?.total_patches,
          'No. of districts involved for raising Compensatory Afforestation': data?.fcFormC?.fcFormCAfforestationDetails?.total_districts_involved_in_ca,
          'Upload a scanned copy of the map of the land identified for creation of Compensatory Afforestation prepared by using GPS or Total Station (pdf only)': data?.fcFormC?.fcFormCAfforestationDetails?.identified_land_for_compensatory_afforestaion_copy,
          'Whether the area of non-forest land or Revenue forest land required to be provided by User Agency for raising Compensatory Afforestation is less than area of forest land proposed to be diverted?': data?.fcFormC?.fcFormCAfforestationDetails?.is_applicable_compensatory_afforestation,
          'Reason thereof': data?.fcFormC?.fcFormCAfforestationDetails?.ua_land_smaller_reason,
          'Area of non-forest or Revenue forest land required to be provided by User Agency for raising Compensatory Afforestation (in ha)': data?.fcFormC?.fcFormCAfforestationDetails?.ua_land_area,
          //only
          'No. of trees proposed to be cut [A]': data?.fcFormC?.fcFormCAfforestationDetails?.trees_to_be_cut,
          'No. of trees proposed to be planted for compensatory plantation [B]': data?.fcFormC?.fcFormCAfforestationDetails?.trees_to_be_planted,
          'Ratio of compensation [B]/[A]': data?.fcFormC?.fcFormCAfforestationDetails?.trees_compensation_ratio,
          'Land on which compensatory plantation proposed ': data?.fcFormC?.fcFormCAfforestationDetails?.trees_plantation_land_type,
          // 'Details of Additional information, if any ': {
          //   type: 'RELATIONAL_DATA',
          //   headers: [
          //     { label: 'Document Category', value: 'document_category' },
          //     { label: 'Document Name', value: 'document_name' },
          //     { label: 'Remarks', value: 'remarks_additional_information' },
          //     { label: 'Document', value: 'upload_additional_information' },
          //   ],
          //   data: data?.additionalDocuments,
          // }
        },
      };
      console.log('Body', body);
      console.log('Machinery data from service check', data?.fcFormC?.machinery[0]?.maximum_noise);
      console.log("Machine from SHow data", this.showData(data?.fcFormC?.machinery, ["name_equipment", "mode_of_traction", "size", "estimated_deployment", "maximum_noise"])?.name_equipment);
      console.log('Exposed Forest Land from service', data?.fcFormC?.exposedForestLand);
      console.log('Complience Report Detail from service check', data?.fcFormC?.complienceReportDetail[0]?.compliance_condition);
      console.log('Sampling Data from service', data?.fcFormC?.surfaceSamplingDropDown);
      console.log('Proposed  DEtail Data from service', data?.proposedDiversionData)
      return body;
    }

    // ----- EC Form 7 ------
    if (type == 'EC_FORM_7') {
      body = {
        CAF,
        ecForm7: {
          'Proposal No.': data?.ecForm7Data?.ec_proposal_no,
          'Name of the Project': data?.ecForm7Data?.project_name,
          'Application filled by': data?.ecForm7Data?.application_made_by,
          'Whether the change of ownership is involved?':
            data?.ecForm7Data?.is_changed_involved,
          'Name of the Company / Organization/User agency':
            data?.ecForm7Data?.company_name,
          'Door/ House No, Street Number and Name':
            data?.ecForm7Data?.company_house,
          State: data?.ecForm7Data?.company_state,
          District: data?.ecForm7Data?.company_district,
          'Pin Code.': data?.ecForm7Data?.company_pincode,
          Landmarks: data?.ecForm7Data?.company_landmark,
          'E-mail address': data?.ecForm7Data?.company_email,
          'Landline Number': data?.ecForm7Data?.company_landline,
          'Mobile number': data?.ecForm7Data?.company_mobile,
          'Legal Status of the Company / Organisation / User Agency':
            data?.ecForm7Data?.company_legal_status,
          'Transferee (Name of the Company / Organization/User agency)':
            data?.ecForm7Data?.transferer_company_name,
          'Door/ House No, Street Number and Name (Registered Address)':
            data?.ecForm7Data?.transferer_company_house,
          'Village /Town / City': data?.ecForm7Data?.transferer_company_village,
          'State (Registered Address)': data?.ecForm7Data?.transferer_company_state,
          'District (Registered Address)':
            data?.ecForm7Data?.transferer_company_district,
          'Pin Code (Registered Address)':
            data?.ecForm7Data?.transferer_company_pincode,
          'Landmarks (Registered Address)':
            data?.ecForm7Data?.transferer_company_landmark,
          'e-mail address': data?.ecForm7Data?.transferer_company_email,
          'Landline Number (Registered Address)':
            data?.ecForm7Data?.transferer_company_landline,
          'Mobile number  (Registered Address)':
            data?.ecForm7Data?.transferer_company_mobile,
          'Legal Status of the Company / Organisation / User Agency  (Registered Address)':
            data?.ecForm7Data?.transferer_company_legal_status,
          'Name of the applicant': data?.ecForm7Data?.applicant_name,
          Designation: data?.ecForm7Data?.applicant_designation,
          'Door/ House No, Street Number and Name (Correspondence Address)':
            data?.ecForm7Data?.applicant_house,
          'Village /Town / City (Correspondence Address)': data?.ecForm7Data?.state,
          'State (Correspondence Address)': data?.ecForm7Data?.applicant_state,
          'District (Correspondence Address)':
            data?.ecForm7Data?.applicant_district,
          'Pin Code (Correspondence Address)': data?.ecForm7Data?.applicant_pincode,
          'Landmarks (Correspondence Address)':
            data?.ecForm7Data?.applicant_landmark,
          'e-mail address (Correspondence Address)':
            data?.ecForm7Data?.applicant_email,
          'Landline Number (Correspondence Address)':
            data?.ecForm7Data?.applicant_landline,
          'Mobile number (Correspondence Address)':
            data?.ecForm7Data?.applicant_mobile,
          'Upload KML': data?.ecForm7Data,
          'Location of the Project or Activity': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Toposheet No.', value: 'toposheet_no' },
              { label: 'State/UT', value: 'state' },
              { label: 'District', value: 'district' },
              { label: 'Sub District', value: 'sub_district_code' },
              { label: 'Village', value: 'village' },
              { label: 'Plot/Survey/Khasra No.', value: 'plot_no' },
            ],
            data: data?.cafData?.ecForm7CafKML?.cafKMLPlots,
          },
          'Plot / Survey / Khasra No.': data?.ecForm7Data?.organization_plot,
          'Village(s)': data?.ecForm7Data?.organization_village,
          'Sub-District(s)': data?.ecForm7Data?.organization_sub_district,
          'District (Organization)': data?.ecForm7Data?.organization_district,
          'State (Organization)': data?.ecForm7Data?.organization_state,
          'Pincode (Organization)': data?.ecForm7Data?.organization_pincode,

          'Item No. as per schedule to EIA Notification, 2006 for Minor Activity': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Activity',
                value: 'activityId',
              },
              {
                label: 'Sub Activity',
                value: 'subActivityId',
              },
            ],
            data: this.changeToArray(data?.ecForm7Data?.activityArray, 'ACTIVITY'),
          },
          'Category of the project as per EIA Notification, 2006':
            data?.ecForm7Data?.project_category,
          'Whether proposal is required to be appraised at Central level?':
            data?.ecForm7Data?.is_proposed_required,
          'Reason for application at Central Level':
            data?.ecForm7Data?.reason_for_application,
          'Reason (Please Specify)':
            data?.ecForm7Data?.reason_for_application_other,

          //-----  Form 7 project details ----//ecForm7ProjectActivity
          'MoEF&CC / SEIAA File No.': data?.ecForm7Data?.ecForm7ProjectActivity?.moef_file_no,
          'Date of issue of Prior environmental clearance':
            data?.ecForm7Data?.ecForm7ProjectActivity?.prior_environmental_clearance_date,
          'Copy of EC letter': data?.ecForm7Data?.ecForm7ProjectActivity?.ec_letter_copy,
          'Whether any amendment/corrigendum/transfer to the earlier EC has been obtained?':
            data?.ecForm7Data?.ecForm7ProjectActivity?.is_earlier_ec_obtained,
          // 'Select ( EC has been obtained)': data?.ecForm7Data?.ec_obtained_select,
          // 'Date of `$ {data?.ecForm7Data?.ec_obtained_select}`': data?.ecForm7Data?.ec_obtained_date,
          // 'Upload `$ {form.get("ec_obtained_select")?.value }` to the earlier EC': data?.ecForm7Data?.ec_obtained_document,
          'Item No. as per schedule to EIA Notification, 2006 for Minor Activity (Yes)':
          {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Select',
                value: 'ec_obtained_select',
              },
              {
                label: 'Date',
                value: 'ec_obtained_date',
              },
              {
                label: 'Upload',
                value: 'ec_obtained_document',
              },
            ],
            data: data?.ecForm7Data?.ecForm7ProjectActivity?.ecForm7Obtaineds,
          },
          'Brief note on the chronology of the clearances and subsequent amendments/corrigendum/transfers, if any.':
            data?.ecForm7Data?.ecForm7ProjectActivity?.chronology_of_clearances,
          // 'Village(s)': data?.ecForm7Data?.organization_village,
          // ---- Form 7 Activity Details ---- //
          'Status of Implementation of Project or Activity?':
            data?.ecForm7Data?.ecForm7ActivityStatus?.status_of_implementation,
          'Reference Number of latest Consent obtained from SPCB/UTPCC':
            data?.ecForm7Data?.ecForm7ActivityStatus?.reference_number_consent_obtained,
          'Date of latest consent issued':
            data?.ecForm7Data?.ecForm7ActivityStatus?.latest_consent_issued_date,
          'Validity of latest consent (valid up to)':
            data?.ecForm7Data?.ecForm7ActivityStatus?.latest_consent_validity,
          'Upload copy of latest consent order':
            data?.ecForm7Data?.ecForm7ActivityStatus?.latest_consent_order_copy,
          'Details of Units / Components / Capacity granted': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Details of Unimplemented units',
                value: 'unimplemented_details',
              },
              {
                label: 'Remarks',
                value: 'remarks',
              },
              {
                label: 'EC',
                value: 'ec_granted',
              },
              {
                label: 'CTE',
                value: 'cte_granted',
              },
              {
                label: 'CTO',
                value: 'cto_granted',
              },
            ],
            data: data?.ecForm7Data?.ecForm7ActivityStatus?.ecForm7EcStatus,
          },
          //--- Form 7 enclouser--- //

          'No Objection from the transferor':
            data?.ecForm7Data?.ecForm7AttachedDocuments?.objection_from_transferor_copy,
          'Undertaking by transferee stating regarding acceptance of the terms and conditions under which the Prior Environment Clearance was granted.':
            data?.ecForm7Data?.ecForm7AttachedDocuments?.undertaking_by_transferee_copy,
          'Copy of Transfer of the firm from competent authority':
            data?.ecForm7Data?.ecForm7AttachedDocuments?.transfer_from_competent_copy,
          'Previous compliance report':
            data?.ecForm7Data?.ecForm7AttachedDocuments?.previous_compliance_report,
        },
      };
      console.log('Body', body);

      return body;
    }
    // ----- EC Form 8 Tor  Project Details ------
    if (type == 'EC_FORM_8') {
      body = {
        CAF,
        ecForm8: {
          'Proposal No.': data?.ecForm8Data?.ec_proposal_no,
          'Name of the Project': data?.ecForm8Data?.project_name,
          'Application filled by': data?.ecForm8Data?.application_made_by,
          'Name of the Company/Organization/User agency': data?.ecForm8Data?.transferer_name,
          'Door/House No.; Street Number and Name': data?.ecForm8Data?.ec_proposal_no,
          'Village/Town/City': data?.ecForm8Data?.transferer_city,
          'State': data?.ecForm8Data?.transferer_state,
          'District': data?.ecForm8Data?.transferer_district,
          'Pin Code': data?.ecForm8Data?.transferer_pincode,
          'Landmarks': data?.ecForm8Data?.transferer_landmark,
          'e-mail address': data?.ecForm8Data?.transferer_email,
          'Landline Number': data?.ecForm8Data?.transferer_landline_no,
          'Mobile number': data?.ecForm8Data?.transferer_mobile_no,
          'Name of the Company/Organization/User agency (Transferee)': data?.ecForm8Data?.transferee_name,
          'Door/House No.; Street Number and Name (Transferee)': data?.ecForm8Data?.transferee_street,
          'Village/Town/City (Transferee)': data?.ecForm8Data?.transferee_city,
          'State (Transferee)': data?.ecForm8Data?.transferee_state,
          'District (Transferee)': data?.ecForm8Data?.transferee_district,
          'Pin Code (Transferee)': data?.ecForm8Data?.transferee_pincode,
          'Landmarks (Transferee)': data?.ecForm8Data?.transferee_landmark,
          'e-mail address (Transferee)': data?.ecForm8Data?.transferee_email,
          'Landline Number (Transferee)': data?.ecForm8Data?.transferee_landline_no,
          'Mobile number (Transferee)': data?.ecForm8Data?.transferee_mobile_no,
          'Legal Status of the Company/Organization/User Agency': data?.ecForm8Data?.transferee_legal_status,
          'Name of the applicant (First Name, Middle Name, Last Name)': data?.ecForm8Data?.applicant_name,
          'Designation': data?.ecForm8Data?.applicant_designation,
          'Enter Door/House No. , Street Number and Name': data?.ecForm8Data?.applicant_street,
          'Village/Town/City (Person Making)': data?.ecForm8Data?.applicant_city,
          'State (Person Making)': data?.ecForm8Data?.applicant_state,
          'District (Person Making)': data?.ecForm8Data?.applicant_district,
          'Pin Code (Person Making)': data?.ecForm8Data?.applicant_pincode,
          'Landmarks (Person Making)': data?.ecForm8Data?.applicant_landmark,
          'E-mail address': data?.ecForm8Data?.applicant_email,
          'Landline Number (Person Making)': data?.ecForm8Data?.applicant_landline_no,
          'Mobile number (Person Making)': data?.ecForm8Data?.applicant_mobile_no,
          'Whether multiple items (Components) as per the notification involved in the proposal?': data?.ecForm8Data?.is_multiple_items_involved,
          'Item No. as per schedule to EIA Notification, 2006 for Major Activity': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Activity',
                value: 'activityId',
              },
              {
                label: 'Sub Activity',
                value: 'subActivityId',
              },
              {
                label: 'Capacity',
                value: 'threshold',
              },
              {
                label: 'Unit',
                value: 'unit',
              },
            ],
            data: this.changeToArray(data?.ecForm8Data?.activityArray, 'ACTIVITY'),
          },
          'Category of the project as per EIA Notification, 2006': data?.ecForm8Data?.project_category,
          'Whether proposal is required to be appraised at Central level?': data?.ecForm8Data?.is_proposed_required,
          'Reason for application at Central Level': data?.ecForm8Data?.reason_for_application,
          'Please Specify (Reason)': data?.ecForm8Data?.reason_for_application_other,
          // ---- EC Form 8 Tor Location of Project ----
          'Upload KML': data?.ecForm8Data,
          // 'Location of the Project or Activity': {
          //   type: 'RELATIONAL_DATA',
          //   headers: [
          //     { label: 'Toposheet No.', value: 'toposheet_no' },
          //     { label: 'State/UT', value: 'state' },
          //     { label: 'District', value: 'district' },
          //     { label: 'Sub District', value: 'sub_district_code' },
          //     { label: 'Village', value: 'village' },
          //     { label: 'Plot/Survey/Khasra No.', value: 'plot_no' },
          //   ],
          //   data: data?.cafData?.ecForm7CafKML?.cafKMLPlots,
          // },
          'Plot / Survey / Khasra No.': data?.ecForm8Data?.organization_plot,
          'Village(s)': data?.ecForm8Data?.organization_village,
          'Sub-District(s)': data?.ecForm8Data?.organization_sub_district,
          'District (Organization)': data?.ecForm8Data?.organization_district,
          'State (Organization)': data?.ecForm8Data?.organization_state,
          'Pincode (Organization)': data?.ecForm8Data?.organization_pincode,

          'From (Bounded Latitudes North)': data?.ecForm8Data?.organization_latitudes_ne_from,
          'To (Bounded Latitudes North)': data?.ecForm8Data?.organization_latitudes_ne_to,
          'From (Bounded Latitudes East)': data?.ecForm8Data?.organization_longitudes_sw_from,
          'To (Bounded Latitudes East)': data?.ecForm8Data?.organization_longitudes_sw_to,
          // ---- EC Form 8 Tor Details of Tor ----
          'MoEF&CC / SEIAA File No': data?.ecForm8Data?.moef_file_no,
          'Date of issue of Terms of Reference': data?.ecForm8Data?.ec_proposal_no,
          'Proposal': data?.ecForm8Data?.date_of_issue_environmental_clearance,
          'Copy of ToR letter': data?.ecForm8Data?.copy_of_ec_letter,
          'Whether any amendment/corrigendum/transfer to the earlier ToR has been obtained?': data?.ecForm8Data?.is_tor_obtained,
          'Upload amendment to the earlier ToR': {
            type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Select', value: 'is_any_tor' },
              { label: 'Date of amendment/corrigendum/transfer', value: 'date_of_tor' },
              { label: 'Upload amendment/corrigendum/transfer', value: 'upload_tor' },
            ],
            data: data?.ecForm8Data?.uploadTOR
          },
          'Brief note on the chronology of the clearances and subsequent transfer, if any': data?.ecForm8Data?.upload_chronology_tor,
          // ---- EC Form 8 Tor Documents ----
          'No Objection from the transferor': data?.ecForm8Data?.objection_from_transferor_copy,
          'Undertaking by transferee stating regarding acceptance of the terms and conditions under which the ToR was granted': data?.ecForm8Data?.undertaking_by_transferee_copy,
          'Copy of Transfer of the firm from competent authority': data?.ecForm8Data?.transfer_from_competent_copy,
        },
      },
        console.log('Body', body);

      return body;
    }

    const PartA = {
      'Whether multiple items (Components) as per the notification involved in the proposal?':
        data?.ecPartA?.is_multiple_item_envolved,
      'Item No. as per schedule to EIA Notification, 2006 ': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Activity',
            value: 'activityName',
          },
          {
            label: 'Sub Activity',
            value: 'subActivityName',
          },
        ],
        data: this.changeToArray(
          data?.ecPartA?.environmentClearanceProjectActivityDetails,
          'ACTIVITY'
        ),
      },
      'Whether project/activity attracts the General Condition specified in the Schedule of EIA Notification?':
        data?.ecPartA?.is_general_condition_specified,
      'Whether located proximity to Protected Area Notified Under the Wild Life (Protection) Act, 1972?':
        data?.ecPartA?.is_protected,
      'located proximity to Protected Area Notified Under the Wild Life (Protection) Act, 1972 (Deatils)':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name',
            value: 'name',
          },
          {
            label: 'Shortest distance from the project boundary in Km',
            value: 'shortest_distance',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: this.filteredArray(
          data?.ecPartA?.environmentClearanceProjectAreaDetails,
          'area_category',
          'Protected'
        ),
      },
      'Whether located proximity to Critically Polluted area as identified by the CPCB from time to time?':
        data?.ecPartA?.is_critically_polluted,
      'located proximity to Critically Polluted area as identified by the CPCB from time to time':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name',
            value: 'name',
          },
          {
            label: 'Shortest distance from the project boundary in Km',
            value: 'shortest_distance',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: this.filteredArray(
          data?.ecPartA?.environmentClearanceProjectAreaDetails,
          'area_category',
          'Critically Polluted'
        ),
      },
      'Whether located proximity to Notified Eco-Sensitive area notified under Environmental (Protection) Act, 1986?':
        data?.ecPartA?.is_eco_sensitive,
      'located proximity to Notified Eco-Sensitive area notified under Environmental (Protection) Act, 1986':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name',
            value: 'name',
          },
          {
            label: 'Shortest distance from the project boundary in Km',
            value: 'shortest_distance',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: this.filteredArray(
          data?.ecPartA?.environmentClearanceProjectAreaDetails,
          'area_category',
          'EcoSensitive'
        ),
      },
      'Whether located proximity to Inter-State Boundaries and International Boundaries?':
        data?.ecPartA?.is_inter_state,
      'located proximity to Inter-State Boundaries and International Boundaries':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name',
            value: 'name',
          },
          {
            label: 'Shortest distance from the project boundary in Km',
            value: 'shortest_distance',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: this.filteredArray(
          data?.ecPartA?.environmentClearanceProjectAreaDetails,
          'area_category',
          'Inter-State'
        ),
      },
      'Whether located proximity to Severely polluted areas as identified by the CPCB from time to time?':
        data?.ecPartA?.is_severely_populated,
      'located proximity to Severely polluted areas as identified by the CPCB from time to time':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name',
            value: 'name',
          },
          {
            label: 'Shortest distance from the project boundary in Km',
            value: 'shortest_distance',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: this.filteredArray(
          data?.ecPartA?.environmentClearanceProjectAreaDetails,
          'area_category',
          'Severely Populated'
        ),
      },
      'Category of the Project as per EIA Notification, 2006':
        data?.ecPartA?.project_category,
      'Whether proposal is required to be appraised at Central level?':
        data?.ecPartA?.is_proposed_required,
      'Reason for application at Central Level':
        data?.ecPartA?.central_application_reason,
      'Whether Proposal has interlinked / interdependent projects or activities?':
        data?.ecPartA?.is_interlinked_proposal,
      'Whether separate application of interlinked/interdependent projects/activities has been submitted?':
        data?.ecPartA?.is_saperate_application_submitted,
      'Date of submission': data?.ecPartA?.interlink_date_of_submission,
      'Whether EC has been obtained?': data?.ecPartA?.is_interlink_ec_obtained,
      'MoEFCC/SEIAA File Number': data?.ecPartA?.interlink_moefcc_file_no,
      'Date of issue of environmental clearance':
        data?.ecPartA?.interlink_date_of_issue,
      'Upload EC Letter': data?.ecPartA?.interlink_ec_letter,
      'Whether any Forest Land involved in the project or part thereof':
        data?.ecPartA?.is_fc_involved,
      'Whether In-principle approval (Stage-I) or Final approval (Stage-II) of Forest Clearance under Van (Sanrakshan Evam Samvardhan) Adhiniyam, 1980 available for the entire forestland involved in the project?':
        data?.ecPartA?.is_fc_approval_invovled,

      'Proposal No./Project ID': data?.ecPartA?.fc_proposal_no,

      'File number': data?.ecPartA?.fc_moefcc_file_no,
      'Date of approval': data?.ecPartA?.fc_date_of_approval,

      'Area diverted/proposed to be diverted (in Ha)':
        data?.ecPartA?.fc_area_diverted,
      'Upload copy of approval': data?.ecPartA?.fc_approval_copy,
      'Status of application': data?.ecPartA?.fc_status_of_application,
      'Date of submission of Application': data?.ecPartA?.fc_date_of_submission,
      'Forest Area applied for diversion (in Ha)':
        data?.ecPartA?.fc_applied_diversion,
      'Reasons for non-submission of application':
        data?.ecPartA?.fc_non_submission_reason,
      'Whether NBWL recommendation is required?': data?.ecPartA?.is_nbwl_recomm,
      'Name of the Protected Area': data?.ecPartA?.nbwl_name_pa,
      'Location of the project': data?.ecPartA?.nbwl_project_location,
      'Whether Application submitted?':
        data?.ecPartA?.is_nbwl_application_submitted,
      'Upload NBWL Recommendation, if any': data?.ecPartA?.upload_nbwl_recomm,

      'Whether consent under Air & Water Act has been obtained from SPCB / UTPCC?':
        data?.ecPartA?.ecProjectDetail?.is_consent_under_air_water,
      'Reference Number of Consent obtained from SPCB / UTPCC':
        data?.ecPartA?.ecProjectDetail?.consent_ref_no,
      'Date of consent issued': data?.ecPartA?.ecProjectDetail?.consent_date,
      'Validity of consent (Valid up to)':
        data?.ecPartA?.ecProjectDetail?.consent_validity,
      'Upload copy of consent order':
        data?.ecPartA?.ecProjectDetail?.consent_order_copy,
      'Whether Environment Clearance available for the existing project / activity?':
        data?.ecPartA?.ecProjectDetail?.is_existing_ec_available,
      'Proposal No.': data?.ecPartA?.ecProjectDetail?.ec_proposal_no_ex,
      'Date of environmental clearance':
        data?.ecPartA?.ecProjectDetail?.issue_date,
      'MoEFCC / SEIAA File Number': data?.ecPartA?.ecProjectDetail?.ec_file_no,
      'Upload EC Letter (in pdf)':
        data?.ecPartA?.ecProjectDetail?.upload_ec_letter_copy,
      'Whether any amendment / corrigendum / transfer to the earlier EC has been obtained?':
        data?.ecPartA?.ecProjectDetail?.is_amendment_obtained_ex,
      'Amendment Type': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Select (Amendment / Corrigendum / Transfer)',
            value: 'type',
          },
          {
            label: 'Date of (Amendment / Corrigendum / Transfer)',
            value: 'amendment_date',
          },
          {
            label:
              'Upload (Amendment / Corrigendum / Transfer) to the earlier EC',
            value: 'transfer_doc',
          },
        ],
        data: data?.ecPartA?.ecProjectDetail?.ecAmendmentTransferDetails,
      },
      'Brief note on the chronology of the clearances and subsequent amendments / corrigendum / transfer, if any':
        data?.ecPartA?.ecProjectDetail?.chronology_clearances_doc,
      'Status of Implementation of Project or Activity':
        data?.ecPartA?.ecProjectDetail?.project_activity_status,
      'Reference Number of latest Consent obtained from SPCB / UTPCC':
        data?.ecPartA?.ecProjectDetail?.latest_consent_ref,
      'Reference Number of Consent to Establishment obtained from SPCB / UTPCC':
        data?.ecPartA?.ecProjectDetail?.latest_consent_ref,
      'Date of latest consent issued':
        data?.ecPartA?.ecProjectDetail?.latest_consent_date,
      'Validity of latest consent (Valid up to)':
        data?.ecPartA?.ecProjectDetail?.latest_consent_validity,
      'Upload copy of latest consent order':
        data?.ecPartA?.ecProjectDetail?.latest_consent_copy,
      'Upload copy of consent order including any renewal taken':
        data?.ecPartA?.ecProjectDetail?.latest_consent_copy,
      'Details of Units / Components / Capacity granted': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'EC',
            value: 'ec_granted',
          },
          {
            label: 'CTE',
            value: 'cte_granted',
          },
          {
            label: 'CTO',
            value: 'cto_granted',
          },
          {
            label: 'Details of Unimplemented units',
            value: 'unimplemented_details',
          },
        ],
        data: data?.ecPartA?.ecProjectDetail?.ecStatus,
      },
      'Brief Note on status of implementation of the project / activity':
        data?.ecPartA?.ecProjectDetail?.brief_note_project_status_copy,
      'Year of commencement of construction':
        data?.ecPartA?.ecProjectDetail?.ec_commencement_year,
      'Reference Number of latest Consent to Operate obtained from SPCB / UTPCC':
        data?.ecPartA?.ecProjectDetail?.ec_latest_consent_operate,
      'Validity of latest consent (valid up to)':
        data?.ecPartA?.ecProjectDetail?.ec_latest_consent_validity,
      'Whether the project/activity located in Notified Industrial Area?':
        data?.ecPartA?.ecProjectDetail?.activity_located_in_sez,
      'Type of Industrial Area':
        data?.ecPartA?.ecProjectDetail?.industrial_area_type,
      'Name of the Notified Industrial Area':
        data?.ecPartA?.ecProjectDetail?.industrial_area_name,
      'Year of notification of the Industrial area':
        data?.ecPartA?.ecProjectDetail?.industrial_area_notifictn_year,
      'Copy of Notification of Industrial area':
        data?.ecPartA?.ecProjectDetail?.industrialAreaNotificatnCopy,
      'Whether Prior Environmental Clearance available for Notified Industrial Area?':
        data?.ecPartA?.ecProjectDetail?.is_ec_available_for_nia,
      'Whether the project/activity located in CRZ or ICRZ area?':
        data?.ecPartA?.ecProjectDetail?.is_activity_located_in_crz,
      'CRZ Classification': data?.ecPartA?.ecProjectDetail?.crz_classifctn,
      'Location type': data?.ecPartA?.ecProjectDetail?.crz_location_type,
      Mangroves: data?.ecPartA?.ecProjectDetail?.mangroves,
      'Corals and Coral reefs': data?.ecPartA?.ecProjectDetail?.corals_reef,
      'Sand dunes': data?.ecPartA?.ecProjectDetail?.sand_dunes,
      'Biologically active mudflats':
        data?.ecPartA?.ecProjectDetail?.bio_active_mud,
      'National parks, marine parks, sanctuaries, reserve forests, wildlife habitats and other protected areas under the provisions of Wildlife (Protection) Act, 1972 or Forest (Conservation) Act, 1980 or Environment (Protection) Act, 1986':
        data?.ecPartA?.ecProjectDetail?.parks_protected_area,
      'Salt marshes': data?.ecPartA?.ecProjectDetail?.salt_marshes,
      'Turtle nesting grounds':
        data?.ecPartA?.ecProjectDetail?.turtle_nesting_grounds,
      "Horseshoe crabs' habitat":
        data?.ecPartA?.ecProjectDetail?.horseshoe_crabs,

      'Sea gross beds': data?.ecPartA?.ecProjectDetail?.sea_gross_beds,

      'TNesting grounds of birds':
        data?.ecPartA?.ecProjectDetail?.nesting_grounds_birds,

      'Areas of structures of archeological importance and heritage sites':
        data?.ecPartA?.ecProjectDetail?.archeological_heritage_sites,

      'Any other (Please specify)': data?.ecPartA?.ecProjectDetail?.any_other,

      'Whether recommendation of the SCZMA obtained':
        data?.ecPartA?.ecProjectDetail?.is_sczma_recomm_obtained,
      'SCZMA- Reference Number':
        data?.ecPartA?.ecProjectDetail?.sczma_application_file_no,
      'Date of recommendation':
        data?.ecPartA?.ecProjectDetail?.sczma_recomm_date,
      'Specific Conditions if any, prescribed in recommendation':
        data?.ecPartA?.ecProjectDetail?.no_sczma_application_reason,
      'Upload recommendation letter':
        data?.ecPartA?.ecProjectDetail?.sczmaRecommLetter,
      'Date of Application':
        data?.ecPartA?.ecProjectDetail?.sczma_application_date,
      'Application/file Number':
        data?.ecPartA?.ecProjectDetail?.sczma_application_file_no,
      'Current Status of application':
        data?.ecPartA?.ecProjectDetail?.sczma_application_current_status,
      'Whether the project proposed to be located in Territorial waters (Off-shore)':
        data?.ecPartA?.ecProjectDetail?.is_located_in_territorial_waters,
      'Whether project/activity attracts the Specific Condition specified in the Schedule of EIA Notification?':
        data?.ecPartA?.ecProjectDetail?.is_located_in_territorial_waters,
      'Please provide details':
        data?.ecPartA?.ecProjectDetail?.specific_condition_details,
      'Whether project/activity located in the Eco-sensitive Zone notified/proposed to be notified under Environment (Protection) Act, 1986':
        data?.ecPartA?.ecProjectDetail?.specific_condition_details,
      'Details of Products & By-products': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of Product',
            value: 'product_name',
          },
          {
            label: 'Product / By Product',
            value: 'product_by',
          },
          {
            label: 'Quantity / Capacity',
            value: 'quantity',
          },
          {
            label: 'Mode of Transport / Transmission',
            value: 'transport_mode',
          },
          {
            label: 'Other',
            value: 'transport_mode_other',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
          {
            label: 'Existing',
            value: 'quantity',
          },
          {
            label: 'Proposed',
            value: 'proposed_quantity',
          },
          {
            label: 'Total',
            value: 'quantity_total',
          },
          {
            label: 'Unit',
            value: 'unit',
          },
        ],
        data: data?.ecPartA?.ecProdTransportDetails,
      },
      'Whether any other Environmental Sensitive area exists within 10 Km from the project/activity boundary? ':
        data?.ecPartA?.ecProductDetail?.is_envrmnt_sensitive_area_exist,

      'Areas protected under international conventions, national or local legislation for their ecological, landscape, cultural or other related value':
        data?.ecPartA?.ecProductDetail?.is_area_protected,
      'Areas which are important or sensitive for ecological reasons- Wetlands, watercourses or other water bodies, coastal zone, biospheres, mountains, forests':
        data?.ecPartA?.ecProductDetail?.is_area_important,
      'Areas used by protected, important or sensitive species of flora or fauna for breeding, nesting, foraging, resting, overwintering, migration':
        data?.ecPartA?.ecProductDetail?.is_area_by_protected,
      'Inland, coastal, marine or underground waters':
        data?.ecPartA?.ecProductDetail?.is_inland,
      'Routes or facilities used by the public for access to recreation or other tourist, pilgrim areas':
        data?.ecPartA?.ecProductDetail?.is_route,
      'Defence installations':
        data?.ecPartA?.ecProductDetail?.is_defence_installation,
      'Densely populated or built-up area':
        data?.ecPartA?.ecProductDetail?.is_density_population,
      'Areas occupied by sensitive man-made land uses':
        data?.ecPartA?.ecProductDetail?.is_area_occupied,
      'Areas containing important, high quality or scarce resources':
        data?.ecPartA?.ecProductDetail?.is_area_containing_important,
      'Areas susceptible to natural hazards which could cause the project to present environmental problems similar effects':
        data?.ecPartA?.ecProductDetail?.is_area_susceptible,
      'Status of collection of baseline data':
        data?.ecPartA?.ecProductDetail?.baseline_data_status,
      From: data?.ecPartA?.ecProductDetail?.baseline_data_from,
      To: data?.ecPartA?.ecProductDetail?.baseline_data_to,
      'Seasons of collection':
        data?.ecPartA?.ecProductDetail?.baseline_data_collection_season,
      'Meteorology (Nos.)': data?.ecPartA?.ecProductDetail?.nml_meterology,
      'Ambient Air Quality (Nos.)':
        data?.ecPartA?.ecProductDetail?.nml_ambient_air_quality,
      'Surface Water Quality (Nos.)':
        data?.ecPartA?.ecProductDetail?.nml_surface_water_quality,
      'Ground Water Quality (Nos.)':
        data?.ecPartA?.ecProductDetail?.nml_ground_water_quality,
      'Ground water level (Nos.)':
        data?.ecPartA?.ecProductDetail?.nml_phreatic_surface,
      'Noise Level (Nos.)': data?.ecPartA?.ecProductDetail?.nml_noise_level,
      'Soil Quality (Nos.)': data?.ecPartA?.ecProductDetail?.nml_soil_quality,
      'Summary on the baseline situation':
        data?.ecPartA?.ecProductDetail?.baseline_situation_summary,
      'Map showing the monitoring locations':
        data?.ecPartA?.ecProductDetail?.baseline_monitoing_location_map,
      'Brief summary on the proposed baseline collection':
        data?.ecPartA?.ecProductDetail?.baselineDataCollectionSummary,
      'Whether QCI/NABET Accredited EIA Consultant engaged?':
        data?.ecPartA?.ecConsultant?.is_eia_consultant_engaged,
      'Accreditation No. / Organization Id':
        data?.ecPartA?.ecConsultant?.accreditation_no_or_organisation_id,
      'Name of the EIA Consultant Organization':
        data?.ecPartA?.ecConsultant?.consultant_name,
      Address: data?.ecPartA?.ecConsultant?.consultant_address,
      'Mobile No.': data?.ecPartA?.ecConsultant?.consultant_mobile,
      'E-mail Id': data?.ecPartA?.ecConsultant?.consultant_email,
      'Category of Accreditation (Eligible for Category A / Eligible for Category B)':
        data?.ecPartA?.ecConsultant?.consultant_category,
      'Sector(s) of Accreditation':
        data?.ecPartA?.ecConsultant?.consultant_sectors,
      'Validity of Accreditation':
        data?.ecPartA?.ecConsultant?.validity_of_accreditation,
      'Reason for not engaging the Consultant':
        data?.ecPartA?.ecConsultant?.no_eia_consultant_engaged,

      'Need for the project or activity and its importance to the country/region':
        data?.ecPartB?.need_of_project,
      'Demand - Supply Gap and Domestic and export markets, if any':
        data?.ecPartB?.demand_supply_gap,
      'Readily available': data?.ecPartB?.social_infra_available,
      'Proposed to be developed': data?.ecPartB?.social_infra_proposed,
      'Nearest railway station and its distance (in Km)':
        data?.ecPartB?.connectivity_railway,
      'Nearest Airport and its distance (in Km)':
        data?.ecPartB?.connectivity_airport,
      'Nearest Town/City/District head quarter and its distance (in Km)':
        data?.ecPartB?.nearest_town_city_details,
      'Soil classification': data?.ecPartB?.soil_classification,
      'Distance from the HFL of the river in m, if any':
        data?.ecPartB?.distance_HFL_river,
      'Social benefits of project or activity':
        data?.ecPartB?.project_social_benefit,
      // Construction Details
      'Project/ Activity Construction Status':
        data?.ecPartB?.project_construction_status,
      'Date of Start': data?.ecPartB?.date_of_start_construction,
      'Likely date of start of construction activity (start of mining operations in case of mining proposals)':
        data?.ecPartB?.date_of_start_construction,
      'Likely date of completion':
        data?.ecPartB?.date_of_completion_construction,
      'Likely date of completion of construction activity (end of mining operations in case of mining proposals)':
        data?.ecPartB?.date_of_completion_construction,

      'Whether requirement of water involved in the project?':
        data?.ecPartB?.ecConstructionDetail?.water_requirement,
      'Details of Water requirement during Construction stage': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Quantity in KLD Present',
            value: 'quantity_present',
          },
          {
            label: 'Quantity in KLD with Expansion',
            value: 'quantity_expansion',
          },
          {
            label: 'Method of water withdrawal',
            value: 'method_of_withdrawl',
          },
          {
            label: 'Distance from Source in mtr',
            value: 'distance_from_source',
          },
          {
            label: 'Mode of Transport',
            value: 'transport_mode',
          },
          {
            label: 'Details of Permission',
            value: 'permission_agreement_details',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecResource,
          'resource_type',
          'water requirement Cp'
        ),
      },
      'Details of Water requirement during Operational stage': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Quantity in KLD Present',
            value: 'quantity_present',
          },
          {
            label: 'Quantity in KLD with Expansion',
            value: 'quantity_expansion',
          },
          {
            label: 'Method of water withdrawal',
            value: 'method_of_withdrawl',
          },
          {
            label: 'Distance from Source in mtr',
            value: 'distance_from_source',
          },
          {
            label: 'Mode of Transport',
            value: 'transport_mode',
          },
          {
            label: 'Details of Permission',
            value: 'permission_agreement_details',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecResource,
          'resource_type',
          'water requirement Op'
        ),
      },

      'Whether requirement of Minerals and/or fuels involved in the project?':
        data?.ecPartB?.ecConstructionDetail?.is_minerals_and_fuels,
      'Details thereof': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of Minerals / Fuel',
            value: 'resource_name',
          },
          {
            label: 'Quantity per annum in MT',
            value: 'quantity_present',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Mode of transport',
            value: 'transport_mode',
          },
          {
            label: 'Distance from source in Km',
            value: 'distance_from_source',
          },
          {
            label: 'Details of linkage / supply agreement',
            value: 'permission_agreement_details',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecResource,
          'resource_type',
          'minerals fuel'
        ),
      },
      'Construction material':
        data?.ecPartB?.ecConstructionDetail?.is_construction_material,
      'construction material': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Construction material',
            value: 'resource_name',
          },
          {
            label: 'Quantity in MT',
            value: 'quantity_present',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Mode of transport',
            value: 'transport_mode',
          },
          {
            label: 'Distance from source in Km',
            value: 'distance_from_source',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecResource,
          'resource_type',
          'construction detail'
        ),
      },

      Timber: data?.ecPartB?.ecConstructionDetail?.is_timber,
      'Quantity of timber required (MT):':
        data?.ecPartB?.ecConstructionDetail?.timber_quantity,
      'Source of timber': data?.ecPartB?.ecConstructionDetail?.timber_source,
      'Other information, if any':
        data?.ecPartB?.ecConstructionDetail?.timber_other,
      'Electric Power': data?.ecPartB?.ecConstructionDetail?.is_electric,
      'Total Electricity requirement (MW)':
        data?.ecPartB?.ecConstructionDetail?.total_electricity_required,
      'Main Source': data?.ecPartB?.ecConstructionDetail?.electric_source,
      'Renewable energy proposed to install (KW)':
        data?.ecPartB?.ecConstructionDetail?.electric_renewable_energy,
      'Percentage contribution of renewable energy':
        data?.ecPartB?.ecConstructionDetail?.electric_percentage_contribution,
      'Standby arrangements (details of DG Sets)':
        data?.ecPartB?.ecConstructionDetail?.electric_standby_arrangements,
      'Stack height in m (DG set)':
        data?.ecPartB?.ecConstructionDetail?.electric_stack_height,
      'Energy conservation measures':
        data?.ecPartB?.ecConstructionDetail?.electric_energy_conversation,
      'Whether any other natural resources / other raw materials required?':
        data?.ecPartB?.ecConstructionDetail?.is_other_natural_resources,
      'Name of the natural resource':
        data?.ecPartB?.ecConstructionDetail?.other_name_of_natural_resources,
      'Quantity (use appropriate standard units)': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the natural resource',
            value: 'resource_name',
          },
          {
            label: 'Quantity (use appropriate standard units)',
            value: 'quantity_present',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Mode of transport',
            value: 'transport_mode',
          },
          {
            label: 'Distance from source in Km',
            value: 'distance_from_source',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecResource,
          'resource_type',
          'raw material'
        ),
      },

      'Whether any use of substances or materials, which are hazardous (as per MSIHC rules) to human health or the environment (flora, fauna, and water supplies) required?':
        data?.ecPartB?.ecConstructionDetail?.is_use_of_substances,
      'quantity (use appropriate standard units)': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the substance or material',
            value: 'resource_name',
          },
          {
            label: 'HS Code',
            value: 'source',
          },
          {
            label: 'Quantity (use appropriate standard units)rce',
            value: 'quantity_present',
          },
          {
            label: 'Toxicity LD50 (mg/ kg)',
            value: 'quantity_expansion',
          },
          {
            label: 'Remarks',
            value: 'other_information',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecResource,
          'resource_type',
          'hazardous material'
        ),
      },
      'Whether any resource efficiency / optimization / recycling and reuse envisaged in the project?':
        data?.ecPartB?.ecConstructionDetail?.is_resource_efficiency,
      Details: data?.ecPartB?.ecConstructionDetail?.resource_efficiency,
      // Airport Proposal
      'Enter the Project components':
        data?.ecPartB?.ecAirportProposals[0]?.project_components,
      'Enter Passenger capacity':
        data?.ecPartB?.ecAirportProposals[0]?.passenger_capacity,
      'Aircraft compatibility':
        data?.ecPartB?.ecAirportProposals[0]?.aircraft_compatibility,
      'Total built-up area in sqm':
        data?.ecPartB?.ecAirportProposals[0]?.total_built_up_area,
      'Details of nearest airport':
        data?.ecPartB?.ecAirportProposals[0]?.nearest_airport_details,
      'Details of nearby habitation':
        data?.ecPartB?.ecAirportProposals[0]?.near_by_habitation,
      'Details of connectivity to the proposed project':
        data?.ecPartB?.ecAirportProposals[0]?.connectivity_to_proposed_project,
      'Parking provision':
        data?.ecPartB?.ecAirportProposals[0]?.parking_provision,

      'Major Project Requirement in terms of the land area, built up area, green belt, parking needs etc.':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Project requirement detail',
            value: 'project_requirement_details',
          },
          {
            label: 'Existing',
            value: 'project_requirement_existing',
          },
          {
            label: 'Upon Expansion',
            value: 'project_requirement_expansion',
          },
        ],
        data: data?.ecPartB?.ecTownshipProposals?.majorProjectRequirements,
      },
      'Whether management of drainage in and around site is proposed as per the Central Public Health & Environment Engineering Organization (CPHEEO) Manual on Storm Water Drainage System, 2019 to avoid flooding or water logging?':
        data?.ecPartB?.ecTownshipProposals?.whether_management_drainage,
      'Details regarding measures are taken to prevent the run-off from construction activities polluting land & aquifers? (Give details of quantities and the measures taken to avoid the adverse impacts)':
        data?.ecPartB?.ecTownshipProposals?.measure_ragarding_details,
      'Impact of the land use changes occurring due to the proposed project on the runoff characteristics of the area in post construction phase on a long term':
        data?.ecPartB?.ecTownshipProposals?.land_use_impact,
      'Will there be any significant land disturbance resulting in erosion, subsidence and instability?':
        data?.ecPartB?.ecTownshipProposals?.any_significant_land,
      'Soil type': data?.ecPartB?.ecTownshipProposals?.soil_type_details,
      'Slope analysis':
        data?.ecPartB?.ecTownshipProposals?.slope_anlysis_details,
      'Vulnerability to subsidence':
        data?.ecPartB?.ecTownshipProposals?.vulnerability_subsidence_details,
      Siesmicity: data?.ecPartB?.ecTownshipProposals?.seismicity_details,
      'Whether soil erosion control measures proposed to conform to best management practices highlighted in the National Building Code (NBC) of India, 2016?':
        data?.ecPartB?.ecTownshipProposals?.whether_soil_erosion,
      'Breakup of water requirement for various daily uses': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Daily Use',
            value: 'daily_use',
          },
          {
            label: 'Present',
            value: 'daily_quantity_present',
          },
          {
            label: 'During Construction',
            value: 'daily_quantity_expansion',
          },
          {
            label: 'Upon Expansion',
            value: 'daily_quantity_expansion',
          },
          {
            label: 'During Operation',
            value: 'daily_quantity_present',
          },
        ],
        data: data?.ecPartB?.ecTownshipProposals?.waterRequirementBreakups,
      },
      'Details of traffic management at the entry & exit to the project site in construction and operation phase with comparison to the present level of traffic':
        data?.ecPartB?.ecTownshipProposals?.traffic_management_details,
      'hether buildings or building complexes have a connected load of 100 kW or greater or a contract demand of 120 kVA or greater and are intended to be used for commercial purposes.':
        data?.ecPartB?.ecTownshipProposals?.whether_building_complexes,
      'What is the Energy Performance Index (EPI) of a building in kilowatt-hours per square meter per year of the building and measures to minimize energy consumption?':
        data?.ecPartB?.ecTownshipProposals?.energy_performance_index,
      'Whether Compliance to the ECBC norms is applicable?':
        data?.ecPartB?.ecTownshipProposals?.whether_compliance_ecbc,
      'Whether compliance to':
        data?.ecPartB?.ecTownshipProposals?.whether_compliance_to,
      'U-Factor (W/m2 .K)':
        data?.ecPartB?.ecTownshipProposals?.fenestraion_u_factor,
      'Solar Heat Gain Coefficient':
        data?.ecPartB?.ecTownshipProposals?.fenestraion_solar_heat_gain,
      'Visual Light Transmittance':
        data?.ecPartB?.ecTownshipProposals?.fenestraion_visual_light,
      '% Useful daylight illuminance (UDI)':
        data?.ecPartB?.ecTownshipProposals?.daylighting_udi_percentage,
      'Area per floor (sq. m) UDI requirement during 90% of the year':
        data?.ecPartB?.ecTownshipProposals?.daylighting_udi_area,
      'Total daylight area (sq. m) in building meeting UDI requirement during 90% of the year':
        data?.ecPartB?.ecTownshipProposals?.daylighting_udi_total_area,
      'Roof assembly U-factor (W/m2 .K)':
        data?.ecPartB?.ecTownshipProposals?.envelope_roof_ufactor,
      'Climate Zone': data?.ecPartB?.ecTownshipProposals?.envelope_climate_zone,
      'Opaque Assembly Maximum U-factor (W/m2 .K)':
        data?.ecPartB?.ecTownshipProposals?.external_wall_opaque,
      Material: data?.ecPartB?.ecTownshipProposals?.external_wall_materials,
      'R Value': data?.ecPartB?.ecTownshipProposals?.external_wall_rvalue,
      'Energy efficiency in Thermal comfort systems and controls':
        data?.ecPartB?.ecTownshipProposals?.energy_efficiency_thermal,
      'Energy efficiency in Lighting and Electrical systems and controls':
        data?.ecPartB?.ecTownshipProposals?.energy_efficiency_lightning,
      'Does the layout of streets & buildings maximize the potential for solar energy devices? Substantiate with details.':
        data?.ecPartB?.ecTownshipProposals?.does_layout_streets_details,
      'What extent the non-conventional energy technologies are utilized in the overall energy consumption?Provide details of the renewable energy technologies used':
        data?.ecPartB?.ecTownshipProposals?.non_conventional_extent_energy,
      'What are the likely effects of the building activity in altering the microclimates? Provide a self-assessment on the likely impacts of the proposed construction on creation of heat island & inversion effects?':
        data?.ecPartB?.ecTownshipProposals?.building_likely_effects,
      'What precautions & safety measures are proposed against fire hazards? Furnish details of emergency plans':
        data?.ecPartB?.ecTownshipProposals?.safety_precaution_measures,
      'Details of NOCs available for the project (if any)':
        data?.ecPartB?.ecTownshipProposals?.noc_available_details,

      'Project configuration':
        data?.ecPartB?.ecCBWTFProposals?.project_components,
      'Any other details': data?.ecPartB?.ecCBWTFProposals?.other_details,
      'Nearest operational CBWTF details':
        data?.ecPartB?.ecCBWTFProposals?.nearest_operational_cbwtf,
      'Details of wastes to be handled':
        data?.ecPartB?.ecCBWTFProposals?.waste_dtls_to_be_handled,
      'Details of member units':
        data?.ecPartB?.ecCBWTFProposals?.member_units_details,
      'Mode of transportation of wastes to CBWTF':
        data?.ecPartB?.ecCBWTFProposals?.mode_of_trnsprt_to_cbwtf,
      'Land area distribution for proposed activities in Ha':
        data?.ecPartB?.ecCBWTFProposals?.lnd_area_distirbution,

      'CETP Proposals': data?.ecPartB?.ecCETPProposals?.project_components,
      'Nearest operational CETP details':
        data?.ecPartB?.ecCBWTFProposals?.nearest_operational_cetp,
      'details of wastes to be handled':
        data?.ecPartB?.ecCBWTFProposals?.waste_dtls_to_be_handled,
      'details of member units':
        data?.ecPartB?.ecCBWTFProposals?.member_units_details,
      'mode of transportation of wastes to CBWTF':
        data?.ecPartB?.ecCBWTFProposals?.mode_of_trnsprt_to_cetp,
      'land area distribution for proposed activities in Ha':
        data?.ecPartB?.ecCBWTFProposals?.lnd_area_distirbution,

      'CMSWMF Proposals': data?.ecPartB?.ecCMSWMFProposals?.project_components,

      'Nearest operational CMSWMF details':
        data?.ecPartB?.ecCMSWMFProposals?.nearest_operational_cmswmf,
      'Details of existing dumpsite (if any)':
        data?.ecPartB?.ecCMSWMFProposals?.existing_dumpsite_details,
      'Adherence to CPCB site selection criteria':
        data?.ecPartB?.ecCMSWMFProposals?.cpcb_site_selection_criteria,
      'details of Wastes to be handled':
        data?.ecPartB?.ecCMSWMFProposals?.waste_dtls_to_be_handled,
      'Details of Member units':
        data?.ecPartB?.ecCMSWMFProposals?.member_units_details,
      'Mode of transportation of wastes to CMSWMF':
        data?.ecPartB?.ecCMSWMFProposals?.mode_of_trnsprt_to_cmswmf,
      'land area distribution for proposed activities in HA':
        data?.ecPartB?.ecCMSWMFProposals?.lnd_area_distirbution,
      'Layout Plan showing the components of the project and green belt proposed; general location and specific location of the project along with coordinates':
        data?.ecPartB?.ecEnclosureDetail?.componentLayoutPlan,
      'Upload copy of District Survey Report':
        data?.ecPartB?.ecEnclosureDetail?.districtSurveyReport,
      'Upload copy of Replenishment Study Report':
        data?.ecPartB?.ecEnclosureDetail?.replenishmentStudyReport,
      'Upload Copy of EMP Report': data?.ecPartB?.ecEnclosureDetail?.empReport,
      'Letter of Intent / Mining Lease':
        data?.ecPartB?.ecEnclosureDetail?.letterOfIntentMining,
      'Conceptual Plan for Building and Construction project':
        data?.ecPartB?.ecEnclosureDetail?.buildingConceptualPlan,
      'Schematic representation of the feasibility drawings which give information for EIA purpose':
        data?.ecPartB?.ecEnclosureDetail?.feasibilityDrawingPlan,

      'Area proposed for green belt (in Ha)':
        data?.ecPartB?.ecGreenBelt?.area_green_belt_existing,
      'Width of green belt (in m) along the boundary of the project or activity':
        data?.ecPartB?.ecGreenBelt?.width_green_belt_existing,
      'Percentage of the total area covered under green belt':
        data?.ecPartB?.ecGreenBelt?.percentage_total_area_covered_existing,
      'Details of the species proposed for plantation':
        data?.ecPartB?.ecGreenBelt
          ?.details_species_proposed_plantation_existing,
      'No. of tree saplings to be planted':
        data?.ecPartB?.ecGreenBelt?.number_tree_saplings_planted_existing,
      'Funds allocated for plantation in Lakhs.':
        data?.ecPartB?.ecGreenBelt?.funds_allocated_plantation_existing,
      'Green Belt (Expansion)': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Description',
            value: 'description',
          },
          {
            label: 'Existing',
            value: 'existing',
          },
          {
            label: 'Proposed Incremental',
            value: 'proposed',
          },
          {
            label: 'Total after Expansion',
            value: 'total',
          },
        ],
        data: [
          {
            description: 'Area proposed for green belt (in Ha)',
            existing: data?.ecPartB?.ecGreenBelt?.area_green_belt_existing,
            proposed: data?.ecPartB?.ecGreenBelt?.area_green_belt__proposed,
            total: data?.ecPartB?.ecGreenBelt?.total_area_green_belt,
          },
          {
            description:
              ' Width of green belt (in m) along the boundaryof the project or activity',
            existing: data?.ecPartB?.ecGreenBelt?.width_green_belt_existing,
            proposed: data?.ecPartB?.ecGreenBelt?.width_green_belt_proposed,
            total: data?.ecPartB?.ecGreenBelt?.total_width_green_belt,
          },
          {
            description:
              'Percentage of the total area covered under green belt',
            existing:
              data?.ecPartB?.ecGreenBelt
                ?.percentage_total_area_covered_existing,
            proposed:
              data?.ecPartB?.ecGreenBelt
                ?.percentage_total_area_covered_proposed,
            total: data?.ecPartB?.ecGreenBelt?.total_percentage_green_belt,
          },
          {
            description: 'Details of the species proposed for plantation',
            existing:
              data?.ecPartB?.ecGreenBelt
                ?.details_species_proposed_plantation_existing,
            proposed:
              data?.ecPartB?.ecGreenBelt
                ?.details_species_proposed_plantation_proposed,
          },
          {
            description: 'No. of tree saplings to be planted',
            existing:
              data?.ecPartB?.ecGreenBelt?.number_tree_saplings_planted_existing,
            proposed:
              data?.ecPartB?.ecGreenBelt?.number_tree_saplings_planted_proposed,
            total: data?.ecPartB?.ecGreenBelt?.total_number_tree_saplings,
          },
          {
            description: 'Funds allocated for plantation in Lakhs.',
            existing:
              data?.ecPartB?.ecGreenBelt?.funds_allocated_plantation_existing,
            proposed:
              data?.ecPartB?.ecGreenBelt?.funds_allocated_plantation_proposed,
            total: data?.ecPartB?.ecGreenBelt?.total_funds_allocated_plantation,
          },
        ],
      },

      'Plant / Equipment / Facility': data?.ecPartB?.ecIndustryProposal?.plant,
      Configuration: data?.ecPartB?.ecIndustryProposal?.existing_configuration,
      'Existing Configuration':
        data?.ecPartB?.ecIndustryProposal?.existing_configuration,
      'Proposed Configuration':
        data?.ecPartB?.ecIndustryProposal?.proposed_configuration,
      'Final configuration after expansion':
        data?.ecPartB?.ecIndustryProposal?.final_configuration,
      'Details of adoption of Best Available Technology, if any':
        data?.ecPartB?.ecIndustryProposal?.adoption_details,
      'Coke Quenching': data?.ecPartB?.ecIndustryProposal?.coke_quenching,
      'Waste heat recovery system for blast furnce':
        data?.ecPartB?.ecIndustryProposal?.heat_recovery_blast_furnace,
      'Waste heat recovery for sinter plant:':
        data?.ecPartB?.ecIndustryProposal?.heat_recovery_sinter,
      'Details of slag utilization':
        data?.ecPartB?.ecIndustryProposal?.slag_details,
      'Details of Metal recovery':
        data?.ecPartB?.ecIndustryProposal?.metal_recovery_details,
      'Slag utilization': data?.ecPartB?.ecIndustryProposal?.slag_utilization,
      'Details of jigging and briquetting plant for Ferro Alloy section':
        data?.ecPartB?.ecIndustryProposal?.jigging_detals,
      'Acid fume control and acid recovery system for cold rolling mills':
        data?.ecPartB?.ecIndustryProposal?.acid_fume_control,
      'details of Metal recovery':
        data?.ecPartB?.ecIndustryProposal?.metal_detail_recovery_metallurgy,
      'Waste Heat Recovery System':
        data?.ecPartB?.ecIndustryProposal?.waste_heat_system,
      'Coprocessing of the Hazardous Waste (HW)':
        data?.ecPartB?.ecIndustryProposal?.hazardous_waste_coproccesing,
      'Fluoride consumption and emission':
        data?.ecPartB?.ecIndustryProposal?.fluoride_consumption,
      'Utilization of Spent Pot Lining waste':
        data?.ecPartB?.ecIndustryProposal?.spent_pot_utilization,
      'Red Mud handling': data?.ecPartB?.ecIndustryProposal?.red_mud_handling,
      'Monitoring of Asbestos fibre in ambient Air and Work place':
        data?.ecPartB?.ecIndustryProposal?.asbestos_fibre_monitoring,
      'Occupational Health and Safety hazards':
        data?.ecPartB?.ecIndustryProposal?.occupational_health,
      'Action plan for handling to Asbestos':
        data?.ecPartB?.ecIndustryProposal?.action_plan_handling,
      'Elemental Chlorine Free':
        data?.ecPartB?.ecIndustryProposal?.elemental_chlorine_free,
      'Odor control Techniques':
        data?.ecPartB?.ecIndustryProposal?.odor_control_techniques,
      'Hazardous waste Management':
        data?.ecPartB?.ecIndustryProposal?.hazardous_waste_mgmt,
      'Reduction of water consumption per ton of paper production':
        data?.ecPartB?.ecIndustryProposal?.water_consumption_reduction,
      'Hazardous waste management':
        data?.ecPartB?.ecIndustryProposal?.hazardous_waste_mgmt_paper_mft,
      'Odor control measures':
        data?.ecPartB?.ecIndustryProposal?.odor_control_measures_chemical_itd,
      'Membership in CETP': data?.ecPartB?.ecIndustryProposal?.cetp_membership,
      'Nearest operational TSDF details':
        data?.ecPartB?.ecIndustryProposal?.tsdf_details,
      'Membership in TSDF': data?.ecPartB?.ecIndustryProposal?.tsdf_membership,

      'Date of issue of LoI/Vesting order/Minning Lease':
        data?.ecPartB?.ecMiningProposals?.date_of_issue,
      'Validity of the LoI/Vesting order':
        data?.ecPartB?.ecMiningProposals?.date_of_validity,
      'Lease Period': data?.ecPartB?.ecMiningProposals?.lease_period,
      'Date of expiry of lease':
        data?.ecPartB?.ecMiningProposals?.date_of_expiry,
      'Lease area (in Ha) as per LoI/Vesting order/Mining Lease':
        data?.ecPartB?.ecMiningProposals?.lease_area,
      'Production capacity (in MTPA) as per LoI / Vesting Order / Mining lease, if any prescribed':
        data?.ecPartB?.ecMiningProposals?.production_capacity,
      'Details of Lease renewal(s), if any':
        data?.ecPartB?.ecMiningProposals?.detail_of_lease,
      'Other information, if any (Mining Lease)':
        data?.ecPartB?.ecMiningProposals?.other_info,
      'Status of approval of Mining plan':
        data?.ecPartB?.ecMiningProposals?.status_of_approval,
      'Minerals to be mined': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of Mineral',
            value: 'mineral_name',
          },
          {
            label: 'Classification',
            value: 'mineral_classification',
          },
          {
            label: 'Production Capacity',
            value: 'production_capacity',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: data?.ecPartB?.ecMiningProposals?.miningMineralsMineds,
      },
      'Total excavation in MTPA':
        data?.ecPartB?.ecMiningProposals?.total_excavation_mtpa,
      'Total Excavation in M.Cu.m/Annum':
        data?.ecPartB?.ecMiningProposals?.total_excavation_annum,
      'Other information, if any (Excavation)':
        data?.ecPartB?.ecMiningProposals?.excavation_other_info,
      'Stripping Ratio': data?.ecPartB?.ecMiningProposals?.stripping_ratio,
      'Mineral Reserves': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of Mineral',
            value: 'mineral_name',
          },
          {
            label: 'Proved Reserves',
            value: 'proved_reserves',
          },
          {
            label: 'Indicated Reserves',
            value: 'indicated_reserves',
          },
          {
            label: 'Inferred Reserves',
            value: 'inferred_reserves',
          },
          {
            label: 'Mineable Reserves',
            value: 'mineable_reserves',
          },
          {
            label: 'Remarks',
            value: 'reserves_remarks',
          },
        ],
        data: data?.ecPartB?.ecMiningProposals?.miningMineralReserves,
      },
      'Life of the mine as per approved mining plan':
        data?.ecPartB?.ecMiningProposals?.approved_life_of_mine,
      'Life of the mine as per total estimated reserves, if any':
        data?.ecPartB?.ecMiningProposals?.estimate_life_of_mine,
      'Other information, if any (Life of Mine)':
        data?.ecPartB?.ecMiningProposals?.life_of_mine_other_info,
      'Type of mining': data?.ecPartB?.ecMiningProposals?.type_of_mining,
      'Method of mining': data?.ecPartB?.ecMiningProposals?.method_of_mining,
      'Other information, if any (Mining Method)':
        data?.ecPartB?.ecMiningProposals?.type_of_mining_other_info,
      'Type of blasting': data?.ecPartB?.ecMiningProposals?.type_of_blasting,
      'Mitigation measures for control of blast induced vibrations':
        data?.ecPartB?.ecMiningProposals?.mitigation_blast_control,
      'Other information, if any (Blasting)':
        data?.ecPartB?.ecMiningProposals?.blasting_other_info,
      'Whether it is proposed to install beneficiation plant/Coal washery within the mining lease area?':
        data?.ecPartB?.ecMiningProposals?.proposed_to_install_coal,
      'Capacity of beneficiation/washery (in MTPA)':
        data?.ecPartB?.ecMiningProposals?.coal_capacity,
      'Proposed process':
        data?.ecPartB?.ecMiningProposals?.coal_proposed_process,
      'Beneficiation/Washing Technology':
        data?.ecPartB?.ecMiningProposals?.coal_beneficiation,
      'Other information, if any (Benefication)':
        data?.ecPartB?.ecMiningProposals?.coal_other_info,
      'Whether it is proposed to install crusher within the mining lease area?':
        data?.ecPartB?.ecMiningProposals?.proposed_to_install_crusher,
      'No. Of crushers': data?.ecPartB?.ecMiningProposals?.number_of_crusher,
      'Capacity of crushers':
        data?.ecPartB?.ecMiningProposals?.capacity_of_crusher,
      'Total capacity of crushers':
        data?.ecPartB?.ecMiningProposals?.total_capacity_of_crusher,
      'External dump': data?.ecPartB?.ecMiningProposals?.external_dumping_area,
      'Maximum height in m':
        data?.ecPartB?.ecMiningProposals?.external_dumping_maximum_area,
      'Area in Ha': data?.ecPartB?.ecMiningProposals?.internal_dumping_area,
      'Total Topsoil excavated during the entire life of the mine (in Million Cubic Meter)':
        data?.ecPartB?.ecMiningProposals?.total_topsoil_excavated,
      'Utilization strategy of topsoil':
        data?.ecPartB?.ecMiningProposals?.topsoil_utilization_strategy,
      'Other information, if any (Toposoil)':
        data?.ecPartB?.ecMiningProposals?.topsoil_other_info,
      'Details of the Quarry/Mine Pit':
        data?.ecPartB?.ecMiningProposals?.total_quarry_area,
      'Area of final void (ha)':
        data?.ecPartB?.ecMiningProposals?.final_void_area,
      'Maximum Depth of final void (in meter)':
        data?.ecPartB?.ecMiningProposals?.final_void_max_depth,
      'Other information, if any (Quarry/Mine Pit)':
        data?.ecPartB?.ecMiningProposals?.quarry_other_info,
      'Mode of transportation upto pit head':
        data?.ecPartB?.ecMiningProposals?.transportation_mode_up_pithead,
      'Mode of transportation from pit head to siding/loading':
        data?.ecPartB?.ecMiningProposals?.transportation_mode_from_pithead,
      'Mode of transport from loading point to consumers':
        data?.ecPartB?.ecMiningProposals?.transportation_mode_from_loading,
      'Other information, if any (Transportation)':
        data?.ecPartB?.ecMiningProposals?.transportation_mode_other_info,
      'Plantation area (ha)': data?.ecPartB?.ecMiningProposals?.plantation_area,
      'Water body in Ha': data?.ecPartB?.ecMiningProposals?.water_body,
      'Public use in Ha': data?.ecPartB?.ecMiningProposals?.public_use,
      'Other uses in Ha': data?.ecPartB?.ecMiningProposals?.other_use,
      'Whether approved DSR available':
        data?.ecPartB?.ecMiningProposals?.approved_dsr,
      'Whether the instant proposal is part of cluster':
        data?.ecPartB?.ecMiningProposals?.instant_proposal,
      'Total ML area (in Ha) of the cluster':
        data?.ecPartB?.ecMiningProposals?.total_ml_area,
      'No. of Mines falling in the cluster':
        data?.ecPartB?.ecMiningProposals?.no_of_mines_falling,
      'Category of the cluster':
        data?.ecPartB?.ecMiningProposals?.category_of_cluster,
      'Replenishment Study in case of Sand/bajri mining':
        data?.ecPartB?.ecMiningProposals?.replenishment_study_case,
      'Year of commencement of mining operations (FY)':
        data?.ecPartB?.ecMiningProposals?.year_of_commencement,
      'Production details for expansion / modernization proposals': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Financial Year',
            value: 'financial_year',
          },
          {
            label: 'EC (MTPA)',
            value: 'sanctioned_ec',
          },
          {
            label: 'CTO (MTPA)',
            value: 'sanctioned_cto',
          },
          {
            label: 'Approved Mining Plan (MTPA)',
            value: 'sanctioned_approved_plan',
          },
          {
            label: 'Actual Production',
            value: 'actual_production',
          },
          {
            label: 'Excess Production',
            value: 'excess_production',
          },
        ],
        data: data?.ecPartB?.ecMiningProposals?.miningProductionDetails,
      },

      // Physical Changes (Done)

      'Whether any permanent or temporary change in land use, land cover or topography due to project activity?':
        data?.ecPartB?.ecPhysicalChanges?.change_in_land_use,
      'Forest Land': data?.ecPartB?.ecPhysicalChanges?.forest_land_area,
      'Agriculture land':
        data?.ecPartB?.ecPhysicalChanges?.agriculture_land_area,
      'Grazing Land': data?.ecPartB?.ecPhysicalChanges?.grazing_land_area,
      'Actual Production': data?.ecPartB?.ecPhysicalChanges?.actual_production,
      'Barren Land': data?.ecPartB?.ecPhysicalChanges?.barren_land_area,
      'Waste Land': data?.ecPartB?.ecPhysicalChanges?.waste_land_area,
      'Surface water bodies':
        data?.ecPartB?.ecPhysicalChanges?.surface_water_bodies_area,
      'Marshy land': data?.ecPartB?.ecPhysicalChanges?.marshy_land_area,
      mangroves: data?.ecPartB?.ecPhysicalChanges?.mangroves_area,
      Settlements: data?.ecPartB?.ecPhysicalChanges?.settlements_area,
      'Roads / Other infrastructure':
        data?.ecPartB?.ecPhysicalChanges?.infrastructure_area,
      'Plantation / Green belt':
        data?.ecPartB?.ecPhysicalChanges?.plantation_area,
      'Industrial use': data?.ecPartB?.ecPhysicalChanges?.industrial_use_area,
      'Other land use': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Land Use',
            value: 'land_use_name',
          },
          {
            label: 'Area in Ha',
            value: 'land_use_area',
          },
          {
            label: 'Remarks',
            value: 'land_use_remarks',
          },
        ],
        data: data?.ecPartB?.currentLandUse,
      },
      'Proposed/Post-project land use': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Description of Activity / Facility / Plant / Others',
            value: 'description',
          },
          {
            label: 'Land requirement in Ha',
            value: 'land_requirement',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: data?.ecPartB?.proposedLandUse,
      },
      'Green belt':
        data?.ecPartB?.ecPhysicalChanges?.green_belt_land_requirement,
      'Description of Activity / Facility / Plant / Others':
        data?.ecPartB?.ecPhysicalChanges?.description == 'Other'
          ? data?.ecPartB?.ecPhysicalChanges?.description_other
          : data?.ecPartB?.ecPhysicalChanges?.description,
      'Proposed/Post-project land use (Expansion)': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Land requirement in Ha',
            value: 'total_land_req',
          },
          {
            label: 'Remarks',
            value: 'expansion_remarks',
          },
        ],
        data: data?.ecPartB?.proposedLandUseExpansion,
      },
      'Whether any clearance of existing vegetation due to  project activity?':
        data?.ecPartB?.ecPhysicalChanges?.existing_vegetation_clearance,
      'No. of Trees': data?.ecPartB?.ecPhysicalChanges?.no_of_trees,
      'Area covered with plantation/ vegetation / greenbelt proposed to be cleared in Ha':
        data?.ecPartB?.ecPhysicalChanges?.plantation_area_to_be_cleared,
      'No. of trees proposed for transplantation':
        data?.ecPartB?.ecPhysicalChanges?.trees_proposed_for_plantation,
      'Other information, if any (Plantation)':
        data?.ecPartB?.ecPhysicalChanges?.vegetation_other_information,
      'Whether any loss of native species or genetic diversity?':
        data?.ecPartB?.ecPhysicalChanges?.loss_of_native_spieces,
      'Details of loss of native species or genetic diversity':
        data?.ecPartB?.ecPhysicalChanges?.loss_of_native_spieces_dtls,
      'Other information, if any (Species)':
        data?.ecPartB?.ecPhysicalChanges?.loss_of_native_spieces_othr_info,
      'Whether any demolition works involved in project activity?':
        data?.ecPartB?.ecPhysicalChanges?.demolition_work_involved,
      'Details of demolition': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Details of structures proposed for demolition',
            value: 'structured_details_proposed',
          },
          {
            label: 'No.s',
            value: 'number',
          },
          {
            label: 'Built up area in Sq.m',
            value: 'built_up_area',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: data?.ecPartB?.demolitionDetails,
      },
      'Whether any  linear structures proposed for diversion or demolition due to project activity? (e.g. roads, transmission lines, rail line, pipeline, conveyor, etc.)':
        data?.ecPartB?.ecPhysicalChanges
          ?.linear_structure_proposed_for_diversion,
      'Details of Linear structures proposed for diversion or demolition':
        data?.ecPartB?.ecPhysicalChanges?.linear_structure_proposed_dtls,
      'Length of linear structure in m':
        data?.ecPartB?.ecPhysicalChanges?.linear_structure_length,
      'Area covered by linear structure in Ha':
        data?.ecPartB?.ecPhysicalChanges?.area_covered_linear_structure,
      'Other information, if any (Diversion / Demolition)':
        data?.ecPartB?.ecPhysicalChanges?.linear_structure_other_information,
      'Whether any closure or diversion of existing transport routes or infrastructure due to project leading to changes in traffic movements?':
        data?.ecPartB?.ecPhysicalChanges?.existing_transportation_change,
      'Length of the existing transport routes or infrastructure proposed to be closed or diverted in Km':
        data?.ecPartB?.ecPhysicalChanges?.existing_transportation_length,
      'Alternative arrangement proposed, if any':
        data?.ecPartB?.ecPhysicalChanges?.alternative_arrangement_proposed,
      'Other information, if any (Existing Transport Routes)':
        data?.ecPartB?.ecPhysicalChanges?.existing_transportation_other_info,
      'Whether any closure or diversion of water bodies present in project area or realignment of water courses passing through project area?':
        data?.ecPartB?.ecPhysicalChanges?.water_bodies_change,
      'Details of water body proposed for closure/diversion':
        data?.ecPartB?.ecPhysicalChanges?.water_body_dtls,
      'Area of water body proposed for closure / diversion (m/Km)':
        data?.ecPartB?.ecPhysicalChanges?.water_bodies_proposed_area,
      'No. of water courses passing through the project area':
        data?.ecPartB?.ecPhysicalChanges?.no_of_water_courses,
      'Length of the water course proposed for re-alignment or diversion (m/Km)':
        data?.ecPartB?.ecPhysicalChanges?.proposed_water_course_length,
      'Other information, if any (Water odies Present)':
        data?.ecPartB?.ecPhysicalChanges?.water_bodies_other_info,
      'Whether any dismantling or decommissioning or restoration works or reclamation works (Long-term/ short-term)?':
        data?.ecPartB?.ecPhysicalChanges?.dismantling_or_restoration_works,
      'Details of dismantling / decommissioning':
        data?.ecPartB?.ecPhysicalChanges?.dismantling_dtls,
      'Duration of dismantling or decommissioning':
        data?.ecPartB?.ecPhysicalChanges?.dismantling_duration,
      'Details of restoration or reclamation':
        data?.ecPartB?.ecPhysicalChanges?.restoration_dtls,
      'Duration of restoration or reclamation':
        data?.ecPartB?.ecPhysicalChanges?.restoration_duration,
      'Other information, if any (Dismantling/Decommissioning/Restoration/Reclamation)':
        data?.ecPartB?.ecPhysicalChanges?.dismantling_or_restoration_other_info,
      'Whether any construction works for temporary use for project activity?':
        data?.ecPartB?.ecPhysicalChanges?.temporary_use_construction_work,
      'Construction works for temporary use for project activity': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Details',
            value: 'details',
          },
          {
            label: 'No.s',
            value: 'number',
          },
          {
            label: 'Built up area',
            value: 'built_up_area',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: data?.ecPartB?.constructionDetails,
      },
      'Whether any cut and fill excavations proposed for the project activity?':
        data?.ecPartB?.ecPhysicalChanges?.cut_and_fill_excavations,
      'Quantity of cutting material in Cu.m':
        data?.ecPartB?.ecPhysicalChanges?.cutting_quantity,
      'Proposed utilization / dispose of cutting material':
        data?.ecPartB?.ecPhysicalChanges?.filling_quantity,
      'Quantity of filling material in Cu.m':
        data?.ecPartB?.ecPhysicalChanges?.cutting_material_dispose,
      'Source of filling material':
        data?.ecPartB?.ecPhysicalChanges?.filling_material_source,
      'Other information, if any (Cutting Material)':
        data?.ecPartB?.ecPhysicalChanges?.cut_and_fill_other_info,
      'Whether any underground works including tunnelling?':
        data?.ecPartB?.ecPhysicalChanges?.underground_works,
      'Length of tunnel in m': data?.ecPartB?.ecPhysicalChanges?.tunnel_length,
      'Quantity of muck likely to be generated in cu.m':
        data?.ecPartB?.ecPhysicalChanges?.muck_quantity,
      'Mode of disposal of Muck':
        data?.ecPartB?.ecPhysicalChanges?.muck_disposal_mode,
      'Area of reclamation work in ha':
        data?.ecPartB?.ecPhysicalChanges?.reclamation_work_area,
      'Mode of reclamation': data?.ecPartB?.ecPhysicalChanges?.reclamation_mode,
      'Other information, if any (Underground Work)':
        data?.ecPartB?.ecPhysicalChanges?.underground_works_other_info,
      'Whether any dredging involved in project?':
        data?.ecPartB?.ecPhysicalChanges?.dredging_involved,
      'Area of dredging in ha': data?.ecPartB?.ecPhysicalChanges?.dredging_area,
      'Quantity of dredging material in cu.m':
        data?.ecPartB?.ecPhysicalChanges?.dredging_material_quantity,
      'Place of disposal of dredging material':
        data?.ecPartB?.ecPhysicalChanges?.dredging_material_disposal_place,
      'Other information, if any (Dredging)':
        data?.ecPartB?.ecPhysicalChanges?.dredging_other_info,
      'Whether any offshore structures involved in project?':
        data?.ecPartB?.ecPhysicalChanges?.offshore_structures_involved,
      'No. of offshore structures':
        data?.ecPartB?.ecPhysicalChanges?.no_of_offshore_structures,
      'Built up area in sq.m': data?.ecPartB?.ecPhysicalChanges?.built_up_area,
      'Other information, if any (Offshore Structure)':
        data?.ecPartB?.ecPhysicalChanges?.offshore_other_info,
      'Whether any new road, rail, sea, airports, helipad, etc. during construction or operation?':
        data?.ecPartB?.ecPhysicalChanges?.new_infrastructure,
      'Length of new road in Km, if any proposed':
        data?.ecPartB?.ecPhysicalChanges?.new_road_length,
      'Length of new rail in Km, if any proposed':
        data?.ecPartB?.ecPhysicalChanges?.new_rail_length,
      'Length of jetty in m, if any proposed':
        data?.ecPartB?.ecPhysicalChanges?.new_jetty_length,
      'Details of airports / helipad':
        data?.ecPartB?.ecPhysicalChanges?.airport_details,
      'Other transport facility, if any':
        data?.ecPartB?.ecPhysicalChanges?.othr_trsprt_fclty,
      'Whether any construction of new linear structures? (e.g. transmission lines, pipelines, etc.)':
        data?.ecPartB?.ecPhysicalChanges?.new_linear_structure,
      'Length of transmission line proposed to be diverted':
        data?.ecPartB?.ecPhysicalChanges?.diverted_transmission_line_length,
      'Length of pipe line proposed to be diverted':
        data?.ecPartB?.ecPhysicalChanges?.diverted_pipeline_length,
      'Length of new transmission line proposed':
        data?.ecPartB?.ecPhysicalChanges?.proposed_transmission_line_length,
      'Length of new pipeline proposed':
        data?.ecPartB?.ecPhysicalChanges?.proposed_pipeline_length,
      'Other information, if any (Transmission Lines)':
        data?.ecPartB?.ecPhysicalChanges?.linear_other_info,
      'Whether any Facilities for storage of goods or raw materials?':
        data?.ecPartB?.ecPhysicalChanges?.goods_storage_facility,
      'Covered area proposed for storage':
        data?.ecPartB?.ecPhysicalChanges?.covered_area_proposed,
      'Open area proposed for storage (sqm/Km)':
        data?.ecPartB?.ecPhysicalChanges?.open_area_proposed,
      'Other information, if any (Storage)':
        data?.ecPartB?.ecPhysicalChanges?.goods_storage_othr_info,
      'Whether any Facilities for long term/ permanent housing of operational workers/ staff?':
        data?.ecPartB?.ecPhysicalChanges?.housing_of_workers,
      'No. of housing units':
        data?.ecPartB?.ecPhysicalChanges?.no_of_housing_units,
      'Total Built up area in sq.m':
        data?.ecPartB?.ecPhysicalChanges?.housing_built_up_area,
      'Distance from the project site in Km':
        data?.ecPartB?.ecPhysicalChanges?.distance_from_project,
      'Transport facilities for workers/ staff (Mode, other details':
        data?.ecPartB?.ecPhysicalChanges?.housing_of_workers_other_info,
      'Other information, if any (Housing)':
        data?.ecPartB?.ecPhysicalChanges?.housing_of_workers_other_info,
      'Whether any Impoundment, damming, culverting, realignment or other changes to the hydrology of watercourses or aquifers?':
        data?.ecPartB?.ecPhysicalChanges?.changes_to_aquifers,
      'Length of impoundment in mtr, if any':
        data?.ecPartB?.ecPhysicalChanges?.impoundment_lenght,
      'Length of damming in mtr, if any':
        data?.ecPartB?.ecPhysicalChanges?.damming_length,
      'Length of culverting in mtr, if any':
        data?.ecPartB?.ecPhysicalChanges?.culverting_length,
      'Length of realignment in mtr, if any':
        data?.ecPartB?.ecPhysicalChanges?.realignment_length,
      'Length of diversion in mtr, if any':
        data?.ecPartB?.ecPhysicalChanges?.diversion_length,
      'Other information, if any (Hydrology of Watercourses)':
        data?.ecPartB?.ecPhysicalChanges?.aquifers_other_info,
      'Whether any Stream crossings, temporary and permanent?':
        data?.ecPartB?.ecPhysicalChanges?.stream_change,
      'No. of stream crossings': data?.ecPartB?.ecPhysicalChanges?.no_of_stream,
      'Stream crossings, temporary and permanent': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Details',
            value: 'details',
          },
          {
            label: 'Temporary/Permanent',
            value: 'temporary_permanent',
          },
          {
            label: 'Length in m',
            value: 'length',
          },
          {
            label: 'Remarks',
            value: 'remarks',
          },
        ],
        data: data?.ecPartB?.ecStreamCrossing,
      },

      'Whether any Influx of people to an area in either temporarily or permanently?':
        data?.ecPartB?.ecPhysicalChanges?.influx_to_area,
      'No. of people likely to influx to an area temporarily':
        data?.ecPartB?.ecPhysicalChanges?.no_of_temporary_influx,
      'No. of people likely to influx to an area Permanently':
        data?.ecPartB?.ecPhysicalChanges?.no_of_permanent_influx,
      'Other information, if any (Influx)':
        data?.ecPartB?.ecPhysicalChanges?.other_info_influx,
      'Whether any other information would like to submit?':
        data?.ecPartB?.ecPhysicalChanges?.any_other_change,
      'Details thereof (Physical Changes)':
        data?.ecPartB?.ecPhysicalChanges?.any_other_info,

      // Pollution Details (Done)

      'Whether any probable air pollutants generated?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Air Pollution Source',
            value: 'pollution_source',
          },
          {
            label: 'Probable Pollutants',
            value: 'pollutant',
          },
          {
            label: 'Mitigation Measures',
            value: 'mitigation_measures',
          },
        ],
        data: data?.ecPartB?.ecAirPollutionMitigations,
      },
      'Other information, if any (Pollutants to Air)':
        data?.ecPartB?.ecPollutionDetails?.other_information,
      'Whether any probable generation of Noise and  vibration from the proposed project?':
        data?.ecPartB?.ecPollutionDetails?.probable_generation_of_noise,
      'Sources of Noise': data?.ecPartB?.ecPollutionDetails?.source_of_noise,
      'Sources of Vibration':
        data?.ecPartB?.ecPollutionDetails?.source_of_vibration,
      'Details of blasting, if any':
        data?.ecPartB?.ecPollutionDetails?.blasting_details,
      'Other information, if any (Noise & Vibration)':
        data?.ecPartB?.ecPollutionDetails?.other_noise_details,
      'Whether any mitigation measures proposed for Noise & Vibration?':
        data?.ecPartB?.ecPollutionDetails?.mitigation_measure_for_noise,
      'Mitigation measures proposed for control of Noise':
        data?.ecPartB?.ecPollutionDetails?.mitigation_measure_for_noise_control,
      'Mitigation measures proposed for control of vibration':
        data?.ecPartB?.ecPollutionDetails
          ?.mitigation_measure_for_vibration_control,
      'Other information, if any (Mitigation Measures Proposed for Noise & Vibration)':
        data?.ecPartB?.ecPollutionDetails?.other_mitigation_measure_details,
      'Whether any probable generation of Light and Heat?':
        data?.ecPartB?.ecPollutionDetails?.probable_generation_of_heat,
      'Sources of Light': data?.ecPartB?.ecPollutionDetails?.source_of_light,
      'Sources of Heat': data?.ecPartB?.ecPollutionDetails?.source_of_heat,
      'Other information, if any (Generation of Light and Heat)':
        data?.ecPartB?.ecPollutionDetails?.other_light_heat_details,
      'Whether any mitigation measures proposed for Light & Heat?':
        data?.ecPartB?.ecPollutionDetails?.mitigation_measures_for_light_heat,
      'Mitigation measures proposed for control of Light':
        data?.ecPartB?.ecPollutionDetails?.mitigation_measure_for_light_control,
      'Mitigation measures proposed for control of Heat':
        data?.ecPartB?.ecPollutionDetails?.mitigation_measure_for_heat_control,
      'Other information, if any (Mitigation Measures Proposed for Light & Heat)':
        data?.ecPartB?.ecPollutionDetails
          ?.other_mitigation_measure_for_light_heat,
      'Whether any probable water pollutants generated?':
        data?.ecPartB?.ecPollutionDetails?.probable_water_pollutants,
      'Organic Pollutants':
        data?.ecPartB?.ecPollutionDetails?.organic_pollutants,
      'Inorganic pollutants':
        data?.ecPartB?.ecPollutionDetails?.inorganic_pollutants,
      'Micro-organism': data?.ecPartB?.ecPollutionDetails?.micro_organism,
      Sediments: data?.ecPartB?.ecPollutionDetails?.sediments,
      'Heavy metals': data?.ecPartB?.ecPollutionDetails?.heavy_metals,
      'Others (Pollutant to Water)': data?.ecPartB?.ecPollutionDetails?.others,
      'Probable sources of water pollutant':
        data?.ecPartB?.ecPollutionDetails?.probable_sources_of_water_pollutants,
      'Details of sources of water pollution':
        data?.ecPartB?.ecPollutionDetails?.sources_of_water_pollution,

      'Quantity of waste water generation per day (KLD)':
        data?.ecPartB?.ecPollutionDetails?.new_quantity_of_wastewater,
      'Quantity of treated water proposed to use per day (KLD)':
        data?.ecPartB?.ecPollutionDetails?.new_quantity_of_treated,
      'Quantity of treated water proposed to discharge outside the premises (KLD)':
        data?.ecPartB?.ecPollutionDetails?.new_quantity_of_treated_water,
      'Purpose for which treated water is proposed to use':
        data?.ecPartB?.ecPollutionDetails?.proposed_purpose_of_treated_water,
      'Whether it is proposed to opt/avail common off-site Sewage Treatment Plant (CSTP)/Effluent Treatment Plant (CETP) facility?':
        data?.ecPartB?.ecPollutionDetails?.offsite_treatment_plant,
      'Type of treatment plant':
        data?.ecPartB?.ecPollutionDetails?.type_of_treatment_plant,
      'Agency Name': data?.ecPartB?.ecPollutionDetails?.agency_name,
      'Both CETP & CSTP': data?.ecPartB?.ecPollutionDetails?.agency_name_both,
      Capacity: data?.ecPartB?.ecPollutionDetails?.capacity,
      Technology: data?.ecPartB?.ecPollutionDetails?.technology,
      'Present load on facility (excluding of proposed project)':
        data?.ecPartB?.ecPollutionDetails?.present_loan_on_facility,
      'Distance from site':
        data?.ecPartB?.ecPollutionDetails?.distance_from_site,
      'Provision for re-use of treated water from the CETP/CSTP':
        data?.ecPartB?.ecPollutionDetails?.reuse_of_treated_water,
      'MoU with agency': data?.ecPartB?.ecPollutionDetails?.mou_with_agency,
      'Whether it is proposed to setup on-site Sewage Treatment Plant (STP)/Effluent Treatment Plant (ETP) facility?':
        data?.ecPartB?.ecPollutionDetails?.onsite_treatment_plant,
      'Whether 100% of the waste water generated will be treated?':
        data?.ecPartB?.ecPollutionDetails?.waste_water_generated,
      'type of treatment plant':
        data?.ecPartB?.ecPollutionDetails?.type_of_treatment_plant_on_site,
      ETP: data?.ecPartB?.ecPollutionDetails?.onsite_capacity,
      STP: data?.ecPartB?.ecPollutionDetails?.onsite_capacity_both,
      'Whether the adequacy of the Sewage Treatment Plant (STP) or Effluent Treatment Plant certified by an independent expert?':
        data?.ecPartB?.ecPollutionDetails?.treatment_plant_adequacy,
      'Whether any other mitigation measures proposed?':
        data?.ecPartB?.ecPollutionDetails?.other_mitigation_measures,
      'Whether Dual Plumbing System proposed to be implemented?':
        data?.ecPartB?.ecPollutionDetails?.dual_plumbing_system,
      'Whether any discharge of treated effluent involved?':
        data?.ecPartB?.ecPollutionDetails?.proposed_mode_of_discharge,
      'Mode of discharge of treated effluent':
        data?.ecPartB?.ecPollutionDetails?.mode_of_discharge,
      'Place of discharge of treated effluent':
        data?.ecPartB?.ecPollutionDetails?.place_of_discharge,
      'Other information, if any (Discharge of Treated Effluent)':
        data?.ecPartB?.ecPollutionDetails
          ?.mode_of_discharge_treated_effluent_other_information,

      // Risk Factor

      'From explosions, spillages, fires etc. from storage, handling, use or production of hazardous substances?':
        data?.ecPartB?.ecRiskFactor?.hazardous_substances_explosion,
      'Details thereof (Production of Hazardous Substances)':
        data?.ecPartB?.ecRiskFactor?.hazardous_substances_explosion_details,
      'From any other causes?': data?.ecPartB?.ecRiskFactor?.any_other_cause,
      'Details thereof (From any other causes)':
        data?.ecPartB?.ecRiskFactor?.any_other_cause_details,
      'Could the project be affected by natural disasters causing environmental damage (e.g.  floods, earthquakes,  landslides, cloudburst etc)?':
        data?.ecPartB?.ecRiskFactor?.project_affected_natural_disaster,
      'Details thereof (Affected by Natural Disasters)':
        data?.ecPartB?.ecRiskFactor?.project_affected_natural_disaster_details,
      'Changes in occurrence of disease or affect disease vectors (e.g. insect or water borne diseases)':
        data?.ecPartB?.ecRiskFactor?.occurences_change_disease,
      'Details thereof (Changes in occurrence of disease)':
        data?.ecPartB?.ecRiskFactor?.occurences_change_disease_details,
      'Could project adversely affect the wellbeing of people in project area e.g. by changing living conditions?':
        data?.ecPartB?.ecRiskFactor?.project_adversely_wellbeing,

      'Details thereof (Affect the wellbeing)':
        data?.ecPartB?.ecRiskFactor?.project_adversely_wellbeing_details,

      'Vulnerable groups of people who could be adversely affected by the project e.g. hospital patients, children, the elderly etc.':
        data?.ecPartB?.ecRiskFactor?.vulnerable_group_people,

      'Details thereof (Vulnerable groups of people)':
        data?.ecPartB?.ecRiskFactor?.vulnerable_group_people_details,

      'Risk Management Plan': data?.ecPartB?.ecRiskFactor?.risk_management_plan,

      'Details thereof (Risk Management Plan)':
        data?.ecPartB?.ecRiskFactor?.risk_management_plan_details,

      'Whether any likely impacts of the proposed activity on the existing facilities adjacent to the proposed site due to generation of dust, smoke, odorous fumes or other hazardous gases?':
        data?.ecPartB?.ecRiskFactor?.impact_proposed_activity,

      'Details thereof (Impacts of the Proposed Activity)': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Existing adjacent facilities/Resources',
            value: 'existing',
          },
          {
            label: 'Impact',
            value: 'impact',
          },
        ],
        data: [
          {
            existing: 'Agriculture',
            impact: data?.ecPartB?.ecRiskFactor?.impact_existing_agriculture,
          },
          {
            existing: 'Natural Resources',
            impact:
              data?.ecPartB?.ecRiskFactor?.impact_existing_natural_resource,
          },
          {
            existing: 'Community facility',
            impact:
              data?.ecPartB?.ecRiskFactor?.impact_existing_community_facility,
          },
          {
            existing: 'Other',
            impact: data?.ecPartB?.ecRiskFactor?.impact_existing_others,
          },
        ],
      },

      'Whether lead to development of supportive facilities, ancillary development or development stimulated by the project which could have impact on the environment e.g.: Supportive infrastructure (roads, power supply, waste or waste water treatment, etc.); housing development; industries in supply chain and downstream; any other?':
        data?.ecPartB?.ecRiskFactor?.lead_development_facilities,

      'Details thereof (Lead to development of supportive facilitie)':
        data?.ecPartB?.ecRiskFactor?.lead_development_facilities_details,

      'Whether lead to after-use of the site, which could have an impact on the environment? (e.g. mine void, dump sites, etc.)':
        data?.ecPartB?.ecRiskFactor?.lead_site_impact,

      'Details thereof (Lead to after-use of the site)':
        data?.ecPartB?.ecRiskFactor?.lead_site_impact_details,

      'Whether set a precedent for later developments?':
        data?.ecPartB?.ecRiskFactor?.precedent_later_development,

      'Details thereof (Set a precedent for later developments)':
        data?.ecPartB?.ecRiskFactor?.precedent_later_development_details,

      'Have cumulative effects due to  proximity to other existing or planned projects with similar effects?':
        data?.ecPartB?.ecRiskFactor?.have_cumulative_effect,

      'Details thereof (Cumulative effects due to  proximity)':
        data?.ecPartB?.ecRiskFactor?.have_cumulative_effect_details,

      'Whether lead to growth of alien species, if any?':
        data?.ecPartB?.ecRiskFactor?.lead_growth_species,

      'Details thereof (Lead to growth of alien species)':
        data?.ecPartB?.ecRiskFactor?.lead_growth_species_details,

      'Is there any threat of the project to the biodiversity (including displacement of fauna-both terrestrial and aquatic and avi-fauna or creation of barriers for their movement)?':
        data?.ecPartB?.ecRiskFactor?.any_threat_biodiversity,

      "Give a description of the local ecosystem with it's unique features, if any along with mitigation measures for conservation of flora and fauna particularly for schedule-Ispecies":
        data?.ecPartB?.ecRiskFactor?.local_ecosystem_description,

      'Will the proposed project in any way result in the obstruction of a view, scenic amenity or landscapes?':
        data?.ecPartB?.ecRiskFactor?.will_proposed_project,

      'Details thereof (Proposed Project in any way result)':
        data?.ecPartB?.ecRiskFactor?.will_proposed_project_details,

      'Is there any impact on anthropological or archaeological sites or any important site feature in the vicinity of the proposed site have been considered?':
        data?.ecPartB?.ecRiskFactor?.any_impact_anthropological,

      'Details thereof (Impact on anthropological or archaeological sites)':
        data?.ecPartB?.ecRiskFactor?.any_impact_anthropological_details,

      'Will the proposed project result in any changes to the demographic structure of local population?':
        data?.ecPartB?.ecRiskFactor?.will_proposal_result,

      'Details thereof (Any changes to the demographic structure of local population)':
        data?.ecPartB?.ecRiskFactor?.will_proposal_result_details,

      'Will the project cause adverse effect on local communities, disturbance to sacred sites or other cultural values?':
        data?.ecPartB?.ecRiskFactor?.will_proposal_cause_details,

      'Details thereof (Adverse effect on local communities)':
        data?.ecPartB?.ecRiskFactor?.will_proposal_cause_details,

      'River Valley Project':
        data?.ecPartB?.ecRiverValley?.river_valley_project,

      'Whether project involves construction of Dam/Barrage/Weir?':
        data?.ecPartB?.ecRiverValley?.is_project_involves_in_construction,
      'Name of the River (source)': data?.ecPartB?.ecRiverValley?.source,
      'Type of Dam': data?.ecPartB?.ecRiverValley?.type_of_dam_configuration,
      'Dam height from the deepest foundation level (m)':
        data?.ecPartB?.ecRiverValley?.dam_height_configuration,
      'Length of the Dam (m)':
        data?.ecPartB?.ecRiverValley?.dam_length_configuration,
      'Maximum Height of the Embankment':
        data?.ecPartB?.ecRiverValley?.max_height_configuration,
      'Type of Spillways':
        data?.ecPartB?.ecRiverValley?.type_of_spillway_configuration,
      'No. of Spillways':
        data?.ecPartB?.ecRiverValley?.no_of_spillway_configuration,
      'Length of Main Canal (in m) (for Irrigation project)':
        data?.ecPartB?.ecRiverValley?.length_of_main_canal_configuration,
      'Length of Distributaries (in m) (for Irrigation project)':
        data?.ecPartB?.ecRiverValley?.length_of_distributaries_configuration,
      Other: data?.ecPartB?.ecRiverValley?.other_please_specify,
      configuration: data?.ecPartB?.ecRiverValley?.other_configuration,
      'Cultural Command Area (ha)':
        data?.ecPartB?.ecRiverValley?.cultural_command_area,
      'Irrigable Command Area (ha)':
        data?.ecPartB?.ecRiverValley?.irrigable_comman,
      'Type of Irrigation': data?.ecPartB?.ecRiverValley?.type_of_irrigable,
      'Type of Irrigation (Flood/Drip)':
        data?.ecPartB?.ecRiverValley?.type_of_irrigable_select,
      'Cropping pattern': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Air Pollution Source',
            value: 'pollution_source',
          },
          {
            label: 'Probable Pollutants',
            value: 'pollutant',
          },
          {
            label: 'Mitigation Measures',
            value: 'mitigation_measures',
          },
        ],
        data: data?.ecPartB?.irrigationEcCroppingPattern,
      },
      'cultural Command Area (ha)':
        data?.ecPartB?.ecRiverValley?.cultural_command_area,
      'irrigable Command Area (ha)':
        data?.ecPartB?.ecRiverValley?.irrigable_command_area,
      'type of Irrigation': data?.ecPartB?.ecRiverValley?.type_of_irrigable,
      'type of Irrigation (Flood/Drip)':
        data?.ecPartB?.ecRiverValley?.type_of_irrigable_select,
      'Head Race Tunnel (HRT) (diameter)':
        data?.ecPartB?.ecRiverValley?.head_race_tunnel_config,
      'Head Race Tunnel (HRT) (length (m))':
        data?.ecPartB?.ecRiverValley?.head_race_tunnel_length_config,
      'Surge shaft (diameter (m))':
        data?.ecPartB?.ecRiverValley?.shaft_configuration,
      'Surge shaft (height (m))':
        data?.ecPartB?.ecRiverValley?.shaft_height_config,
      'Pressure Shaft (Nos)& Penstock':
        data?.ecPartB?.ecRiverValley?.pshaft_config,
      'No. of Turbine Generating unit (MW)':
        data?.ecPartB?.ecRiverValley?.no_of_turbine,
      'Capacity of each turbine (MW)':
        data?.ecPartB?.ecRiverValley?.capacity_of_each_turbine,
      'Size of the Power House Cavern (m) (L*W*H)':
        data?.ecPartB?.ecRiverValley?.size_of_village,
      'Tail Race Tunnel (TRT) (diameter)': data?.ecPartB?.ecRiverValley?.trt,
      'Tail Race Tunnel (TRT) (length)':
        data?.ecPartB?.ecRiverValley?.trt_length,
      'Total design discharge (Cumec)':
        data?.ecPartB?.ecRiverValley?.total_design_discharge,
      'Installed Capacity (MW)':
        data?.ecPartB?.ecRiverValley?.installed_capacity,
      'Catchment Area (ha)': data?.ecPartB?.ecRiverValley?.catchment_area,
      'Average rainfall (mm)': data?.ecPartB?.ecRiverValley?.average_rainfall,
      'Water availability (MCM) (approx.)':
        data?.ecPartB?.ecRiverValley?.water_availability,
      'Submergence Area (ha), if any': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Area submerged',
            value: 'area_submerged',
          },
          {
            label: 'Area (Ha)',
            value: 'area',
          },
          {
            label: 'Mitigation Measures',
            value: 'mitigation_measures',
          },
        ],
        data: [],
      },
      'Muck Restoration Plan':
        data?.ecPartB?.ecRiverValley?.muck_restoration_plan,
      'Presence of Schedule I species within the study area':
        data?.ecPartB?.ecRiverValley?.schedule_1_species,

      'Brief on E flow': data?.ecPartB?.ecRiverValley?.fish_pass_envisaged,

      'Project components': data?.ecPartB?.ecTSDFProposals?.project_components,
      'any other details': data?.ecPartB?.ecTSDFProposals?.other_details,
      'nearest operational TSDF details':
        data?.ecPartB?.ecTSDFProposals?.nearest_operational_tsdf,
      'adherence to CPCB site selection criteria':
        data?.ecPartB?.ecTSDFProposals?.cpcb_site_selection_criteria,
      'details of Wastes to be Handled':
        data?.ecPartB?.ecTSDFProposals?.waste_dtls_to_be_handled,
      'details of Member units':
        data?.ecPartB?.ecTSDFProposals?.member_units_details,
      'mode of transportation of wastes to TSDF':
        data?.ecPartB?.ecTSDFProposals?.mode_of_trnsprt_to_tsdf,
      'land area distribution for Proposed activities in Ha':
        data?.ecPartB?.ecTSDFProposals?.lnd_area_distirbution,

      'Whether any generation of Solid waste (domestic wastes)?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Mode of Transport',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.SOLID_WASTE
        ),
      },
      'Whether any generation of plastic waste?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Mode of Transport',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.PLASTIC_WASTE
        ),
      },
      'Whether any generation of e-waste?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Mode of Transport',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.E_WASTE
        ),
      },
      'Whether any generation of batteries waste?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Mode of Transport',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.BATTERIES
        ),
      },
      'Whether any generation of Bio-medical waste?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Category as per BMWR, 2016',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.BIO_MEDICAL_WASTE
        ),
      },
      'Whether any generation of hazardous wastes (as per Hazardous Waste Management Rules)?':
      {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Mode of Transport',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.HAZARDOUS_WASTES
        ),
      },
      'Whether any generation of construction or demolition wastes?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Mode of Transport',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.CONSTRUCTION_OR_DEMOLITION_WASTES
        ),
      },
      'Whether any generation of other wastes?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the waste',
            value: 'name',
          },
          {
            label: 'Source',
            value: 'source',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Disposal',
            value: 'mode_of_disposal',
          },
          {
            label: 'Mode of Transport',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.OTHER_WASTES
        ),
      },
      'Whether any generation of surplus products?': {
        type: 'RELATIONAL_DATA',
        headers: [
          {
            label: 'Name of the surplus product',
            value: 'name',
          },
          {
            label: 'Qty (TPA)',
            value: 'quantity',
          },
          {
            label: 'Mode of Storage',
            value: 'mode_of_disposal',
          },
          {
            label: 'Remarks',
            value: 'mode_of_transport',
          },
        ],
        data: this.filteredArray(
          data?.ecPartB?.ecWasteDetails,
          'waste_type',
          EC_TypeOfWaste.SURPLUS_PRODUCT
        ),
      },

      'Whether measures for waste minimization proposed?':
        data?.ecPartB?.ecWasteProduction?.is_major_for_waste,
      'Details Thereof':
        data?.ecPartB?.ecWasteProduction?.major_for_waste_reason,

      // Water Details

      'Whether ground water table intersection involved in the project activities?':
        data?.ecPartB?.ecWaterDetails?.is_Ground_Water_Intersection,
      'Details of the Ground Water table':
        data?.ecPartB?.ecWaterDetails?.ground_Water_Intersection_Details,
      'Measures to recharge ground water':
        data?.ecPartB?.ecWaterDetails?.ground_Water_Intersection_Measures,
      'Whether approval obtained for ground water intersection from the competent authority':
        data?.ecPartB?.ecWaterDetails?.ground_Water_Intersection_Approval,
      'Area category from Groundwater availability perspective?':
        data?.ecPartB?.ecWaterDetails?.ground_Water_Availability_Description,
      'Whether Rainwater harvesting proposed?':
        data?.ecPartB?.ecWaterDetails?.is_Rainwater_Harvesting,
      'Capacity of facilities provided':
        data?.ecPartB?.ecWaterDetails?.rainwater_Capacity,
      'Description of facilities provided':
        data?.ecPartB?.ecWaterDetails?.rainwater_Description,
      'Total Quantity of water requirements met from water harvesting in KLD':
        data?.ecPartB?.ecWaterDetails?.rainwater_Harvesting_Quantity,
      'Storage capacity of rain water harvested in cubic meters':
        data?.ecPartB?.ecWaterDetails?.rainwater_Harvesting_Capacity,
      'Whether any other water conservation measures proposed?':
        data?.ecPartB?.ecWaterDetails?.other_Water_Conservation_Details,
      'Whether the ZLD is proposed?':
        data?.ecPartB?.ecWaterDetails?.is_ZLD_Achieved,
      'Details of ZLD': data?.ecPartB?.ecWaterDetails?.zld_Achieved_Details,
    };
    // ----EC Form 7 Basic ------//


    body = {
      CAF,
      PartA,
    };

    if (type == 'PartC') {
      body = {
        CAF,
        PartC: {
          ...PartA,
          'Select nature of the ToR': data?.ecPartC?.nature_of_tor,
          'Date of issuance of ToR/Standard ToR':
            data?.ecPartC?.date_of_issue_tor,
          'Date of issuance of Additional ToR, if any':
            data?.ecPartC?.date_of_issue_additional_tor,
          'MoEF&CC / SEIAA File No.': data?.ecPartC?.moef_file_no,
          'Upload ToR letter (PDF only)': data?.ecPartC?.tor_letter,
          'Whether any amendment to ToR has been obtained':
            data?.ecPartC?.is_any_amendment_tor,
          'Date of issuance of amendment in ToR':
            data?.ecPartC?.date_of_issue_amendment_tor,
          'Details of amendment': data?.ecPartC?.amendment_details,
          'Upload copy of letter of amendment in ToR (PDF Only)':
            data?.ecPartC?.tor_letter_copy,
          'Whether the Project has been exempted from Public Hearing?':
            data?.ecPartC?.is_project_exempted,
          Reason: data?.ecPartC?.project_exempted_reason,
          'Upload recommendation of EAC (pdf only)':
            data?.ecPartC?.eac_recommendation,
          'Please Specify': data?.ecPartC?.reason_other_field,
          'Details of Public Hearing': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Date of advertisement',
                value: 'date_of_advertisment',
              },
              {
                label: 'Date of PH',
                value: 'date_of_PH',
              },
              {
                label: 'No. of People attended',
                value: 'no_of_people_attended',
              },
              {
                label: 'Designation of Preceding Officer',
                value: 'designation_of_presiding',
              },
              {
                label:
                  ' Copy of duly signed Proceedings of Public Hearing in English',
                value: 'public_hearing_copy',
              },
              {
                label: 'State',
                value: 'state_name',
              },
              {
                label: 'District',
                value: 'district_name',
              },
              {
                label: 'Sub District',
                value: 'sub_district_name',
              },
              {
                label: 'Village',
                value: 'village_name',
              },
              {
                label: 'Distance from project boundary (Km)',
                value: 'distance',
              },
            ],
            data: data?.ecPartC?.hearingDetails,
          },
          'Major issues raised during public hearing & written submissions': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Major issues raised',
                value: 'major_issues_raised',
              },
              {
                label: 'Response of Project Proponent',
                value: 'pp_response',
              },
              {
                label: 'Whether addressed in Final EIA/EMP',
                value: 'is_final_eia_addressed',
              },
              {
                label: 'Reference of Final EIA/EMP',
                value: 'final_eia_reference',
              },
            ],
            data: data?.ecPartC?.hearingIssues,
          },
          'Action Plan on issues raised during public hearing & written submissions as per MoEF&CC OM dated 30/09/2020':
            data?.ecPartC?.action_plan_raised,
          'Upload documents if any': data?.ecPartC?.additional_document,
          // ----started BaseLine----
          Season: data?.ecPartC?.ecBaseLineDetails?.season,
          'From (Period of collection)':
            data?.ecPartC?.ecBaseLineDetails?.period_from,
          'To  (Period of collection)':
            data?.ecPartC?.ecBaseLineDetails?.period_to,
          'Meteorological Parameters': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Parameter',
                value: 'parameter',
              },
              {
                label: 'Min. Value',
                value: 'min_value',
              },
              {
                label: 'Max. Value',
                value: 'max_value',
              },
              {
                label: 'Mean Value',
                value: 'mean_value',
              },
            ],
            data: [
              {
                parameter: 'Temperature (°C)',
                min_value: data?.ecPartC?.ecBaseLineDetails?.temperature_min,
                max_value: data?.ecPartC?.ecBaseLineDetails?.temperature_max,
                mean_value: data?.ecPartC?.ecBaseLineDetails?.temperature_mean,
              },
              {
                parameter: 'Wind Speed (m/s)',
                min_value: data?.ecPartC?.ecBaseLineDetails?.wind_speed_min,
                max_value: data?.ecPartC?.ecBaseLineDetails?.wind_speed_max,
                mean_value: data?.ecPartC?.ecBaseLineDetails?.wind_speed_mean,
              },
              {
                parameter: 'Relative Humidity (%)',
                min_value:
                  data?.ecPartC?.ecBaseLineDetails?.relative_humidity_min,
                max_value:
                  data?.ecPartC?.ecBaseLineDetails?.relative_humidity_max,
                mean_value:
                  data?.ecPartC?.ecBaseLineDetails?.relative_humidity_mean,
              },
              {
                parameter: 'Solar Radiation (W/m<sup>2</sup>)',
                min_value:
                  data?.ecPartC?.ecBaseLineDetails?.solar_radiation_min,
                max_value:
                  data?.ecPartC?.ecBaseLineDetails?.solar_radiation_max,
                mean_value:
                  data?.ecPartC?.ecBaseLineDetails?.solar_radiation_mean,
              },
            ],
          },
          Rainfall: {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Parameter',
                value: 'parameter',
              },
              {
                label: 'Total rainfall (mm)',
                value: 'rainfall_total',
              },
              {
                label: 'No. of rainy days',
                value: 'rainfall_days',
              },
              {
                label: 'Average annual rainfall (mm)',
                value: 'rainfall_average',
              },
            ],
            data: [
              {
                parameter: 'rainfall',
                rainfall_total:
                  data?.ecPartC?.ecBaseLineDetails?.rainfall_total,
                rainfall_days: data?.ecPartC?.ecBaseLineDetails?.rainfall_days,
                rainfall_average:
                  data?.ecPartC?.ecBaseLineDetails?.rainfall_average,
              },
            ],
          },

          'Ambient Air Quality (Buffer Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'aaq_location_core',
              },
              {
                label: 'Criteria Pollutant',
                value: 'aaq_criterial_pollutant',
              },
              {
                label: 'Unit',
                value: 'aaq_unit',
              },
              {
                label: 'From',
                value: 'aaq_max_value',
              },
              {
                label: 'To',
                value: 'aaq_min_value',
              },
              {
                label: 'Mean Value',
                value: 'value.aaq_percentile_value',
              },
              {
                label: 'Prescribed Standard',
                value: 'aaq_prescribed_standard',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecAmbientAirQualities,
              'aaq_location_core',
              'Buffer Zone'
            ),
          },

          'Ambient Air Quality (Core Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'aaq_location_core',
              },
              {
                label: 'Criteria Pollutant',
                value: 'aaq_criterial_pollutant',
              },
              {
                label: 'Unit',
                value: 'aaq_unit',
              },
              {
                label: 'From',
                value: 'aaq_max_value',
              },
              {
                label: 'To',
                value: 'aaq_min_value',
              },
              {
                label: 'Mean Value',
                value: 'aaq_percentile_value',
              },
              {
                label: 'Prescribed Standard',
                value: 'aaq_prescribed_standard',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecAmbientAirQualities,
              'aaq_location_core',
              'Core Zone'
            ),
          },
          'Surface Water Quality (Buffer Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'swq_location_core',
              },
              {
                label: 'Criteria Pollutant',
                value: 'swq_criterial_pollutant',
              },
              {
                label: 'Unit',
                value: 'swq_unit',
              },
              {
                label: 'Observed Value',
                value: 'swq_observed_value',
              },
              {
                label: 'Standard as per IS: 2296-1982',
                value: 'swq_is_2296',
              },
              {
                label: 'From',
                value: 'swq_observed_value',
              },
              {
                label: 'To',
                value: 'swq_observed_value_to',
              },
              {
                label: 'Class',
                value: 'swq_cpcb_criteria_class',
              },
              {
                label: 'Standard',
                value: 'swq_cpcb_criteria_standard',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecSurfaceWaterQualities,
              'swq_location_core',
              'Buffer Zone'
            ),
          },
          'Surface Water Quality (Core Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'swq_location_core',
              },
              {
                label: 'Criteria Pollutant',
                value: 'swq_criterial_pollutant',
              },
              {
                label: 'Unit',
                value: 'swq_unit',
              },
              {
                label: 'Observed Value',
                value: 'swq_observed_value',
              },
              {
                label: 'Standard as per IS: 2296-1982',
                value: 'swq_is_2296',
              },
              {
                label: 'From',
                value: 'swq_observed_value',
              },
              {
                label: 'To',
                value: 'swq_observed_value_to',
              },
              {
                label: 'Class',
                value: 'swq_cpcb_criteria_class',
              },
              {
                label: 'Standard',
                value: 'swq_cpcb_criteria_standard',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecSurfaceWaterQualities,
              'swq_location_core',
              'Core Zone'
            ),
          },
          'Ground Water Quality (Buffer Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'gwq_location_core',
              },
              {
                label: 'Criteria Pollutant',
                value: 'gwq_criterial_pollutant',
              },
              {
                label: 'Unit',
                value: 'gwq_unit',
              },
              {
                label: 'Observed Value',
                value: 'gwq_observed_value',
              },
              {
                label: 'Standard as per IS: 10500 Desired Limits',
                value: 'gwq_desired_limits',
              },
              {
                label: 'Standard as per IS: 10500 Permissible Limits',
                value: 'gwq_permissible_limits',
              },
              {
                label: 'From',
                value: 'gwq_observed_value',
              },
              {
                label: 'To',
                value: 'gwq_observed_value_to',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecGroundWaterQualities,
              'gwq_location_core',
              'Buffer Zone'
            ),
          },
          'Ground Water Quality (Core Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'gwq_location_core',
              },
              {
                label: 'Criteria Pollutant',
                value: 'gwq_criterial_pollutant',
              },
              {
                label: 'Unit',
                value: 'gwq_unit',
              },
              {
                label: 'Observed Value',
                value: 'gwq_observed_value',
              },
              {
                label: 'Standard as per IS: 10500 Desired Limits',
                value: 'gwq_desired_limits',
              },
              {
                label: 'Standard as per IS: 10500 Permissible Limits',
                value: 'gwq_permissible_limits',
              },
              {
                label: 'From',
                value: 'gwq_observed_value',
              },
              {
                label: 'To',
                value: 'gwq_observed_value_to',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecGroundWaterQualities,
              'gwq_location_core',
              'Core Zone'
            ),
          },
          'Ground Water Level (Phreatic Surface) (Buffer Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location ',
                value: 'gwl_location_core',
              },
              {
                label: 'From (Pre-monsoon)',
                value: 'gwl_pre_monsoon_from',
              },
              {
                label: 'To (Pre-monsoon)',
                value: 'gwl_pre_monsoon_to',
              },
              {
                label: 'From (Post-monsoon)',
                value: 'gwl_post_monsoon_from',
              },
              {
                label: 'To (Post-monsoon)',
                value: 'gwl_post_monsoon_to',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecGroundWaterLevels,
              'gwl_location_core',
              'Buffer Zone'
            ),
          },
          'Ground Water Level (Phreatic Surface) (Core Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location ',
                value: 'gwl_location_core',
              },
              {
                label: 'From (Pre-monsoon)',
                value: 'gwl_pre_monsoon_from',
              },
              {
                label: 'To (Pre-monsoon)',
                value: 'gwl_pre_monsoon_to',
              },
              {
                label: 'From (Post-monsoon)',
                value: 'gwl_post_monsoon_from',
              },
              {
                label: 'To (Post-monsoon)',
                value: 'gwl_post_monsoon_to',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecGroundWaterLevels,
              'gwl_location_core',
              'Core Zone'
            ),
          },
          'Noise Level (Buffer Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location ',
                value: 'nl_location_core',
              },
              {
                label: 'Category',
                value: 'nl_category',
              },
              // {
              //   label: 'To (Pre-monsoon)',
              //   value: 'nl_observed_noise_day_time',
              // },
              {
                label: 'Day Time Level',
                value: 'nl_observed_noise_day_time',
              },
              {
                label: 'To',
                value: 'nl_observed_noise_day_time_to',
              },
              {
                label: 'Night Time Level',
                value: 'nl_observed_noise_night_time',
              },
              {
                label: 'Day Time Level',
                value: 'nl_prescribed_standard_day_time',
              },
              {
                label: 'Night Time Level',
                value: 'nl_prescribed_standard_night_time',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecNoiseLevels,
              'nl_location_core',
              'Buffer Zone'
            ),
          },
          'Noise Level (Core Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location ',
                value: 'nl_location_core',
              },
              {
                label: 'Category',
                value: 'nl_category',
              },
              // {
              //   label: 'To (Pre-monsoon)',
              //   value: 'nl_observed_noise_day_time',
              // },
              {
                label: 'Day Time Level',
                value: 'nl_observed_noise_day_time',
              },
              {
                label: 'To',
                value: 'nl_observed_noise_day_time_to',
              },
              {
                label: 'Night Time Level',
                value: 'nl_observed_noise_night_time',
              },
              {
                label: 'Day Time Level',
                value: 'nl_prescribed_standard_day_time',
              },
              {
                label: 'Night Time Level',
                value: 'nl_prescribed_standard_night_time',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecNoiseLevels,
              'nl_location_core',
              'Core Zone'
            ),
          },
          'Soil Quality (Buffer Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'sq_location_core',
              },
              {
                label: 'Soil Texture',
                value: 'sq_soil_texture',
              },
              {
                label: 'Sand (Particle Size Distribution (%))',
                value: 'sq_particle_size_sand',
              },
              {
                label: 'Silt (Particle Size Distribution (%))',
                value: 'sq_particle_size_silt',
              },
              {
                label: 'Clay (Particle Size Distribution (%))',
                value: 'sq_particle_size_clay',
              },
              {
                label: 'Water Holding Capacity (%)',
                value: 'sq_water_holding_capacity',
              },
              {
                label: 'Porosity (%)',
                value: 'sq_porosity',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecSoilQualities,
              'sq_location_core',
              'Core Zone'
            ),
          },
          'Soil Quality (Core Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'sq_location_core',
              },
              {
                label: 'Soil Texture',
                value: 'sq_soil_texture',
              },
              {
                label: 'Sand (Particle Size Distribution (%))',
                value: 'sq_particle_size_sand',
              },
              {
                label: 'Silt (Particle Size Distribution (%))',
                value: 'sq_particle_size_silt',
              },
              {
                label: 'Clay (Particle Size Distribution (%))',
                value: 'sq_particle_size_clay',
              },
              {
                label: 'Water Holding Capacity (%)',
                value: 'sq_water_holding_capacity',
              },
              {
                label: 'Porosity (%)',
                value: 'sq_porosity',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecSoilQualities,
              'sq_location_core',
              'Core Zone'
            ),
          },
          'Chemical Properties (Buffer Zone)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'cp_location_core',
              },
              {
                label: 'Criteria Parameter',
                value: 'cp_criteria_parameter',
              },
              {
                label: 'Unit',
                value: 'cp_unit',
              },

              {
                label: 'From',
                value: 'cp_observed_value',
              },
              {
                label: 'To',
                value: 'cp_observed_value_to',
              },
              {
                label: 'Permissible Standard',
                value: 'cp_permissible_standard',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecChemicalProperties,
              'cp_location_core',
              'Buffer Zone'
            ),
          },
          'Chemical Properties': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Monitoring Location',
                value: 'cp_location_core',
              },
              {
                label: 'Criteria Parameter',
                value: 'cp_criteria_parameter',
              },
              {
                label: 'Unit',
                value: 'cp_unit',
              },

              {
                label: 'From',
                value: 'cp_observed_value',
              },
              {
                label: 'To',
                value: 'cp_observed_value_to',
              },
              {
                label: 'Permissible Standard',
                value: 'cp_permissible_standard',
              },
            ],
            data: this.filteredArray(
              data?.ecPartC?.ecChemicalProperties,
              'cp_location_core',
              'Core Zone'
            ),
          },
          'Whether Traffic study has been conducted?':
            data?.ecPartC?.ecBaseLineDetails?.is_traffic_study_conducted,

          'Traffic study has been conducted?': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Parameter',
                value: 'parameter',
              },
              {
                label: 'Existing',
                value: 'existing_value',
              },
              {
                label: 'Proposed',
                value: 'proposed_value',
              },
            ],
            data: [
              {
                parameter: 'Road',
                existing_value: data?.ecPartC?.ecBaseLineDetails?.road_existing,
                proposed_value: data?.ecPartC?.ecBaseLineDetails?.road_proposed,
              },
              {
                parameter: 'V (volume in PCU/day)',
                existing_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_existing_v,
                proposed_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_proposed_v,
              },
              {
                parameter: 'C (capacity in PCU/day)',
                existing_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_existing_c,
                proposed_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_proposed_c,
              },
              {
                parameter: 'Existing V/C Ratio',
                existing_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_existing_radio,
                proposed_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_proposed_radio,
              },
              {
                parameter: 'LOS',
                existing_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_existing_los,
                proposed_value:
                  data?.ecPartC?.ecBaseLineDetails?.road_proposed_los,
              },
            ],
          },

          'Reason thereof': data?.ecPartC?.ecBaseLineDetails?.traffic_reason,

          'Whether any Schedule-I Species found in the study area?':
            data?.ecPartC?.ecBaseLineDetails?.is_any_species_found,
          'Details of Schedule-I Species':
            data?.ecPartC?.ecBaseLineDetails?.species_details,
          'Whether conservation plan for Schedule-I Species has been prepared?':
            data?.ecPartC?.ecBaseLineDetails
              ?.is_conservation_plan_species_prepared,
          'Copy of conservation plan':
            data?.ecPartC?.ecBaseLineDetails?.conservation_plan_copy,
          'Fund Provision made (In Lakhs)':
            data?.ecPartC?.ecBaseLineDetails?.fund_provision_made,
          'Period of Implementation':
            data?.ecPartC?.ecBaseLineDetails?.period_of_implementation,
          'Whether conservation plan for Schedule-I Species has been approved by competent authority?':
            data?.ecPartC?.ecBaseLineDetails
              ?.is_conservation_plan_species_approved,
          'Copy of approval (Upload PDF Only)':
            data?.ecPartC?.ecBaseLineDetails?.approval_copy,
          'Letter No.': data?.ecPartC?.ecBaseLineDetails?.letter_no,
          'Date of issue': data?.ecPartC?.ecBaseLineDetails?.issue_date,
          'Recommendations if any':
            data?.ecPartC?.ecBaseLineDetails?.any_recommendation,

          //  -----started other details----
          'Impact Prediction (Air Quality Impact Prediction)': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Lat',
                value: 'aqi_location_lat',
              },
              {
                label: 'Long',
                value: 'aqi_location_long',
              },
              {
                label: 'Core/Buffer',
                value: 'aqi_location_core',
              },

              {
                label: 'Unit',
                value: 'aqi_unit',
              },
              {
                label: 'Baseline Concentration [A]',
                value: 'aqi_baseline_concentration',
              },
              {
                label:
                  'Predicted incremental value considering worst case stability class [B]',
                value: 'aqi_predicted_incr_value',
              },
              {
                label: 'Total GLC [A]+[B]',
                value: 'aqi_total_glc',
              },
              {
                label: 'Prescribed Standard',
                value: 'aqi_prescribed_standard',
              },
            ],
            data: data?.ecPartC?.ecOtherDetails?.ecAirQualityImpacts,
          },
          'Funds Allocated for Environment Management': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'EMPs',
                value: 'emp',
              },
              {
                label: 'Capital Cost (INR)',
                value: 'capital_cost',
              },
              {
                label: 'Recurring Cost per Annum (INR)',
                value: 'recurring_cost',
              },
            ],
            data: data?.ecPartC?.ecOtherData?.ecSummaryAllocations,
          },
          'Status of Land Acquisition':
            data?.ecPartC?.ecOtherData?.land_acquisition_status,
          'Acquired Land (Ha)': data?.ecPartC?.ecOtherData?.acquired_land,
          'Land yet to be acquired (Ha)':
            data?.ecPartC?.ecOtherData?.land_to_be_acquired,
          'Details of Post-project monitoring program': {
            type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Attribute',
                value: 'attribute',
              },
              {
                label: 'Parameters proposed for monitoring',
                value: 'proposed_parameter',
              },
              {
                label: 'Lat (Monitoring)',
                value: 'location_lat',
              },
              {
                label: 'Long (Monitoring)',
                value: 'location_long',
              },
              {
                label: 'Mode of Monitoring',
                value: 'monitoring_mode',
              },
              {
                label: 'Frequency of Monitoring',
                value: 'monitoring_frequency',
              },
              {
                label: 'Project phase in which monitoring is required',
                value: 'project_phase',
              },
              {
                label: 'Monitoring Agency',
                value: 'monitoring_agency',
              },
            ],
            data: data?.ecPartC?.ecParameterMonitors,
          },
          'Whether Environmental cell is proposed for implementation and monitoring of EMP':
            data?.ecPartC?.ecOtherData?.is_environmental_cell_proposed,
          'Organizational structure of the Environmental Management Cell':
            data?.ecPartC?.ecOtherData?.env_organisational_structure,
          'Details of responsibilities and scope of work, assigned to each member in the organizational structure of the Environmental Management Cell':
            data?.ecPartC?.ecOtherData?.details_of_responsibilities,
          'Details on procedure to report observation of Environmental Management Cell to Project Head':
            data?.ecPartC?.ecOtherData?.procedure_to_report,
          'Reason thereof (Cell Reason)':
            data?.ecPartC?.ecOtherData?.env_cell_reason,
          'Procedure to review effective implementation and monitoring of EMP and reporting of status of implementation to Project Head':
            data?.ecPartC?.ecOtherData?.procedure_to_review,
          'Whether compliance report from integrated regional office on existing EC is obtained?':
            data?.ecPartC?.ecOtherData?.is_compliance_report,
          'Date of site visit':
            data?.ecPartC?.ecOtherData?.compliance_date_of_site,
          'Final observation of IRO':
            data?.ecPartC?.ecOtherData?.compliance_final_observation,
          'Upload Compliance Report':
            data?.ecPartC?.ecOtherData?.compliance_report,
          'Reason thereof (Compliance Reason)':
            data?.ecPartC?.ecOtherData?.compliance_reason,
          // ----Enclosure started from here ------
          'Upload Copy of Final EIA/EMP Report':
            data?.ecPartC?.ecEnclosures?.eia_final_copy,
          'Executive summary of feasibility report/project report':
            data?.ecPartC?.ecEnclosures?.feasibility_summary_report,
          'Copy of approved mining plan (in case of mining project)':
            data?.ecPartC?.ecEnclosures?.approved_mining_plan_copy,
          'Add URL': data?.ecPartC?.ecEnclosures?.approved_mining_plan_url,
        },
      };
    }//ec end 

    if (type == 'WL_FORM'){
      body = {
        CAF,
        wlForm:{
          'State' : data?.wlForm?.proponentApplications?.state,
        //case yes
        'Whether proposal is for investigation/survey?' : data?.wlForm?.for_investigation_survey_new,
        //'Upload a copy of note containing justification for locating the project in protected area' : data?.doctoBase64[1]?.base64,
        'Category of the project ' : data?.wlForm?.category_of_project,
        'Category of area required ' : data?.wlForm?.category_of_area,
        //'Whether any proposal seeking prior approval of Central Government under the Forest (Conservation) Act for diversion of forest land required for this project has been submitted in the past? ' : ,
        'Status of the proposal ' : {
          type: 'RELATIONAL_DATA',
            headers: [
              { label: 'Status of the proposal', value: 'proposal_status' },
              { label: 'Proposal No./Project ID ', value: 'proposalNo' },
              { label: 'Project Name  ', value: 'proposalName' },
              { label: 'MoEFCC File No. ', value: 'moefccFileNo' },
              {
                label: 'Area proposed for Diversion (ha.) ',
                value: 'proposedDiversionArea',
              },
              {
                label: 'Area Diverted (Ha) ',
                value: 'divertedArea',
              },
              {
                label: 'Area recommended for Diversion (ha.)',
                value: 'divertedArea',
              },
              {
                label: 'Date of in-principle approval',
                value: 'inPrincipleApprovalDate',
              },
              { label: 'Date of final approval', value: 'approvalDate' },
              { label: 'Date of Application', value: 'applicationDate' },
            ],
            data: data?.wlForm?.priorApprovals,
        },
        'Division' : data?.wlForm?.division_name,
        'Purpose for the investigation/survey' : data?.wlForm?.investigation_purpose,
        'Location and Map (1:50000 scale) of the area duly authenticated by the competent authority to be investigated/surveyed' : data?.wlForm?.location_and_map_new,
        'Whether investigation/ survey involves destruction, exploitation or removal of any wild life including forest produce from National Park/ sanctuary or destruction or damage or diversion of the habitat of any wild animal by any act whatsoever or diversion, stoppage or enhancement of the flow of water into or outside the National Park/ sanctuary under section 29 or 35 (6) of Wild Life Protection Act, 1972 ' : data?.wlForm?.physical_disturbance_within_protected_area,
        'Extent of physical disturbance needed within the PA ' : data?.wlForm?.extent_of_physical_disturbance_new,
        'Earth work (cu. m.)': data?.wlForm?.earthwork_new,
        'Time Required (days)' :  data?.wlForm?.earthwork_timerequired_new ,
        //'Upload a copy of note containing justification for locating the project in protected area ' : data?.doctoBase64[1]?.base64,
        'Location of Project Area' : 
        data?.wlForm?.wlProposedLand?.location_of_project_area,
        
        'No. of Division involved in Protected Areas' : data?.wlForm?.no_of_division,
        'Detailed breakup of land required for the project' : {
          type: 'RELATIONAL_DATA',
            headers: [
              {
                label: 'Division ',
                value: 'division',
              },
              { label: 'Total Patches', value: 'total_patches' },
              {
                label: 'Upload single KML of all patches/segments for the selected division ',
                value: 'kml',
              },
            ],
            data: data?.wlForm?.wildLifeClearanceProposedDiversions,
        },
        'Division-wise breakup of land required' :{
          type: 'RELATIONAL_DATA',
              headers: [
                {
                  label: 'Division',
                  value: 'division',
                },
                { label: 'District', 
                  value: 'district' 
                },
                {
                  label: 'Name of the protected area/ Tiger Reserve',
                  value: 'protected_area',
                },
                {
                  label: 'Tiger Reserve (Ha) Forest Land',
                  value: 'forest_protected_area',
                },
                {
                  label: 'Tiger Reserve (Ha) Non-Forest Land',
                  value: 'non_forest_protected_area',
                },
                {
                  label: 'Tiger Reserve (Ha) Total Land',
                  value: 'total_protected_area',
                },
                {
                  label: 'Project area outside Protected Area (Ha) Forest Land',
                  value: 'forest_protected_outside_area',
                },
                {
                  label: 'Project area outside Protected Area (Ha) Non-Forest Land',
                  value: 'non_forest_protected_outside_area',
                },
                {
                  label: 'Project area outside Protected Area (Ha) Total Land',
                  value: 'total_protected_outside_area',
                },
  
              ],
              data: data?.wlForm?.
              wlProposedLand
              ?.wldivisionLandDetails,
        } ,
        'Total Area (ha)' : data?.wlForm?.wlProposedLand?.total_division_land,
        ' Component-wise breakup of land required Linear' : {
          type: 'RELATIONAL_DATA',
              headers: [
                {
                  label: 'Component',
                  value: 'component',
                },
                { label: 'Name of the PA', 
                  value: 'name_protected_area' },
                {
                  label: 'Project area under PA (ha) Forest ',
                  value: 'forest_land_under_protected_area',
                },
                {
                  label: 'Project area under PA (ha) Non-Forest',
                  value: 'non_forest_land_under_protected_area',
                },
            
                {
                  label: 'Project area under PA (ha) Total Land',
                  value: 'total_land_under_protected_area',
                },
                {
                  label: 'Project area outside PA (Y) (ha) Forest Land',
                  value: 'forest_land_outside_protected_area',
                },
                {
                  label: 'Project area outside PA (Y) (ha) Non-Forest Land',
                  value: 'non_forest_land_outside_protected_area',
                },
                {
                  label: 'Project area outside PA (Y) (ha) Total Land',
                  value: 'total_land_outside_protected_area',
                },
                // {
                //   label : 'Component Area (X + Y) (ha)',
                //   value : ''
                // }
  
              ],
              data: data?.wlForm?.wlComponentWiseDetails[1],
              
        },
  
        ' Component-wise breakup of land required Non-Linear' : {
          type: 'RELATIONAL_DATA',
              headers: [
                {
                  label: 'Component',
                  value: 'component',
                },
                { label: 'Name of the PA',
                  value: 'name_protected_area' },
                {
                  label: 'Project area under PA (ha) Forest ',
                  value: 'forest_land_under_protected_area',
                },
                {
                  label: 'Project area under PA (ha) Non-Forest',
                  value: 'non_forest_land_under_protected_area',
                },
            
                {
                  label: 'Project area under PA (ha) Total Land',
                  value: 'total_land_under_protected_area',
                },
                {
                  label: 'Project area outside PA (Y) (ha) Forest Land',
                  value: 'forest_land_outside_protected_area',
                },
                {
                  label: 'Project area outside PA (Y) (ha) Non-Forest Land',
                  value: 'non_forest_land_under_protected_area',
                },
                {
                  label: 'Project area outside PA (Y) (ha) Total Land',
                  value: 'total_land_outside_protected_area',
                },
                // {
                //   label : 'Component Area (X + Y) (ha)',
                //   value : ''
                // }
  
              ],
              data:   data?.wlForm?.wlComponentWiseDetails[0],
        },
        'Total Area Required (ha)' : data?.wlForm?.wlProposedLand?.total_component_land,
        'Provide animal passage plan' : 
        data?.wlForm?.wlProposedLand?.animal_passage_plan,
        
        'Total period for which land is required (years)' : data?.wlForm?.wlProposedLand?.total_land_period,
        // 'Aerial distance of the project from the boundary of the PA (km)' : ,
        // 'Project area under PA / Tiger corridor / Tiger Reserve / ESZ (ha)' : ,
        'No of trees proposed to be cut' : data?.wlForm?.no_of_trees_to_be_cut
        ,
        'Scanned copy of the Geo-referenced map of the project area with respect to the protected area prepared by using DGPS or Total Station (pdf only) ' : data?.wlForm?.wlProposedLand?.diversion_map_copy,
       // 'Information on the projects undertaken by the proponent agency in the past in Protected Areas ' : data?.doctoBase64?.[0]?.base64,
        'Cost-benefit analysis in terms of maintenance of carbon neutrality or increase in carbon sequestration' : data?.wlForm?.wlProposedLand?.cost_benefit_analysis,
        'Whether the Project requires Clearance under the Environment (Protection) Act 1986 (Environmental clearance)? ' :  data?.wlForm?.wlProposedLand?.is_project_require_clearance_under_ec_act,
        'Status of Environmental Clearance ' : data?.wlForm?.wlProposedLand?.status_ec_clearance,
        'Project ID/Proposal No. ' : data?.wlForm?.wlProposedLand?.
        proposalNo,
        ' Date of issue of environmental clearance' : data?.wlForm?.wlProposedLand?.
        issue_of_ec,
  
        //'Date of application' : ec_submission_date,
        'MoEFCC / SEIAA File Number' : data?.wlForm?.moefccFileNo,
        'Status of application [Submitted recently / ToR Granted / PH Conducted / Under appraisal]' : data?.wlForm?.status_of_application,
        //'Reasons thereof' : ec_application_sub_status  ,
        'Upload EC Letter (in pdf)' : data?.wlForm?.wlProposedLand?.ec_letter,
        'Date of issue of environmental clearance ' :  data?.wlForm?.wlProposedLand?.ec_issued_date,
        'Upload information on previous projects undertaken by the proponent agency in Protected Areas' : data?.wlForm?.wlEnclosures?.proponent_undertaken_report,
        'Copy of approved mining plan along with approval letter' : data?.wlForm?.wlEnclosures?.copy_of_mining_plan,
        'Copy of map of the outer boundary of mining lease area (pdf only)' : data?.wlForm?.wlEnclosures?.copy_of_mining_lease,
        'Copy of the detailed land use plan in 1:4,000 scale prepared by using DGPS or Total Station (Kml only)': data?.wlForm?.wlEnclosures?.copy_detail_land,
        'Copy of the prospecting licence and extension of the prospecting licence, if any' : data?.wlForm?.wlEnclosures?.copy_perspecting_licence,
        'Copy of the approval accorded for MoEFCC for prospecting' : data?.wlForm?.wlEnclosures?.approval_moefcc,
        'Copy of note containing details of the plan for the transportation of the minerals proposed to be raised from the mining lease (pdf only)' : data?.wlForm?.wlEnclosures?.transportation_minerals_proposed,
        'Period of validity of Mining Plan From' : data?.wlForm?.wlcMiningProposals
        ?.from_mining_plan,
        'Period of validity of Mining Plan To': data?.wlForm?.wlcMiningProposals
        ?.to_mining_plan,
        'Period of Mining Lease (Years)' : data?.wlForm?.wlcMiningProposals?.period_of_mining_lease,
        'Mineral Reserves':{
              type: 'RELATIONAL_DATA',
              headers: [
                { label: 'Name of the Mineral', value: 'mineral_name' },
                { label: 'Proved Reserves (Million Ton)', value: 'proved_reserves' },
                { label: 'Indicated Reserves (Million Ton)', value: 'indicated_reserves' },
                { label: 'Inferred Reserves (Million Ton)', value: 'inferred_reserves' },
                { label: 'Mineable Reserves (Million Ton)', value: 'mineable_reserves' },
                { label: 'Remarks', value: 'remarks' },
              ],
              data: data?.wlForm?.wlcMiningProposals
              ?.miningMineralReserves,
              
            },
  
              'Date of issue of LoI/Vesting order/Minning Lease' : data?.wlForm?.wlcMiningProposals?.date_of_issue,
              'Period of validity of LoI / Vesting order (years and months)' : data?.wlForm?.wlcMiningProposals?.date_of_validity,
              'Period of lease (years and months)' : data?.wlForm?.wlcMiningProposals?.lease_period,
              'Date of expiry of lease' : data?.wlForm?.wlcMiningProposals?.date_of_expiry,
              'Lease area (in ha) as per Lol/Vesting order/Mining Lease': data?.wlForm?.wlcMiningProposals?.lease_area,
              'Production capacity (in MTPA) as per Lol/Vesting Order/Mining lease, if any prescribed' : data?.wlForm?.wlcMiningProposals?.production_capacity,
              'Details of Lease renewal(s), if any' : data?.wlForm?.wlcMiningProposals?.detail_of_lease,
              'Other information, if any' : data?.wlForm?.wlcMiningProposals?.other_info,
              'Status of approval of Mining plan '  : data?.wlForm?.wlcMiningProposals?.other_info,
              'Minerals to be mined' :  {
                type: 'RELATIONAL_DATA',
                  headers: [
                    {
                      label: 'Name of the Mineral to be mined ',
                      value: 'division',
                    },
                    { label: 'Classification of mineral ', value: 'total_patches' },
                    {
                      label: 'Production capacity in MTPA',
                      value: 'kml',
                    },
                    {
                      label: 'Remarks',
                      value: 'kml',
                    },
                  ],
                  data: data?.wlForm?.wlcMiningProposals?.miningMineralsMineds,
              },
              'Total excavation in MTPA' : data?.wlForm?.wlcMiningProposals?.total_excavation_mtpa,
              'Total excavation in M.Cu.m/Annum' :  data?.wlForm?.wlcMiningProposals?.total_excavation_annum,
              'Stripping Ratio' : data?.wlForm?.wlcMiningProposals?.stripping_ratio,
              'Other information, if any ' : data?.wlForm?.wlcMiningProposals?.excavation_other_info,
              'Life of the mine as per approved mining plan (in years and months)': data?.wlForm?.wlcMiningProposals?.approved_life_of_mine,
              'Life of the mine as per total estimated reserves, if any (in years and months)': data?.wlForm?.wlcMiningProposals?.estimate_life_of_mine,
              'Other information,if any ' : data?.wlForm?.wlcMiningProposals?.life_of_mine_other_info,
              'Type of blasting ' : data?.wlForm?.wlcMiningProposals?.type_of_blasting,
              'Mitigation measures for control of blast induced vibrations ': data?.wlForm?.wlcMiningProposals?.mitigation_blast_control,
              'Other information, if any  ' : data?.wlForm?.wlcMiningProposals?.type_of_mining_other_info,
              'Whether it is proposed to install beneficiation plant/Coal washery within the mining lease area? ' : data?.wlForm?.wlcMiningProposals?.proposed_to_install_coal,
              'Capacity of beneficiation/washery (in MTPA)': data?.wlForm?.wlcMiningProposals?.coal_capacity,
              'Proposed process ' : data?.wlForm?.wlcMiningProposals?.coal_proposed_process,
              'Beneficiation/Washing Technology ': data?.wlForm?.wlcMiningProposals?.coal_beneficiation,
              'Capacity Other information, if any' : data?.wlForm?.wlcMiningProposals?.coal_other_info,
              'No. Of crushers': data?.wlForm?.wlcMiningProposals?.number_of_crusher,
              'Capacity of crushers' : data?.wlForm?.wlcMiningProposals?.capacity_of_crusher,
              'Capacity of crushers selection' : data?.wlForm?.wlcMiningProposals?.capacity_of_crusher_unit,
              'Total capacity of crushers ' : data?.wlForm?.wlcMiningProposals?.total_capacity_of_crusher,
              'Total capacity of crushers selection' : data?.wlForm?.wlcMiningProposals?.total_capacity_of_crusher_unit,
              //dumpuning strategy data in string
              'Dumping strategy External dump Area in Ha' : data?.wlForm?.wlcMiningProposals?.external_dumping_area,
              'Dumping strategy External dump  Maximum height in m ': data?.wlForm?.wlcMiningProposals?.external_dumping_maximum_area,
              'Dumping strategy External dump Remark' : data?.wlForm?.wlcMiningProposals?.external_dumping_remarks,
              'Dumping strategy Internal dump Area in Ha' : data?.wlForm?.wlcMiningProposals?.internal_dumping_area,
              'Dumping strategy Internal dump Maximum height in m' : data?.wlForm?.wlcMiningProposals?.internal_dumping_maximum_area,
              'Dumping strategy Internal dump Remark' : data?.wlForm?.wlcMiningProposals?.internal_dumping_remarks,
              'Dumping strategy  Topsoil dump/storage Area in Ha' : data?.wlForm?.wlcMiningProposals?.toposoil_dumping_area,
              'Dumping strategy  Topsoil dump/storage Remark' : data?.wlForm?.wlcMiningProposals?.toposoil_dumping_remarks,
              'Dumping strategy  Topsoil dump/storage Maximum height in m': data?.wlForm?.wlcMiningProposals?.toposoil_dumping_maximum_area,
          
              'Total Topsoil excavated during the entire life of the mine (mm3)' : data?.wlForm?.wlcMiningProposals?.total_topsoil_excavated,
              'Utilization strategy of topsoil ' : data?.wlForm?.wlcMiningProposals?.topsoil_utilization_strategy,
              ' Dumping Other information, if any' : data?.wlForm?.wlcMiningProposals?.topsoil_other_info,
              'Total Quarry Area (ha.)' : data?.wlForm?.wlcMiningProposals?.total_quarry_area,
              'Area of final void (ha.)' : data?.wlForm?.wlcMiningProposals?.final_void_area,
              'Maximum Depth of final void (m)' : data?.wlForm?.wlcMiningProposals?.final_void_max_depth,
              'Quarry Other information, if any' : data?.wlForm?.wlcMiningProposals?.quarry_other_info,
              'Mode of transportation upto pit head' : data?.wlForm?.wlcMiningProposals?.transportation_mode_up_pithead,
              'Mode of transportation from pit head to siding/loading' : data?.wlForm?.wlcMiningProposals?.transportation_mode_from_pithead,
              'Mode of transport from loading point to consumers ' : data?.wlForm?.wlcMiningProposals?.transportation_mode_from_loading,
              'Transportation Other information, if any' : data?.wlForm?.wlcMiningProposals?.transportation_mode_other_info,
              'Plantation area (ha.)' : data?.wlForm?.wlcMiningProposals?.plantation_area,
              'Water body (ha.)' : data?.wlForm?.wlcMiningProposals?.water_body,
              'Public use (ha.)' : data?.wlForm?.wlcMiningProposals?.public_use,
              'Other uses(ha.)' : data?.wlForm?.wlcMiningProposals?.other_use,
              
      },
      
        }  
        //console.log('Body' , body);
        // console.log('Mining Proposal', data?.wlForm?.wlcMiningProposals?.topsoil_utilization_strategy);
        // console.log('Blasting', data?.wlForm?.wlcMiningProposals?.type_of_blasting);
        //console.log("conversion", data?.doctoBase64);
        
        
    }// widlife end

    console.log('Body for AI', body);
    return body;
    
  }

  getProposalDataHistory = (proposalNo: string) =>
    this.http.post<any>(
      environment.baseURL + PROPOSAL_DATA.HISTORY_GET,
      {},
      { params: { proposalNo } }
    );


  isSczmaRecommendationAvailable = (data: any) => this.environment.dev && (data?.proponentApplications?.certificate_url1 && !data?.proponentApplications?.certificate_url) || (data?.proponentApplications?.certificate_url && data?.proponentApplications?.certificate_url1 && data?.proponentApplications?.last_visible_status?.toLowerCase()?.includes('granted'));

  isNAUnfndback = (value: any, fallback: any = "N/A"): any => (value ?? "") === "" ? fallback : value;

  loadingEffect = () => {
    this.loaderService.isLoading.next(true);
    setTimeout(() => this.loaderService.isLoading.next(false), this.getRandomNumber(1, 200));
  }

  getRandomNumber = (min: number, max: number) => {
    min = Math.ceil(min);
    return Math.floor(Math.random() * (Math.floor(max) - min + 1)) + min;
  }

} 
