<div class="modal-body my-3">
  <div class="text-center">
    <h1 class="h3">{{ title }}</h1>
    <p class="mt-5" [innerHTML]="message"></p>
    <div [innerHTML]="htmlElement"></div>
  </div>
</div>
<div class="modal-footer justify-content-center">
  
  <!-- <button
    type="button"
    class="btn btn-danger text-white mn-w-100"
    (click)="decline()"
    *ngIf="btnCancelText != 'undefined' && (btnCancelText =='Cancel' || btnCancelText == 'No')"
  >
    {{ btnCancelText }}
  </button> -->
  <button
    type="button"
    class="btn btn-danger text-white mn-w-100"
    (click)="decline()"
    *ngIf="btnCancelText != 'undefined' && btnCancelText !='not'"
  >
    {{ btnCancelText }}
  </button>
  
  <button
    type="button"
    class="btn btn-primary mn-w-100"
    (click)="accept()"
    *ngIf="btnOkText != 'undefined'"
  >
    {{ btnOkText }}
  </button>
</div>
