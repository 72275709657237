import { environment } from '../../environments/environment';

export const API = {
  GET_DASHBOARD_NOTIFICATION: 'dashboardNotification/get',
  UPDATE_DASHBOARD_NOTIFICATION: 'dashboardNotification/updateReadStatus',
  SAVE_TOR_CERTIFICATE: 'standardCertificate/ecCertificate',
  GET_TOR_CERTIFICATE: 'get_tor',
  DUPLICATE_CIN: 'user/duplicateCIN',
  GET_VILLAGES: 'kyc/getVillagesBySubDistrictCodes',
  // GET_CIN: 'cin/getInfo',
  CHECKLIST: 'clearance-checklist',
  GET_CHECKLIST: 'clearance-checklist-master',
  GET_CIN: 'mca/cinRequest',
  GET_EC_PART_B: 'ecpartB/getEcPartB',
  GET_ACTIVITIES: 'activity/action/getAll',
  GET_SUB_ACTIVITIES: 'kya/getSubactivity',
  GET_ALL_SUB_ACTIVITIES: 'kya/getSubactivities',
  GET_ACTIVITY_THRESHOLD: 'kya/getActivityThreshold',
  GET_SUB_ACTIVITY_THRESHOLD: 'kya/getSubActivityThreshold',
  GET_DECISION: 'kya/getDecision',
  GET_FILE_VALIDATION: 'kya/getFileValidation',
  GET_GEN_CODE: 'kyaGenCode/getGenCode',
  GET_DISTINCT_GEN_CODE: 'kyaGenCode/getDistinctRegistrationType',
  GET_GENDER_GEN_CODE: 'kyaGenCode/getGenderGenCode',
  DELETE_GEN_CODE: 'kyaGenCode/deleteGenCode',
  UPDATE_GEN_CODE: 'kyaGenCode/updateGenCode',
  ADD_GEN_CODE: 'kyaGenCode/addGenCode',
  GET_HOME_APPROVAL: 'home/approvals',
  GET_HOME_HERO: 'home/hero',
  GET_HOME_KPI: 'home/kpi',
  GET_HOME_UPDATES: 'home/updates',
  GET_HOME_RECENT_EVENTS: 'home/recentEvents',
  GET_COUNTRY_CODE: 'kya/getcountrycodes',
  CHECK_DUPLICATE_EMAIL: 'user/duplicateEmail',
  GET_STATE: 'kyc/getstates',
  STATE_REPORT: '/getFCStateReport',
  ACQUTITON_MODE: 'parivesh/wlpa-Common/acquisition-modes',
  REPORTING_REASON: 'parivesh/wlpa-Common/reporting-reasons',
  GET_STATEDEPARTMENT: 'kyc/getStateDepartment',
  SAVE_IMPORTANT_DOCUMENT: 'userImportantDocumentLog/save',
  GET_IRO_STATE: '/kyc/getUserStateDetails',
  GET_DISTRICT: 'kyc/getdistricts',
  GET_SUB_DISTRICT: 'kyc/getSubDistrictsByDistrictCode',
  GET_VILLAGE: 'kyc/getVillagesBySubDistrictCode',
  GET_DISTRICT_BY_CODE: 'kyc/getdistrictbyCode',
  GET_REGISTRATION_TYPE: 'user/registrationTypes',
  GET_ENTITY_TYPE: 'user/entityTypes',
  VERIFY_EMAIL: 'user/verifyEmail',
  RESEND_EMAIL: 'user/resendMail',
  VERIFY_TOKEN: 'user/verifyToken',
  AddHomeHeroSection: 'home/hero/save',
  AddHomeMediaSection: 'home/getMedia/save',
  AddHomeApprovalSection: 'home/approvals/save',
  AddHomeUpdateSection: 'home/updates/save',
  AddHomeRecentSection: 'home/recentEvents/save',
  HomeUploadImage: 'home/uploadfile',
  HomeReorder: 'home/updateOrder',
  GET_HOME_BULLITEN_BOARD: 'home/recentUploads',
  GET_HOME_USEFUL_LINK: 'home/usefulLink',
  GET_HOME_MEDIA: 'home/getMedia',
  SAVE_HOME_KPI: 'home/kpi/save',
  SAVE_HOME_BULLITEN_BOARD: 'home/recentUploads/save',
  SAVE_HOME_USEFUL_LINK: 'home/usefulLink/save',
  SAVE_PROJECT: 'project/save',
  GET_PROJECT: 'project/list',
  GET_EDS_ADS_PROPOSALS: 'process/getlistById',
  GET_PROJECT_DETAIL_BY_ID: 'project/getProjectbyID',
  GET_PROJECT_BY_ID: 'project/get',
  UPDATE_PROJECT: 'project/updateProject',
  GET_EMPLOYEES: 'employee/list',
  GET_EMPLOYEES_BY_ID: 'employee/listById',
  GET_EMPLOYEES_BY_PROJECT: 'project/list',
  GET_EMPLOYEES_BY_STATUS: 'employee/getDetailsByStatus',
  SAVE_EMPLOYEE_DETAILS: 'employee/add',
  UPDATE_EMPLOYEE_STATUS: 'employee/updateStatus',
  GET_DETAILS_BY_EMAIL: 'employee/getDetailsByEmail',
  Get_EMPLOYEE_DETAILS: 'employee/detail',
  UPDATE_EMPLOYEE_DETAILS: 'employee/update',
  DELETE_EMPLOYEE_DETAILS: 'employee/delete',
  ACTIVE_DEACTIVE_EMPLOYEE_STATUS: 'employee/disable',
  GET_EMPLOYEE_HISTORY: 'history/get',
  MAP_PROJECT_TO_EMPLOYEE: 'project/addEmployee',
  REMOVE_EMPLOYEE_FROM_PROJECT: 'project/deleteEmployee',
  SAVE_LOCATION_CAF: 'caf/saveCafLocationKml',
  GET_PROJECT_BY_EMPLOYEE_ID: 'project/getProjectbyID',
  GET_MY_EMPLOYEE: 'employee/me',
  CREATE_CAF_PROJECT_DETAIL: 'caf/saveCafDetails',
  SAVE_PROJECT_ACTIVITY_COST: 'caf/saveCafprojectActivityCost',
  GET_MY_ORGANIZATION: 'user/getOrganisation',
  SEARCH_ORGANIZATION: 'user/searchOrganization',
  GET_CAF_DETAIL: 'caf/getCafDetails',
  SAVE_OTHER_CAF: 'caf/saveCafOthers',
  UPLOAD_KML: '/okm/uploadDocument',
  LOCATION_UPLOAD_KML: 'caf/saveCafKML',
  CAF_KML_BY_Id: 'caf/getCafKMLbyCAFId',
  GET_KML_INTERSECTION_DETAIL: 'api/GetData',
  SAVE_FC_PROPOSED_LAND: 'fc/addProposedLand',
  SAVE_FC_PROJECT_DETAILS: 'fc/addForm',
  UPDATE_FC_DIVISION_PATCH_DETAILS: 'fc/updateDivisionPatchDetails',
  // GET_FC_PROJECT_CATEGORY: 'projectcategory/selectionlist',
  GET_FC_PROJECT_CATEGORY:
    'projectcategory/getAllProjectCategory?isactive=true',
  GET_DFO_DATA: 'fcFactsheet/getDfoData',
  SAVE_FC_AFORESTATION_DETAILS: 'fc/addAforestationDetail',
  SAVE_FC_OTHER_DETAILS: 'fc/addOthersDetail',
  GET_FC_DETAILS: 'fc/getForm',
  GET_AIRPORT_PROPOSAL_DETAILS: 'common/airportProposals',
  GET_PROPOSAL_VALIDITY_TO_PROCEED: 'common/validateProposal',
  GET_ALL_DIVISION: 'division/getAllDivisionData',
  GET_DIVISION_BY_STATEID: 'division/getDivisionByStateId',
  GET_WLC_DIVISION: 'division/getAllDivisionDataWLC',
  SAVE_FC_PRIOR_APPROVAL: 'priorApprovalDetails/save',
  DOCUMENT_TYPE_OKM: '/okm/getDocumentByModuleIdAndStep',
  DOWNLOAD_FILE: '/okm/downloadDocument',
  DOWNLOAD_AGENDA_FILE: 'MoM/downloadAgendaDocument',
  DOWNLOAD_MOM_FILE: 'MoM/downloadMomDocument',
  GET_APPLICATIONS: 'applications/getList',
  SAVE_FC_ENCLOSURE_DETAILS: 'fc/addEnclosuresDetail',
  SAVE_FC_AIRPORT_PROPOSAL: 'fc/saveFcAirportProposal',
  SAVE_FC_MINING_PROPOSAL: 'fc/saveFcMiningProposals',
  SAVE_FC_RIVER_VALLEY: 'fc/saveFcRiverValleyProject',
  SAVE_FC_AREA_SUBMERGED: 'fc/saveSubmergedArea',
  SAVE_FC_IRRIGATION_VILLAGE: 'fc/saveFcIrrigationProjectCapacityVillage',
  SAVE_FC_CROPPING_PATTERN: 'fc/saveFcCroppingPattern',
  DELETE_FC_AREA_SUBMERGED: 'fc/deleteSubmergedArea',
  DELETE_FC_IRRIGATION_VILLAGE: 'fc/deleteFcIrrigationProjectCapacityVillage',
  DELETE_FC_CROPPING_PATTERN: 'fc/deleteFcCroppingPattern',
  SUBMIT_FOR_APPROVAL: 'projectProponent/submit',
  SAVE_FC_PATCH_KML_DETAILS: 'fc/saveForestClearancePatchKmls',
  PROJECT_APPLICATION_DETAILS: 'projectapplicants/list',
  SAVE_COMPONENT: 'forestClearanceComponentDetails/save',
  GET_FC_PARTB_BASIC_DETAILS: 'fc/partb/getBasicDetails',
  SAVE_FC_PARTB_AFFORESTATION_DETAILS: 'fc/partb/saveAfforestationDetails',
  SAVE_FC_PARTB_AFFORESTATION_PATCH_DETAILS: 'fc/partb/savePatch',
  SAVE_FC_PARTB_AFFORESTATION_ADD_CA_LAND: 'fc/partb/saveCaLand',
  SAVE_FC_PARTB_AFFORESTATION_PATCH_WISE_DETAILS:
    'fc/partb/savePatchWiseDetails',

  SAVE_WL_SPECIFIC_DETAILS: 'fc/partb/saveWLSpecificDetails',
  SAVE_FC_COMPONENET_DETAILS: 'fc/partb/saveComponentDetails',
  PROCESS_HISTORY_FALL_BACK: 'proponentApplicant/updateStatus',

  DELETE_FC_COMPONENET_DETAILS: 'fc/partb/deleteComponentDetails',
  DELETE_COMPONENT: 'forestClearanceComponentDetails/delete',
  DELETE_PROPOSED_DIVERSION_DETAILS:
    'forestClearanceProposedDiversionDetails/delete',
  DELETE_PROPOSED_DIVERSION_DETAILS_WLC:
    'wildLifeClearanceProposedDiversionDetails/delete',
  DELETE_FC_B_PROPOSED_DIVERSION_DETAILS: 'fc/formb/deleteProposedDiversion',
  DELETE_PROPOSED_DIVERSION_DETAILS_INTERSECTION:
    'forestClearanceProposedDiversionDetails/intersection/delete',
  DELETE_PATCH_KML: 'forestClearanceKmlsDetails/delete',
  DELETE_PATCH_KML_AFFORESTATION: 'fc/formb/deleteFcFormBPatchKmls',
  SAVE_PROPOSED_DIVERSION: 'forestClearanceProposedDiversionDetails/save',
  SAVE_PROPOSED_DIVERSION_WLC: 'wildLifeClearanceProposedDiversionDetails/save',
  GET_FC_COMPONENT: 'forestClearanceComponentDetails/list',
  GET_PROPOSED_DIVERSION_KML_DATA:
    'forestClearanceProposedDiversionDetails/list',
  GET_PROPOSED_DIVERSION_KML_DATA_WLC:
    'wildLifeClearanceProposedDiversionDetails/list',
  SAVE_EC_BASIC_DETAILS: 'ec/saveForm',
  SAVE_EC_PROJECT_DETAILS: 'ec/saveProjectDetail',
  SAVE_EC_PRODUCT_DETAILS: 'ec/saveProductDetail',
  SAVE_EC_ENCLOSURE_DETAILS: 'ec/saveEnclosureDetail',
  SAVE_EC_OTHER_DETAILS: 'ec/saveOthersDetail',
  GET_EC_DETAILS: 'ec/getForm',
  SAVE_EC_PRODUCT_TRANSPORT: 'ecProdTransportDetails/save',
  SAVE_EC_ACTIVITY_DETAIL: 'ECProjectActivityDetails/save',
  GET_EC_PRODUCT_TRANSPORT: 'ecProdTransportDetails/list',
  DELETE_EC_PRODUCT_TRANSPORT: 'ecProdTransportDetails/delete',
  GET_EC_ACTIVITY_DETAIL: 'ECProjectActivityDetails/list',
  DELETE_EC_ACTIVITY_DETAIL: 'ECProjectActivityDetails/delete',
  DELETE_EC_AIR_POLLUTION_MITIGATION: 'ecpartB/deleteAirPollutionMitigation',
  DELETE_EC_MINING_MINERALS_MINED: 'ecpartB/deleteMiningMineralsMined',
  DELETE_EC_MINING_MINERALS_RESERVES: 'ecpartB/deleteMiningMineralsReserves',
  DELETE_EC_MINING_PRODUCTION_DETAIL: 'ecpartB/deleteMiningproductionDetails',
  SAVE_EC_AMENDMENT: 'ECProjectActivityDetails/save',
  GET_EC_AMENDMENT: 'ecProdTransportDetails/list',
  DELETE_EC_AMENDMENT: 'ecProdTransportDetails/delete',
  SAVE_EC_AREA_DETAIL: 'ECProjectAreaDetails/save',
  APPLICATION_NAME: 'applications/getById',
  APPLICATION_LIST: 'applications/getList',
  APPLICATION_BY_ID: 'applications/getById',
  DELETE_APPLICATION: 'applications/delete',
  SAVE_APPLICATION_DETAILS: 'applications/save',
  GET_EC_AREA_DETAIL: 'ecProdTransportDetails/list',
  DELETE_EC_AREA_DETAIL: 'ecProdTransportDetails/delete',

  GET_CLEARENCE_COUNT: 'proponentApplicant/getClearancesCount',
  COPY_CAF: 'caf/copyCafDetails',
  COPY_PROPOSAL: 'proponentApplicant/copyProposal',
  COPY_PROPOSAL_EC_PART_C: 'proponentApplicant/copyProposalEcPartC',
  COPY_CRZ_PROPOSAL: 'proponentApplicant/copyProposalCrz',
  COPY_FC_FORM_A_PROPOSAL: 'proponentApplicant/copyProposalFcFormA',
  COPY_FC_FORM_B_PROPOSAL: 'proponentApplicant/copyProposalFcFormB',
  COPY_FC_FORM_C_PROPOSAL: 'proponentApplicant/copyProposalFcFormC',
  COPY_FC_FORM_D_PROPOSAL: 'proponentApplicant/copyProposalFcFormD',
  COPY_FC_FORM_E_PROPOSAL: 'proponentApplicant/copyProposalFcFormE',
  SAVE_EC_PARTB: 'ecpartB/saveEcPartB',
  SAVE_EC_WASTE_DETAILS: 'ecpartB/saveWasteDetails',
  DELETE_EC_WASTE_DETAILS: 'ecpartB/deleteWasteDetails',
  GET_PROPOSAL_DOCUMENT: 'documentdetails/getDocumentDetail',
  SAVE_PROPOSAL_DOCUMENT: 'documentdetails/addDocumentDetail',
  DELETE_PROPOSAL_DOCUMENT: 'documentdetails/deleteDocumentDetail',

  GET_COMPLIANCE_PROPOSAL: 'proponentApplicant/getStage1CompletedProposal',
  GET_CORRIGENDUM_PROPOSAL:
    'fcCorrigendumCertificate/getProposalsForCorrigendum',
  CHECK_ACTIVE_DEMAND: 'demand/checkActiveDemand',
  SELECTED_SECTOR: 'proponentApplicant/updateSelectedSector',

  GET_LIST_OF_SECTOR: 'trackYourProposal/getListOfSector',
  IMPORTANT_NOTICES: 'importantNotice/getImportantNotices',

  //for Ec form SIX
  SAVE_EC_FORM_SIX_BASIC_DETAILS: 'ecForm6/saveBasicDetails',
  SAVE_EC_FORM_SIX_PRODUCT_DETAILS: 'ecForm6Part2/saveEcForm6Productdetails',
  SAVE_SIX_CONSULTANT_DETAILS: 'ecForm6Part3/saveEiaConsultantDetails',
  SAVE_EC_SIX_UNDERTAKING: 'ecForm6Part5/saveEcForm6Undertaking',
  GET_EC_SIX_DETAILS: 'ecForm6/get',
  // for EC form SEVEN
  // EC Ms Recommendation
  GET_DATE_TIME: 'workgroupmaster/api/datetime',

  // dashboardService\
  SECRETARY_PROPOSAL_LIST: 'jsdashboard/getRolePendency',

  SAVE_EC_MS_RECOMMENDATION: 'msRecommendation/save',
  GET_EC_MS_RECOMMENDATION: 'msRecommendation/get',
  // for EC form SEVEN
  // EC Ms Recommendation
  SAVE_EC_MS_EC_DETAILS: 'msRecommendation/saveEACDetails',
  GET_EC_MS_EAC_DETAILS: 'msRecommendation/getEACDetails',
  GET_EC_TOR_STATUS: 'ec/getTORStatus',
  // for EC form SEVEN

  SAVE_EC_FORM_SEVEN_BASIC_DETAILS: 'ecForm7/save',
  SAVE_EC_FORM_SEVEN_PROJECT_DETAILS: 'ecForm7/saveEcForm7ProjectActivity',
  SAVE_EC_FORM_SEVEN_EC_OBTAINED: 'ecForm7/saveEcForm7Obtained',
  SAVE_EC_FORM_SEVEN_MINOR_ACTIVITY: 'ecForm7/save/ProjectActivityDetails',
  GET_EC_FORM_SEVEN_MINOR_ACTIVITY: 'ecForm7/list/ProjectActivityDetails',
  SAVE_EC_FORM_SEVEN_ACIVITY_DETAILS: 'ecForm7/saveEcForm7ActivityStatus',
  SAVE_EC_FORM_SEVEN_ENCLOUSERS: 'ecForm7/saveEcForm7AttachedDocuments',
  SAVE_EC_FORM_SEVEN_UNDERTAKING: 'ecForm7/saveEcForm7Undertaking',
  GET_EC_FORM_SEVEN_DETAILS: 'ecForm7/get',

  SAVE_EC_FORM_SEVEN_UPLOAD_KML: 'ecForm7/saveEcForm7CafKML',

  GET_EC_FORM_EIGHT_DETAILS: 'ecForm8/get',

  SAVE_EC_FORM_EIGHT_MINOR_ACTIVITY: 'ecForm8/save/ProjectActivityDetails',
  GET_EC_FORM_EIGHT_MINOR_ACTIVITY: 'ecForm8/list/ProjectActivityDetails',
  SAVE_EC_FORM_EIGHT_ACIVITY_DETAILS: 'ecForm8/saveEcForm7ActivityStatus',
  DELETE_EC_FORM_EIGHT_MINOR_ACTIVITY: 'ecForm8/delete/ProjectActivityDetails',

  GET_EC_FORM_SIX_ADD_DETAILS: 'ecForm6/get',
  GET_EC_FORM_TEN_ADD_DETAILS: 'ecForm10/get',
  GET_EC_FORM_NINE_ADD_DETAILS: 'ecForm9/get',

  //  EC Form Two

  SAVE_EC_FORM_TWO_PROJECT_DETAILS: 'ecForm2/saveProjectDetails',
  SAVE_EC_FORM_TWO_ACTIVITY_DETAILS: 'ecForm2/saveProjectActivityDetails',
  GET_EC_FORM_TWO_DETAILS: 'ecForm2/get',
  DELETE_EC_FORM_TWO_ACTIVITY_DETAILS: 'ecForm2/deleteProjectActivityDetails',
  SAVE_EC_FORM_TWO_UNDERTAKING: 'ecForm2/saveUndertaking',
  SAVE_EC_FORM_TWO_PROJECT_IMPLEMENTATION_STATUS:
    'ecForm2/saveProjectImplementationStatus',
  SAVE_EC_FORM_TWO_ENCLOUSERS: 'ecForm2/saveEnclosureDetails',
  SAVE_EC_FORM_TWO_IMPLEMENTATION_STATUS: 'ecForm2/saveImplementationStatus',
  SAVE_EC_FORM_TWO_CORRGENDUM_DESCRIPTION: 'ecForm2/saveCorrigendumDescription',
  DELETE_EC_FORM_TWO_IMPLEMENTATION_STATUS:
    'ecForm2/deleteImplementationStatus',
  DELETE_EC_FORM_TWO_CORRGENDUM_DESCRIPTION:
    'ecForm2/deleteCorrigendumDescription',

  // for EC form Eleven
  SAVE_EC_FORM_ELEVEN_BASIC_DETAILS: 'ecForm11/save',
  SAVE_EC_FORM_ELEVEN_PROJECT_DETAILS: 'ecForm11/saveOtherDetails',
  SAVE_EC_FORM_ELEVEN_EC_OBTAINED: 'ecForm11/saveSPCB',
  SAVE_EC_FORM_ELEVEN_NEW_PROJECT_ACTIVITY_DETAILS:
    'ecForm11/saveProjectActivityDetails',
  GET_EC_FORM_ELEVEN_DETAILS: 'ecForm11/get',
  SAVE_EC_FORM_ELEVEN_UNDERTAKING: 'ecForm11/saveUndertaking',
  DELETE_EC_FORM_ELEVEN_EC_OBTAINED: 'ecForm11/deleteSPCB',
  // NEW EC SIX
  GET_EC_FORM_SIX_NEW_PROJECT_ACTIVITY_LIST:
    'ecForm6/v2/getProjectActivityList',
  SAVE_EC_FORM_SIX_NEW_PROJECT_ACTIVITY_DETAILS:
    'ecForm6/v2/saveProjectActivityDetails',
  SAVE_EC_FORM_SIX_NEW_PROJECT_DETAILS: 'ecForm6/v2/saveProjectDetails',
  DELETE_EC_FORM_SIX_NEW_PROJECT_ACTIVITY_LIST:
    'ecForm6/v2/deleteProjectActivityList',

  GET_EC_FORM_SIX_NEW_IMPLEMENTATION_DETAILS:
    'ecForm6/v2/getImplementationDetails',
  SAVE_EC_FORM_SIX_NEW_IMPLEMENTATION_DETAILS:
    'ecForm6/v2/saveImplementationDetails',

  GET_EC_FORM_SIX_NEW_AMENDMENT_DETAILS: 'ecForm6/v2/getAmendmentDetails',
  SAVE_EC_FORM_SIX_NEW_AMENDMENT_DETAILS: 'ecForm6/v2/saveAmendmentDetails',
  DELETE_EC_FORM_SIX_NEW_AMENDMENT_DETAILS: 'ecForm6/v2/deleteAmendmentDetails',
  DELETE_EC_FORM_SIX_NEW_AMENDMENT_STATUS: 'ecForm6/v2/deleteAmendmentStatus',
  DELETE_EC_FORM_SIX_NEW_OBTAINED_STATUS: 'ecForm6/v2/deleteObtainedStatus',

  SAVE_EC_FORM_SIX_NEW_CONSULTANT_DETAILS: 'ecForm6/v2/saveConsultantDetails',
  SAVE_EC_FORM_SIX_NEW_UNDERTAKING_DETAILS: 'ecForm6/v2/saveUndertaking',
  GET_EC_FORM_SIX_NEW_DETAILS: 'ecForm6/v2/getForm',

  SAVE_EC_FORM_SIX_NEW_UNIT_DETAILS: 'ecForm6/v2/saveUnitDetails',
  DELETE_EC_FORM_SIX_NEW_UNIT_DETAILS: 'ecForm6/v2/deleteUnitDetails',

  GET_EC_FORM_SIX_NEW_UNIT_DETAILS: 'ecForm6/v2/getUnitDetails',

  // EC FORM SIX
  SAVE_EC_FORM_SIX_UNDERTAKING: 'ecForm6/saveEcForm6Undertaking',

  SAVE_EC_SIX_CAF_DETAILS: 'ecForm6/saveCafDetails',

  UPDATE_EC_SIX_CAF_DETAILS: 'ecForm6/UpdateCafDetails',

  FINAL_SAVE_EC_SIX_CAF_DETAILS: 'ecForm6/FinalSaveCafDetails',

  GET_EC_FORM_SIX_DETAILS: 'ecForm6/getBasicDetails',

  GET_CAFID_BY_NEW_CAFID: 'ecForm6/getCafDetails',

  GET_EC_FORM_SIX_UNDERTAKING: 'ecForm6Part5/getUndertaking',

  GET_EC_FORM_SIX_IMPLIMENTION_DETAILS: 'ecForm6Part2/getProductDetails',

  GET_EC_FORM_SIX_CONSULTANT_DETAILS: 'ecForm6Part3/getConsultantDetails',

  DELETE_EC_FORM_SEVEN_EC_OBTAINED: 'ecForm7/deleteEcForm7Obtained',
  DELETE_EC_FORM_SEVEN_MINOR_ACTIVITY: 'ecForm7/delete/ProjectActivityDetails',
  GET_EC_FORM_SEVEN_SEARCH_ORGANISATION_DETAILS:
    'ecForm8/getOrganizationDetailsByName',

  // for EC form EIGHT
  GET_EC_FORM_Eight_PROJECT_DETAILS: 'ecForm8/get',
  SAVE_EC_FORM_Eight_PROJECT_DETAILS: 'ecForm8/save',
  SAVE_EC_FORM_Eight_LOCATION: 'ecForm8/saveLocation',
  SAVE_EC_FORM_Eight_TOR: 'ecForm8/saveEcForm8DetailOfTOR',
  SAVE_EC_FORM_Eight_CATEGORY: 'ecForm8/saveECForm8TransferCOP',
  SAVE_EC_FORM_Eight_DOCUMENT: 'ecForm8/saveEcForm8Document',
  SAVE_EC_FORM_Eight_ADD_TOR: 'ecForm8/saveTOR',

  SAVE_EC_FORM_Eight_ADDITIONAL_DOCUMENT: 'ecForm8/saveAdditionDoc',
  GET_EC_FORM_Eight_ADDITIONAL_DOCUMENT: 'ecForm8/getAdditionalInformation',
  GET_EC_FORM_Eight_SEARCH_PROJECT_DETAILS:
    'ecForm8/getOrganizationDetailsByName',
  DELETE_EC_FORM_Eight_ADDITIONAL_DOCUMENT:
    'ecForm8/deleteAdditionalInformation',
  SAVE_EC_FORM_Eight_UNDERTAKING: 'ecForm8/saveUndertaking',

  //for ec form NINE

  SAVE_EC_FORM_Nine_LEGAL_DETAILS: 'ecForm9/saveLegalDetails',
  SAVE_EC_FORM_Nine_PROJECT_DETAILS: 'ecForm9/save',
  SAVE_EC_FORM_Nine_UNDERTAKING: 'ecForm9/saveUndertaking',
  SAVE_EC_FORM_Nine_PROPOSAL_DETAILS: 'ecForm9/saveProposalDetails',
  SAVE_EC_FORM_Nine_LOCATION_DETAILS: 'ecForm9/saveLocation',
  GET_EC_FORM_Nine_PROJECT_DETAILS: 'ecForm9/get',
  DELETE_EC_FORM_Nine_GOVT_ORDER: 'ecForm9/deleteGovernmentOrder',
  DELETE_EC_FORM_Nine_LIGITATION_PENDING: 'ecForm9/deleteLigitationPending',
  DELETE_EC_FORM_Nine_VIOLATION_OF_ACT: 'ecForm9/deleteViolationOfAct',
  DELETE_EC_FORM_Nine_REGULAR_AUTHORITY: 'ecForm9/deleteRegularAuthority',

  //for ec form TEN

  SAVE_EC_FORM_Ten_LEGAL_DETAILS: 'ecForm10/saveLegalDetails',
  SAVE_EC_FORM_Ten_PROJECT_DETAILS: 'ecForm10/save',
  SAVE_EC_FORM_Ten_litigation_DETAILS: 'ecForm10/saveLegalDetails',
  SAVE_EC_FORM_TEN_MINOR_ACTIVITY: 'ecForm10/save/ProjectActivityDetails',
  GET_EC_FORM_TEN_MINOR_ACTIVITY: 'ecForm10/list/ProjectActivityDetails',
  DELETE_EC_FORM_TEN_MINOR_ACTIVITY: 'ecForm10/delete/ProjectActivityDetails',
  FORM_TEN_PDF_UTIL: '/pdf/generateHtmlPdf',

  //  For EC form twelve

  SAVE_EC_FORM_TWELVE_BASIC_DETAILS: 'ecForm12/save',
  SAVE_EC_FORM_TWELVE_MINOR_ACTIVITY: 'ecForm12/save/ProjectActivityDetails',
  DELETE_EC_FORM_TWELVE_MINOR_ACTIVITY:
    'ecForm12/delete/ProjectActivityDetails',
  GET_EC_FORM_TWELVE_DETAILS: 'ecForm12/get',
  GET_EC_FORM_TWELVE_MINOR_ACTIVITY: 'ecForm12/list/ProjectActivityDetails',
  DELETE_EC_FORM_TWELVE_TRANSFEREE_DETAILS:
    'ecForm12/deleteEcForm12TransfreeDetails',
  SAVE_EC_FORM_TWELVE_PROJECT_DETAILS: 'ecForm12/saveEcForm12ProjectActivity',
  SAVE_EC_FORM_TWELVE_EC_OBTAINED: 'ecForm12/saveEcForm12Obtained',
  DELETE_EC_FORM_TWELVE_EC_OBTAINED: 'ecForm12/deleteEcForm12Obtained',
  SAVE_EC_FORM_TWELVE_ACIVITY_DETAILS: 'ecForm12/saveEcForm12ActivityStatus',
  SAVE_DETAILS_OF_COMPONENT: 'ecForm12/saveEcForm12DetailsOfComponents',
  SAVE_EC_FORM_TWELVE_ADDENDUM_TRANSFEROR:
    'ecForm12/saveEcForm12AddendumOfTransferor',
  SAVE_EC_FORM_TWELVE_ADDENDUM_TRANSFEREE:
    'ecForm12/saveEcForm12AddendumOfTransferee',
  SAVE_EC_FORM_TWELVE_UNDERTAKING: 'ecForm12/saveEcForm12Undertaking',
  DELETE_EC_FORM_TWELVE_DELETE_CAF_KML_SPLITTED:
    'ecForm12/delete/EcForm12CafKMLSplitted',
  SAVE_EC_FORM_TWELVE_SAVE_TRANSFEREE_DETAILS:
    'ecForm12/saveEcForm12TransfreeDetail',

  // SAVE_EC_FORM_Ten_PROPOSAL_DETAILS: 'ecForm9/saveProposalDetails',
  //  EC Form 10 APIs
  SAVE_EC_FORM_Ten_LOCATION_OF_PROJECT: 'ecForm10/saveLocation',
  SAVE_EC_FORM_Ten_BASIC_INFORMATION: 'ecForm10/saveBasicInformation',
  SAVE_EC_FORM_Ten_PRODUCT_DETAILS: 'ecForm10/saveProductDetails',
  SAVE_EC_FORM_Ten_WASTE_DETAILS: 'ecForm10/saveHazardousWasteGeneration',
  SAVE_EC_FORM_Ten_EMISSION_GENERATION: 'ecForm10/saveEmissionGeneration',
  GET_EC_FORM_Ten_PROJECT_DETAILS: 'ecForm10/get',
  DELETE_EC_FORM_Ten_GOVT_ORDER: 'ecForm10/deleteGovernmentOrder',
  DELETE_EC_FORM_Ten_LIGITATION_PENDING: 'ecForm10/deleteLigitationPending',
  DELETE_EC_FORM_Ten_VIOLATION_OF_ACT: 'ecForm10/deleteViolationOfAct',
  DELETE_EC_FORM_Ten_REGULAR_AUTHORITY: 'ecForm10/deleteRegularAuthority',
  SAVE_EC_FORM_Ten_UNDERTAKING: 'ecForm10/saveUndertaking',
  SAVE_EC_FORM_TEN_CONSENT_ORDER: 'ecForm10/saveConsentUnderAirDetails',
  SAVE_EC_FORM_TEN_AUTHORIZATION_ORDER:
    'ecForm10/saveAuthorizationHazardousDetails',
  DELETE_EC_FORM_TEN_CONSENT_ORDER: 'ecForm10/deleteConsentUnderAirDetails',
  DELETE_EC_FORM_TEN_AUTHORIZATION_ORDER:
    'ecForm10/deleteAuthorizationHazardousDetails',

  SAVE_WL_PROJECT_DETAILS: 'wl/addForm',
  SAVE_WL_OTHER_DETAILS: 'wl/addOtherDetails',
  SAVE_Wl_PRIOR_APPROVAL: 'priorApprovalDetails/save',
  SAVE_WL_PROPOSED_LAND_DETAILS: 'wl/addWLProposedLand',
  SAVE_WL_DIVISSION_DETAILS: 'wl/addDivisionLandDetails',
  SAVE_WL_COMPONENT_DETAILS: 'wl/addComponentWiseDetails',
  SAVE_WL_ENCLOSURE_DETAILS: 'wl/addEnclosuresDetail',
  SAVE_WL_UNDERTAKING: 'wl/addundertaking',
  SAVE_FC_UNDERTAKING: 'fc/addundertaking',
  DELETE_WL_DIVISION_DETAILS: 'wl/deleteDivisionLandDetail',
  DELETE_WL_COMPONENT_DETAILS: 'wl/deleteComponentWiseDetails',
  Delete_WL_PRIOR_APPROVAL: 'priorApprovalDetails/delete',
  GET_WL_DETAILS: 'wl/getForm',
  PROPOSAL_DETAIL: 'wlc-workflow/dashboard-view-record?proposalId=',
  PROPOSAL_HISTORY: 'wlc-workflow/proposal-history?proposalid=',
  EDS_DETAIL: 'wlc-workflow/get-eds-query/',
  SAVE_PHYSICAL_CHANGE: 'ecpartB/saveEcPhysicalChange',
  SAVE_WATER_DETAILS: 'ecpartB/saveEcWaterDetails',
  SAVE_CURRENT_LAND_USE: 'ecpartB/saveCurrentLandUse',
  DELETE_CURRENT_LAND_USE: 'ecpartB/deleteCurrentLandUse',
  SAVE_PROPOSED_LAND_USE: 'ecpartB/saveProposedLandUse',
  DELETE_PROPOSED_LAND_USE: 'ecpartB/deleteProposedLandUse',
  SAVE_PROPOSED_LAND_EXPANSION_USE: 'ecpartB/saveProposedLandUseExpansion',
  DELETE_PROPOSED_LAND_EXPANSION_USE: 'ecpartB/deleteProposedLandUseExpansion',
  SAVE_DEMOLITION: 'ecpartB/saveDemolitionDetails',
  DELETE_DEMOLITION: 'ecpartB/deleteDemolitionDetails',
  SAVE_CONSTRUCTION: 'ecpartB/saveConstructionDetails',
  DELETE_CONSTRUCTION: 'ecpartB/deleteConstructionDetails',
  SAVE_STREAM_CROSSING: 'ecpartB/saveStreamCrossing',
  DELETE_STREAM_CROSSING: 'ecpartB/deleteStreamCrossing',
  SAVE_CONSTRUCTION_DETAILS: 'ecpartB/saveEcConstructionDetail',
  SAVE_RESOURCE_DETAILS: 'ecpartB/saveEcResource',
  DELETE_RESOURCE_DETAILS: 'ecpartB/deleteEcResource',
  SAVE_PHYSICAL_CHANGES: 'ecpartB/saveEcPhysicalChanges',
  SAVE_POLLUTION_DETAILS: 'ecpartB/saveEcPollutionDetails',
  SAVE_AIR_POLLUTION_MITIGATION: 'ecpartB/saveAirPollutionMitigation',
  SAVE_AIRPORT_PROPOSAL: 'ecpartB/saveEcAirportProposal',
  SAVE_MINING_PROPOSAL: 'ecpartB/saveEcMiningProposals',
  GET_ECB_DETAILS: 'ecpartB/getEcPartB',
  GET_ECB_DETAILS_ID: 'ecpartB/getEcPartBbyEcId',
  SAVE_RISK_FACTOR: 'ecpartB/saveEcRiskFactor',
  SAVE_GREEN_BELT: 'ecpartB/saveGreenBelt',
  SAVE_TSDF_PROPOSAL: 'ecpartB/saveEcTSDFProposals',
  SAVE_CBWTF_PROPOSAL: 'ecpartB/saveEcCBWTFProposals',
  GET_QCI_ORGANIZATION: 'ConsultantOrganisation/get',
  ASSIGN_ROLE: 'userAccess',
  SAVE_BUILDING_CONSTRUCTION: 'ecpartB/saveEcTownshipProposals',
  SAVE_RIVER_VALLEY: 'ecpartB/saveEcRiverValleyProject',
  SAVE_EC_WASTE_PRODUCTION: 'ecpartB/saveEcWasteProduction',
  SAVE_INDUSTRIAL_PROPOSAL: 'ecpartB/saveEcIndustryProposal',
  SAVE_CETP_PROPOSAL: 'ecpartB/saveEcCETPProposals',
  SAVE_CMSWMF_PROPOSAL: 'ecpartB/saveEcCMSWMFProposals',
  CONSULTANT_LIST: 'consultant/consultantList',
  CONSULTANT_LIST_ALL: 'ConsultantOrganisation/allConsultant',
  ACTIVATE_CONSULTANT: 'consultant/activate',
  USER_LOGOUT: 'app/logout',
  GET_LOGGED_IN_USER: 'user/getLoggerInUser',
  GET_SUPER_EMPLOYEE: 'employee/getSuperEmployees',
  GET_LOGGED_IN_USER_ID: 'parivesh/userprofile', //
  GET_LOGGED_IN_USER_ROLE: 'user/getSelectedRoleName',
  MAP_PROJECT_TO_CONSULTANT: 'project/addConsultant',
  MAP_PROJECT_TO_CONSULTANT_NEW: 'project/addConsultantToProject',
  SAVE_ACO_STATUS: 'project/saveAcoStatus',
  UPDATE_ACO_STATUS: 'project/updateAcoStatus',
  GET_ACO_LIST: 'project/getAcoLogListbyUserId',
  SAVE_EC_AREA_SUBMERGED: 'ecpartB/saveSubmergedArea',
  SAVE_EC_IRRIGATABLE_VILLAGE: 'ecpartB/saveEcIrrigationProjectCapacityVillage',
  SAVE_EC_CROPPING_PATTERN: 'ecpartB/saveEcCroppingPattern',
  DELETE_EC_AREA_SUBMERGED: 'ecpartB/deleteSubmergedArea',
  DELETE_EC_IRRIGATABLE_VILLAGE:
    'ecpartB/deleteEcIrrigationProjectCapacityVillage',
  DELETE_EC_CROPPING_PATTERN: 'ecpartB/deleteEcCroppingPattern',
  PART_C_SAVE_TOR_DETAILS: 'ecPartC/save',
  PART_C_DELETE_PUBLIC_HEARING: 'ecPartC/deletePublicHearing',
  PART_C_DELETE_MAJOR_ISSUES: 'ecPartC/deleteMajorIssues',
  PART_C_SAVE_BASELINE_DATA: 'ecPartC/saveBaseLineDetails',

  PART_C_SAVE_BASELINE_COLLECTION: 'ecPartC/saveBaseLineCollection',
  PART_C_SAVE_AMBIENT_AIR_QUALITY: 'ecPartC/saveAmbientAirQuality',
  PART_C_SAVE_SURFACE_WATER_QUALITY: 'ecPartC/saveSurfaceWaterQuality',
  PART_C_SAVE_GROUND_WATER_QUALITY: 'ecPartC/saveGroundWaterQuality',
  PART_C_SAVE_GROUND_WATER_LEVEL: 'ecPartC/saveGroundWaterLevel',
  PART_C_SAVE_NOISE_LEVEL: 'ecPartC/saveNoiseLevel',
  PART_C_SAVE_SOIL_QUALITY: 'ecPartC/saveSoilQuality',
  PART_C_SAVE_CHEMICAL_PROPERTIES: 'ecPartC/saveChemicalProperties',

  PART_C_DELETE_BASELINE_COLLECTION: 'ecPartC/deleteBaseLineCollection',
  PART_C_DELETE_AMBIENT_AIR_QUALITY: 'ecPartC/deleteAmbientAirQuality',
  PART_C_DELETE_SURFACE_WATER_QUALITY: 'ecPartC/deleteSurfaceWaterQuality',
  PART_C_DELETE_GROUND_WATER_QUALITY: 'ecPartC/deleteGroundWaterQuality',
  PART_C_DELETE_NOISE_LEVEL: 'ecPartC/deleteNoiseLevel',
  PART_C_DELETE_GROUND_WATER_LEVEL: 'ecPartC/deleteGroundWaterLevel',
  PART_C_DELETE_SOIL_QUALITY: 'ecPartC/deleteSoilQuality',
  PART_C_DELETE_CHEMICAL_PROPERTIES: 'ecPartC/deleteChemicalProperties',
  PART_C_SAVE_OTHER_DETAILS: 'ecPartC/saveOtherDetails',

  PART_C_SAVE_AIR_QUALITY_IMPACT: 'ecPartC/saveAirQualityImpacts',
  PART_C_SAVE_SUMMARY_ALLOCATION: 'ecPartC/saveSummaryAllocations',
  PART_C_SAVE_PARAMETER_MONITORING: 'ecPartC/saveParameterMonitor',

  PART_C_DELETE_AIR_QUALITY_IMPACT: 'ecPartC/deleteAirQualityImpact',
  PART_C_DELETE_SUMMARY_ALLOCATION: 'ecPartC/deleteSummaryAllocation',
  PART_C_DELETE_PARAMETER_MONITORING: 'ecPartC/deleteParameterMonitor',

  CRZ_TRANSFER_SAVE_PROPOSAL_DETAILS: 'crzTransferV2/saveProposalDetails',
  CRZ_TRANSFER_SAVE_ENCLOSURE_DETAILS: 'crzTransferV2/saveEnclosureDetails',
  CRZ_TRANSFER_SAVE_UNDERTAKING: 'crzTransferV2/saveUndertaking',
  GET_CRZ_TRANSFER_FORM: 'crzTransferV2/getForm',
  GET_CRZ_BASIC_FORM_DETAILS: 'crz/getBasicDetails',
  GET_CRZ_BASIC_FORM_DETAILS_INFO: 'crztransfer/getBasicDetails',
  CRZ_TRANSFER_SAVE_BASIC_DETAILS: 'crztransfer/save',

  //CLEARANCE-MATRIX
  CLEARANCE_MATRIX_SAVE: 'clearanceMatrix/save',
  CLEARANCE_MATRIX_SAVE_ALL: 'clearanceMatrix/saveAll',
  CLEARANCE_MATRIX_DELETE: 'clearanceMatrix/delete',
  CLEARANCE_MATRIX_GETBYACTIVITYID: 'clearanceMatrix/getByActivityId',

  PART_C_SAVE_ENCLOSURES: 'ecPartC/saveEnclosures',
  PART_C_SAVE_UNDERTAKING: 'ecPartC/saveUndertaking',
  GET_ECC_DETAILS: 'ecPartC/getForm',
  GET_CRITERIA_BY_APLICATIONID: 'ProposalEvaluation/criteria',
  GET_EC_C_DETAILS: 'ecPartC/getCompleteForm',
  REFRESH_TOKEN: 'refreshtoken',
  CONSULTANT_ORGANIZATION_LIST: 'ConsultantOrganisation/listOrganisations',
  CRZ_SAVE_BASIC_DETAILS: 'crz/save',
  CRZ_SAVE_PROJECT_DETAILS: 'crz/saveProjectDetails',
  GET_CRZ_DETAILS: 'crz/getBasicDetails',
  CRZ_SAVE_OTHER_DETAILS: 'crz/saveOtherDetails',
  SAVE_CRZ_WATER_REQUIREMENT: 'crz/saveCrzWaterRequirement',
  DELETE_CRZ_WATER_REQUIREMENT: 'crz/deleteCrzWaterRequirement',
  GET_CRZ_WATER_REQUIREMENT: 'crz/getCrzWaterRequirement',
  SAVE_CRZ_SOLID_WASTE: 'crz/saveCrzSolidWaste',
  DELETE_CRZ_SOLID_WASTE: 'crz/deleteCrzSolidWaste',
  GET_CRZ_SOLID_WASTE: 'crz/getCrzSolidWaste',
  SAVE_CRZ_DISPOSAL: 'crz/saveCrzDisposal',
  DELETE_CRZ_DISPOSAL: 'crz/deleteCrzDisposal',
  GET_CRZ_DISPOSAL: 'crz/getCrzDisposal',
  GET_CRZ_CONDITIONS: 'crz/getConditons',
  GET_CAF_DETAIL_BY_PROPOSAL_NO: 'proponentApplicant/getCafDataByProposalNo',
  GET_DETAIL_BY_PROPOSAL_ID: 'proponentApplicant/getByProposalId',
  GET_PARENT_APPLICATON_BY_ID: 'proponentApplicant/getByProposalId',
  IMPLEMENTATION_STATUS_SAVE: 'ecImplementationStatus/save',
  IMPLEMENTATION_STATUS_DELETE: 'ecImplementationStatus/delete',
  AMENDMENT_TRANSFER_SAVE: 'ecAmendmentTransferDetails/save',
  AMENDMENT_TRANSFER_DELETE: 'ecAmendmentTransferDetails/delete',
  START_CPC_WORKFLOW: 'parivesh/camunda/cpc/bpmn/CPC_WRLFLW/startCPCflow',
  GET_FAQS: 'faq/getAllFaq',
  GET_STAKEHOLDERS: 'stakeHolder/getAll',
  GET_ACTS_RULES_GUIDELINES: '/okm/getDocumentByWorkgroupNameAndDocCatName',
  SAVE_CONTACT_US: 'contact/createContact',
  GET_POLICY_BY_ABBR: 'cms/getByAbbr',
  GET_ROLE_BY_ABBR: 'role/getRoleByAbbreviation',
  CAF_ALTERNATE_KML_DELETE: 'caf/deleteCafKML',
  AADHAR_SIGNATURE: 'adhaar/esign',
  AADHAR_SIGNATURE_KYC: 'adhaar/esignEkyc',
  GET_ALL_RANGE: 'range/getAllRangeData',
  WORK_GROUP_LIST: 'workgroup/selectionlist',
  CHANGE_PASSWORD: 'user/changepassword',
  CHANGE_EMAIL: 'userEmailChangeLog/updateEmail',
  GET_DESIGNATION: 'userDesignationChangeLog/getAllDesignation',
  SAVE_DESIGNATION: 'userDesignationChangeLog/saveDesignation',
  UPDATE_DESIGNATION: 'userDesignationChangeLog/updateDesignation',
  CRZ_SAVE_UNDERTAKING: 'crz/saveUndertaking',
  SAVE_ADDITIONAL_INFORMATION: 'fc/saveAddtionalInfomation',
  SEND_NOTIFICATION: 'user/sendNotification',
  DElETE_ADDITIONAL_INFORMATION: 'fc/deleteAdditionalInformation',
  GET_VISITOR_COUNT: 'visitor/list',
  DELETE_CRZ_FACILITY_STORAGE_GOODS: 'crz/deleteFacilityStorageGoods',
  DELETE_CRZ_OTHER_EFFLUENT: 'crz/deleteOtherEffluent',
  FC_PART_B_GET_BASIC_DETAILS: 'fc/partb/getBasicDetails',
  FC_PART_B_SAVE_BASIC_DETAILS: 'fc/partb/saveBasicDetails',

  SAVE_FC_PARTB_OTHER: 'fc/partb/saveOtherDetails',
  GET_ADDITIONAL_INFORMATION: 'fc/getAdditionalInformation',
  GET_ADDITIONAL_INFORMATION_V2: 'fc/getAdditionalInformationv2',
  GET_PROPOSAL_DETAILS: 'wl/get-FC-Granted',
  GET_EC_PROPOSAL_DETAILS: 'wl/get-EC-Obtained',
  FC_PART_B_SAVE_TREE_DETAILS: 'fc/partb/saveTreeDetails',
  START_DFO_WORKFLOW: 'proposaltask/submit',
  FC_PART_B_DELETE_VIOLATION_DETAILS: 'fc/partb/deleteViolationDetails',
  FC_PART_B_DELETE_DISTRICT_DETAILS: 'fc/partb/deleteDistrictWise',
  FC_PART_B_DELETE_LEGAL_STATUS_DETAILS: 'fc/partb/deleteLegalStatus',
  FC_PART_B_SAVE_VIOLATION_DETAILS: 'fc/partb/saveViolationDetails',
  FC_PART_B_SAVE_DENSITY_VIOLATION: 'fc/partb/saveDensityOfVegetation',
  DELETE_FC_PART_B_DENSITY_VEGETATION: 'fc/partb/deleteDensityOfVegetation',
  DELETE_FC_PART_B_TREE_DETAILS: 'fc/partb/deleteTreeDetails',
  DELETE_FC_PART_B_PATCH_DETAILS: 'fc/partb/deletePatch',
  DELETE_FC_PART_B_ADD_CA_LAND: 'fc/partb/deleteCaLand',
  DELETE_FC_E_PART_B_PATCH_DETAILS: 'fc/forme/partb/deletePatch',
  DELETE_ALL_COMPONENT_DETAILS: 'fc/partb/deleteAllComponentDetails',
  FC_PRIOR_APPROVAL_LIST: 'fcPriorApproval/get',
  FC_PRIOR_APPROVAL_DETAIL: 'fcPriorApproval/get',
  EDS_GET_ALL_PROPONENT_USER_TASK: 'proposaltask/getAllproponentUserTask',
  FC_PRIOR_APPROVAL_MARK_PROCESSED: 'fcPriorApproval/setProcessedFlag',
  UPDATE_FC_PRIOR_APPROVAL: 'fcPriorApproval/updatePriorApproval',
  GET_PROPONENT_APPLICATION: 'proponentApplicant/list',
  GET_TASK_DETAIL: 'proposaltask/taskByid',
  SUBMIT_TASK_DETAIL: 'proposaltask/processProposalTask',
  GET_PROPONANT_DETAILS: 'consultant/getByProjectId',
  SAVE_CONSULTANT_DETAILS: 'ec/saveConsultantDetail',
  SAVE_FC_PART_B_ENUMERATION_DETAILS: 'fc/partb/saveEnumerationDetails',
  GET_SCENARIO: 'decision-definition/key/ScenarioForFCWorkFlow/evaluate',
  DELETE_FC_PART_B_ENUMERATION_DETAILS: 'fc/partb/deleteEnumerationDetails',

  SAVE_RECOMMENDED_AREA: 'fc/recommendedArea/save',
  GET_RECOMMENDED_AREA: 'fc/recommendedArea/get',

  //Site Inspection Report
  DELETE_SIR_UNDERTAKING: 'steInspectionReport/deleteUndertaking',
  DELETE_SIR_LEGAL_STATUS: 'steInspectionReport/deleteLegalStatus',
  DELETE_SIR_SPECIFIC_COMPONENT:
    'steInspectionReport/deleteSpecificComponentDetails',
  DELETE_SIR_QUARRY: 'steInspectionReport/deleteQuarryDetails',
  DELETE_SIR_SPECIES_DETAILS: 'steInspectionReport/deleteSpecieDetails',
  DELETE_SIR_COMPONENT_WISE_DETAILS:
    'steInspectionReport/deleteComponentDetails',
  DELETE_SIR_OFFICIAL_DETAILS: 'steInspectionReport/deleteOfficialDetails',
  SAVE_SIR_BASIC_DETAILS: 'steInspectionReport/saveBasicDetails',
  SAVE_SIR_OTHER_DETAILS: 'steInspectionReport/saveOtherDetails',
  SAVE_SIR_QUARRY_DETAILS: 'steInspectionReport/saveQuarryDetails',
  SAVE_SIR_SPECIFIC_COMPONENT_DETAILS:
    'steInspectionReport/saveSpecificComponentDetails',
  SAVE_SIR_UNDERTAKING: 'steInspectionReport/saveUndertaking',
  GET_SIR_DETAILS: 'steInspectionReport/getForm',

  //set esignedDocumentPath
  SET_ESIGNED_SIR_PATH: 'steInspectionReport/setESignedSirPath',

  // For FC Form A Mining Mineral Oil

  SAVE_MINING_MINERAL_OIL: 'fc/saveMiningMineralOilProposal',
  DELETE_MINING_MINERAL_OIL_RESERVE: 'fc/deleteMiningMineralOilReserve',
  DELETE_MINING_MINERAL_OIL_ESTIMATED_RESERVE:
    'fc/deleteMiningMineralOilEstimatedReserve',
  DELETE_MINING_MINERAL_OIL_EXTRACTED: 'fc/deleteMiningMineralOilExtracted',
  DELETE_MINING_MINERAL_OIL_PRODUCTION_DETAIL:
    'fc/deleteMiningMineralOilProductionDetail',

  // For FC Form B Part B
  FC_B_PART_B_SAVE_TREE_DETAILS: 'fc/formb/partb/saveTreeDetails',
  DELETE_FC_B__PART_B_TREE_DETAILS: 'fc/formb/partb/deleteTreeDetails',
  FC_B_PART_B_SAVE_BASIC_DETAILS: 'fc/formb/partb/saveBasicDetails',
  FC_B_PART_B_GET_BASIC_DETAILS: 'fc/formb/partb/getBasicDetails',
  FC_B_PART_B_DELETE_VIOLATION_DETAILS: 'fc/formb/partb/deleteViolationDetails',
  FC_B_PART_B_DELETE_DISTRICT_DETAILS: 'fc/formb/partb/deleteDistrictWise',
  FC_B_PART_B_DELETE_LEGAL_STATUS_DETAILS: 'fc/formb/partb/deleteLegalStatus',
  FC_A_DELETE_LEGAL_STATUS_DETAILS: 'fc/deleteLegalStatus',
  FC_B_DELETE_LEGAL_STATUS_DETAILS: '/fc/formb/deleteLegalStatus',
  DELETE_FC_B_PART_B_ENUMERATION_DETAILS:
    'fc/formb/partb/deleteEnumerationDetails',
  FC_B_PART_B_SAVE_VIOLATION_DETAILS: 'fc/formb/partb/saveViolationDetails',
  FC_B_PART_B_SAVE_DISTRICT_WISE_DETAILS: 'fc/formb/partb/saveDistrictWise',
  FC_B_PART_B_SAVE_DENSITY_VIOLATION: 'fc/formb/partb/saveDensityOfVegetation',
  DELETE_FC_B_PART_B_DENSITY_VEGETATION:
    'fc/formb/partb/deleteDensityOfVegetation',
  FC_B_PART_B_SAVE_WL_SPECIFIC_DETAILS: 'fc/formb/partb/saveWLSpecificDetails',
  FC_B_PART_B_DELETE_ALL_COMPONENT_DETAILS:
    'fc/formb/partb/deleteAllComponentDetails',
  FC_B_PART_B_DELETE_COMPONENT_DETAILS: 'fc/formb/partb/deleteComponentDetails',
  FC_B_PART_B_SAVE_FC_COMPONENET_DETAILS: 'fc/formb/partb/saveComponentDetails',
  FC_B_PART_B_SAVE_FC_AFFORESTATION_DETAILS:
    'fc/formb/partb/saveAfforestationDetails',
  FC_B_PART_B_SAVE_FC_AFFORESTATION_PATCH_DETAILS:
    'fc/formb/partb/savePatchWiseDetails',
  FC_B_PART_B_SAVE_FC_PARTB_AFFORESTATION_PATCH_DETAILS:
    'fc/formb/partb/savePatch',
  SAVE_FC_B_PARTB_AFFORESTATION_ADD_CA_LAND: 'fc/formb/partb/saveCaLand',
  SAVE_FC_E_PARTB_AFFORESTATION_ADD_CA_LAND: 'fc/forme/partb/saveCaLand',
  DELETE_FC_B_PART_B_ADD_CA_LAND: 'fc/formb/partb/deleteCaLand',
  DELETE_FC_E_PART_B_ADD_CA_LAND: 'fc/forme/partb/deleteCaLand',
  FC_B_PART_B_DELETE_FC_PART_B_PATCH_DETAILS: 'fc/formb/partb/deletePatch',
  FC_B_PART_B_SAVE_FC_PARTB_OTHER: 'fc/formb/partb/saveOtherDetails',

  // Amendments
  AMENDMENTS_SAVE: 'amendmentLogs/save',
  AMENDMENTS_GET: 'amendmentLogs/get',
  AMENDMENTS_DELETE: 'amendmentLogs/delete',

  CORRIGENDUM_GET: 'fcCorrigendumCertificate/getCorrigendumByUser',
  CORRIGENDUM_REASON_SAVE: 'fcCorrigendumCertificate/saveCorrigendumReason',

  REMOVED_PROPOSAL_GET: 'removedProposals/getList',
  REMOVED_PROPOSAL_REMOVE: 'removedProposals/remove',
  UPLOAD_DOC: 'facMomConsent/uploadLegacyFile',
  MIGRATION_DATA_SAVE: 'migrationDocumentDetails/saveDocumentDetail',
  ADD_COMMITTEE_MEMBER: 'proponentApplicant/addCommittee',
  GET_COMMITTEE_LIST: 'proponentApplicant/getCommitteeList',
  GET_COMMITTEE_MEMBER: 'proponentApplicant/getUserDetailsById',
  DELETE_COMMITTEE_MEMBER: 'proponentApplicant/deleteCommittee',
  REVOKE_PROPOSAL_SAVE: 'revokeProposal/save',
  ROLE_LIST: 'role/getRolelist',
  SAVE_STATE_APPROVAL_AUTHORITY:
    'fcApprovingAuthority/saveFcApprovingAuthority',
  GET_STATE_APPROVAL_AUTHORITY: 'fcApprovingAuthority/getFcApprovingAuthority',
  GET_LEGACY_BASIC_DETAIL_BY_STATE_NAME:
    'migrationBasicDetails/getBasicDetailsByStateName',
  GET_SCENARIO_BASED_ON_ROLE: 'fcApprovingAuthority/getScenerioBasedOnRole',
  DELETE_STATE_APPROVAL_AUTHORITY:
    'fcApprovingAuthority/deleteFcApprovingAuthority',
  REVOKE_PROPOSAL_UPDATE: 'process/updateRevokedProposal',
  REVOKE_PROPOSAL_GET: 'revokeProposal/getList',
  GET_CAMPA_DASHBOARD_DETAILS: 'campaPayment/getCampaDashboardDetails',
  GET_DELISTED_PROPOSAL: 'delistProposal/getDelistedProposal',
  GET_DELISTED_PROPOSAL_REPORT:
    'departmentApplication/getDelistedProposalFromView',
  RELISTED_PROPOSAL: 'delistProposal/relistProposal',

  //FC Stipulated Form
  SAVE_STIPULATED_FORM_FC: 'fcStipulateDetails/createFcStipulatedCaDetails',
  GET_STIPULATED_FORM_DATA: 'fcStipulateDetails/getFcStipulateCaDetails',

  //Pull proposal
  PULL_PROPOSAL__LIST: 'proposal/pullLogs/list',
  PULL_PROPOSAL__GET: 'proposal/pullLogs/get',
  PULL_PROPOSAL__SAVE: 'proposal/pullLogs/save',
  PULL_PROCESS__APPLICATION_ID: 'process/getApplicationById',
  // remarks
  GENERAL_REMARKS_SAVE: 'generalRemarks/save',
  GENERAL_REMARKS_GET: 'generalRemarks/get',
  GENERAL_REMARKS_DELETE: 'generalRemarks/delete',

  // For FC FORM C PART B
  FC_C_PART_B_GET: 'fc/formc/partb/get',
  FC_C_PART_B_SAVE_FC_PARTB_OTHER: 'fc/formc/partb/saveOtherDetails',
  FC_C_PART_B_SAVE_WL_SPECIFIC_DETAILS: 'fc/formc/partb/saveWLSpecificDetails',
  FC_C_PART_B_DELETE_ALL_COMPONENT_DETAILS:
    'fc/formc/partb/deleteAllComponentDetails',
  FC_C_PART_B_SAVE_FC_COMPONENET_DETAILS: 'fc/formc/partb/saveComponentDetails',
  FC_C_PART_B_SAVE_TREE_DETAILS: 'fc/formc/partb/saveTreeDetails',
  FC_C_PART_B_SAVE_VIOLATION_DETAILS: 'fc/formc/partb/saveViolationDetails',
  FC_C_PART_B_SAVE_DISTRICT_WISE_DETAILS:
    'fc/formc/partb/saveDistrictWiseDetails',
  FC_C_PART_B_SAVE_DENSITY_VIOLATION: 'fc/formc/partb/saveDensityOfVegetation',

  FC_C_PART_B_SAVE_BASIC_DETAILS: 'fc/formc/partb/save',
  FC_C_PART_B_DELETE_VIOLATION_DETAILS: 'fc/formc/partb/deleteViolationDetails',
  DELETE_FC_C__PART_B_TREE_DETAILS: 'fc/formc/partb/deleteTreeDetails',
  DELETE_FC_C_PART_B_ENUMERATION_DETAILS:
    'fc/formc/partb/deleteEnumerationDetails',
  DELETE_FC_C_PART_B_DENSITY_VEGETATION:
    'fc/formc/partb/deleteDensityOfVegetation',
  FC_C_PART_B_DELETE_DISTRICT_DETAILS: 'fc/formc/partb/deleteDistrictWise',
  FC_C_PART_B_DELETE_AFFORESTATION_DETAILS:
    'fc/formc/partb/deleteAfforestationDetails',
  FC_C_PART_B_DELETE_PATCH: 'fc/formc/partb/deletePatch',
  FC_C_PART_B_SAVE_AFFORESTATION_DETAILS:
    'fc/formc/partb/saveAfforestationDetails',
  FC_C_PART_B_DELETE_LEGAL_STATUS_DETAILS: 'fc/formc/partb/deleteLegalStatus',
  SAVE_FC_C_PARTB_AFFORESTATION_ADD_CA_LAND: 'fc/formc/partb/saveCaLand',
  DELETE_FC_C_PART_B_ADD_CA_LAND: 'fc/formc/partb/deleteCaLand',
  FC_C_PART_B_SAVE_FC_AFFORESTATION_PATCH_WISE_DETAILS:
    'fc/formc/partb/savePatchWiseDetails',
  FC_C_PART_B_SAVE_FC_AFFORESTATION_PATCH_DETAILS: 'fc/formc/partb/savePatch',

  //  For FC form D part B

  FC_D_PART_B_SAVE_BASIC_DETAILS: 'fc/formd/partb/saveBasicDetails',
  FC_D_PART_B_SAVE_TREE_DETAILS: 'fc/formd/partb/saveTreeDetails',
  FC_D_PART_B_SAVE_VIOLATION_DETAILS: 'fc/formd/partb/saveViolationDetails',
  FC_D_PART_B_SAVE_DENSITY_VIOLATION: 'fc/formd/partb/saveDensityOfVegetation',
  FC_D_PART_B_SAVE_DISTRICT_WISE_DETAILS: 'fc/formd/partb/saveDistrictWise',
  FC_D_PART_B_GET_BASIC_DETAILS: 'fc/formd/partb/getBasicDetails',
  FC_D_PART_B_DELETE_DISTRICT_DETAILS: 'fc/formd/partb/deleteDistrictWise',
  FC_D_PART_B_DELETE_VIOLATION_DETAILS: 'fc/formd/partb/deleteViolationDetails',
  FC_D_PART_B_DELETE_LEGAL_STATUS_DETAILS: 'fc/formd/partb/deleteLegalStatus',
  DELETE_FC_D_PART_B_DENSITY_VEGETATION:
    'fc/formd/partb/deleteDensityOfVegetation',
  DELETE_FC_D_PART_B_ENUMERATION_DETAILS:
    'fc/formd/partb/deleteEnumerationDetails',
  FC_D_PART_B_DELETE_FC_PART_B_PATCH_DETAILS: 'fc/formd/partb/deletePatch',
  DELETE_FC_D_PART_B_TREE_DETAILS: 'fc/formd/partb/deleteTreeDetails',
  FC_D_PART_B_SAVE_WL_SPECIFIC_DETAILS: 'fc/formd/partb/saveWLSpecificDetails',
  FC_D_PART_B_SAVE_FC_AFFORESTATION_DETAILS:
    'fc/formd/partb/saveAfforestationDetails',
  FC_D_PART_B_SAVE_FC_PARTB_OTHER: 'fc/formd/partb/saveOtherDetails',
  FC_D_PART_B_DELETE_ALL_COMPONENT_DETAILS:
    'fc/formd/partb/deleteAllComponentDetails',
  FC_D_PART_B_SAVE_FC_PARTB_AFFORESTATION_PATCH_DETAILS:
    'fc/formd/partb/savePatch',
  FC_D_PART_B_SAVE_FC_AFFORESTATION_PATCH_DETAILS:
    'fc/formd/partb/savePatchWiseDetails',

  // For FC form B
  SAVE_FC_FORM_B_PROJECT_DETAILS: 'fc/formb/saveProjectDetails',
  SAVE_FC_FORM_B_PRIOR_PROPOSALS_DETAILS: 'fc/formb/savePriorProposals',
  SAVE_FC_FORM_B_APPROVAL_DETAILS: 'fc/formb/saveApprovalDetails',
  GET_FC_FORM_B_PROJECT_DETAILS: 'fc/formb/getProjectDetails',
  GET_FC_FORM_B_PATCH_DETAILS: 'fc/formb/getFcFormBPatchKmls',
  SAVE_FC_FORM_B_PROPOSED_LAND: 'fc/formb/saveProposedLand',
  SAVE_FC_FORM_B_AFORESTATION_DETAILS: 'fc/formb/saveAfforestationDetails',
  SAVE_FC_FORM_B_MINING_PROPOSAL: 'fc/formb/saveMiningProposals',
  SAVE_FC_FORM_B_COMPLIANCE_DETAILS: 'fc/formb/saveComplianceDetails',
  SAVE_FC_FORM_B_PAYMENT_DETAILS: 'fc/formb/savePaymentDetails',
  SAVE_FC_FORM_B_PATCH_AREA_DETAILS: 'fc/formb/savePatchAreaDetails',
  SAVE_FC_FORM_B_LEASE_DETAILS: 'fc/formb/saveLeaseDetails',
  SAVE_FC_FORM_B_OTHERS_DETAIL: 'fc/formb/saveOthersDetail',
  SAVE_COMPONENT_FC_FORM_B: 'fc/formb/saveComponentDetails',
  //SAVE_FC_FORM_B_UNDERTAKING: 'fc/formb/saveAddundertaking',
  SAVE_FC_FORM_B_PROPOSED_DIVERSION: 'fc/formb/saveProposedDiversion',
  SAVE_FC_FORM_B_UNDERTAKING: 'fc/formb/addUndertaking',
  DELETE_FC_FORM_B_PROPOSED_DIVERSION_KML_DATA:
    'fc/formb/deleteProposedDiversion',
  SAVE_FC_FORM_B_PATCHWISE_DETAILS: 'fc/formb/saveFcFormBPatchKmls',
  //DELETE_FC_FORM_B_PRIOR_PROPOSALS: '/fc/formb/deletePriorProposals',
  DELETE_FC_FORM_B_PRIOR_DETAILS: 'fc/formb/deletePriorProposals',
  DELETE_FC_FORM_B_COMPONENT: 'fc/formb/deleteComponentDetails',
  DELETE_FC_FORM_B_COMPLIANCE: 'fc/formb/deleteComplianceDetails',
  DELETE_FC_FORM_B_PAYMENT: 'fc/formb/deletePaymentDetails',
  DELETE_FC_FORM_B_PATCH_WISE: 'fc/formb/deletePatchAreaDetails',
  DELETE_FC_FORM_B_APPROVAL: 'fc/formb/deleteApprovalDetails',
  GET_FC_FORM_B_PROPOSED_DIVERSION_KML_DATA: 'fc/formb/getProposedDiversion',

  // FC Foem C Exempted Category
  SAVE_FC_FORM_C_EXEMPTED_PROJECT_DETAILS: 'FcFormCExemptCat/saveForm',
  SAVE_FC_FORM_C_EXEMPTED_PROPOSED_LAND: 'FcFormCExemptCat/saveProposedLand',
  SAVE_FC_FORM_C_EXEMPTED_PROPOSED_LAND_DETAILS:
    'FcFormCExemptCat/saveProposedLandDetails',
  SAVE_FC_FORM_C_EXEMPTED_ACTIVITY: 'FcFormCExemptCat/saveActivity',
  SAVE_FC_FORM_C_EXEMPTED_EQUIPMENT: 'FcFormCExemptCat/saveEquipment',
  SAVE_FC_FORM_C_EXEMPTED_UNDERTAKING: 'FcFormCExemptCat/saveUndertaking',
  GET_FC_FORM_C_EXEMPTED_PROJECT_DETAILS: 'FcFormCExemptCat/getForm',
  DELETE_FC_FORM_C_EXEMPTED_EQUIPMENTS: 'FcFormCExemptCat/deleteEquipment',
  DELETE_FC_FORM_C_EXEMPTED_PROPOSED_LAND:
    'FcFormCExemptCat/deleteProposedLand',
  SAVE_FC_FORM_C_EXEMPTED_PROPOSED_DIVERSION:
    'FcFormCExemptCat/saveFcFormCExemptCatProposedDiversionDetails',
  GET_FC_FORM_C_EXEMPTED_PROPOSED_DIVERSION:
    'FcFormCExemptCat/getProposedDiversion',
  DELETE_FC_EXEMPTED_PROPOSED_DIVERSION:
    'FcFormCExemptCat/deleteFcFormCExemptCatProposedDiversionDetails',

  // FC Form C PArt 2

  SAVE_FC_FORM_C_EXEMPTED_PARTII_PROJECT_DETAILS:
    'fcFormCExemptCatPartII/saveForm',
  SAVE_FC_FORM_C_EXEMPTED_PARTII_VEGETATION_DETAILS:
    'fcFormCExemptCatPartII/saveVegetationDetails',
  SAVE_FC_FORM_C_EXEMPTED_PARTII_PROPOSED_DETAILS:
    'fcFormCExemptCatPartII/saveProposedLandDetails',
  GET_FC_FORM_C_EXEMPTED_PARTII_PROJECT_DETAILS:
    'fcFormCExemptCatPartII/getFormII',
  DELETE_PARTII_VEGETATION_DETAILS:
    'fcFormCExemptCatPartII/deleteVegetationDetails',
  DELETE_PARTII_PROPOSED_LAND: 'fcFormCExemptCatPartII/deleteProposedLand',

  // For FC form C
  SAVE_FC_FORM_C_PROJECT_DETAILS: 'fc/formC/saveFormC',
  SAVE_FC_FORM_C_PROPOSED_LAND: 'fc/formC/saveProposedLand',

  SAVE_FC_FOLRM_C_EXPLORED_FORESTLAND: 'fc/formC/saveExploredForestLand',
  GET_FC_FORM_C_EXPLORED_FORESTLAND: 'fc/formC/getExploredForestLand',
  DELETE_FC_FORM_C_EXPLORED_FORESTLAND: 'fc/formC/deleteExploredForestLand',

  SAVE_FC_FORM_C_OTHER_DETAILS: 'fc/formC/saveOtherDetails',
  SAVE_FC_FORM_C_ACTIVITY_DETAILS: 'fc/formC/saveActivityDetails',

  SAVE_ACTIVITY_PROPOSED_DIVSERSION: 'fc/formC/saveSurfaceSampling',
  GET_FC_FORM_C_ACTIVITY_PROPOSED_DIVSERSION: 'fc/formC/getSurfaceSampling',
  DELETE_FC_FORM_C_ACTIVITY_PROPOSED_DIVSERSION:
    'fc/formC/deleteSurfaceSampling',

  SAVE_FC_FORM_C_REPORT_COMPLIANCE: 'fc/formC/saveComplianceReport',
  DELETE_FC_FORM_C_REPORT_COMPLIANCE: 'fc/formC/deleteComplianceReport',
  GET_FC_FORM_C_COMPLIANCE_REPORT: 'fc/formC/getComplianceReport',

  SAVE_FC_FORM_C_LAND_DETAILS: 'fc/formC/saveLandDetailsN',

  SAVE_FC_FORM_C_DETAILS_OF_MACHINERY: 'fc/formC/saveDetailsOfMachinery',
  GET_FC_FORM_C_DETAILS_OF_MACHINERY: 'fc/formC/getDetailsOfMachinery',
  DELETE_FC_FORM_C_DETAILS_OF_MACHINERY: 'fc/formC/deleteDetailsOfMachinery',

  SAVE_FC_FORM_C_AFFORESTATION_DETAILS: 'fc/formC/saveAfforestationDetails',
  SAVE_FC_FORM_C_UNDETAKING: 'fc/formC/saveUndertaking',

  SAVE_FC_FORM_C_PROPOSED_DIVERSION: 'fc/formC/saveProposedDiversion',
  GET_FC_FORM_C_PROPOSED_DIVERSION_KML_DATA: 'fc/formC/getProposedDiversion',
  DELETE_FC_FORM_C_PROPOSED_DIVERSION_KML_DATA:
    'fc/formC/deleteProposedDiversion',

  SAVE_FC_FORM_C_PRIOR_APPROVAL: '/fc/formC/savePriorApproval',
  SAVE_FC_FORM_C_PATCHWISE_DDETAILS: 'fc/formC/saveFcFormCPatchKmls',
  GET_FC_FORM_C_PATCH_DETAILS: 'fc/formC/getFcFormCPatchKmls',
  DELETE_FC_FORM_C_PATCH_DETAILS: 'fc/formC/deleteFcFormCPatchKmls',
  GET_FC_FORM_C_DETAILS: 'fc/formC/get',
  DELETE_FC_FORM_C_PRIOR_APPROVAL: 'fc/formC/deletePriorApproval',

  //PROCESS FLOW APIS
  CORRIGENDUM_FORWARDING_DATA_GET:
    'process/fcCorrigendum/getCorrigendumForwadingData',
  CORRIGENDUM_SAVE_PROCESS_STEP_PARAMETER: 'fcCorrigendumStepParameter/save',
  CORRIGENDUM_SAVE_HISTORY_WISE: 'fcCorrigendumHistoryWise/save',
  GET_CORRIGENDUM_PROPOSAL_HISTROY: 'fcCorrigendumHistoryWise/getProcessHis',
  GET_CORRIGENDUM_HISTORY_WISE_WITH_APPLICATION_LIST:
    'fcCorrigendumHistoryWise/getHistoryWiseList',
  CORRIGENDUM_NOTESHEET_GET: 'fcCorrigendumNotesheet/get',
  CORRIGENDUM_NOTESHEET_SAVE: 'fcCorrigendumNotesheet/save',
  CORRIGENDUM_NOTESHEET_DELETE: 'fcCorrigendumNotesheet/delete',
  CORRIGENDUM_PROCESS_HISTROY: 'process/fcCorrigendum/addHistory',
  GET_CORRIGENDUM_TASK: 'process/fcCorrigendum/getCorrigendumTask',
  GET_CORRIGENDUM_TASK_HISTORY: 'process/fcCorrigendum/getCorrigendumHistory',
  ADD_PROCESS_HISTROY: 'process/addHistory',
  ADD_NPV_PAYMENT_PROCESS_HISTROY: 'process/fcPayment/addHistory',
  SAVE_NPV_PAYEMNT_HISTORY_WISE: 'fcPaymentHistoryWise/save',
  SAVE_NPV_PROCESS_STEP_PARAMETER: 'fcPaymentStepParameter/save',
  GET_NPV_PROCESS_STEP_PARAMETER: 'fcPaymentStepParameter/getByApplicationId',
  GET_NPV_CHALLAN_DETAILS: 'campav2Demand/getCampav2Demand',
  GET_STATE_HIERARCHY: 'getStateHierarchy',
  GET_STATE_HIERARCHY_BY_PROPOSAL: 'DataMigartion/FetchOfficeDataService',
  GET_MY_PROPOSAL: 'process/getApplicationsList',
  GET_MY_PROPOSAL_NEW_API: 'jsdashboard/getInboxApplications',
  PROPOSAL_COUNT: 'departmentApplication/getFcAuthorityBucketProposalCount',
  PROPOSAL_DETAILS: 'departmentApplication/fcAuthorityBucketProposalDetails',
  GET_MY_PROPOSAL_WL: 'wlc-workflow/dashboard-record',
  GET_PROCESS_STEP_ACTION: 'getProcessStepActionWithApplications',
  GET_CORRIGENDUM_PROCESS_STEP_ACTION:
    'fcCorrigendum/getProcessStepActionWithApplications',
  SAVE_EDS_DETAILS: 'eds/saveEDSDetails',
  CERTIFICATESPLIT_DETAILS: 'EcForm12Certificate/splitProposal',
  GET_EDS_DETAILS: 'eds/getEDSDetails',
  GET_PROPOSAL_HISTROY: 'process/getProcessHis',
  DFO_PROPOSAL_COUNT: 'departmentApplication/getFcBucketProposalCount',
  DFO_PROPOSAL_DETAILS: 'departmentApplication/getfcBucketProposalDetails',

  SAVE_HISTORY_WISE: 'process/saveHistoryWise',
  GET_HISTORY_WISE: 'process/getHistoryWise',
  UPDATE_PROPONENT_APPLICATION: 'proponentApplicant/updateByProposalNo/V3',
  IS_HIGHEST_DIVISION_AREA: 'process/isHighestAreaDivision',
  GET_HISTORY_WISE_WITH_APPLICATION_LIST:
    'process/getHistoryWiseWithApplicationListV2',
  GET_SIR_PROPOSAL: 'process/getSirApplicationList',
  GET_APPLICATION_LIST: 'process/getCountApplicationsList',
  DELETE_FLAG_PROPONENT_APPLICATION:
    'proponentApplicant/updateFlagByProposalId',

  //FC DC APIS
  SAVE_FC_DC_BASIC_DETAILS: 'fcFormAPartBDC/save',
  GET_FC_DC_BASIC_DETAILS: 'fcFormAPartBDC/get',
  SAVE_FC_DC_INSPECTION_DETAILS: 'fcFormAPartBDCInspectionDetails/save',
  GET_FC_DC_INSPECTION_DETAILS: 'fcFormAPartBDCInspectionDetails/get',
  DELETE_FC_DC_INSPECTION_DETAIL: 'fcFormAPartBDCInspectionDetails/delete',
  SAVE_FC_DC_UNDERTAKING: 'fcFormAPartBDCInspectionDetails/saveUndertaking',
  GET_FORM_C_SCENARIO: 'scenario/getScenarios',

  SAVE_EC_FORM_FIVE: 'ecFormV/save',
  GET_EC_FORM_FIVE: 'ecFormV/get',
  SAVE_EC_FORM_FIVE_UNDERTAKING: 'ecFormV/saveUndertaking',

  // CLEARANCE FLOW
  SAVE_PROCESS_DFINITION: 'saveProcessDefinition',
  GET_PROCESS_DFINITION: 'getProcessDefinitionList',
  SAVE_PROCESS_STEP: 'saveProcessSteps',
  GET_PROCESS_STEP: 'getStepsByDefId',
  GET_PROCESS_STEP_BY_ID: 'getProcessSteps',
  SAVE_PROCESS_STEP_MAPPING: 'saveProcessStepMapping',
  SAVE_PROCESS_STEP_ROLE_OFFICE_MAPPING: 'saveProcessStepRoleOfficeType',
  GET_PROCESS_STEP_MAPPING: 'getStepMappingByDefId',
  SAVE_PROCESS_STEP_ACTION_MAPPING: 'saveProcessStepAction',
  GET_PROCESS_STEP_ACTION_MAPPING: 'getStepActionByDefId',
  GET_PROCESS_STEP_ROLE_OFFICE_MAPPING: 'getStepRoleOfficeTypeDefId',
  DELETE_PROCESS_STEPS: 'deleteProcessSteps',
  DELETE_PROCESS_STEP_MAPPING: 'deleteProcessStepMapping',
  DELETE_PROCESS_STEP_ACTION_MAPPING: 'deleteProcessStepAction',
  DELETE_PROCESS_STEP_ROLE_OFFICE_MAPPING: 'deleteProcessStepRoleOfficeType',

  SAVE_PROCESS_STEP_PARAMETER: 'saveProcessStepParameter',
  SAVE_PROCESS_STEP_PARAMETER_VERSION: 'saveProcessStepParameterV4',
  SAVE_PROCESS_STEP_PARAMETER_VERSION3: 'saveProcessStepParameters/V3',
  GET_APPLICATION_SCENARIO: 'getApplicationScenario',
  GET_CORRIGENDUM_APPLICATION_SCENARIO:
    'fcCorrigendumStepParameter/getByApplicationId',
  GET_APPLICATION_SCENARIO_VERSION: 'getComplianceStepParameter',
  //Role
  GET_ROLES: 'role/selectionlist',
  GET_ROLE: 'getRoles',
  GET_ROLE_BY_USER_ID: 'process/getRoleByUser',

  //Office Type
  GET_OFFICE_TYPE: 'officetype/selectionlist',

  // Site Inspection
  SAVE_SITE_INSPECTION: 'siteInspectionReport/saveDetails',
  GET_SITE_INSPECTION: 'siteInspectionReport/getDetails',

  //Form D
  SAVE_FORM_D: 'fc/formD/saveFormD',
  GET_FORM_D_DETAILS: 'fc/formD/getProjectDetails',
  GET_FORM_D_LEGAL_STATUS: 'fc/formD/getLegalStatus',
  SAVE_FORM_D_SECTOR_SPECIFIC: 'fc/formD/saveMiningPlan',
  SAVE_FORM_D_UNDERTAKING: 'fc/formD/saveUndertaking',
  SAVE_FORM_D_PROPOSED_LAND: 'fc/formD/saveProposedLand',
  SAVE_FORM_D_PROPOSED_DIVERSION_DETAILS: 'fc/formD/saveProposedDiversion',
  SAVE_FORM_D_LEGAL_STATUS_DETAILS: 'fc/formD/saveLegalStatus',
  DELETE_FORM_D_PROPOSED_DIVERSION_DETAILS: 'fc/formD/deleteProposedDiversion',
  DELETE_FORM_D_LEGAL_STATUS_DETAILS: 'fc/formD/deleteLegalStatus',
  GET_FORM_D_PROPOSED_DIVERSION_DETAILS: 'fc/formD/listProposedDiversion',

  //Form E
  FC_FORM_E_DELETE_PRIOR_APPROVAL: 'fc/formE/deletePriorProposals',
  FC_FORM_E_DELETE_PATCH_DETAILS: 'fc/formE/deletePatchDetails',
  FC_FORM_E_DELETE_COMPLIANCE_DETAILS: 'fc/formE/deleteComplianceDetails',
  FC_FORM_E_SAVE_BASIC_DETAILS: 'fc/formE/saveFormE',
  FC_FORM_E_SAVE_LAND_DETAILS: 'fc/formE/saveProposedLand',
  FC_FORM_E_SAVE_OTHER_DETAILS: 'fc/formE/saveOtherDetails',
  FC_FORM_E_SAVE_UNDERTAKING_DETAILS: 'fc/formE/saveUndertaking',
  FC_FORM_E_SAVE_COMPLIANCE_DETAILS: 'fc/formE/saveComplianceDetails',
  FC_FORM_E_SAVE_PRIOR_PROPOSALS: 'fc/formE/savePriorProposals',
  FC_FORM_E_SAVE_KML_DETAILS: 'fc/formE/saveKmls',
  FC_FORM_E_SAVE_PATCH_DETAILS: 'fc/formE/savePatchDetails',
  GET_FC_FORM_E_DETAILS: 'fc/formE/get',
  FC_FORM_E_DELETE_KML_DETAILS: 'fc/formE/deleteKmls',

  // Form E Part II
  FC_PART_E_SAVE_BASIC_DETAILS: 'fc/forme/partb/saveBasicDetails',
  FC_PART_E_GET_BASIC_DETAILS: 'fc/forme/partb/getBasicDetails',
  SAVE_FC_E_PART_B_AFFORESTATION_PATCH_DETAILS: 'fc/forme/partb/savePatch',
  SAVE_FC_E_PART_B_AFFORESTATION_PATCH_WISE_DETAILS:
    'fc/forme/partb/savePatchWiseDetails',
  FC_PART_E_SAVE_DENSITY_VEGETATION: 'fc/forme/partb/saveDensityOfVegetation',
  FC_PART_E_DELETE_DENSITY_VEGETATION:
    'fc/forme/partb/deleteDensityOfVegetation',
  FC_PART_E_SAVE_TREE_DETAILS: 'fc/forme/partb/saveTreeDetails',
  FC_PART_E_DELETE_TREE_DETAILS: 'fc/forme/partb/deleteTreeDetails',
  FC_PART_E_SAVE_DISTRICT_WISE_DETAILS: 'fc/forme/partb/saveDistrictWise',
  FC_PART_E_DELETE_DISTRICT_WISE_DETAILS: 'fc/forme/partb/deleteDistrictWise',
  FC_PART_E_SAVE_VIOLATION_DETAILS: 'fc/forme/partb/saveViolationDetails',
  FC_PART_E_DELETE_VIOLATION_DETAILS: 'fc/forme/partb/deleteViolationDetails',
  FC_PART_E_SAVE_ENUMERATION_DETAILS: 'fc/forme/partb/deleteViolationDetails',
  FC_PART_E_DELETE_ENUMERATION_DETAILS:
    'fc/forme/partb/deleteEnumerationDetails',
  FC_PART_E_SAVE_LEGAL_STATUS_DETAILS:
    'fc/forme/partb/deleteEnumerationDetails',
  FC_PART_E_DELETE_LEGAL_STATUS_DETAILS: 'fc/forme/partb/deleteLegalStatus',
  FC_PART_E_WL_SPECIFIC_DETAILS: 'fc/forme/partb/saveWLSpecificDetails',
  FC_PART_E_SAVE_AFFORESTATION_DETAILS:
    'fc/forme/partb/saveAfforestationDetails',
  FC_PART_E_SAVE_COMPONENT_DETAILS: 'fc/forme/partb/saveComponentDetails',
  FC_PART_E_DELETE_COMPONENT_DETAILS: 'fc/forme/partb/deleteComponentDetails',
  FC_PART_E_SAVE_OTHER_DETAILS: 'fc/forme/partb/saveOtherDetails',

  // Form F
  FC_FORM_F_SAVE_UNDERTAKING_DETAILS: 'fcFormF/saveUndertaking',
  FC_FORM_F_SAVE_PROJECT_DETAILS: 'fcFormF/saveForm',
  FC_FORM_F_GET_PROJECT_DETAILS: 'fcFormF/getForm',
  FC_FORM_F_SAVE_OTHER_DETAILS: 'fcFormF/saveOtherDetails',

  // Form F Part 2
  FC_FORM_F_PARTII_BASIC_DETAILS: 'fcFormFPartII/save',
  FC_FORM_F_PARTII_SAVE_OTHER_DETAILS: 'fcFormFPartII/saveOtherDetails',
  FC_FORM_F_PARTII_GET_PROJECT_DETAILS: 'fcFormFPartII/getForm',
  SAVE_FC_FORM_F_PARTII_DETAILS: 'fcFormFPartII/saveForestDetails',
  SAVE_FC_FORM_F_PARTII_PATCH_DETAILS: 'fcFormFPartII/savePatchDetails',
  SAVE_FC_FORM_F_ADD_CA_LAND: 'fcFormFPartII/saveCaDetails',
  FC_FORM_F_DELETE_PATCH: 'fcFormFPartII/deletePatchWiseDetails',
  FC_FORM_F_DELETE_CA_LAND: 'fc/formc/partb/deleteCaLandDetails',

  // Consultant
  CONSULTANT_SYNC: 'ConsultantOrganisation/syncConsultantOrganisation',

  // notification
  NOTIFICATION_SAVE: 'notificationTemplate/save',
  NOTIFICATION_DELETE: 'notificationTemplate/delete',
  NOTIFICATION_GET: 'notificationTemplate/get',
  NOTIFICATION_LIST: 'notificationTemplate/getList',
  // State Hierarchy
  GET_EC_STATE_HIERARCHY_BY_PROPOSAL: 'getOfficeHierarchy',
  GET_STATE_HIERARCHY_BY_STATE_ID: 'crz/getOfficeHierarchy',
  // Dashboard History
  GET_HISTORY_PROCESS_APPLICATION: 'process/getApplicationsList/history',

  // FC Fact Sheet
  FC_FACT_SHEET_SAVE: 'fcFactsheet/save',
  FC_FACT_SHEET_GET: 'fcFactsheet/get',

  // EC Fact Sheet
  EC_FACT_SHEET_SAVE: 'ecFactsheet/save',
  CRZ_FACT_SHEET_SAVE: 'crzFactsheet/save',
  CRZ_FACT_SHEET_GET: 'crzFactsheet/get',
  EC_FACT_SHEET_GET: 'ecFactsheet/get',
  EC_FACT_SHEET_SAVE_OTHER_DETAILS: 'ecFactsheet/saveOtherDetails',
  EC_FACT_SHEET_SAVE_PUBLIC_HEARING_DETAILS:
    'ecFactsheet/savePublicHearingdetails',
  EC_FACT_SHEET_DELETE_PUBLIC_HEARING_DETAILS:
    'ecFactsheet/deletePublicHearingdetails',
  EC_FACT_SHEET_GET_AUTHARITY_DETAILS: 'ecFactsheet/getAuthorityDetails',

  // EC Form Five Part B
  EC_FORM_FIVE_PART_TWO_SAVE: 'ecFormVPart2/save',
  EC_FORM_FIVE_PART_TWO_GET: 'ecFormVPart2/get',
  EC_FORM_FIVE_PART_TWO_SAVE_UNDERTAKING: 'ecFormVPart2/saveUndertaking',
  EC_FORM_FIVE_PART_TWO_BY_TOR_ID: 'ecFormVPart2/getByTorId',
  // Site Visit Report
  SITE_VISIT_REPORT_SAVE: 'saveEcSVReport',
  SITE_VISIT_REPORT_GET: 'getEcSVReport',

  // condition
  ALL_SPECIFIC_CONDITION: 'condition/getAllSpecificCondition',
  ALL_CONDITION: 'condition/getAllCondition',
  CONDITION_SAVE: 'fcComplianceController/save',
  CONDITION_LIST: 'fcComplianceController/get',
  ALTER_CONDITION_LIST: 'fc/certificate/Condition/getCondition',
  CONDITION_LIST_BY_APPLICATIO_ID: 'fcComplianceController/getByApplicationId',

  // ec-complaince-condition

  //EC-STATUS-STARTS
  GET_PROPOSAL_LIST: 'parivesh_admin/dashboard/getProposals',
  GET_STATE_HEAD_LIST: 'data/getAllStateForIroHead',
  GET_SEC_USER_DETAILS: 'parivesh_admin/dashboard/seac-user-details',
  GET_PROPOSAL_COMPLIANCE_LIST: 'compliance/data/previousCompliance',
  GET_PROPOSAL_COMPLIANCE_DOCUMENTS: 'compliance/data/getAllDocs',

  //EC-STATUS_ENDS
  // CLEARANCE_MATRIX_SAVE: 'clearanceMatrix/save',
  CLEARANCE_MATRIX_GET: 'clearanceMatrix/get',
  CLEARANCE_MATRIX_GET_V2: 'clearanceMatrix/getV2',
  CLEARANCE_MATRIX_GET_V3: 'clearanceMatrix/getV3',
  CLEARANCE_MATRIX_GET_CATEGORY: 'clearanceMatrix/getCategory',
  // CLEARANCE_MATRIX_DELETE: 'clearanceMatrix/delete',
  GET_ACTIVITY_NAME: 'kya/getactivity/id',
  GET_ACTIVITY_SEQUENCE: 'standardToRSequence/get',

  // Dispatch Module
  RECIPIENT_LIST: 'recipients',
  RECIPIENT_BY_ID: 'recipients/getRecipient',
  RECIPIENT_SAVE: 'recipients',
  RECIPIENT_UPDATE: 'recipients/updateRecipient',
  RECIPIENT_DELETE_BY_ID: 'recipients/deleteRecipient',
  RECIPIENT_DISPTACHED_DELETE_BY_ID: 'dispatch/deleteDispatch',

  DISPATCH_TO_LIST: 'dispatch/getGrantedProposals',
  DISPATCH_TO_SAVE: 'dispatch/save',
  DISPATCH_TO_SEND: 'dispatch/send',
  DISPATCH_TO_STATUS: 'dispatch/save',
  DISPATCH_BY_APPLICATION_ID: 'dispatch/getAllDispatchByApplicationId',
  DISPATCH_BY_ID: 'dispatch/getDispatchById',
  DISPATCH_BY_HISTORY: 'dispatch/getDispatchProposalHistory',

  //Noting
  DSC_DETAILS: 'digitalsigner/dsc/getUserCertById',
  NOTESHEET_GET: 'notesheet/get',
  FORWARDING_DATA_GET: 'getForwadingData',
  GET_FORWARDING_DATA_BY_ROLE: 'getForwadingDataByRole',
  NOTESHEET_SAVE: 'notesheet/save',
  UPDATE_NOTING_USER: 'updateNotingUserId',
  BULK_FORWARD_PROPOSAL: 'process/sendProposals/bulk',

  //Ec Form Five Accept
  SAVE_EC_FORM_FIVE_ACCEPT_PROPOSAL: 'saveEcForm5AcceptProposal',
  GET_EC_FORM_FIVE_ACCEPT_PROPOSAL: 'getEcForm5AcceptProposal',
  GET_EC_FORM_FIVE_REMARKS_DETAILS: 'getEcForm5AcceptProposalList',
  DELETE_EC_FORM_FIVE_REMARKS_DATA: 'deleteEcForm5AcceptProposal',

  //NPV Payment
  SAVE_NPV_CAMPA_PAYMENT: 'CampaV2ProjectEntity/createProjectEntity',
  GET_NPV_CAMPA_PAYMENT: 'CampaV2ProjectEntity/getProjectEntity',
  SAVE_NPV_OTHER_CHARGES: 'campaV2OtherCharges/createCampaV2OtherCharges',
  DELETE_NPV_OTHER_CHARGES: 'campaV2OtherCharges/deleteCampaV2OtherCharges',
  SAVE_NPV_OTHER_DETAILS: 'campaV2OtherDetails/createCampaV2OtherDetails',
  GET_NPV_OTHER_DETAILS: 'campaV2OtherDetails/getCampaV2OtherDetails',
  GET_NPV_DETAILS: 'CampaV2ProjectEntity/getProjectEntityFormDetails',
  GET_PAYMENT_LIST: 'process/fcPayment/getPaymentTask',
  GET_PAYMENT_LIST_HISTORY: 'process/fcPayment/getPaymentTaskHistory',
  GET_USER_PAYMENT_LIST: 'process/fcPayment/getPaymentTaskUser',
  GET_NPV_PAYMENT_HISTORY: 'fcPaymentHistoryWise/getProcessHis',
  GET_NPV_PAYMENT_HISTORY_WISE_WITH_APPLICATION_LIST:
    'fcPaymentHistoryWise/getHistoryWiseList',
  GET_NPV_PAYMENT_PROCESS_STEP_ACTION:
    'fcPayment/getProcessStepActionWithApplications',
  SAVE_NPV_CHALLAN_DETAILS: 'campav2Demand/saveCampav2Demand',
  SAVE_NPV_APPROVAL_DETAILS: 'campav2-eds-service/saveEds',
  GET_NPV_APPROVAL_DETAILS: 'campav2-eds-service/getEds',

  SAVE_CAMPA_PAYMENT: 'campaPayment/save',
  GET_CAMPA_PAYMENT: 'campaPayment/get',
  GET_CLIENT_CODE: 'clientCode/get',
  SAVE_OTHER_DETAILS: 'campaPayment/saveOtherDetails',
  SAVE_OTHER_CHARGES: 'campaPayment/saveOtherCharges',

  DELETE_OTHER_CHARGES: 'campaPayment/deleteOtherCharges',
  INSERT_CHALLAN: 'challan/insert',
  SAVE_FC_CHALLAN_DETAILS: 'fcChallanDetails/saveFcChallanDetails',
  GET_FC_CHALLAN_DETAILS: 'fcChallanDetails/getFcChallanDetails',

  //NPV PP Payment

  SAVE_NPV_PROJECT_PROPONENT_DETAILS:
    'fcCampaV2PaymentCompletionDetails/createFcCampaV2PaymentCompletionDetails',
  GET_NPV_PROJECT_PROPONENT_DETAILS:
    'fcCampaV2PaymentCompletionDetails/getFcCampaV2PaymentCompletionDetails',
  GET_NPV_PP_DETAILS:
    'fcCampaV2PaymentCompletionDetails/getFcCampaV2PaymentCompletionDetails',
  SAVE_NPV_PROJECT_PROPOSAL_DETAILS:
    'FcCampaV2PaymentProposalDetails/createFcCampaV2PaymentProposalDetails',
  SAVE_NPV_TRANSACTION_DETAILS:
    'fcCampaV2TransactionDetails/createFcCampaV2TransactionDetails',
  NPV_PUSH_TRANSACTION_DETAILS: 'CampaV2Status/pushTransactionDetails',

  //  Payment Completion
  SAVE_PROJECT_PROPONENT_DETAILS:
    'campaPayment/completion/save/fcCampaPaymentCompletion',
  GET_PROJECT_PROPONENT_DETAILS:
    'campaPayment/completion/get/fcCampaPaymentCompletion',
  SAVE_PROJECT_PROPOSAL_DETAILS:
    'campaPayment/completion/save/fcCampaPaymentProposalDetails',
  SAVE_AMMOUNT_PAID_DETAILS:
    'campaPayment/completion/save/fcCampaPaymentTransactionDetails',
  DELETE_AMMOUNT_PAID_DETAILS:
    'campaPayment/completion/delete/fcCampaPaymentTransactionDetails',
  SAVE_BANK_DRAFT_DETAILS:
    'campaPayment/completion/save/fcCampaPaymentBankDetails',
  GET_APPLICATION_CERTIFICATE: 'applicationCertificate/get',
  PUSH_TRANSACTION_DETAILS: 'CampaStatus/pushTrasactionDetails',
  GET_LATEST_DEMAND: 'campaPayment/getLatestDemand',
  CHECK_DEMAND_STATUS: 'campaPayment/completion/checkDemandStatus',
  GET_APPLICATION_DATE: 'proponentApplicant/getApplicationDate',
  UPDATE_PROPONENT_CERTIFICATE_URL: 'proponentApplicant/updateCertificateURL',
  GET_CORRIGENDUM_DETAILS_BY_PROPOSAL_NO:
    'fcCorrigendumCertificate/getCorrigendumByProposalNo',
  UPDATE_FC_CORRIGENDUM_CERTIFICATE_URL:
    'fcCorrigendumCertificate/updateCertificateUrl',
  CHECK_TRANSACTION_STATUS: 'campaPayment/completion/checkTransactionExist',

  // FC PART 3

  SAVE_PROJECT_DETAILS: 'fc/forma/partc/saveBasicDetails',
  GET_PROJECT_DETAILS: 'fc/forma/partc/getBasicDetails',
  FC_PART_3_SAVE_PROJECT_DETAILS: 'fc/forma/partc/saveBasicDetails',
  FC_PART_3_GET_PROJECT_DETAILS: 'fc/forma/partc/getBasicDetails',
  FC_PART_3_SAVE_CHECKLIST_DETAILS: 'fc/forma/partc/saveCheckListData',
  SAVE_CERTIFICATE_URL: 'pdf/certificate',

  //  Fc Report For Legacy Legacy

  GET_FC_FORM_A_PART_3_LEGACY:
    'formAPartIIINodalOfficerController/formAPartIIINodalOfficer',
  GET_FC_FORM_C_PART_3_LEGACY:
    'formCPartIIINodalOfficer/formCPartIIINodalOfficer',
  GET_FC_FORM_B_PART_3_LEGACY:
    'formBPartIIINodalOfficerController/formBPartIIINodalOfficer',
  GET_FC_FORM_D_PART_3_LEGACY:
    'formDPartIIINodalOfficer/formDPartIIINodalOfficer',

  GET_FC_FORM_A_PART_4_LEGACY:
    'formAPartIVStateGovtController/formAPartIVStateGovt',
  GET_FC_FORM_B_PART_4_LEGACY:
    'formBPartIVStateGovtController/formBPartIVStateGovt',
  GET_FC_FORM_C_PART_4_LEGACY:
    'formCPartIVStateGovtController/formCPartIVStateGovt',
  GET_FC_FORM_D_PART_4_LEGACY:
    'ormDPartIVStateGovtController/formDPartIVStateGovt',
  // FC-D PART 3
  FC_D_PART_3_SAVE_CHECKLIST_DETAILS: 'fcFormD/PartIII/saveCheckListDetails',
  FC_D_PART_3_SAVE_PROJECT_DETAILS: 'fcFormD/PartIII/saveProjectDetails',
  FC_D_PART_3_GET_DETAILS: 'fcFormD/PartIII/get',

  // FC-D PART 4
  FC_D_PART_4_SAVE_BASIC_DETAILS: 'fc/formd/part4/saveBasicDetails',
  FC_D_PART_4_GET_BASIC_DETAILS: 'fc/formd/part4/getBasicDetails',

  // FC PART 4
  SAVE_PART_FOUR_DETAILS: 'fc/forma/partd/saveBasicDetails',
  GET_PART_FOUR_DETAILS: 'fc/forma/partd/getBasicDetails',

  // FC A PART 3
  FC_A_PART_3_SAVE_CHECKLIST_DATA: 'fc/formaNodal/partIII/saveCheckListData',
  FC_A_PART_3_SAVE_PROJECT_DETAILS: 'fc/formaNodal/partIII/saveBasicDetails',
  FC_A_PART_3_GET: 'fc/formaNodal/partIII/getCheckListDetails',

  // FC A PART 4
  FC_A_PART_FOUR_DETAILS: 'fc/formA/partIV/saveBasicDetails',
  FC_A_PART_FOUR_GET: 'fc/formA/partIV/getBasicDetails',

  // FC B PART 3
  FC_B_PART_3_SAVE_CHECKLIST_DATA: 'fcFormB/PartIII/saveCheckListDetails',
  FC_B_PART_3_SAVE_PROJECT_DETAILS: 'fcFormB/PartIII/saveProjectDetails',
  FC_B_PART_3_GET: 'fcFormB/PartIII/get',

  // FC B PART 4
  FC_B_PART_FOUR_DETAILS: 'fc/formB/partIV/saveBasicDetails',
  FC_B_PART_FOUR_GET: 'fc/formB/partIV/getBasicDetails',

  //FC C PART 3
  FC_C_PART_3_SAVE_CHECKLIST_DATA: 'fcFormC/PartIII/saveCheckListDetails',
  FC_C_PART_3_SAVE_PROJECT_DETAILS: 'fcFormC/PartIII/saveProjectDetails',
  FC_C_PART_3_GET: 'fcFormC/PartIII/get',

  // FC C PART 4
  FC_C_PART_FOUR_DETAILS: 'fc/formc/part4/saveBasicDetails',
  FC_C_PART_FOUR_GET: 'fc/formc/part4/getBasicDetails',

  // fc form f part 3
  FC_FORM_F_PART_THREE: 'fcFormFPartIII/saveBasicDetails',
  GET_FC_FORM_F_PART_THREE: 'fcFormFPartIII/getBasicDetails',

  // fc form f part 4
  FC_FORM_F_PART_FOUR: 'fcFormFPartIV/saveBasicDetails',
  GET_FC_FORM_F_PART_FOUR: 'fcFormFPartIV/getBasicDetails',

  GET_HISTORY_VIA_APP_ID: 'generic/getHistoryViaAppID',
  GET_MY_MULTIPLE_EDS_PROPOSAL: 'process/getFcProposalMultiDfo',
  GET_PROPONENT_EDS_PROPOSALS: 'proponentApplicant/edsList',
  WLC_PDF_GENERATION: '/WildLife/get-wlc-Pdf',

  // Scenario
  GET_NODAL_PROPOSAL: 'nodalScenarioDashboard/getProposals',
  GET_NODAL_PROPOSAL_FC: 'removedProposals/getProposalForRemoval',
  GET_SCENARIO_LOGS: 'nodalScenarioDashboard/diversionLogs',
  SAVE_CHANGE_SCENARIO: 'nodalScenarioDashboard/divertScenario',
  GET__NEW_SCENARIO_LIST: 'nodalScenarioDashboard/getScenarioList',
  GET_STATE_AND_PENDENCY: 'generic/getCountPendingProposals',
  GET_SCENARIO_LOGS_BY_PROPOSAL_NO:
    'nodalScenarioDashboard/getDiversionLogsByProposalNo',
  //WORK_PERMISSION_LOGS
  GET_PERMISSION_LOGS: 'fc/workingPermission/getWorkingPermission',
  GET_ALL_PERMISSION_LOGS: 'fc/workingPermission/getAllWorkingPermission',
  SAVE_PERMISSION_LOGS: 'fc/workingPermission/createWorkingPermission',
  DELTE_PERMISSION_LOGS: 'fc/workingPermission/deleteWorkingPermission',
  GET_WORKING_PERMISSON_PROPOSAL_LIST: 'additionalReports/getWorkingPermissionProposals',



  // State Header

  GET_STATE_HEADER: 'fc/stateCertificate/getHeader',
  SAVE_STATE_HEADER: 'fc/stateCertificate/saveHeader',

  GET_DFA_VERSIONS: 'specificCertificate/getDFAVersions',
  GET_DFA_SPECIFIC_CERTIFICATE: 'specificCertificate/getDFAByTypeById',
  GET_PROPOSAL_HISTORY: 'generic/getProcessHis',
  GET_DATA_FOR_AUTO_ADS: 'AutoAds/getProcessDetails',
  SAVE_DATA_FOR_AUTO_ADS: 'AutoAds/saveJson',
  PROCESS_AUTO_ADS: 'AutoAds/hitAddHistory',
  GET_JSON_AUTO_ADS: 'AutoAds/getJsonViaMomId',
  PUBLISH_MOM: 'pdf/publicDocumentStatus',

  // Withdraw Certificate

  GET_WITHDRAW_CERTIFICATE: 'withdrawCertificate/getByProposalNo',
  SAVE_WITHDRAW_CERTIFICATE: 'withdrawCertificate/save',

  //  Recall
  recall_proposal: 'proposal/pull/getProposals',
  recall_proposal_save: 'proposal/pull/save',
  recall_All_proposal: 'proposal/pull/getAll',
  kyc_All: 'ekyc/getAll',
  kyc_Save: 'ekyc/update',
  kyc_Status: 'ekyc/updateStatus',
  SAVE_PROCESS_STEP_PARAMETER_CRZ: 'saveProcessStepParameter/CRZ',

  GET_PROPOSAL_DIVISIONS: 'proponentApplicant/getApplicationDivision',
  GET_PROPOSAL_PROJECTS: 'proponentApplicant/getProposalsOfProjects',
  GET_PROPOSAL_WORKING_PERMISSION: 'fc/workingPermission/getWorkingPermissionByAppId',


  //Fc-recall
  fc_recall_All_logs: 'fcRecallLogs/getLogs',
  fc_recall_proposal: 'fcRecallLogs/getProposalList',
  fc_recall_save: 'fcRecallLogs/saveLogs',
  fc_state_recall_save: 'fc/proposalRecall',

  // For SEIAA

  CHECK_SIEAA_STATUS: 'departmentApplication/checkSeiaaStatus',

  // EC_FORM_2_PRIOR
  SAVE_EC_FORM_2_PRIOR_BASIC_DETAILS: 'ecMiningForm2/save',
  SAVE_EC_FORM_2_ACTIVITY_DETAIL: 'ecMiningForm2/saveProjectActivityDetails',
  SAVE_EC_FORM_2_PRIOR_PROTECTED_AREA_DETAILS:
    'ecMiningForm2/saveProtectedAreaDetails',
  GET_EC_FORM_2_PRIOR_DETAILS: 'ecMiningForm2/get',
  SAVE_EC_FORM_2_CTE_DETAILS: 'ecMiningForm2/saveCteDetails',
  SAVE_EC_FORM_2_AMENDMENT_TRANSFER_DETAILS:
    'ecMiningForm2/saveAmendmentTransferDetails',
  SAVE_EC_FORM_2_PRODUCT_DETAILS: 'ecMiningForm2/saveProductDetail',
  SAVE_EC_FORM_2_PRODUCT_TRANSPORT: 'ecMiningForm2/saveProdTransportDetails',
  SAVE_EC_FORM_2_CONSTRUCTION_DETAILS: 'ecMiningForm2/saveConstructionDetails',
  SAVE_EC_FORM_2_RESOURCE_DETAILS: 'ecMiningForm2/saveResourceDetails',
  SAVE_EC_FORM_2_ENCLOSURES: 'ecMiningForm2/saveEnclosureDetails',
  SAVE_EC_FORM_2_AIR_POLLUTION_MITIGATION:
    'ecMiningForm2/saveAirPollutionMitigationDetails',
  SAVE_EC_FORM_2_POLLUTION_DETAILS: 'ecMiningForm2/savePollutionDetails',
  SAVE_EC_FORM_2_WATER_DETAILS: 'ecMiningForm2/saveEcWaterDetails',
  SAVE_EC_FORM_2_GREEN_BELT: 'ecMiningForm2/saveGreenBeltDetails',
  SAVE_EC_FORM_2_WASTE_DETAILS: 'ecMiningForm2/saveWasteDetails',
  SAVE_EC_FORM_2_WASTE_PRODUCTION: 'ecMiningForm2/saveEcWasteProduction',
  SAVE_EC_FORM_2_CONSULTANT_DETAILS: 'ecMiningForm2/saveConsultantDetails',
  SAVE_EC_FORM_2_OTHER_DETAILS: 'ecMiningForm2/saveOtherDetails',
  SAVE_EC_FORM_2_AIR_QUALITY_IMPACT: 'ecMiningForm2/saveAirQualityImpacts',
  SAVE_EC_FORM_2_SUMMARY_ALLOCATION: 'ecMiningForm2/saveSummaryAllocations',
  SAVE_EC_FORM_2_MINING_PROPOSAL: 'ecMiningForm2/saveEcMiningProposals',
  SAVE_EC_FORM_2_UNDERTAKING: 'ecMiningForm2/saveUndertaking',
  SAVE_EC_FORM_2_PHYSICAL_CHANGES: 'ecMiningForm2/saveEcPhysicalChanges',
  SAVE_EC_FORM_2_CURRENT_LAND_USE: 'ecMiningForm2/saveCurrentLandUse',
  SAVE_EC_FORM_2_PROPOSED_LAND_USE: 'ecMiningForm2/saveProposedLandUse',
  SAVE_EC_FORM_2_PROPOSED_LAND_EXPANSION_USE:
    'ecMiningForm2/saveProposedLandUseExpansion',
  SAVE_EC_FORM_2_PRIOR_AREA_DETAIL:
    'ecMiningForm2/saveProposedLandUseExpansion',
  SAVE_EC_FORM_2_PRIOR_PROJECT_AREA_DETAIL:
    'ecMiningForm2/saveProjectAreaDetails',
  DELETE_EC_FORM_2_RESOURCE_DETAILS: 'ecMiningForm2/deleteResourceDetails',
  DELETE_EC_FORM_2_PRODUCT_TRANSPORT:
    'ecMiningForm2/deleteProdTransportDetails',
  DELETE_EC_FORM_2_CURRENT_LAND_USE: 'ecMiningForm2/deleteCurrentLandUse',
  DELETE_EC_FORM_2_PROPOSED_LAND_USE: 'ecMiningForm2/deleteProposedLandUse',
  DELETE_EC_FORM_2_PROPOSED_LAND_EXPANSION_USE:
    'ecMiningForm2/deleteProposedLandUseExpansion',
  DELETE_EC_FORM_2_AIR_POLLUTION_MITIGATION:
    'ecMiningForm2/deleteAirPollutionMitigationDetails',
  DELETE_EC_FORM_2_WASTE_DETAILS: 'ecMiningForm2/deleteWasteDetails',
  DELETE_EC_FORM_2_MINING_MINERALS_MINED:
    'ecMiningForm2/deleteMiningMineralsMined',
  DELETE_EC_FORM_2_MINING_MINERALS_RESERVES:
    'ecMiningForm2/deleteMiningMineralsReserves',
  DELETE_EC_FORM_2_MINING_PRODUCTION_DETAIL:
    'ecMiningForm2/deleteMiningproductionDetails',
  DELETE_EC_FORM_2_AIR_QUALITY_IMPACT: 'ecMiningForm2/deleteAirQualityImpact',
  DELETE_EC_FORM_2_SUMMARY_ALLOCATION: 'ecMiningForm2/deleteSummaryAllocation',
  UPDATE_FC_AGENDA_MOM_SIGNED_DOCUMENT: 'facMomConsent/uploadEsignFile',
  UPDATE_PP_APPLICATION_DOCUMENT: 'proponentApplicant/uploadEsignFile',
  //set esignedDocumentPath


  // DEIAA
  SAVE_EC_FORM_DEIAA: 'ecSeiaaForm/saveDeiaaProposalTransferForm',
  GET_EC_FORM_DEIAA: 'ecSeiaaForm/getDeiaaProposalTransferForm',
  // SEIAA
  SAVE_EC_FORM_SEIAA: 'ecSeiaaForm/saveEcSeiaaProjectCreationForm',
  GET_EC_FORM_SEIAA: 'ecSeiaaForm/getEcSeiaaProjectCreationForm',
  //FC FORM G
  SAVE_CHAPTER_WISE: 'fcFormG/saveChapterwiseDetails',
  SAVE_ANNEXURE: 'fcFormG/saveAnnexureDetails',
  DELETE_CHAPTER_WISE: 'fcFormG/deleteChapterwiseDetails',
  DELETE_ANNEXURE: 'fcFormG/deleteAnnexureDetails',
  SAVE_PARTICULAR_TAGET: 'fcFormG/saveTargetDetails',
  GET_FORMS_G_STEP_ONE_DETAILS: 'fcFormG/getForm',
  SAVE_FORMS_G_STEP_ONE_DETAILS: 'fcFormG/save',
  GET_FOREST_DIVISION: 'parivesh/division/getAllDivisionByStateId',
  SAVE_GAP_PERIOD: 'fcFormG/saveGapDetails',
  SAVE_FORMS_G_STEP_TWO_DETAILS: 'fcFormG/saveOtherDetails',
  SAVE_FLAURA_DETAILS: 'fcFormG/saveFlauraDetails',
  SAVE_COMPLIANCE_DETAILS: 'fcFormG/saveComplianceDetails',
  SAVE_MAIN_WORKING_DETAILS: 'fcFormG/saveMainWorkingCirclePlanDetails',
  SAVE_OVERLAPPING_WORKING_DETAILS:
    'fcFormG/saveOverlappingWorkingCirclePlanDetails',
  GET_WORKORDER_DETAILS: 'fcFormG/getFormGDetailsByUserId',
  SAVE_EXOTIC_SPECIES: 'parivesh/wlpas/saveform1',
  ESIGN_EXOTIC_SPECIES: 'esign/esignform1exotic',
  ESIGN_EXOTIC_FORM1_PDF: 'parivesh/wlpas/getform1download',
  EXOTIC_FORM_3: 'parivesh/wlpas/getform3download',
  EXOTIC_HEARING: 'parivesh/wlpa/get-application-hearing',
  //GET_EXOTIC_DASHBOARD_DETAILS: 'parivesh/wlpas/getForm1ByStatus',
  GET_EXOTIC_DASHBOARD_DETAILS: 'parivesh/wlpas/wlpa-dashboard-record',
  Get_EXOTIC_PREVIEW: 'parivesh/wlpas/getform1',
  // CERTIFICATE COPY EMAIL
  GET_COPY_EMAIL: 'copyto/getData',
  COMPLIANCE_REPORT_6MONTHLY: 'compliance/data/getPublicDataByProposal',
  SAVE_APPLICANT_DETAILS: 'api/applicant/saveAppliacantDetails',
  GET_APPLICANT_DETAILS: 'api/applicant/getApplicant',
  SAVE_APPLICANT_EDUCATION_DETAILS:
    'api/applicant/saveApplicantEducationalQualifications',
  GET_APPLICANT_EDUCATION_DETAILS:
    'api/applicant/getApplicantEducationalQualificationsByApplicantId',
  SAVE_APPLICANT_PERSONAL_RECORD: 'api/applicant/saveApplicantPersonalRecord',
  GET_APPLICANT_PERSONAL_RECORD:
    'api/applicant/getApplicantPersonalRecordsByApplicantId',
  SAVE_APPLICANT_EMPLOYMENT_RECORD:
    'api/applicant/saveApplicantEmploymentRecord',
  GET_APPLICANT_EMPLOYMENT_RECORD:
    'api/applicant/getApplicantEmploymentRecordsByApplicantId',
  ADD_DOCUMENT_DETAILS: 'api/applicant/addDocumentDetails',
  GET_DOCUMENT_DETAILS: 'api/applicant/getAllDocumentDetails',
  GET_SINGLE_DOCUMENT_DETAILS: 'api/applicant/getDocumentDetails',
  VALIDATE_PHONE: 'api/applicant/validatePhone',
  REGISTER_APPLICANT: 'api/applicant/registerApplicant',
  SEND_OTP: 'api/applicant/sendOtp',
  VALIDATE_OTP: 'api/applicant/validateOtp',
  SAVE_APPLICANT_DETAILS_REGISTER: 'api/applicant/saveAppliacantDetails',
  GET_ALL_APPLICANTS_BY_PHONE: 'api/applicant/getAllApplicantsByPhone',
  GET_ALL_APPLICANTS_BY_PHONE_V2: 'api/applicant/getAllApplicantsByPhoneV2',
  FINAL_SUBMIT_NGT: 'api/applicant/finalSubmit',
  GET_ALL_NOTIFICATIONS_NGT: 'api/applicant/getAllNotifications',
  DELETE_APPLICANT_PERSONAL_RECORDS:
    'api/applicant/deleteApplicantPersonalRecordsByAPRId',
  DELETE_APPLICANT_EMPLOYMENT_RECORDS:
    'api/applicant/deleteApplicantEmploymentRecordsByAERId',
  DELETE_APPLICANT_EDUCATIONAL_RECORDS:
    'api/applicant/deleteApplicantEducationalQualificationsByAEQId',
  GET_APPLICANT_PERSONAL_RECORDS:
    'api/applicant/getApplicantPersonalRecordsByAPRId',
  GET_APPLICANT_EMPLOYMENT_RECORDS:
    'api/applicant/getApplicantEmploymentRecordsByAERId',
  GET_APPLICANT_EDUCATIONAL_RECORDS:
    'api/applicant/getApplicantEducationalQualificationsByAEQId',
  UPDATE_OFFER_ACCEPTED: 'api/applicant/updateOfferAccepted',
  GENERATE_PDF: 'api/processing/generatePdf',
  //Legal Associate Details Start
  SAVE_BSIC_DETAILS: 'api/legal-associate/saveBasicDetails',
  UPDATE_BSIC_DETAILS: 'api/legal-associate/updateBasicDetails',
  SAVE_EDUCATION_DETAILS: 'api/degree-details',
  GET_EDUCATION_DETAILS: 'api/degree-details',
  SAVE_EDUCATIONAL_DETAILS: 'api/education-details/saveEducationDetails',
  UPDATE_EDUCATIONAL_DETAILS: 'api/education-details',
  UPDATE_EXPERIENCE_DETAILS: 'api/experience-details',
  UPDATE_ELIGIBILITY_DETAILS: 'api/eligibility-criteria-details',
  UPDATE_UNDERTAKING_DETAILS: 'api/undertaking',

  SAVE_GOV_EXP_DETAILS: 'api/govt-organization-experience',
  SAVE_PVT_EXP_DETAILS: 'api/private-organization-experience',
  SAVE_EXPERIENCE_DETAILS: 'api/experience-details',
  SAVE_ELIGIBILTIY_DETAILS: 'api/certificate-details',
  SAVE_ELIGIBILITY_DETAILS: 'api/eligibility-criteria-details',
  SAVE_DOCUMENT_DETAILS: 'api/legal-associate/documents/upload',
  SAVE_UNDERTAKING_DETAILS: 'api/undertaking',
  GET_FORM_DETAILS: 'api/legal-associate/all-details',
  GET_APPLICANT_STATUS: 'api/applicant/getApplicantInfoWithStatus',

  //Legal Associate Details End

  //ACA form
  ACA_SAVE_UNDERTAKING: 'aca/saveUndertaking',
  CREATE_BASIC_PROJECT_DETAIL: 'aca/saveBasicDetails',
  CREATE_BASIC_SITE_DETAIL: 'aca/saveOtherDetails',
  GET_FORM_DETAIL_BY_ID: 'aca/getForm',
  GET_ACA_KML_INTERSECTION_DETAIL: 'api/GetPlantationSiteData',
  GET_ACA_LIST: 'aca/getDetailsByUserId',
  GET_DIVISION: 'aca//saveDivisionDetails',
  GET_DELETE_DIVISION: 'aca//deleteDivisionDetails',
  //ACA Sir Form
  CREATE_BASIC_GENERAL_DETAIL: 'fcAcaSirForm/saveBasicDetails',
  CREATE_BASIC_OTHER_DETAIL: 'fcAcaSirForm/saveOtherDetails',
  GET_FORM_DETAIL: 'fcAcaSirForm/getForm',
  GET_SPECIES_DETAIL: 'fcAcaSirForm/getDistinctSpeciesName',
  GET_SAVE_SPECIES: 'fcAcaSirForm/saveSpeciesDetails',
  GET_DELETE_SPECIES: 'fcAcaSirForm/deleteSpeciesDetails',
  GET_SAVE_ACTIVITY: 'fcAcaSirForm/saveActivityDetails',
  GET_DELETE_ACTIVITY: 'fcAcaSirForm/deleteActivityDetails',
  GET_SAVE_IMAGE: 'fcAcaSirForm/saveImageDetails',
  GET_DELETE_IMAGE: 'fcAcaSirForm/deleteImageDetails',
  GET_SAVE_INVASIVE: 'fcAcaSirForm/saveInvasiveSpeciesDetails',
  GET_DELETE_INVASIVE: 'fcAcaSirForm/deleteInvasiveSpeciesDetails',

  // FC-COMPLIANCE-AUTHORITY
  GET_FC_COMPLIANCE_DATA: 'authority/fc/getFcComplianceData',
  GET_FC_COMPLIANCE_VIEW_DATA: 'authority/fc/viewFcComplianceDataDetails',
  GET_RECIPIENT_DATA: 'authority/fc/getStateFlowToUser',
  GET_EDS_HISTORY_DATA: 'authority/fc/getFcUserActivityDetail',
  GET_FC_EDS_HISTORY_DATA: 'edsRaised/getAllEdsRaised',
  GET_FC_COMPLIANCE_PRODUCTION_DATA: 'data/fc/getProductionCapacityList',
  GET_FC_COMPLIANCE_CONDITION_DATA:
    'data/fc/v1/getConditionsByComplianceReportId',
  SAVE_FC_COMPLIANCE_CONDITION_DATA: 'data/fc/saveSelfCompliance',
  SAVE_FC_NOTING_DATA: 'authority/fc/saveFcUserNoting',
  SAVE_FC_USER_ACTIVITY: 'authority/fc/saveFcUserActivity',
  SAVE_FC_USER_EDS_RAISED: 'edsRaised/saveEdsRaised',
  ADD_COMPLIANCE_UPLOAD_DATA: '/user/addDocumentDetails',
  GET_NOTING_DATA: '/authority/fc/viewFcMessageNotingDetails',
  SAVE_FC_IRO_REMARK: 'authority/fc/updateTORemark',
  SAVE_FC_MONITORING_REMARK: 'authority/fc/saveFcMonitoringObservation',
  GET_FC_EDS_DATA: 'authority/fc/getFcUserActivity',
  EDQ_QUERIES: 'EdsProposalsEntry/getProposalEntry',
  GET_COMPLIANCE_HISTORY: 'authority/fc/getFcComplianceHistory',
  GET_DOCUMENT_DETAIL: 'user/getDocumentDetails',
  SAVE_FC_INSPECTION_REPORT: 'authority/fc/saveFcSiteInspectionDetail',
  GET_FC_INSPECTION_REPORT: 'authority/fc/getFcSiteInspectionDetail',
  GET_FC_ROLE_TYPE: 'authority/fc/getFcFlowTypeByRole',
  SAVE_FC_EDS_REPLY_ROLE: 'edsRaised/updateEdsReply',
  GET_FC_STATE_IRO_REPORT: 'authority/fc/getFcMonitoringObservation',

  //Meeting-Notice
  MEETING_NOTICE_CREATEMEETINGNOTICE: 'cms/meeting-notice/createMeetingNotice',
  MEETING_NOTICE_MEETINGNOTICEBYID: 'cms/meeting-notice/getMeetingNoticeById',
  MEETING_NOTICE_MEETINGNOTICEBYUSERID:
    'cms/meeting-notice/getMeetingNoticeByUserId',
  MEETING_NOTICE_MEETINGNOTICEDELETE:
    'cms/meeting-notice/deleteMeetingNoticeById',
  MEETING_NOTICE_GETALLMEETING: 'cms/meeting-notice/getAllMeetingNotice',
  MEETING_NOTICE_STATUS: 'cms/meeting-notice/getMeetingNoticeByStatus',

  // GEAC Form 1

  GEAC_FORM_1_SAVE_APLICATION_DETAILS: 'geac/saveGeacForm1ApplicationDetails',
  GEAC_FORM_1_GET_APLICATION_DETAILS: 'geac/getGeacForm1',
  GEAC_FORM_1_SAVE_IBSC_DETAILS: 'geac/saveSpeciesDetail',
  GEAC_FORM_1_SAVE_SPECIES_DETAILS: 'geac/saveSpeciesDetail',
  GEAC_FORM_1_SAVE_GENETIC_FEATURES: 'geac/saveGeacGeneticFeatures',
  GEAC_FORM_1_SAVE_GENETIC_DETAILS: 'geac/saveGeneticDetail',
  GEAC_FORM_1_DELETE_GENETIC_FEATURES: 'geac/deleteGeacGeneticFeatures',

  // product
  GEAC_FORM_1_SAVE_PRODUCT_FEATURES: 'geac/saveGeacProteinProduct',
  GEAC_FORM_1_DELETE_PRODUCT_FEATURES: 'geac/deleteGeacProteinProduct',
  GEAC_FORM_1_SAVE_PRODUCT_DETAILS: 'geac/saveProductDetail',

  GEAC_FORM_1_SAVE_TRIAL_DETAILS: 'geac/saveTrialDetails',
  GEAC_FORM_1_SAVE_PROTOCOL_DETAILS: 'geac/saveProtocolDetails',
  GEAC_FORM_1_SAVE_UNDERTAKING_DETAILS: 'geac/saveUndertaking',

  //--------- GEAC Form 2A ------
  //-- GET API for All 2A---
  GEAC_FORM_2A_GET_DETAILS: 'geac/form2a/getGeacForm2A',
  // 2. Save Basic Details
  GEAC_FORM_2A_SAVE_BASIC_DETAILS: 'geac/form2a/saveGeacForm2ABasicDetails',
  // 3.  Save Production Details
  GEAC_FORM_2A_SAVE_PRODUCTION_DETAILS: 'geac/form2a/saveProductionDetail',
  // 4. Save Quality Details
  GEAC_FORM_2A_SAVE_QUALITY_DETAILS: 'geac/form2a/saveQualityDetail',
  // 5. Save Trial Details
  GEAC_FORM_2A_SAVE_TRIAL_DETAILS: 'geac/form2a/saveTrialDetails',
  // 5. Save Undertaking
  GEAC_FORM_2A_SAVE_UNDERTAKING: 'geac/form2a/saveUndertaking',

  //--------- GEAC Form 2B ------
  //-- GET API for All 2B---
  GEAC_FORM_2B_GET_DETAILS: 'geac/form2b/getGeacForm2B',
  // 2. Save Basic Details
  GEAC_FORM_2B_SAVE_BASIC_DETAILS: 'geac/form2b/saveGeacForm2BBasicDetails',
  // 3.  Save Production Details
  GEAC_FORM_2B_SAVE_PRODUCT_DETAILS: 'geac/form2b/saveProductDetail',
  // 5. Save Undertaking
  GEAC_FORM_2B_SAVE_UNDERTAKING: 'geac/form2b/saveUndertaking',

  //--------- GEAC Form 3 ------
  //-- GET API for All 3---
  GEAC_FORM_3_GET_DETAILS: 'geac/form3/getGeacForm3',
  // 2. Save Basic Details
  GEAC_FORM_3_SAVE_BASIC_DETAILS: 'geac/form3/saveGeacForm3BasicDetails',
  // 3.  Save Production Details
  GEAC_FORM_3_SAVE_PRODUCT_DETAILS: 'geac/form3/saveProductDetail',
  // 5. Save Undertaking
  GEAC_FORM_3_SAVE_UNDERTAKING: 'geac/form3/saveUndertaking',


  GET_COAL_REGISTRATION_DATA: 'api/coalRegistration/fetch',

  GET_GRANTED_PROPOSALS_PP: 'spcb/getGrantedProposalsForPp',
  SENT_TO_SPCB: 'spcb/sentToSPCB',
  GET_PROPOSALS_ON_SPCB: 'spcb/getOnSpcb',
  SAVE_DEMAND_NOTE: 'spcb/savePaymentDetails',
  GET_DEMANS_NOTE: 'spcb/getPaymentDetails',
  SAVE_CHARGE_TYPES: 'spcb/saveChargeDetails',
  DELETE_CHARGE_TYPES: 'spcb/deleteChargeDetails',
  SAVE_ACCOUNT_DETAILS: 'spcb/saveAccountDetails',
  DELETE_ACCOUNT_DETAILS: 'spcb/deleteAccountDetails',
  GET_PAYMENT_DETAILS_BY_SPCB_ID: 'spcb/getPaymentDetailsBySpcbId',
  GET_PROPOSALS_FOR_PAYMENT: 'spcb/getOnPP',
  SAVE_PAYMENT: 'spcb/savePropPayment',
  SAVE_PAYMENT_RECEIPT: 'spcb/savePaymentReceipts',
  DELETE_PAYMENT_RECEIPTS: 'spcb/deletePaymentReceipts',
  SAVE_SPCB_COMMENT: 'spcb/saveSpcbComments',
  GET_PROPOSALS_FOR_SPCB_COMMENT: 'spcb/getPaymentProcessedProposals',
  VERIFY_PAYMENT: 'spcb/verifyPayment'

};

export const AI_APIS = {
  SAVE_TO_AI: 'ai',
  SAVE_TO_PARIVESH_AI: 'EdsProposalsEntry',
};

export const USER_DISTRIBUTION = {
  USER_LIST_DISTRIBUTION: 'ecuserdistribution/getUsers',
  GET_DISTRIBUTION: 'ecuserdistribution/get',
  ADD_DISTRIBUTION: 'ecuserdistribution/add',
  DELETE_DISTRIBUTION: 'ecuserdistribution/delete',
};

export const API_AGENDA = {
  SAVE_DATE_TIME_OF_MEETING: 'saveDateTimeofMeetingList',
  GET_DATE_TIME_OF_MEETING: 'getDateTimeofMeetingList',
  SAVE_AGENDA: 'saveAgenda',
  DELETE_AGENDA: 'deleteAgenda',
  GET_AGENDA: 'getAgenda',
  SAVE_AGENDA_REMARKS: 'saveAgendaRemarks',
  GET_AGENDA_REMARKS: 'getAgendaRemarks',
  SAVE_AGENDA_PROPOSAL: 'saveAgendaProposal',
  DELETE_AGENDA_PROPOSAL_DOCUMENT: 'deleteAgendaProposalDocument',
  SAVE_AGENDA_PROPOSAL_LIST: 'saveAgendaProposalList',
  UPDATE_AGENDA_PROPOSAL_LIST: 'updateAgendaProposalList',
  GET_AGENDA_PROPOSAL: 'getAgendaProposal',
  GET_AGENDA_PROPOSAL_LIST: 'getAgendaProposalList',
  SAVE_AGENDA_MEETING_DETAILS: 'saveAgendaMeetingDetails',
  GET_AGENDA_MEETING_DETAILS_List: 'getAgendaMeetingDetailsList',
  GET_PROPOSAL_FOR_AGENDA: 'getProposalsForAgenda',
  GET_PROPOSAL_FOR_AGENDA_CRZ: 'crz/getProposalsForAgenda',
  GET_DRAFT_PROPOSAL_FOR_AGENDA_BY_ABBR: 'getAgendaProposalListByAbbr',
  GET_AGENDA_SUMMARY: 'EdsProposalsEntry/getProposalsEntry',
  GET_SUBMITTED_PROPOSAL_FOR_AGENDA_BY_ABBR:
    'getSubmittedAgendaProposalListByAbbr',
  GET_PROCESS_HISTORY: 'getAgendaHistory',
  GET_PROPOSAL_FOR_AGENDA_LIST: 'getAgendaProposalList',

  GET_ALL_COMMITTEE_TYPES: 'CommitteeType/getAllCommitteeType',
  GET_COMMITTEE_BY_ABBR: 'CommitteeType/getCommitteeTypeByAbbr',

  GET_PSC_MEMBERS: 'userprofile/userListByRoleAndState',
  GET_MEMBERS: 'CommitteeType/getEacMember',
  GET_SEIAA_SEAC_MEMBER: 'CommitteeType/getSeacMember',
  GET_MEMBERS_CRZ: 'CommitteeType/getSczmaMember',
  GET_MEMBERS_CRZ_MOEFCC: 'CommitteeType/crz/getEacMember',

  SAVE_MEETING_DETAIL: 'saveAgendaMeetingDetails',
  UPDATE_MEETING_DETAIL: 'updateAgendaMeetingDetailsList',
  DELETE_MEETING_DETAIL: 'deleteAgendaMeetingDetails',
  SAVE_REORDERING_REQUEST: 'saveReorderingRequestList',
  GET_REORDERING_REQUEST: 'getReorderingRequest',
  GET_OFFICE_HIERARCHY: 'getOfficeHierarchy',
  GET_FC_OFFICE_HIERARCHY: 'getFcAgendaOfficeHierarchy',
  GET_FC_NPV_OFFICE_HIERARCHY: 'process/fcPayment/getFcPaymentOfficeHierarchy',
  AGENDA_HISTORY: 'dashboard/agendaHistory',
  SAVE_STEP_PARAMETER: 'saveAgendaProcessStepParameter',
  GET_STEP_PARAMETER: 'getAgendaParameter',
  UPDATE_REORDERING: 'updateReordering',
  GET_REORDERING_PROPOSALS: 'getReorderedProposalByAgenda',
  GET_DATE_TIME: 'getDateTimeofMeetingList',
  AGENDA_PDF: 'pdf/agendaPdf',
  AGENDA_CRZ_PDF: 'pdf/crz/agendaPdf',
  MOM_PDF: 'pdf/momPdf',
  MOM_PDF_CRZ: 'pdf/crz/momPdf',
  MOM_PROPOSAL_PDF: 'pdf/momProposalPdf',
  DISCARD_AGENDA: 'discardAgenda',
  GET_AGENDA_BY_PROPOSAL: 'dashboard/getAgendaMomByProposal',
  GET_FC_AGENDA_BY_PROPOSAL: 'agenda/getAgendaDetails',
  GET_FC_MOM_BY_PROPOSAL: 'MoM/getMomDetails',
  SAVE_ANY_OTHER_PROPOSAL: 'saveOtherProposal',
  DELETE_AGENDA_PROPOSAL: 'deleteAgendaProposal',
  DELETE_REORDERING_PROPOSAL: 'deleteReorderingProposal',
  GET_OTHER_PROPOSALS: 'getAllOtherProposals',
  GET_OTHER_PROPOSAL: 'getOtherProposal',
  GET_AGENDA_SECTOR: 'user/getSelectedSector',
  NOTIFY_USER: 'notifyUser',
  UPDATE_MOM_PDF_URL: 'pdf/momPdfUpdate',
  AGENDA_MOM_CONSENT_DETAILS: 'facMomConsent/getMomConsentDetails',

  // APPROVAL
  GET_APPROVAL: 'approvalForm/get',
  SAVE_APPROVAL: 'approvalForm/save',
  DELETE_APPROVAL: 'approvalForm/delete',
  // For MOM

  GET_RECOMMENDATION_DATA: 'CommitteeType/getMomStatusByAbbr',
  SAVE_MOM_CREATION: 'mom/saveMomCreation',
  SAVE_MOM_REMARKS: 'mom/saveMomRemarks',
  GET_MOM_CREATION: 'mom/getMomCreation',
  SAVE_PROJECT_DETAILS: 'mom/saveProjectDetails',
  SAVE_PROJECT_DESCRIPTION: 'mom/saveProjectDescription',
  SAVE_MOM_RECOMMENDATION: 'mom/saveMomRecommendation',
  GET_PROJECT_DETAILS: 'mom/getProjectDetails',
  GET_PROJECT_DESCRIPTION: 'mom/getProjectDescription',
  GET_MOM_RECOMMENATION: 'mom/getMomRecommendation',
  GET_MOM_REMARKS: 'mom/getMomRemarks',
  SAVE_DELIBERATE_DETAILS: 'mom/deliberate',
  GET_PENDING_AGENDA_LIST: 'mom/getPendingAgenda',
  GET_PREVIOUS_DELIBERATIONS: 'mom/getPreviousDeliberations',
  GET_CREATED_MOM_LIST: 'mom/getMomByStatus',
  GET_EAC_MEMBER_LIST: 'getAgendaMeetingDetailsList',
  SAVE_AGENDA_MEETING_DETAIL: 'saveAgendaMeetingDetails',

  ADD_PROCESS_HISTROY: 'process/agenda/addHistory',
  GET_EC_CONDITION_LIST: 'agendaProposalCondition/get',
  GET_EC_CONDITION_SEQUENCE: 'agendaProposalCondition/getSequence',
  SAVE_EC_CONDITION_SEQUENCE: 'agendaProposalCondition/saveSequence',
  GET_EC_CONDITION_LIST_BY_PROPOSAL_ID_V2:
    'agendaProposalCondition/v2/getConditionByApplicationId',
  GET_FC_ALL_CONDITION_LIST: 'conditionMaster/getAllFCCondition',
  GET_EC_CONDITION_LIST_BY_PROPOSAL_ID:
    'agendaProposalCondition/getConditionByApplicationId',
  MASTER_EC_CONDITION_LIST: 'agendaProposalCondition/copyMasterCondition',
  SAVE_EC_CONDITION_LIST: 'agendaProposalCondition/save',
  DELETE_EC_CONDITION_LIST: 'agendaProposalCondition/delete',
  UNDO_EC_CONDITION_LIST: 'agendaProposalCondition/removeDeleteForRedo',
  REDO_EC_CONDITION_LIST: 'agendaProposalCondition/deleteForRedo',
  GET_MY_AGENDAS: 'dashboard',
  GET_MY_AGENDAS_CRZ: 'crz/dashboard',

  SAVE_REMARKS_DETAILS: 'saveAgendaRemarks',
  GET_REMARKS_DETAILS: 'getAgendaRemarks',
  SAVE_MOM_PROPOSAL_LIST: 'mom/saveProposalList',
  UPDATE_DOCUMENT_BY_ID: 'updateDocumentById',
  UPDATE_APPROVAL_BY_ID: 'pdf/mergeApprovalForm',

  SAVE_PARAMETER_VIA_MOM_ID: 'ParameterController/saveParameterViaMomId',

  // CMS
  GET_AGENDA_LIST: 'getAgendaMomDocumentByCommittee',
  GET_AGENDA_LIST_V2: 'getAgendaMomDocumentByCommitteeV2',
  GET_NOTIFY_AGENDA: 'notifyAgenda',
  GET_AGENDA_LIST_WLC: 'wlcTrackYourProposal/getWlcAgendaMomPublic',

  GET_AGENDA_PROPOSAL_CONDITION: 'agendaProposalCondition/getByProposalNo',

  UPDATE_REFERREDFOR_MEETING: 'updateReferredForMeeting',
  REFERREDFOR_MEETING_SAVE: 'ecReferredForMeetingStatusLogs/save',
  UPDATE_AGENDA_PROPOSAL_INDEX: 'updateAgendaPropoaslIndex',
};

export const AGENDA_MOM_API_FC = {
  GET_APPLICATION_LIST: 'fcAgenda/getApplicationsList',
  GET_AGENDA_PDF_PATH: 'agenda/getAgendaPdfPath',
  UPDATE_FC_AGENDAMOM_SIGNED_DOCUMENT: 'agenda/uploadEsignFile',
  GET_MOM_PDF_PATH: 'mom/getMomPdfPath',
  // GET_PROPOSAL_LIST: 'msdashboard/proposaltask/getfacproposaltasks',
  GET_PROPOSAL_LIST: 'agenda/getFcProposalForAgenda',
  GET_REC_MEMBERS_LIST: 'userprofile/recUserListByOfficeTypeAndRoleAbbervation',
  GET_MEMBERS_LIST: 'userprofile/facUserListByOfficeTypeAbbervation',
  GET_PSC_MEMBERS_LIST: 'userprofile/userListByRoleAndState',
  GET_ROLE_LIST: 'role/getAllRole',
  GET_AGENDA_TASK: 'agenda/getAgendaTask',
  GET_AGENDA_TASK_HISTORY: 'agenda/getFcAgendaTaskHistory',
  GET_AGENDA_BY_COMMITTEE: 'agenda/getAgendaByCommittee',
  SAVE_AGENDA_CHANGE_DATE_TIME: 'agenda/updateAgendaDateTime',
  SAVE_AGENDA: 'agenda/createAgenda',
  SAVE_AGENDA_OTHER_ITEM: 'agenda/saveAgendaOtheritems',
  DELETE_AGENDA_PROPOSAL: 'agenda/deleteAgendaProposal',
  DELETE_AGENDA_OTHER_ITEM: 'agenda/deleteAgendaOtheritems',
  GET_AGENDA: 'agenda/getAgendaById',
  ADD_PROCESS_HISTROY: 'process/agenda/addHistory',
  GET_FORWARDING_DATA: 'process/agenda/getForwadingData',
  SAVE_AGENDA_PROCESS_STEP_PARAMETER: 'saveAgendaProcessStepParameter',
  GET_AGENDA_MOM_PROCESS_STEP_ACTION: 'getProcessStepActionForFcAgendaMom',
  SAVE_AGENDA_HISTORY_WISE: 'process/agenda/saveAgendaHistoryWiseForm',
  GET_AGENDA_HISTORY_WISE_WITH_APPLICATION_LIST:
    'process/agenda/getAgendaHistoryWise',
  AGENDA_NOTESHEET_SAVE: 'notesheet/V2/save',
  AGENDA_NOTESHEET_GET: 'agenda/getFcAgendaNotesheetDetails',
  GET_MY_AGENDAS: 'dashboard',
  SAVE_STEP_PARAMETER: 'saveAgendaProcessStepParameter',
  GET_STEP_PARAMETER: 'getAgendaParameter',
  SAVE_DELIBERATION: 'agenda/saveDelibration',
  GET_DELIBERATION: 'agenda/getDeliberation',
  UPDATE_AGENDA_MEMBER: 'agenda/updateAgendaMemberDetails',
  SAVE_MOM: 'mom/createMom',
  GET_MOM: 'mom/getMomById',
  GET_MOM_DATA: 'mom/getMomData',
  SENT_CONSENT_NOTIFICATION: 'momConsent/addFacMembers',
  SAVE_CONSENT: 'momConsent/saveIndividualConsent',
  GET_CONSENT_BY_MOM_ID: 'momConsent/getMomConsentStatusByMomId',
  GET_PROPOSAL_CONSENT_BY_MOM_ID: 'momConsent/getProposalConsentDetailsByMomId',
  GET_PROPOSAL_CONSENT_BY_USER_ID:
    'momConsent/getProposalConsentDetailsByUserId',
  GET_CONSENT_BY_USER_ID: 'momConsent/getMomConsentStatusByUserId',
};

export const API_EDS_QUERY = {
  UPDATE_EDS_QUERY: 'edsV2/updateQuery',
  GET_EDS_QUERIES: 'edsV2/getEDS',
  GET_QUERIES: 'edsV2/getQueries',
  GET_QUERIES_FROM_HISTORY: 'edsV2/getQueriesById',
  DELETE_EDS_QUERY: 'edsV2/deleteQuery',
  SUBMIT_EDS_QUERIES: 'edsV2/saveEDS',
  EDS_REPORT: 'departmentApplication/fcEdsReport',
};

export const API_E_SIGN = {
  INITIATE: 'sign',
  COMPLETE: 'getEsignData',
  GET_PROJECT_DETAILS: 'mom/getProjectDetails',
  GET_PROJECT_DESCRIPTION: 'mom/getProjectDescrition',
  GET_MOM_REMARKS: 'mom/getMomRemarks',
  GET_APPLICATION_CERTIFICATE: 'applicationCertificate/get',
};

export const API_INTRASTATE = {
  EXECUTE_QUERY: 'intrastate/executeQuery',
  UPDATE_STATUS: 'intrastate/updateStatus',
  FORWARDING_RIGHTS: 'intrastate/forwardingRights',
};

export const API_WITHDRAW = {
  GET_MY_WITHDRAW_PROPOSAL: 'process/withdrawal/dashboard',
  CHECK_OFFICE: 'checkOfficeExist',
  ADD_PROCESS_HISTROY: 'process/withdrawal/addHistory',
  SAVE_PROPOSAL_WITHDRAW: 'proposalWithdrawRequest/save',
  GET_PROPOSAL_WITHDRAW: 'proposalWithdrawRequest/get',
  GET_PROPOSAL_WITHDRAW_DETAILS: 'proposalWithdrawRequest/getByProposalNo',
  GET_PROPONENT_PROPOSAL_LIST: 'proponentApplicant/getProposalList',
  GET_WITHDRAWAL_PROPOSAL_HISTROY: 'process/getWithdrawalProcessHis',
  SAVE_WITHDRAWAL_REMARKS_DETAILS: 'eds/saveWithdrawalRemarks',
  GET_WITHDRAWAL_REMARKS_DETAILS: 'eds/getWithdrawalRemarks',
  PROCESS_WITHDRAW_PROPOSAL: 'process/withdrawProposal',
  PROCESS_WITHDRAW_FINAL_SUBMIT: 'process/withdrawal/finalSubmit',
};

export const API_CHANGESECTOR = {
  GET_MY_SECTOR_PROPOSAL: 'proponentApplicant/getECProposalList',
  SAVE_MY_SECTOR: 'workgroupmaster/sector/transfer/save',
  SECTOR_LOGS: 'workgroupmaster/sector/transfer/getAll',
};

export const API_PAYMENT = {
  GET_REQUEST: '/bharatkosh/getRequest',
  UPDATE_RESPONSE: '/bharatkosh/response',
  GET_PAYMENT_STATUS: '/bharatkosh/paymentStatus',
  SAVE_FC_CHALLAN_DETAILS: 'demand/saveDemand',
  GET_FC_CHALLAN_DETAILS: 'demand/getDemandByUuid',
  GET_TRANSACTION_STATUS: '/bharatkosh/transactionDetails',
  GET_ALL_TRANSACTIONS: 'getAllTransaction',
  SAVE_DEMAND_CAMPA: 'demand/saveDemandForCampa',

  GET_ALL_TRANSACTIONS_OFFLINE: 'demand/getAllTransactionOffline',
  GET_PROPONENT_PAYMENT: 'demand/getTransactionsById',
  REJECT_PAYMENT: 'demand/rejectPayment',
  NPV_REJECT_PAYMENT: 'campav2Demand/rejectPayment',
  GET_TRANSACTION_DETAILS: 'demand/getTransactionDetails',
  SUBMIT_STATUS: 'demand/saveResponse',
  NPV_SUBMIT_STATUS: 'campav2Demand/saveCampaV2PaymentCompletionStatus',
  GET_BY_APPLICATION_ID: 'campaPayment/getByApplicationId',
  GET_ALL_TRANSACTION_LOGS: 'bharatkosh/allTransactionLogsCampa',
  BILL_DESK: 'billdesk/createOrder',
  BILL_DESK_TXN: 'transactions/get',
  GET_USER_TXN_DATA: 'billdesk/getPaymentDetailsByUser',
  GET_DETAILS_BY_ORDER_ID: 'billdesk/getPaymentDetailsByOrderId',
  GET_DETAILS_BY_CAMPA_ID: 'billdesk/getPaymentDetailsByCampaId',
  GET_AMOUNT_DETAILS_BY_CAMPA_ID: 'billdesk/getAmountRaisedAndPaid'
};

export const API_CAMPA = {
  GET_Proposed_OUTLAY: 'api/listProposedOutlay',
  ADD_Proposed_OUTLAY: 'api/addProposedOutlay',
  UPDATE_Proposed_OUTLAY: 'api/updateProposedOutlay',
  VIEW_Proposed_OUTLAY: 'api/viewProposedOutlay',
  GET_MAJOR_HEAD: 'api/getPOMajorHead',
  GET_ALL_CIRCLE: 'circle/getAllCircle',
  GET_List_MAJOR_HEAD: 'api/listMajorHead',
  GET_LIST_OF_REGION: 'api/listRegion',
  ADD_MAJORHEAD_TO_DO: 'api/addMajorHeadToPo',
  DELETE_MAJORHEAD_PO: 'api/deleteMajorHeadFromPo',
  GET_ALL_DIVISION: 'api/listDivisionByState',
  GET_DFO_BY_DIVISION: 'api/getDfoByDivision',
  GET_WORK_LIST: 'api/listWork',
  VIEW_WORK_LIST: 'api/viewWork',
  UPDATE_WORK: 'api/updateWorkV2',
  ADD_WORK: 'api/addWork',
  DELETE_WORK: 'api/deleteWork',
  ADD_MINOR_HEAD: 'api/addMinorHeadToWorkV3',
  GET_COMPARTMENT_LIST: 'api/getCompartmentList',
  GET_MINORHEAD_LIST: 'api/getMinorHeadList',
  GET_WORK_MINOR_HEAD: 'api/getWorkMinorHead',
  GET_WORK_COMPARTMENT: 'api/getWorkCompartmentV2',
  GET_ACTIVITY_LIST: 'api/getActivityList',
  ADD_WORK_COMPARTMENT: 'api/addWorkCompartmentV2',
  DELETE_WORK_COMPARTMENT: 'api/deleteWorkCompartment',
  GET_DFO_DETAILS: 'api/getDfoDetails',
  DELETE_MINOR_HEAD: 'api/deleteMinorHeadToWork',
  GET_ALL_RANGE: 'range/getallRange',
  GET_DISTRICT: 'api/getDistrictByStateId',
  GET_FC_PROPOSAL: 'api/getCampaFcProposal',
  GET_APO_DETAIL: 'api/getDfoApoDetails',
  GET_YEAROFPHASING: 'api/getPhasingYears',
  DELETE_YEAROFPHASING: 'api/deletePhasingYears',
  GET_DENSITY: 'api/getDensityList',
  GET_SLOPE: 'api/getSlopeList',
  GET_FORESTTYPELIST: 'api/getForestTypeList',
  GET_SOILTYPELIST: 'api/getSoilTypeList',
  GET_WORKINGCIRCLELIST: 'api/getWorkingCircleList',
  GET_EXTENTOFDEGRADATIONLIST: 'api/getExtentOfDegradationList',
  ADD_APO: 'api/generateDfoApo',
  GET_APO: 'api/getAllApo',
  GET_CAMPA_DASHBOARD: 'api/getStateCampaDashboardDataV2',
  GET_NATIONAL_CAMPA_DASHBOARD: 'api/getNationalCampaDashboardData',
  SUBMIT_DFO_APO: 'api/submitDfoApo',
  GET_ACTION_HISTORY: 'api/getActionHistory',
  CHECK_DFO_APO_EXISTS: 'api/checkDfoApoExist',
  DELETE_OUTLAY: 'api/deleteProposedOutlay',
  SUBMIT_DFO_APO_CF_APPROVAL: 'api/approveApoByCf',
  SUBMIT_DFO_APO_CF_CORRECTION: 'api/rejectApoByCf',
  GET_CCF_APO: 'api/getGeneratedCfApo',
  ADD_CIRCLE_APO: '/api/generateCfApo',
  SUBMIT_DFO_APO_PCF_APPROVAL: 'api/submitCfApo',
  ADD_PCF_APO: '/api/generatePccfApo',
  GET_PCCF_DFO_APO: 'api/getCfApoForPccf',
  GET_PCCF_GENERATE_APO: 'api/getGeneratedPccfApo',
  GET_APO_DASHBOARD_LIST: 'api/getApoReportStateWise',
  GET_ALL_STATUS_APO: 'api/getAllStatus',
  GET_ALL_DIVISION_STATE: 'api/getAllDivitionByState',
  GET_ALL_UPDATED_DIVISION_STATE_DATA: 'api/saveUpdateNationalDataWithState',
  GET_CIRCLE_WORLK_LIST: 'api/getAllCircleWork',
  APO_APPROVE_BY_PCF: 'api/approveApoByPccf',
  APO_SEND_CORRECTION_BY_PCF: 'api/sentForCorrectionApoByPccf',
  GET_CF_APO_FOR_PCCF: 'api/getCfApoForPccf',
  GET_DFO_APO_FOR_CF: 'api/getDfoApoForCf',
  GET_DFO_APO_FOR_CF_WITH_CIRCLE_ID: 'api/getDfoApoForCfWithCircleId',
  GET_ROLE_NAME: 'api/getCurrentRole',
  GET_USER_DETAIL: 'api/getCurrentUserDetails',
  GET_CAMPA_HEADING: 'api/getCampaHeadings',
  GET_CAMPA_SUB_HEADING: 'api/getCampaSubHeadings',
  ADD_ACTIVITY: 'api/saveActivityDetails',
  GET_ACTIVITY_DETAILS: 'api/getActivityDetails',
  ADD_PURPOSAL: 'api/addProposals',
  GET_PURPOSAL_BY_WORK: 'api/getProposalsByWork',
  GET_ACTIVITY_BY_WORK_ID: 'api/getWorkActivity',
  ADD_LOC_CHANGED_BY_WORK_ID: 'api/updateLocationChanged',
  GET_LOCATION_CHANGED_BY_WORK: 'api/getLocationChanged',
  GET_WORK_ADDITIONAL_DETAILS: 'api/getWorkAdditionalDetails',
  GET_CAMPA_HEADING_BY_SUB_HEADING_ID: 'api/getCampaHeadingBySubheadingId',
  GET_CAMPA_SUB_HEADING_BY_SUB_HEADING_ID: 'api/getCampaHeadingById',
  SUBMIT_DFO_APO_TO_STATE_CEO_FOR_APPROVAL: 'api/submitDfoApoToCampaCeo',
  GET_DFO_APO_FOR_STATE: 'api/getDfoApoForStateCampaWithStateId',
  SUBMIT_DFO_APO_BY_STATE_CEO_FOR_APPROVAL: 'api/approveDfoApoByStateCampa',
  SUBMIT_DFO_APO_BY_STATE_CEO_FOR_CORRECTION:
    'api/sentForCorrectionApoByStateCampa',
  GET_STATE_GENERATED_APO: 'api/getGeneratedStateCampaCeoApo',
  ADD_STATE_APO: 'api/generateStateCampaCeoApo',
  GET_STATE_APO: 'api/getDfoApoForStateCampaWithStateId',
  SUBMIT_APO_TO_NATIONAL_CAMPA: 'api/submitCampaCeoApoToNationalCampa',
  GET_TOTAL_COAST: 'api/getTotalCost',
  GET_TOTAL_COST_V2: 'api/getTotalCostV2',
  SUBMIT_DFO_DOC: 'api/addDfoApoClearance',
  SUBMIT_STATE_DFO_DOC: 'api/addStateCampaApoClearance',
  GET_STATE_OF_CUR_USER: 'api/getStateOfCurrentUser',
  GET_DFO_DASHBOARD_DATA: 'api/getDfoDashboardData',
  GET_DFO_APO_BY_STATUS: 'api/getDfoApoByStatus',
  GET_STATE_DASHBOARD_DATA: 'api/getStateCampaDashboardData',
  GET_STATE_APO_BY_STATUS: 'api/getStateCampaApoByStatus',
  GET_DATA_BY_PURPOSAL: 'trackYourProposal/dataOfProposalNo',
  GET_DATA_BY_REF_ID: 'fc/partb/getBasicDetails',
  ADD_INSPECTION_NOTE: 'api/addWorkInspectionNoteByType',
  ADD_COMPLETION_CERT_DETAIL: 'api/addCertificateCompletion',
  EDIT_MINOR_HEAD_DETAILS: 'api/editMinorHeadToWorkV3',

  GET_CAMPA_INSPECTION_NOTE_BY_TYPE: 'api/viewWorkInspectionNoteByType',
  DELETE_INSPECTION_NOTE: 'api/deleteInspectionNote',
  GET_CERT_COMPLETION_BY_WORK_ID: 'api/viewCertificateCompletion',
  GET_APO_CLEARANCE_DOC: 'api/getDfoApoClearance',
  GET_STATE_APO_CLEARANCE_DOC: 'api/getStateCampaApoClearance',
  GET_WORKTYPELIST: 'api/getWorkType',
  GET_FC_PROPOSAL_TWO_DOT_ZERO: 'api/getTwoDotZeroProposalsData',
  GET_FC_PROPOSAL_TWO_DOT_ZERO_PROPOSAL_KML: 'api/getTwoPointZeroProposalKml',
  ADD_MULTIPLE_KML: 'api/addWorkKml',
  GET_WORK_KML_LIST: 'api/viewWorkKml',
  DELETE_WORK_KML: 'api/deleteWorkKml',
  ADD_LETTER_APPROVE_CCF: 'api/addLetterOfApprovCcf',
  GET_APO_PDF_URL: '/pdf/generateConsolidatedStateApoPdf',
  GET_APO_PDF: '/pdf/generateApoPdf',
  GET_APO_PDF_NEW: 'pdf/generateApoPdf2',

  VIEW_LETTER_APPROVE_CCF: 'api/getLetterOfApprovCcf',
  ADD_SPILL_OVER_DATA: 'api/addSpilloverActivityToWork',
  GET_WORK_SPILLOVER_LIST: 'api/getWorkSpilloverActivity',
  DELETE_SPILL_OVER_RECORD: 'api/deleteSpilloverActivityFromWork',
  GET_CONSOLIDATE_STATE_APO: 'api/getConsolidatedStateApo',
  ADD_INTEGRATED_LIABILITY: 'api/addCommitedLiabilities',
  GET_INTEGRATED_LIABILITY: 'api/getCommitedLiabilities',
  DELETE_INTEGRATED_LIABILITY: 'api/deleteCommitedLiabilities',
  GET_INTEGRATED_APO_DATA: 'api/getIntegratedApoActivityListDataV2',
  GENERATE_INTEGRATED_APO: 'api/generateIntegratedApo',
  GET_INTEGRATED_STATE_APO_LIST: 'api/getIntegratedApoList',
  ADD_YEAR_FUND_AVAILIABILITY: 'api/addYearFundAvailability',
  GET_YEAR_FUND_AVAILIABILITY: 'api/getYearFundAvailabilityList',
  DELETE_YEAR_FUND_AVAILIABILITY: 'api/deleteYearFundAvailability',
  SUBMIT_INTEGRATED_APO: 'api/updateIntegratedApo',
  GET_SUBMIT_INTEGRATED_APO: 'api/getIntegratedApoList',
  GET_INTEGRATED_APO_BY_ID: 'api/viewIntegratedApoById',
  DELETE_INTEGRATED_APO_BY_ID: 'api/deleteIntegratedApoById',
  GET_STATE_APO_RECEIVED_ON_NATIONAL: 'api/getStateApoReceivedOnNational',
  GET_STATE_APO_PENDING_ON_NATIONAL: 'api/getStateApoPendingOnNational',
  GET_STATE_APO_PROPOSED_AMOUNT: 'api/getStateApoProposedAmount',
  GET_APO_HYPERLINK: 'api/getNationalDashboardStateWiseApoList',
  GET_INTEGRATED_APO_HYPERLINK:
    'api/getNationalDashboardStateWiseIntegratedApoList',
  GET_CIRCLE: 'api/getStateCircleFilterData',
  GET_DIVISION: 'api/getCircleDivisionFilterData',
  GET_DFO_FOR_NATIONAL_BY_FILTER: 'api/getDfoApoForNationalByFilter',
  GET_STATE_SUBMITTED_PROPOSALS: 'api/getStateSubmittedProposals',
  GET_STATE_IN_PROGRESS_PROPOSALS: 'api/getStateInprogressProposals',
  GET_NFL_SELECTED_PROPOSALS: 'api/getStateNflSelectedProposals',
  GET_DFL_SELECTED_PROPOSALS: 'api/getStateDflSelectedProposals',
  GET_DIVISION_BY_PROPOSALS: 'api/getDivisionFilterDataByProposals',
  GET_STATEWISE_ACTIVITY_AMOUNT: 'api/getStateWiseActivityAmount',
  GET_DRILLDOWN_TO_PROPOSAL_WISE_ACTIVITY:
    'api/getDrillDownToProposalWiseActivityAmount',

  SAVE_INTEGRATED_APO_STEP_CA: 'api/addIntegratedMandatoryActivitiesCa',
  SAVE_INTEGRATED_APO_STEP_CAT: 'api/addIntegratedMandatoryActivitiesCat',
  SAVE_INTEGRATED_APO_STEP_IWMP: 'api/addIntegratedMandatoryActivitiesIwmp',
  SAVE_INTEGRATED_APO_STEP_OTHER: 'api/addIntegratedMandatoryActivitiesOther',

  GET_INTEGRATED_APO_STEP: 'api/getIntegratedMandatoryActivities',
  // GET_INTEGRATED_APO_STEP_CAT: 'api/getIntegratedMandatoryActivitiesCat',
  // GET_INTEGRATED_APO_STEP_IWMP: 'api/getIntegratedMandatoryActivitiesIwmp',
  // GET_INTEGRATED_APO_STEP_OTHER: 'api/getIntegratedMandatoryActivitiesOther',

  SAVE_INTEGRATED_APO_STEP_THREE_PLANTATION:
    'api/addIntegratedNpvInterestActivitiesPlantation',
  SAVE_INTEGRATED_APO_STEP_THREE_ANR:
    'api/addIntegratedNpvInterestActivitiesAnr',
  SAVE_INTEGRATED_APO_STEP_THREE_SOIL:
    'api/addIntegratedNpvInterestActivitiesSoil',
  SAVE_INTEGRATED_APO_STEP_THREE_WILDLIFE:
    'api/addIntegratedNpvInterestActivitiesWildlife',
  SAVE_INTEGRATED_APO_STEP_THREE_FIRE:
    'api/addIntegratedNpvInterestActivitiesFire',

  GET_INTEGRATED_APO_STEP_THREE: 'api/getIntegratedNpvInterestActivities',
  // GET_INTEGRATED_APO_STEP_THREE_ANR: 'api/getIntegratedNpvInterestActivitiesAnr',
  // GET_INTEGRATED_APO_STEP_THREE_SOIL: 'api/getIntegratedNpvInterestActivitiesSoil',
  // GET_INTEGRATED_APO_STEP_THREE_WILDLIFE: 'api/getIntegratedNpvInterestActivitiesWildlife',
  // GET_INTEGRATED_APO_STEP_THREE_FIRE: 'api/getIntegratedNpvInterestActivitiesFire',

  SAVE_INTEGRATED_APO_STEP_FOUR_CAPACITY:
    'api/addIntegratedNpvInterestActivitiesCapacity',
  SAVE_INTEGRATED_APO_STEP_FOUR_FRONTLINE:
    'api/addIntegratedNpvInterestActivitiesFrontline',
  SAVE_INTEGRATED_APO_STEP_FOUR_LABOUR:
    'api/addIntegratedNpvInterestActivitiesLabour',
  SAVE_INTEGRATED_APO_STEP_FOUR_VEHICLE:
    'api/addIntegratedNpvInterestActivitiesVehicle',
  SAVE_INTEGRATED_APO_STEP_FOUR_OTHER:
    'api/addIntegratedNpvInterestActivitiesOther',
  SAVE_INTEGRATED_APO_STEP_FOUR_EXPENDITURE:
    'api/addIntegratedNpvInterestActivitiesExpenditure',

  GET_INTEGRATED_APO_STEP_FOUR: 'api/getIntegratedNpvInterestActivities',
  // GET_INTEGRATED_APO_STEP_FOUR_FRONTLINE: 'api/getIntegratedNpvInterestActivitiesFrontline',
  // GET_INTEGRATED_APO_STEP_FOUR_LABOUR: 'api/getIntegratedNpvInterestActivitiesLabour',
  // GET_INTEGRATED_APO_STEP_FOUR_VEHICLE: 'api/getIntegratedNpvInterestActivitiesVehicle',
  // GET_INTEGRATED_APO_STEP_FOUR_OTHER: 'api/getIntegratedNpvInterestActivitiesOther',
  // GET_INTEGRATED_APO_STEP_FOUR_EXPENDITURE: 'api/getIntegratedNpvInterestActivitiesExpenditure',

  SAVE_INTEGRATED_APO_STEP_FIVE_FO: 'api/addIntegratedFinancialOutlayFo',
  SAVE_INTEGRATED_APO_STEP_FIVE_FOEXPECTED:
    'api/addIntegratedFinancialOutlayFoExpected',
  GET_INTEGRATED_APO_STEP_FIVE: 'api/getIntegratedFinancialOutlay',
  FINAL_SUBMIT_STEP_FIVE: 'api/submitIntegratedApo',
  // GET_INTEGRATED_APO_STEP_FIVE_FOEXPECTED: 'api/getIntegratedFinancialOutlayFoExpected',
  GET_VIEW_INTEGRATED_APO_LIST: 'api/viewIntegratedApoById',
  GET_CCF_DFO_APO: 'api/getDfoApoForCcf',
  SUBMIT_DFO_APO_BY_CCF_FOR_APPROVAL: 'api/approvedDfoApoByCcf',
  SUBMIT_DFO_APO_BY_CCF_FOR_CORRECTION: 'api/revertDfoApoByCcf',
  ADD_YEAR_FINANCIAL_OUTLAY: 'api/addFoYearWise',
  GET_YEAR_FINANCIAL_OUTLAY: 'api/getFoYearWiseList',
  DELETE_YEAR_FINANCIAL_OUTLAY: 'api/deleteFoYearWise',
  GET_NAME_SCHEME_LIST: 'api/getSchemeList',
  SAVE_BALANCE_AFFORESTATION: 'api/addIntegratedBalanceAfforestation',
  GET_BALANCE_AFFORESTATION: 'api/getIntegratedBalAfforestData',
  SAVE_STATUS_COMPENSATORY_AFFORESTATION: 'api/addIntegratedCaStatus',
  GET_STATUS_COMPENSATORY_AFFORESTATION: 'api/getIntegratedCaStatus',
  NATIONAL_CAMPA_APO_LIST: 'api/getApoAndIapoOfStateCampa',
  GET_DFO_APO_FOR_NATIONAL: 'api/getDfoApoForNational',
  GET_DFO_APO_FOR_NATIONAL_V2: 'api/getDfoApoForNationalV2',
  GET_FINANCIAL_YEAR: 'api/getFinancialYears',
  GET_HEADING_DETAILS: 'api/getCampaHeadings',
  GET_PREVIOUS_FINANCIAL_YEAR: 'api/getPreviousFinancialYears',
  GET_FINANCIAL_YEAR_SEARCH: 'api/getFinYearForWorkSearch',
  GET_FC_STATE_SEARCH_PROPOSAL: 'api/searchToGetTwoDotZeroProposalsData',
  GET_WORK_SPECIFIC_ACTIVITY: 'api/getWorkSpecificActivities',
  GET_ACTIVITY_FOR_HEADING: 'api/getActivityForHeading',
  GET_FC_FORM_DATA: 'fc/getForm',
  GET_GIS_CA_KML: 'api/getKmlLocationDetails',
  ADD_STATE_PROPOSED_OUTLAY: 'api/addStateProposedActivityCost',
  DELETE_STATE_PROPOSED_OUTLAY: 'api/deleteStateProposedActivityCost',
  VIEW_STATE_PROPOSED_OUTLAY: 'api/viewStateProposedActivityCost',
  GET_TOTAL_COST_RULE_WISE_APO: 'api/getTotalCostRuleWiseForApo',
  API_EXCEL_SAVE_DATA: 'api/excel/saveData',
  GET_DIVISION_LEGACY_STATE: 'api/excel/getDivisions',
  GET_DATA_BY_DIVISION: 'api/excel/getDataByDivision',
  SAVE_LEGACY_ROW_DATA: 'api/excel/saveDataByDivisionUnit',
  GET_CIRCLE_LEGACY_TABLE: 'api/getCircleByStateFromHierarchy',
  GET_DIVISION_LEGACY_TABLE: 'api/getDivisionByCircleFromHierarchy',
  GET_STATE_CODE: 'api/excel/getStateId',
  GET_YEARS: 'api/excel/getYears',
  GET_DATA_NATIONAL_BY_STATE: 'api/excel/getAllDataNationalByState',
  GET_DRILLDOWN_TO_ALL_PROPOSAL_WISE_DETAILS:
    'api/getDrillDownToAllProposalWiseDetail',
  GET_DRILLDOWN_TO_ALL_PROPOSAL_WISE_DETAILS_UTILIZED:
    'api/getDrillDownToAllProposalWiseDetailUtilised',
  GET_PENDING_PROPOSALS_LIST: 'api/getPendingProposalsList',
  GET_STATE_NAME: 'kyc/getstateById',
  GET_STATES_LIST: 'api/getStatesList',
  GET_ACTIVITY_BY_STATE: 'api/getActAndSubActivityList',
  GET_ACTIVITY_BY_CIRCLE: 'api/getCircleDivisionAmountData',
  GET_CAMPA_AGENDA_APO_LIST: 'api/getStateApoListForAgenda',
  GET_CAMPA_AGENDA_SELECTED_APO_LIST:
    'api/getAgendaSelectedAndAvailableApoList',
  GET_CAMPA_AGENDA_LIST: 'api/getAllCampaAgenda',
  GET_COMMITTEE_MEMBER_LIST: 'api/listAllCommitteeMember',
  ADD_COMMITTEE_MEMBER: 'api/addCommitteeMember',
  ADD_CF_STATE_WORK: 'api/addWorkForStateCircle',
  DELETE_COMMITTEE_MEMBER: 'api/deleteCommitteeMember',
  LIST_COMMITTEE_MEMBER: 'api/listAllCommitteeMember',
  VIEW_COMMITTEE_MEMBER: 'api/viewCommitteeMember',
  ADD_OTHER_ITEM_DOCUMENT: 'api/addCampaAgendaOtherDoc',
  DELETE_OTHER_ITEM_DOCUMENT: 'api/deleteCampaAgendaOtherDoc',
  GET_OTHER_ITEM_DOCUMENT: 'api/getCampaAgendaOtherDoc',
  ADD_AGENDA_TIME: 'api/addCampaAgendaTime',
  GET_AGENDA_TIME: 'api/getCampaAgendaTime',
  ADD_AGENDA_COMMITTEE_MEMBER: 'api/addCampaAgendaCommitteeMember',
  GET_AGENDA_COMMITTEE_MEMBER: 'api/getCampaAgendaCommitteeMember',
  ADD_CAMPA_AGENDA: 'api/addCampaAgenda',
  ADD_CAMPA_AGENDA_MANEGEMENT: 'api/getCampaAgenda',
  GET_CAMPA_AGENDA_DETAILS: 'api/getCampaAgenda',
  SEND_AGENDA_TO_PARENT_OFFICER: 'api/sendCampaAgendaToParentOfficer',
  REVERT_AGENDA_TO_PARENT_OFFICER: 'api/revertCampaAgendaToOfficer',
  ADD_CAMPA_AGENDA_ADDITIONAL_DATA: 'api/addCampaAgendaAdditionalData',
  GET_CAMPA_AGENDA_ADDITIONAL_DATA: 'api/getCampaAgendaAdditionalData',
  ADD_CAMPA_AGENDA_WITH_APO: 'api/addCampaAgendaApo',
  GET_CAMPA_AGENDA_WITH_APO: 'api/getCampaAgendaApo',
  GET_ACTION_HISTORY_CAMPA: 'api/getAgendaHistory',
  GET_APPROVED_AGENDA_LIST_FOR_MOM: 'api/getApprovedAgendaListForMom',
  CREATE_MOM_FOR_AGENDA: 'api/createAgendaMom',
  GET_CAMPA_MOM_DETAILS: 'api/getAgendaMomDetails',
  DELETE_AGENDA_HISTORY_CAMPA: 'api/deleteAgenda',
  SAVE_COMMITTEE_REMARK_MOM: 'api/updateCommitteeMemberMomStatus',
  GET_CAMPA_AGENDA_USERLIST: 'api/getToUserList',
  ADD_DEFERED_ITEM_APO: 'api/addDeferredStateApoData',
  GET_DEFERED_ITEM_APO: 'api/getDeferredStateApoData',
  DELETE_DEFERED_ITEM_APO: 'api/deleteDeferredStateApoData',
  ADD_PROPOSAL_PROGRAM_DATA: 'api/addAgendaProjectData',
  GET_PROPOSAL_PROGRAM_DATA: 'api/getAgendaProjectData',
  DELETE_PROPOSAL_PROGRAM_DATA: 'api/deleteAgendaProjectData',
  ADD_MISCELLANEOUS_FUND: 'api/addAgendaMiscFund',
  GET_MISCELLANEOUS_FUND: 'api/getAgendaMiscFund',
  DELETE_MISCELLANEOUS_FUND: 'api/deleteAgendaMiscFund',
  SAVE_APO_DELIBERATION_DATA: 'api/createUpdateApoDeliberation',
  GET_APO_DELIBERATION_DATA: 'api/getMomApoDeliberation',
  GENERATE_PDF_INTERGARED: '/pdf/generateIntegratedApoPdf',
  GENERATE_CAMPA_AGENDA_SCHEME: 'pdf/generateCampaAgendaScheme',
  GENERATE_CAMPA_AGENDA_ACTION_TAKEN: 'pdf/generateCampaAgendaActionTaken',
  GENERATE_CAMPA_AGENDA_DEFERRED: 'pdf/generateCampaAgendaDeferred',
  GENERATE_CAMPA_AGENDA_PDF: 'pdf/generateCampaAgendaPdf',
  GENERATE_CAMPA_MOM_SCHEME: 'pdf/generateCampaAgendaActionTaken',
  GENERATE_CAMPA_MOM_PART_A: 'pdf/generateCampaMomPartA',
  GENERATE_CAMPA_MOM_PDF: 'pdf/generateCampaMomPdf',

  // CAMPA:: State Dashboard

  GET_CURRENT_STATES: 'api/getStateDetailsOfLoggedInUser',
  GET_UTR_DETAILS_CAMPA: 'api/getProposalUtrDetails',
  GET_CF_APO_LIST: 'api/getDfoApoForCfUser',
  GET_ADJUSTMENT_AMOUNT: 'api/getAdjustedProposalAmount',
  GET_CA_DETAILS_NATIONAL_DASH: 'api/getCaDetails',
  GET_CF_STATE_WORK_LIST: 'api/getWorkSC',
  ADD_STATE_ACTIVITY_DETAIl: 'api/saveWorkMinorSC',
  GET_ACTIVITY_LIST_STATE: 'api/getWorkMinorSC',
  DELETE_ACTIVITY_LIST_STATE: 'api/deleteWorkMinorSC',
  DELETE_WORK_STATE: 'api/deleteWorkSC',
  SUBMIT_WORK_STATE: 'api/submitWork',
  GET_STATE_WORK_APPROVED: 'api/approveRevertWorkByState',
  GET_CONSOLIDATED_APO: 'api/getConsolidatedStateCircleWork',




};

export const API_DRAFT = {
  SAVE_DRAFT: 'draftFormData/save',
  GET_FORM_DATA_FOR_COMPARE: 'draftFormData/getFormDataForCompare',
  GET_FORM_DATA_FOR_COMPARE_WITH_STEP:
    'draftFormData/getFormDataForCompareWithStep',
  GET_CURRENT_VERSION: 'draftFormData/getCurrentVersion',
  GET_ALL_VERSION: 'draftFormData/getAllVersion',
};

export const FC_PARIVESH1_TYP_LINKS = {
  LINK1: 'https://forestsclearance.nic.in/search.aspx',
  LINK2: 'https://forestsclearance.nic.in/Online_Status.aspx',
};

export const API_OTP = {
  SEND_OTP: 'notify/sendSMS',
  VERIFY_OTP: 'notify/authenticateOTP',
  UPDATE_USER_MOBILE: 'user/updateUserMobile',
};

export const API_JS_DASHBOARD = {
  PROPOSAL_COUNT: 'jsdashboard/getCount',
  PROPOSAL_LIST: 'jsdashboard/getInboxApplications',
  PROPOSAL_HISTORY_LIST: 'jsdashboard/getHistoryApplication',
  SECTOR_LIST: 'jsdashboard/getSectors',
  CLEARANCE_LIST: 'jsdashboard/getClearance',
  GET_ROLE_PENDENCY: 'jsdashboard/getRolePendency',
  SEND_PROPOSAL_TO_SEIAA: 'process/sendProposals/seiaa',
  PARK_PROPOSALS: 'parkProposal/park',
  GET_PARKED_PROPOSAL: 'parkProposal/parkedViaUser',
  UNPARK_PROPOSAL: 'parkProposal/unpark',
};
export const API_GEAC_DASHBOARD = {
  GEAC_ALL_PROPOSAL_LIST: 'geacmsdashboard/getGeacInboxApplications',
};

export const DASHBOARD = {
  PROPOSAL_LIST: 'jsdashboard/getRolePendency',
};

export const API_OFFICIAL_DASHBOARD = {
  PROPOSAL_LIST: 'process/getOfficialDashboard',
};

export const API_SWS = {
  UPDATE_APPLICATION: 'PariveshSWS/updateApplication',
};

export const API_CRZ = {
  GET_CONDITIONS_BY_TYPE: 'crz/getConditonsByType',
  GET_CONDITIONS_BY_STATEID: 'crz/getConditonsByStateId',
  SAVE_CONDITIONS_BY_TYPE: 'crz/saveConditons',
  SAVE_CONDITIONS: 'agendaProposalCondition/crz/save',
  GET_CONDITIONS: 'agendaProposalCondition/crz/get',
  DELETE_CRZ_CONDITION_LIST: 'agendaProposalCondition/crz/delete',
  DELETE_CRZ_CONDITION_LIST_BY_CONDITION_ID:
    'agendaProposalCondition/crz/deleteByConditionId',

  GET_APPLICATIONS_LIST: 'jsdashboard/getCrzInboxApplications',
  GET_APPLICATIONS_LIST_V3: 'jsdashboard/getCrzInboxApplications/v3',
  GET_APPLICATIONS_HISTORY_LIST: 'jsdashboard//getCrzHistoryApplication',
  GET_APPLICATIONS_LIST_COUNT: 'jsdashboard/getCrzCount',
  // GET_APPLICATIONS_LIST_CRZ_HISTORY: 'jsdashboard/getCrzHistoryApplication',
  // GET_APPLICATIONS_LIST_CRZ_HISTORY: 'jsdashboard/getCrzHistoryApplicationWithStartandEndDate',
  GET_APPLICATIONS_LIST_CRZ_HISTORY: 'jsdashboard/getCrzHistoryApplicationV2',
};

export const API_MIS_DASHBOARD = {
  GET_TOKEN: 'loginApi/login',
  GET_EMBED_TOKEN: 'loginApi/guestToken',
};

export const API_DSE = {
  CHECK_LIVE: 'check/isLive',
  DSC_GET_CERTIFICATE_LIST: 'dsc/getCertList',
  DSC_GET_CERTIFICATE_LIST_V2: 'dsc/v2/getCertList',
  DSC_REGISTRATION: 'dsc/registration',
  DSC_VERIFIY_REGISTRATION: 'dsc/verify/registration',
  DSC_SIGNED_PDF: 'dsc/signPdf',
  DSC_PUT_SIGNATURE: 'dsc/putSignature',
  DSC_SIGNER_SERVICE: 'dsc/signer_service',
  DSC_GET_USER_CERTIFICATE: 'dsc/getUserCertList',
};

export const API_GENERAIC_E_SIGN = {
  INITIATE: 'signProposal',
  COMPLETE: 'getEsignRecord',
  DOWNLOAD_SIGN_DOCUMENT: 'downloadEsignedDoc',
};

export const GEN_CODE_TYPE = {
  RECIPIENT_DEPARTMENT_TYPE: 'RECIPIENT_DEPARTMENT_TYPE',
  RECIPIENT_DEPARTMENT: 'RECIPIENT_DEPARTMENT',
  FORM_FOR: 'FORM_FOR',
  EC_FACTSHEET_PUBLIC_HEARING: 'EC_FACTSHEET_PUBLIC_HEARING',
  EC_CLEARANCE_MATRIX_CATEGORY: 'EC_CLEARANCE_MATRIX_CATEGORY',
  EC_TYPE_PROPOSAL: 'EC_TYPE_PROPOSAL',
  FC_PART_FOUR_SIGNING_TYPE: 'FC_PART_FOUR_SIGNING_TYPE',
  FC_PART_FOUR_PROPOSAL_TYPE: 'FC_PART_FOUR_PROPOSAL_TYPE',
  FC_FORM_E_RE_DIVERSION_LUC: 'FC_FORM_E_RE_DIVERSION_LUC',
  FC_E_REDIVERSION_CATEGORY: 'FC_E_REDIVERSION_CATEGORY',
  FC_FORM_E_APPLICATION_FOR: 'FC_FORM_E_APPLICATION_FOR',
  FC_MINERAL_OIL_EXTRACTED: 'FC_MINERAL_OIL_EXTRACTED',
  FC_MINERAL_OIL_EXTRACTION_PHASE: 'FC_MINERAL_OIL_EXTRACTION_PHASE',
  PROJECT_BENEFIT_TYPE: 'PROJECT_BENEFIT_TYPE',
  PROJECT_PROPOSAL_FOR: 'PROJECT_PROPOSAL_FOR',
  PROJECT_LEGAL_STATUS: 'LEGAL_STATUS_OF_COMPANY',
  PROJECT_SHAPE_OF_PROJECT: 'SHAPE_OF_THE_PROJECT',
  AMENDMENT_NATURE: 'AMENDMENT_NATURE',
  DOCUMENT_CATEGORY: 'DOCUMENT_CATEGORY',
  SURVEY_PURPOSE: 'SURVEY_PURPOSE',
  PROJECT_VIOLATION_INVOLVED: 'VIOLATION_INVOLVED',
  PROJECT_DIRECTIONS: 'DIRECTIONS',
  PROJECT_STATUS_OF_COURT_CASE: 'STATUS_OF_COURT_CASE',
  PROJECT_CASE_CATEGORY: 'CASE_CATEGORY',
  PROJECT_NAME_OF_COURT: 'NAME_OF_COURT',
  FC_PROJECT_ACTIVITY: 'PROJECT_CATEGORY',
  FC_CATEGORY_AREA_REQUIRED: 'CATEGORY_OF_AREA_REQUIRED',
  FC_STATUS_OF_PROPOSAL: 'STATUS_OF_THE_PROPOSAL',
  FC_APPROVAL_STAGE: 'FC_APPROVAL_STAGE',
  FC_STATUS_OF_APPLICATION: 'STATUS_OF_APPLICATION',
  FC_CORRIGENDUM_CERTIFICATE_STAGE: 'FC_CORRIGENDUM_CERTIFICATE_STAGE',
  FC_STATUS_OF_ENVIRONMENTAL_CLEARANCE: 'STATUS_OF_ENVIRONMENTAL_CLEARANCE',
  FC_PROTECTED_AREA_ECO_SENSITIVE_ZONE: 'PROTECTED_AREA_/_ECO_SENSITIVE_ZONE',
  FC_SETTLEMENT_RIGHTS: 'SETTLEMENT_RIGHTS',
  FC_STATUS_OF_APPROVAL_OF_THE_SUPREME_COURT:
    'STATUS_OF_APPROVAL_OF_THE_SUPREME_COURT',
  FC_STATUS_OF_APPROVAL_OF_THE_STANDING_COMMITTEE_OF_NBWL:
    'STATUS_OF_APPROVAL_OF_THE_STANDING_COMMITTEE_OF_NBWL',
  FC_Compensator_Afforestation: 'COMPENSATORY_AFFORESTATION',
  FC_LAND_ON_WHICH_COMPENSATORY_PLANTATION_PROPOSED:
    'LAND_ON_WHICH_COMPENSATORY_PLANTATION_PROPOSED',
  FC_LEGAL_STATUS_OF_THE_FOREST_LAND: 'FC_LEGAL_STATUS_OF_THE_FOREST_LAND',
  FC_COMPONENT: 'COMPONENT',
  FC_A_PART_B_PATCH_WISW_DETAILS: 'FC_A_PART_B_PATCH_WISW_DETAILS',
  COMPENSATORY_PLANTATION_LAND: 'COMPENSATORY_PLANTATION_LAND',
  FC_A_PART_B_CATEGORY_CA_LAND: 'FC_A_PART_B_CATEGORY_CA_LAND',
  EC_A_PART_B_PROJECT_CONSTRUCTION_STATUS:
    'EC_A_PART_B_PROJECT_CONSTRUCTION_STATUS',
  FC_B_PART_B_CONDITION_STIPULATED: 'FC_B_PART_B_CONDITION_STIPULATED',
  FC_B_PART_B_CATEGORY_OF_CA_LAND: 'FC_B_PART_B_CATEGORY_OF_CA_LAND',

  EC_MS_EAC_SYSTEM_GENERATED: 'EC_MS_EAC_SYSTEM_GENERATED',
  EC_MS_SEAC_SYSTEM_GENERATED: 'EC_MS_SEAC_SYSTEM_GENERATED',

  EC_EIA_PROJECT_CATEGORY: 'EC_EIA_PROJECT_CATEGORY',
  NATURE_OF_TOR: 'NATURE_OF_TOR',
  NATURE_OF_AMENDEMENT: 'NATURE_OF_AMENDEMENT',
  TRANSFER_FROM: 'TRANSFER_FROM',
  EC_CENTRAL_APPLICATION_REASON: 'EC_CENTRAL_APPLICATION_REASON',
  EC_FORM_PROJECT_LOCATION: 'EC_FORM_PROJECT_LOCATION',
  EC_FORM_PROJECT_STATUS: 'EC_FORM_PROJECT_STATUS',
  EC_APPLICATION_STATUS: 'EC_APPLICATION_STATUS',
  EC_PROPOSAL_CATEGORY: 'EC_PROPOSAL_CATEGORY',
  EC_PROPOSAL_PERCENTAGE_EXPANSION: 'EC_PROPOSAL_PERCENTAGE_EXPANSION',
  EC_PROPOSAL_VIOLATION_PROVISION: 'EC_PROPOSAL_VIOLATION_PROVISION',
  EC_PROJECT_LOCATION: 'EC_PROJECT_LOCATION',
  EC_SEASON_OF_COLLECTION: 'EC_SEASON_OF_COLLECTION',
  EC_STATUS_OF_COLLECTION: 'EC_STATUS_OF_COLLECTION',
  EC_TRANSPORT_MODES: 'EC_TRANSPORT_MODES',
  EC_PRODYCT_BY_PRODUCTS: 'EC_PRODYCT_BY_PRODUCTS',
  EC_PRODUCT_UNITS: 'EC_PRODUCT_UNITS',
  EC_STATUS: 'EC_STATUS',
  EC_LOCATION_TYPE: 'EC_LOCATION_TYPE',
  EC_CRZ_CLASSIFICATION: 'EC_CRZ_CLASSIFICATION',
  EC_PROPOSAL_FOR: 'EC_PROPOSAL_FOR',
  EC_NOTIFICATION_YEAR: 'EC_NOTIFICATION_YEAR',
  EC_INDUSTRIAL_AREA: 'EC_INDUSTRIAL_AREA',
  EC_REASON_THEREFOR: 'EC_REASON_THEREFOR',
  EC_PROJECT_IMPLEMENTATION_STATUS: 'EC_PROJECT_IMPLEMENTATION_STATUS',
  EC_OPTAINED_STATUS: 'EC_OPTAINED_STATUS',
  EC_NBW_STATUS: 'EC_NBW_STATUS',
  EC_PARTB_RELEASE_OF_POLLUTANTS: 'EC_PARTB_RELEASE_OF_POLLUTANTS',
  EC_PART_B_DISTANCE_SITE_UNIT: 'EC_PART_B_DISTANCE_SITE_UNIT',
  EC_PART_B_CAPACITY_UNIT: 'EC_PART_B_CAPACITY_UNIT',
  EC_PARTB_ETP_STP: 'EC_PARTB_ETP_STP',
  EC_PARTB_POLLUTANTS_TREATMENT_PLANT: 'EC_PARTB_POLLUTANTS_TREATMENT_PLANT',
  EC_PARTB_MINING_PROPOSALS: 'EC_PARTB_MINING_PROPOSALS',
  EC_PARTB_MINED_MINING_PROPOSALS: 'EC_PARTB_MINED_MINING_PROPOSALS',
  EC_PARTB_TYPE_OF_MINING_PROPOSALS: 'EC_PARTB_TYPE_OF_MINING_PROPOSALS',
  EC_PART_B_MINING_PROPOSALS_CRUSHER_UNIT:
    'EC_PART_B_MINING_PROPOSALS_CRUSHER_UNIT',
  EC_PARTB_METHOD_OF_MINING_PROPOSALS: 'EC_PARTB_METHOD_OF_MINING_PROPOSALS',
  EC_PARTB_INSTALL_BENEFICATION_PLANT_OF_MINING_PROPOSALS:
    'EC_PARTB_INSTALL_BENEFICATION_PLANT_OF_MINING_PROPOSALS',
  EC_PARTB_DUMPING_STRATEGY_OF_MINING_PROPOSALS:
    'EC_PARTB_DUMPING_STRATEGY_OF_MINING_PROPOSALS',
  EC_PARTB_CLUSTER_OF_MINING_PROPOSALS: 'EC_PARTB_CLUSTER_OF_MINING_PROPOSALS',
  EC_PARTB_REPLENISHMENT_STUDY_OF_MINING_PROPOSALS:
    'EC_PARTB_REPLENISHMENT_STUDY_OF_MINING_PROPOSALS',
  EC_PART_B_SOURCE: 'EC_PART_B_SOURCE',
  EC_PART_B_MODE_OF_TRANSPORT: 'EC_PART_B_MODE_OF_TRANSPORT',
  EC_PART_B_MODE_OF_TRANSPORT_1: 'EC_PART_B_MODE_OF_TRANSPORT_1',
  EC_PART_B_METHOD_OF_WATER_WITHDRAWAL: 'EC_PART_B_METHOD_OF_WATER_WITHDRAWAL',
  EC_PART_B_PROJECT_STAGE: 'EC_PART_B_PROJECT_STAGE',
  EC_PART_B_CONSTRUCTION_MATERIAL: 'EC_PART_B_CONSTRUCTION_MATERIAL',
  EC_PARTB_GROUND_WATER_CATEGORY: 'EC_PARTB_GROUND_WATER_CATEGORY',
  EC_PARTB_BUILDING_OR_CONSTRUCTION_PROJECTS:
    'EC_PARTB_BUILDING_OR_CONSTRUCTION_PROJECTS',
  EC_PART_B_COKE_QUENCHING: 'EC_PART_B_COKE_QUENCHING',
  EC_PARTB_PHYSICAL_CHANGES_DESCRIPTION_ACTIVITY:
    'EC_PARTB_PHYSICAL_CHANGES_DESCRIPTION_ACTIVITY',
  EC_PARTB_PHYSICAL_CHANGES_UNDERGROUND_WORKS:
    'EC_PARTB_PHYSICAL_CHANGES_UNDERGROUND_WORKS',
  EC_PARTB_PHYSICAL_CHANGES_STREAM: 'EC_PARTB_PHYSICAL_CHANGES_STREAM',
  EC_PART_B_PHYSICAL_CHANGES_UNIT: 'EC_PART_B_PHYSICAL_CHANGES_UNIT',
  EC_PARTB_DAM_WIER_BARRAGE: 'EC_PARTB_DAM_WIER_BARRAGE',
  EC_PARTB_TYPE_OF_IRRIGATION: 'EC_PARTB_TYPE_OF_IRRIGATION',
  EC_PARTB_AREA_SUBMERGED: 'EC_PARTB_AREA_SUBMERGED',
  EC_PARTB_PROJECT_TYPE: 'EC_PARTB_PROJECT_TYPE',
  CAF_OTHER_REHABILITATION_STATUS: 'CAF_OTHER_REHABILITATION_STATUS',
  EC_PART_C_PUBLIC_CONSULTATION: 'EC_PART_C_PUBLIC_CONSULTATION',
  EC_PART_C_DESIGNATION_PRESIDING: 'EC_PART_C_DESIGNATION_PRESIDING',
  EC_PART_C_SEASON: 'EC_PART_C_SEASON',
  EC_PART_C_WIND_DIRECTION: 'EC_PART_C_WIND_DIRECTION',
  EC_PART_C_LOCATION_CORE: 'EC_PART_C_LOCATION_CORE',
  EC_PART_C_AIR_CRITERIA_POLLUTANT: 'EC_PART_C_AIR_CRITERIA_POLLUTANT',
  CRZ_FORM_STEPS: 'CRZ_FORM_STEPS',
  EC_PART_C_AIR_UNIT: 'EC_PART_C_AIR_UNIT',
  EC_PART_C_SURFACE_WATER_QUALITY: 'EC_PART_C_SURFACE_WATER_QUALITY',
  EC_PART_C_SURFACE_WATER_QUALITY_UNIT: 'EC_PART_C_SURFACE_WATER_QUALITY_UNIT',
  EC_PART_C_GROUND_WATER_QUALITY_CRITERIA:
    'EC_PART_C_GROUND_WATER_QUALITY_CRITERIA',
  EC_PART_C_NOISE_LEVEL: 'EC_PART_C_NOISE_LEVEL',
  EC_PART_C_SOIL_QUALITY: 'EC_PART_C_SOIL_QUALITY',
  EC_PART_C_CHEMICAL_PROPERTIES: 'EC_PART_C_CHEMICAL_PROPERTIES',
  EC_PART_C_CHEMICAL_PROPERTIES_UNIT: 'EC_PART_C_CHEMICAL_PROPERTIES_UNIT',
  EC_PART_C_AIR_QUALITY_IMPACT_PREDICTION:
    'EC_PART_C_AIR_QUALITY_IMPACT_PREDICTION',
  EC_PART_C_AIR_QUALITY_IMPACT_PREDICTION_UNIT:
    'EC_PART_C_AIR_QUALITY_IMPACT_PREDICTION_UNIT',
  EC_PART_C_STATUS_LAND_AQUISITION: 'EC_PART_C_STATUS_LAND_AQUISITION',
  EC_PART_C_MONITORING_ATTRIBUTE: 'EC_PART_C_MONITORING_ATTRIBUTE',
  EC_PART_C_MONITORING_MODE: 'EC_PART_C_MONITORING_MODE',
  EC_PART_C_FREQUENCY: 'EC_PART_C_FREQUENCY',
  EC_PART_C_PHASE: 'EC_PART_C_PHASE',
  EC_PART_C_MONITORING_AGENCY: 'EC_PART_C_MONITORING_AGENCY',
  EC_PART_C_COMPLIANCE_REPORT: 'EC_PART_C_COMPLIANCE_REPORT',
  QUANTITY_PERMITTED: 'QUANTITY_PERMITTED',
  FC_FORMC_EXEMPTED_CATEGORY: ' FC_FORMC_EXEMPTED_CATEGORY ',
  FC_FORMC_EXEMPTED_CATEGORY_DFO: 'FC_FORMC_EXEMPTED_CATEGORY_DFO',
  EC_LOCATED_AREA: 'EC_LOCATED_AREA',
  EC_ESZ_NOTIFIED: 'EC_ESZ_NOTIFIED',
  //  EC Form 2

  EC_FORM_TWO_PROPOSAL_TYPE: 'EC_FORM_TWO_PROPOSAL_TYPE',

  // EC FORM SEVEN

  // EC_FORM_SEVEN_SCHEDULE_ITEM_NUMBER: 'EC_FORM_SEVEN_SCHEDULE_ITEM_NUMBER',
  // EC_FORM_SEVEN_SCHEDULE_ITEM_NUMBER_MAJOR_ACTIVITY:
  //   'EC_FORM_SEVEN_SCHEDULE_ITEM_NUMBER_MAJOR_ACTIVITY',
  // EC_FORM_SEVEN_SCHEDULE_ITEM_NUMBER_MINOR_ACTIVITY:
  //   'EC_FORM_SEVEN_SCHEDULE_ITEM_NUMBER_MINOR_ACTIVITY',

  EC_FORM_SEVEN_LEGAL_STATUS: 'EC_FORM_SEVEN_LEGAL_STATUS',
  EC_FORM_SEVEN_PROJECT_CATEGORY: 'EC_FORM_SEVEN_PROJECT_CATEGORY',
  EC_FORM_SEVEN_REASON_FOR_APPLICATION: 'EC_FORM_SEVEN_REASON_FOR_APPLICATION',
  EC_OBTAINED_SELECT: 'EC_OBTAINED_SELECT',

  EC_IMPLIMENTED_STATUS: 'EC_IMPLIMENTED_STATUS',

  EC_FORM_SEVEN_STATUS_OF_IMPLEMENTATION:
    'EC_FORM_SEVEN_STATUS_OF_IMPLEMENTATION',
  EC_FORM_SEVEN_APPLICATION_MADE_BY: 'EC_FORM_SEVEN_APPLICATION_MADE_BY',
  EC_FORM_EIGHT_APPLICATION_MADE_BY: 'EC_FORM_EIGHT_APPLICATION_MADE_BY',

  EC_FORM_NINE_APPLICATION_MADE_BY: 'EC_FORM_NINE_APPLICATION_MADE_BY',
  EC_FORM_NINE_PROJECT_CATEGORY: 'EC_FORM_NINE_PROJECT_CATEGORY',
  EC_FORM_NINE_PROJECT_PROPOSALS: 'EC_FORM_NINE_PROJECT_PROPOSALS',
  EC_FORM_NINE_EIA_NOTIFICATION: 'EC_FORM_NINE_EIA_NOTIFICATION',
  EC_FORM_NINE_NAME_OF_THE_COURT: 'EC_FORM_NINE_NAME_OF_THE_COURT',
  EC_FORM_NINE_CASE_CATEGORY: 'EC_FORM_NINE_CASE_CATEGORY',
  EC_FORM_NINE_STATUS_OF_COURT_CASE: 'EC_FORM_NINE_STATUS_OF_COURT_CASE',
  EC_FORM_NINE_ACT_UNDER_VIOLATION: 'EC_FORM_NINE_ACT_UNDER_VIOLATION',
  EC_FORM_NINE_TYPE_OF_VIOLATION: 'EC_FORM_NINE_TYPE_OF_VIOLATION',
  EC_FORM_NINE_DIRECTION_ISSUED_BY: 'EC_FORM_NINE_DIRECTION_ISSUED_BY',
  EC_FORM_NINE_STATUS_OF_THE_COURT: 'EC_FORM_NINE_STATUS_OF_THE_COURT',

  //EC FORM TEN
  EC_FORM_TEN_PROJECT_PROPOSAL_FOR: 'EC_FORM_TEN_PROJECT_PROPOSAL_FOR',
  EC_FORM_TEN_INDUSTRIAL_AREA_NOTIFIED: 'EC_FORM_TEN_INDUSTRIAL_AREA_NOTIFIED',
  EC_FORM_TEN_EXPANSION_UNDER_SEVEN: 'EC_FORM_TEN_EXPANSION_UNDER_SEVEN',
  EC_FORM_TEN_LEGAL_STATUS_OF_COMPANY: 'EC_FORM_TEN_LEGAL_STATUS_OF_COMPANY',
  EC_FORM_TEN_SHAPE_OF_PROJECT: 'EC_FORM_TEN_SHAPE_OF_PROJECT',
  EC_FORM_TEN_NAME_OF_COURT: 'EC_FORM_TEN_NAME_OF_COURT',
  EC_FORM_TEN_CASE_CATEGORY: 'EC_FORM_TEN_CASE_CATEGORY',
  EC_FORM_TEN_STATUS_OF_COURT_CASE: 'EC_FORM_TEN_STATUS_OF_COURT_CASE',
  EC_FORM_TEN_ACT_RULE_REGULATION: 'EC_FORM_TEN_ACT_RULE_REGULATION',
  EC_FORM_TEN_TYPE_OF_VIOLATION: 'EC_FORM_TEN_TYPE_OF_VIOLATION',
  EC_FORM_TEN_DIRECTION_ISSUED_BY: 'EC_FORM_TEN_DIRECTION_ISSUED_BY',
  EC_FORM_TEN_CATEGORY: 'EC_FORM_TEN_CATEGORY',
  EC_FORM_TEN_EIA_NOTIFICATION: 'EC_FORM_TEN_EIA_NOTIFICATION',
  EC_FORM_TEN_EFFLUENT_GENERATION_QUANTITY_PURPOSE:
    'EC_FORM_TEN_EFFLUENT_GENERATION_QUANTITY_PURPOSE',
  EC_FORM_TEN_UNITS: 'EC_FORM_TEN_UNITS',
  EC_FORM_TEN_AUTHORIZED_ENVIRONMENTAL_AUDITOR:
    'EC_FORM_TEN_AUTHORIZED_ENVIRONMENTAL_AUDITOR',
  EC_FORM_TEN_ATTRIBUTE: 'EC_FORM_TEN_ATTRIBUTE',
  EC_FORM_TEN_SECTOR: 'EC_FORM_TEN_SECTOR',
  EC_FORM_TEN_PROJECT_ACTIVITY_PROVISION_UNDER:
    'EC_FORM_TEN_PROJECT_ACTIVITY_PROVISION_UNDER',
  EC_FORM_TWELVE_STATUS_OF_IMPLEMENTATION:
    'EC_FORM_TWELVE_STATUS_OF_IMPLEMENTATION',

  EC_FORM_SIX_STATUS_OF_IMPLEMENTATION: 'EC_FORM_SIX_STATUS_OF_IMPLEMENTATION',
  // CRZ

  EC_FORM_SIX_AMENDMENT_SOUGHT_FOR: 'EC_FORM_SIX_AMENDMENT_SOUGHT_FOR',

  //CERTIFICATES
  EC_FORM_CERTIFICATES_UNITS: 'EC_FORM_CERTIFICATES_UNITS',
  EC_FORM_CERTIFICATES_MODE_OF_TRANSPORT:
    'EC_FORM_CERTIFICATES_MODE_OF_TRANSPORT',
  EC_FORM_CRETIFICATES_PRODUCT_OR_BY_PRODUCT:
    'EC_FORM_CRETIFICATES_PRODUCT_OR_BY_PRODUCT',

  CRZ_PROJECT: 'CRZ_FORM_STEPS',
  CRZ_PROJECT_CRZ_I_A: 'CRZ_PROJECT_CRZ_I_A',
  CRZ_PROJECT_CRZ_I_B: 'CRZ_PROJECT_CRZ_I_B',
  CRZ_PROJECT_LOCATION: 'CRZ_PROJECT_LOCATION',
  CRZ_PROJECT_LOCATED_IN: 'CRZ_PROJECT_LOCATED_IN',
  CRZ_TYPE_OF_WASTE: 'CRZ_TYPE_OF_WASTE',
  CRZ_TYPE_DISPOSAL: 'CRZ_BASIC_TYPE_DISPOSAL',
  CRZ_MODE_OF_DISPOSAL: 'CRZ_MODE_OF_DISPOSAL',
  CRZ_WATER_SOURCE: 'CRZ_WATER_SOURCE',
  CRZ_WATER_MODE_OF_DISPOSAL: 'CRZ_WATER_MODE_OF_DISPOSAL',

  // EC PART A
  EC_PART_A_ENVIRONMENT_CLEARANCE_REASON:
    'EC_PART_A_ENVIRONMENT_CLEARANCE_REASON',
  EC_PART_A_AMENDMENT_TYPE: 'EC_PART_A_AMENDMENT_TYPE',
  EC_PART_A_PROJECT_ACTIVITY: 'EC_PART_A_PROJECT_ACTIVITY',
  WL_EXTENT_OF_PHYSICAL_DISTURBANCE: 'WL_EXTENT_OF_PHYSICAL_DISTURBANCE',
  WL_STATUS_OF_APPLICATION: 'WL_STATUS_OF_APPLICATION',
  WL_CATEGORY_AREA_REQUIRED: 'WL_CATEGORY_AREA_REQUIRED',
  WL_LEGAL_STATUS_OF_THE_FOREST_LAND: 'WL_LEGAL_STATUS_OF_FOREST_LAND',
  WL_LOCATION_OF_PROJECT_AREA: 'WL_LOCATION_OF_PROJECT_AREA',
  CRZ_STATUS_OF_PROPOSAL_EC: 'CRZ_STATUS_OF_PROPOSAL_EC',
  CRZ_TRANSMISSION_LINE: 'CRZ_TRANSMISSION_LINE',
  CRZ_GROUND_WATER_INTERSECTION: 'CRZ_GROUND_WATER_INTERSECTION',
  CRZ_TERRESTRIAL_OPTIONS: 'CRZ_TERRESTRIAL_OPTIONS',
  CRZ_MARINE_OPTIONS: 'CRZ_MARINE_OPTIONS',
  CRZ_GROUNDWATER_WITHDRAWAL: 'CRZ_GROUNDWATER_WITHDRAWAL',
  CRZ_TYPE_PRODUCT: 'CRZ_TYPE_PRODUCT',
  AUTHORITY_TYPE: 'AUTHORITY_TYPE',
  CAF_TYPE_OF_VIOLATION: 'CAF_TYPE_OF_VIOLATION',
  FC_LEGAL_STATUS_FOREST_LAND: 'FC_LEGAL_STATUS_FOREST_LAND',
  SIR_RECOMMENDATION_OPTION: 'SIR_RECOMMENDATION_OPTION',
  LOCATED_WITHIN: 'LOCATED_WITHIN',
  FC_ECO_CLASS: 'FC_ECO_CLASS',
  FC_GIRTH_WISE: 'FC_GIRTH_WISE',
  FC_DFO_DCF: 'FC_DFO_DCF',
  FC_SCIENTIFIC_NAME: 'FC_SCIENTIFIC_NAME',
  FC_PARTA_AREA_TYPE: 'FC_PARTA_AREA_TYPE',
  FC_FormB_PAYMENTS_ITEM_NATURE: 'FC_FormB_PAYMENTS_ITEM_NATURE',
  STATUS: 'STATUS',
  FC_FormB_FOREST_LAND_AUTHORITY: 'FC_FormB_FOREST_LAND_AUTHORITY',
  FC_FormB_LAND_PROPOSED_RETURN: 'FC_FormB_LAND_PROPOSED_RETURN',
  FC_FORM_B_LEGAL_STATUS_OF_THE_FOREST_LAND:
    'FC_FORM_B_LEGAL_STATUS_OF_THE_FOREST_LAND',
  FC_FORM_B_COMPENSATORY_AFFORESTATION: 'FC_FORM_B_COMPENSATORY_AFFORESTATION',
  FC_FORM_B_LAND_ON_WHICH_COMPENSATORY_PLANTATION_PROPOSED:
    'FC_FORM_B_LAND_ON_WHICH_COMPENSATORY_PLANTATION_PROPOSED',
  FC_FORM_B_MINING_PROPOSALS: 'FC_FORM_B_MINING_PROPOSALS',
  FC_FORM_B_MINING_PROPOSALS_CRUSHER_UNIT:
    'FC_FORM_B_MINING_PROPOSALS_CRUSHER_UNIT',
  FC_FORM_B_MINED_MINING_PROPOSALS: 'FC_FORM_B_MINED_MINING_PROPOSALS',
  FC_FORM_B_TYPE_OF_MINING_PROPOSALS: 'FC_FORM_B_TYPE_OF_MINING_PROPOSALS',
  FC_FORM_B_METHOD_OF_MINING_PROPOSALS: 'FC_FORM_B_METHOD_OF_MINING_PROPOSALS',
  FC_FORM_B_INSTALL_BENEFICATION_PLANT_OF_MINING_PROPOSALS:
    'FC_FORM_B_INSTALL_BENEFICATION_PLANT_OF_MINING_PROPOSALS',
  FC_FORM_B_CLUSTER_OF_MINING_PROPOSALS:
    'FC_FORM_B_CLUSTER_OF_MINING_PROPOSALS',
  FC_FORM_B_REPLENISHMENT_STUDY_OF_MINING_PROPOSALS:
    'FC_FORM_B_REPLENISHMENT_STUDY_OF_MINING_PROPOSALS',
  FC_FORM_B_DUMPING_STRATEGY_OF_MINING_PROPOSALS:
    'FC_FORM_B_DUMPING_STRATEGY_OF_MINING_PROPOSALS',
  FC_FORM_B_STATUS_OF_APPLICATION: 'FC_FORM_B_STATUS_OF_APPLICATION',
  FC_FORM_B_PROTECTED_AREA_ECO_SENSITIVE_ZONE:
    'FC_FORM_B_PROTECTED_AREA_/_ECO_SENSITIVE_ZONE',
  FC_FORM_B_SETTLEMENT_RIGHTS: 'FC_FORM_B_SETTLEMENT_RIGHTS',
  FC_FORM_B_STATUS_OF_ENVIRONMENTAL_CLEARANCE:
    'FC_FORM_B_STATUS_OF_ENVIRONMENTAL_CLEARANCE',
  FC_FORM_B_STATUS_OF_APPROVAL_OF_THE_SUPREME_COURT:
    'FC_FORM_B_STATUS_OF_APPROVAL_OF_THE_SUPREME_COURT',

  FC_PROJECT_CATEGORY_TYPE: 'FC_PROJECT_CATEGORY_TYPE',

  FC_FORM_C_SURFACE_SAMPLING: 'FC_FORM_C_SURFACE_SAMPLING',
  FC_FORM_C_ESTIMATED_ACCURACY_LEVEL_BOREHOLES:
    'FC_FORM_C_ESTIMATED_ACCURACY_LEVEL_BOREHOLES',
  FC_FORM_C_LEGAL_STATUS_OF_THE_FOREST_LAND:
    'FC_FORM_C_LEGAL_STATUS_OF_THE_FOREST_LAND',
  FC_FORM_C_Compensator_Afforestation: 'FC_FORM_C_Compensator_Afforestation',
  FC_FORM_C_STATUS_OF_APPLICATION: 'FC_FORM_C_STATUS_OF_APPLICATION',
  FC_FORM_C_STATUS_OF_ENVIRONMENTAL_CLEARANCE:
    'FC_FORM_C_STATUS_OF_ENVIRONMENTAL_CLEARANCE',
  // FC_FORM_C_ESTIMATED_CONFIDENCE_BOREHOLE:FC_FORM_C_ESTIMATED_CONFIDENCE_BOREHOLES',
  FC_FORM_D_NATURE_OF_MINING: 'FC_FORM_D_NATURE_OF_MINING',
  FC_FORM_D_PROJECT_CATEGORY: 'FC_FORM_D_PROJECT_CATEGORY',
  // FC_FORM_C_ESTIMATED_CONFIDENCE_BOREHOLES:'FC_FORM_C_ESTIMATED_CONFIDENCE_BOREHOLES'
  FC_A_PartB_DC_DEPARTMENT_NAME: 'FC_A_PartB_DC_DEPARTMENT_NAME',
  FC_FORM_E_STATUS_OF_RECLAMATION: 'FC_FORM_E_STATUS_OF_RECLAMATION',
  FC_FORM_E_AUTHORITY_OF_FOREST_LAND: 'FC_FORM_E_AUTHORITY_OF_FOREST_LAND',
  CLEARANCE_SCENARIOS: 'APP_CLEARANCE_SCENARIO',
  CLEARANCE_STEP_TYPE: 'APP_CLEARANCE_STEP_TYPE',
  CLEARANCE_ACTIONS: 'APP_CLEARANCE_ACTION',

  EC_FORM_EIGHT_LIST_TOR_OBTAINED: 'EC_FORM_EIGHT_LIST_TOR_OBTAINED',
  PROJECT_TYPE: 'PROJECT_TYPE',
  CAPACITY_UNIT: 'CAPACITY_UNIT',
  COMMITTEE_ROLE: 'COMMITTEE_ROLE',

  OFFICER_DESIGNATION: 'OFFICER_DESIGNATION',

  PAYMENT_MODE: 'PAYMENT_MODE',

  WHETHER_ORIGINAL_OR_EXTENSION: 'WHETHER_ORIGINAL_OR_EXTENSION',
  VERIFICATION_MODE: 'VERIFICATION_MODE',
  BANK_NAME: 'BANK_NAME',
  MEETING_MODE: 'MEETING_MODE',
  MERGED_TYPE: 'MERGED_TYPE',
  MEMBER_CONSENT_MODE: 'MEMBER_CONSENT_MODE',
  MEMBER_CONSENT_REMARKS: 'MEMBER_CONSENT_REMARKS',
  DELIBERATION_RECOMMENDATION_TYPE: 'DELIBERATION_RECOMMENDATION_TYPE',

  EC_MINISTER_NOTING_OPTION: 'EC_MINISTER_NOTING_STATUS',

  CRZ_AMENDMENT_LOGS: 'CRZ_AMENDMENT_LOGS',
  AMENDMENT_LOGS: 'AMENDMENT_LOGS',
  AMENDMENT_LOGS_CAF: 'AMENDMENT_LOGS_CAF',
  AMENDMENT_LOGS_TOR_A: 'AMENDMENT_LOGS_TOR_A',
  AMENDMENT_LOGS_TOR_EC: 'AMENDMENT_LOGS_TOR_EC',
  AMENDMENT_LOGS_TOR_B: 'AMENDMENT_LOGS_TOR_B',
  AMENDMENT_LOGS_EC: 'AMENDMENT_LOGS_EC',

  // FC PART 3
  RECOMMENDATION_TYPE: 'RECOMMENDATION_TYPE',
  FC_PART_3_CA_LAND_TYPE: 'FC_PART_3_CA_LAND_TYPE',
  FC_PART_3_PROPOSAL_STATUS: 'FC_PART_3_PROPOSAL_STATUS',
  FC_FORM_F_PART_THREE: 'FC_FORM_F_PART_THREE',

  SEVEN_2_A: 'SEVEN_2_A',
  CRZ_TRANSFER_AREA_CLASSIFICATION: 'CRZ_TRANSFER_AREA_CLASSIFICATION',

  //----------------
  EC_FORM_PRIOR_TWO_PHYSICAL_UNIT: 'EC_FORM_PRIOR_TWO_PHYSICAL_UNIT',
  EC_FORM_PRIOR_TWO_DISTANCE_UNIT: 'EC_FORM_PRIOR_TWO_DISTANCE_UNIT',
  FC_FORM_G_PARTICULAR_TARGET: 'FC_FORM_G_PARTICULAR_TARGET',
  AREA_FOREST_DIFFERENT_LEGAL: 'AREA_FOREST_DIFFERENT_LEGAL',
  FC_FORM_G_OLD_NEW_CODE: 'FC_FORM_G_OLD_NEW_CODE',
  FC_FORM_G_PLAN_NAME: 'FC_FORM_G_PLAN_NAME',

  // FC form F
  FC_FORM_F_PROPOSED_TRANSFER_BETWEEN: 'FC_FORM_F_PROPOSED_TRANSFER_BETWEEN',
  FC_FOREST_AREA_APPLIED_FOR_TRANSFER: 'FC_FOREST_AREA_APPLIED_FOR_TRANSFER',

  // ACA

  ACA_OWNERSHIP_DETAILS_FORM: 'ACA_OWNERSHIP_DETAILS_FORM',
  ACA_PLANTATION_DETAILS: 'ACA_PLANTATION_DETAILS',
  ACA_TREES: 'ACA_TREES',
  ACA_SHRUBS: 'ACA_SHRUBS',
  ACA_HERBS: 'ACA_HERBS',
  ACA_INVASIVE: 'ACA_INVASIVE',
  ACA_FOREST_STATUS: 'ACA_FOREST_STATUS',

  //---- GEAC Module Form 1-----//

  GEAC_APPLICATION_TYPE: 'GEAC_APPLICATION_TYPE',
  GEAC_PROJECT_PROPOSAL_FOR: 'GEAC_PROJECT_PROPOSAL_FOR',
  GEAC_PROJECT_PROPOSAL_FOR_LARGE_SCALE_PRODUCTION: 'GEAC_PROJECT_PROPOSAL_FOR_LARGE_SCALE_PRODUCTION',
  GEAC_APPLICATION_TYPE_NEW: 'GEAC_APPLICATION_TYPE_NEW',
  GEAC_Final_Determination: 'GEAC_Final_Determination',
  GEAC_Biology_Document_Plant_Species: 'GEAC_Biology_Document_Plant_Species',
  GEAC_Main_Mechanism_Pollen_Dispersal: 'GEAC_Main_Mechanism_Pollen_Dispersal',
  GEAC_Mechanisms_Natural_Seed_Dispersal:
    'GEAC_Mechanisms_Natural_Seed_Dispersal',
  GEAC_Seed_Dormancy_Including_Tubers: 'GEAC_Seed_Dormancy_Including_Tubers',
  GEAC_CATEGORY_GENETIC_MODIFICATION: 'GEAC_CATEGORY_GENETIC_MODIFICATION',
  GEAC_MODIFICATION_METHOD: 'GEAC_MODIFICATION_METHOD',
  GEAC_SELECTION_METHOD_USED_PLANT_REGENERATION:
    'GEAC_SELECTION_METHOD_USED_PLANT_REGENERATION',
  GEAC_INTRODUCED_DNA: 'GEAC_INTRODUCED_DNA',
  GEAC_CONSTRUCT_COMPOSITION_FEATURE: 'GEAC_CONSTRUCT_COMPOSITION_FEATURE',
  GEAC_CONSTRUCT_COMPOSITION_TRAIT: 'GEAC_CONSTRUCT_COMPOSITION_TRAIT',
  GEAC_PRODUCT_DETAILS: 'GEAC_PRODUCT_DETAILS',
  GEAC_ISOLATION_APPROPRIATE: 'GEAC_ISOLATION_APPROPRIATE',
  GEAC_PLANTING_MATERIAL: 'GEAC_PLANTING_MATERIAL',
  GEAC_HARVESTING_MATERIAL: 'GEAC_HARVESTING_MATERIAL',

  //------ GEAC form 2A ------//
  GEAC_FTA_PRODUCT_NAME: 'GEAC_FTA_PRODUCT_NAME',
  GEAC_FTA_APPROVAL_REQUIRED: 'GEAC_FTA_APPROVAL_REQUIRED',
  //------ GEAC form 2B ------//
  GEAC_FTB_APPROVAL_REQUIRED: 'GEAC_FTB_APPROVAL_REQUIRED',
  GEAC_FTB_RELEASE_REQUIRED_APPROVAL: 'GEAC_FTB_RELEASE_REQUIRED_APPROVAL',
  GEAC_FTB_IDENTIFICATION_TRANSGENIC_PLANT:
    'GEAC_FTB_IDENTIFICATION_TRANSGENIC_PLANT',
  GEAC_FTB_RISK_ASSESSMENT: 'GEAC_FTB_RISK_ASSESSMENT',
  GEAC_FTB_ANTICIPATED_ACTUAL_EXPRESSION:
    'GEAC_FTB_ANTICIPATED_ACTUAL_EXPRESSION',
  GEAC_FTB_MODIFIED_PLANTS: 'GEAC_FTB_MODIFIED_PLANTS',
  GEAC_FTB_PURPOSE_EXPERIMENTAL_DESIGN: 'GEAC_FTB_PURPOSE_EXPERIMENTAL_DESIGN',

  //nvp payment
  PAYMENT_DEMAND_FOR: 'PAYMENT_DEMAND_FOR',
  PAYMENT_REJECTION_REASON: 'PAYMENT_REJECTION_REASON'
};

export const ACA_DETAILS = {
  ACA_PLANTATION_DETAILS: 'ACA_PLANTATION_DETAILS',
};

export const ACA_APIS = {
  SAVE_ACA_BASIC_DETAILS: '/aca/saveBasicDetails',

  SAVE_ACA_MODEL_LIST: 'acaModelDetails/saveActivityDetails',
  SAVE_ACA_MODEL: 'acaModelDetails/save',
  GET_MODEL: 'acaModelDetails/getListByState',
  GET_LIST_BY_STATE: 'acaModelDetails/getActivityDetails',
  DELETE_MODEL_DETAILS: 'acaModelDetails/deleteActivityDetails',
  DELETE_MODEL: 'acaModelDetails/deleteModelDetails',
  GET_DISTINCT_ACTIVITY: 'acaModelDetails/getDistinctActivityNames',
};

export const API_CRZ_VALIDITY_EXTENSION = {
  SAVE_BASIC_DETAILS: 'crzValidityofExtension/save/StepI',
  GET_BASIC_DETAILS: 'crzValidityofExtension/getBasicDetails',

  // STEP 2
  SAVE_IMPLEMENTATION_DETAILS:
    'crzValidityofExtension/saveImplementationDetails',
  SAVE_CRZ_SOLID_WASTE: 'crzValidityofExtension/saveCrzSolidWaste',
  GET_CRZ_SOLID_WASTE: 'crzValidityofExtension/getCrzSolidWaste',
  DELETE_CRZ_SOLID_WASTE: 'crzValidityofExtension/deleteCrzSolidWaste',
  SAVE_CRZ_WATER_REQUIREMENT: 'crzValidityofExtension/saveCrzWaterRequirement',
  GET_CRZ_WATER_REQUIREMENT: 'crzValidityofExtension/getCrzWaterRequirement',
  DELETE_CRZ_WATER_REQUIREMENT:
    'crzValidityofExtension/deleteCrzWaterRequirement',
  SAVE_CRZ_DISPOSAL: 'crzValidityofExtension/saveCrzDisposal',
  GET_CRZ_DISPOSAL: 'crzValidityofExtension/getCrzDisposal',
  DELETE_CRZ_DISPOSAL: 'crzValidityofExtension/deleteCrzDisposal',

  // STEP 4
  SAVE_UNDERTAKING: 'crzValidityofExtension/saveUndertaking',
};

export const FORM_CLEARENCE_ID = {
  EC_PART_A: 5,
  EC_PART_C: 2,
  EC_FORM_3: 36,
  EC_FORM_4: 37,
  EC_FORM_5: 34,
  EC_FORM_6: 65,
  EC_FORM_7: 38,
  EC_FORM_8: 39,
  EC_FORM_9: 44,
  EC_FORM_10: 62,
  EC_FORM_11: 225,
  EC_FORM_12: 226,
  EC_FORM_1_B2: 243,
  EC_FORM_2_PRIOR: 246,
  FC_FORM_F: 251,
  APPROVAL_FORM: 17,
};

export const type_of_fields = {
  MAIN_SPECIES: 'Main-Species',
  FRL_2: 'FRL-2',
  FRL_4: 'FRL-4',
};

export const CAF_STATUS = {
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
  GRANTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  EDS_RAISED: 'EDS_RAISED',
};

export const FC_AGENDA_MOM = {
  INITIAL: 'INITIAL',
  DRAFT: 'DRAFT',
  SUBMITTED: 'SUBMITTED',
};

export const STATUS = {
  PENDING: 'Pending',
  VERIFIED: 'Verified',
  REJECTED: 'Rejected',
  ACTIVE: 'Active',
  DEACTIVATE: 'Deactive',
};

export const ACTION_ABBR = {
  EDS_REPLY: 'EDS_REPLY',
  EDS_REPLY_NEW: 'EDS_REPLY_NEW',
  PROCESS_FLOW_FC_RAISE_NODAL: 'PROCESS_FLOW_FC_RAISE_NODAL',
  PROCESS_FLOW_FC_RAISE_DFO: 'PROCESS_FLOW_FC_RAISE_DFO',
  EDS_RAISED_NEW: 'EDS_RAISED_NEW',
  PROCESS_FLOW_FC_NOTING_EDS: 'PROCESS_FLOW_FC_NOTING_EDS',
  PROCESS_FLOW_FC_RAISE_STATE_LEVEL: 'PROCESS_FLOW_FC_RAISE_STATE_LEVEL',
  ADS_RAISED: 'ADS_FORM',
  ADS_RAISED_STATUS: 'ADS_RAISED',
  ADS_REPLY: 'ADS_REPLY',
};

export const FORWARD_EDS = [
  ACTION_ABBR.PROCESS_FLOW_FC_RAISE_NODAL,
  ACTION_ABBR.PROCESS_FLOW_FC_RAISE_DFO,
  ACTION_ABBR.EDS_RAISED_NEW,
  ACTION_ABBR.PROCESS_FLOW_FC_RAISE_STATE_LEVEL,
];

export const CRZ_DOCUMENT_MANDATORY_ABBR = [
  'PROCESS_FLOW_REFER_TO_TOWN_PLANNER_AUTHORITY',
];

export const DEPARTMENT_EDS_EDIT_ACTION = [
  ACTION_ABBR.PROCESS_FLOW_FC_NOTING_EDS,
];

export const STATE_STATUE = {
  ACTIVE: 'active',
  INACTIVE: 'deactive',
};

export const FORM_STATUS = ['DRAFT', 'EDS_RAISED', 'COMPLETED'];

export const APPLICATION_ONLY_ALLOWED_FOR_STATUS = [
  'DRAFT',
  'GRANTED',
  'REJECTED',
  'FORM_RETURN_TO_PP',
];

export const APPLICATION_ONLY_ALLOWED_FOR_VISIBLE_STATUS = [
  'Proposal Withdrawal Request Accepted',
  'Proposal returned in present form',
  'Withdrawal Request Accepted',
];

export const MODULE_ID = {
  USER_REGISTRATION: environment.production ? 752198 : 717048,
  CAF: 131,
  EC_TOR: 86633,
  EC: 132,
  EC_FORM_FIVE: environment.production ? 943634 : 732871,
  EC_FORM_FIVE_PART_TWO: environment.production ? 1583543 : 829484,
  EC_FORM_SEVEN: environment.production ? 1883275 : 795451,
  EC_FORM_SIX: environment.production ? 1719143 : 808539,
  EC_FORM_EIGHT: environment.production ? 1719159 : 808867,
  EC_FORM_NINE: environment.production ? 1719175 : 809276,
  EC_FORM_TEN: environment.production ? 1719180 : 855660,
  // EC_FORM_TEN: environment.production ? 0 : ,
  WL: 86950,
  WLC_Exotics: environment.production ? 50809219 : 22624779,
  CRZ: 95312,
  CRZ_VALIDITY_EXTENSION: environment.production ? 24636125 : 10933700,
  FC: 95310,
  FC_A_PART_B: 162282,
  EC_PRIOR_FORM_TWO: environment.production ? 32862481 : 8588755,
  EC_FORM_DEIAA: environment.production ? 32878686 : 13672206,
  EC_FORM_SEIAA: environment.production ? 1125312 : 13672819,
  FC_FORM_B: environment.production ? 1125312 : 730865,
  FC_FORM_C: environment.production ? 1125665 : 726457,
  FC_FORM_D: environment.production ? 1399421 : 802091,
  FC_FORM_E: environment.production ? 1243326 : 757431,
  FC_FORM_F: environment.production ? 25610687 : 11114780,
  FC_FORM_A_PART_B_DC: environment.production ? 1125383 : 753199,
  FC_C_PART_B: environment.production ? 1125393 : 758331,
  FC_B_PART_B: environment.production ? 1206579 : 778441,
  FC_D_PART_B: environment.production ? 1719185 : 809253,
  EDS: environment.production ? 943631 : 732762,
  FC_FORM_E_PART_B: environment.production ? 1207123 : 778483,
  EC_MS_RECOMMENDATION: environment.production ? 1883339 : 852459,
  NOTING: environment.production ? 1687821 : 854495,
  AGENDA: environment.production ? 2021560 : 1068294,
  MOM: environment.production ? 3045849 : 1044928,
  FC_COMPLAINCE_STATUS: environment.production ? 3039326 : 1200477,
  EDS_QUERY: environment.production ? 2370310 : 1199445,
  FC_PART_4: environment.production ? 2508028 : 1292954,
  WITHDRAWAL_PROPOSAL: environment.production ? 6232965 : 1352449,
  CRZ_TRANSFER_PROPOSAL: environment.production ? 6235778 : 1401460,
  FC_PART_3: environment.production ? 3901446 : 1473878,
  // FC_FORM_A_PART_3 : environment.production ? 0 : 752623,
  FC_FORM_A_PART_4: environment.production ? 4700281 : 752624,
  FC_FORM_B_PART_4: environment.production ? 6235943 : 1608182,
  CERTIFICATE: environment.production ? 5549613 : 1747467,
  EC_FORM_TWELVE: environment.production ? 6235979 : 1826941,
  EC_FORM_ELEVEN: environment.production ? 6235993 : 1825534,
  AUTHORITY_LETTER: environment.production ? 6236005 : 1747467,
  EC_FORM_TWO: environment.production ? 6908811 : 2010233,
  CAMPA: environment.production ? 10122189 : 2051808,
  WLC_PAYMENT_PP: environment.production ? 3113834 : 1405663,
  FC_FORM_G: environment.production ? 51711331 : 15455119,
  FC_F_PART_B: environment.production ? 1125393 : 18307084,
  ACA_LIST: environment.production ? 68768784 : 1068294,
  LEGAL_REPO: environment.production ? 72255097 : 2095574,
  FC_SIR_FORM: environment.production ? 84764326 : 41638560,
  //aca form
  ACAUPLOAD: 95310,
  //aca form Data
  ACAFORM: 95310,
  GEAC_FORM_ONE: environment.production ? 0 : 51545455,
  GEAC_FORM_TWO: environment.production ? 0 : 59909924,
  MEETING_NOTICE: environment.production ? 114506804 : 63115177,
  FORM_C_EXEMPTED: environment.production ? 116438616 : 65616916,
  WORKING_PERMISSION: environment.production ? 68108664 : 68108664,
  PAYMENT: environment.production ? 0 : 72756165
};

export const EC_TypeOfWaste = {
  SOLID_WASTE: 'Solid Waste',
  PLASTIC_WASTE: 'Plastic waste',
  E_WASTE: 'e-waste',
  BATTERIES: 'batteries',
  BIO_MEDICAL_WASTE: 'Bio-medical waste',
  HAZARDOUS_WASTES: 'Hazardous wastes',
  CONSTRUCTION_OR_DEMOLITION_WASTES: 'Construction or demolition wastes',
  OTHER_WASTES: 'Other wastes',
  SURPLUS_PRODUCT: 'Surplus product',
};

export const DOCUMENT_TYPE = {
  SPCB_COMMENT: 'SPCB_COMMENT',
  PP_PAYMENT_RECEIPT: 'PP_PAYMENT_RECEIPT',
  PRIMARY_KML: 'Main_kml',
  ALT_KML: 'Alternate_kml',
  JUSTIFICATION_COPY: 'Justification_copy',
  ORDER_COPY: 'Order_copy',
  COURT_ORDER: 'Court_order',
  DIRECTION_COPY: 'Direction_copy',
  VIOLATION_REPORT: 'Violation_report',
  ALTERNATIVE_SITE_ORDER_COPY: 'ALTERNATIVE_SITE_ORDER_COPY',
  EC_LETTER: 'EC_LETTER',
  EC_LETTER_PROJECT: 'EC_LETTER_PROJECT',
  COPY_OF_APPROVAL: 'COPY_OF_APPROVAL',
  NBWL_RECOMMENDATIONS: 'NBWL_RECOMMENDATIONS',
  COPY_OF_CONSENT_ORDER: 'COPY_OF_CONSENT_ORDER',
  CONSENT_ORDER: 'CONSENT_ORDER',
  LATEST_CONSENT__OPERATE_ORDER: 'LATEST_CONSENT__OPERATE_ORDER',
  LATEST_CONSENT__OPERATE_ORDER_ESTABLISH: 'LATEST_CONSENT__OPERATE_ORDER_ESTABLISH',
  EC_COMPLIANCE_REPORT: 'EC_COMPLIANCE_REPORT',
  CORRIGENDUM_AMENDMENT_TRANSFER_TYPE: 'CORRIGENDUM_AMENDMENT_TRANSFER_TYPE',
  NOTE_ON_CHRONOLOGY: 'NOTE_ON_CHRONOLOGY',
  NOTE_ON_STATUS_OF_IMPLEMENTATION: 'NOTE_ON_STATUS_OF_IMPLEMENTATION',
  BASELINE_SITUATION_SUMMARY: 'BASELINE_SITUATION_SUMMARY',
  MONITORING_LOCATIONS: 'MONITORING_LOCATIONS',
  MONITORING_LOCATIONS_MAP: 'MONITORING_LOCATIONS_MAP',
  PROPOSED_BASELINE_COLLECTION: 'PROPOSED_BASELINE_COLLECTION',
  LATEST_CONSENT_ORDER: 'LATEST_CONSENT_ORDER',
  NOTIFICATION_OF_SAID_AREA: 'NOTIFICATION_OF_SAID_AREA',
  RECOMMENDATION_LETTER: 'RECOMMENDATION_LETTER',
  LAYOUT_PLAN_COMPONENT: 'LAYOUT_PLAN_COMPONENT',
  LETTER_OF_INTENT: 'LETTER_OF_INTENT',
  DISTRICT_SURVEY_REPORT_EC_ENCLOSURES: 'DISTRICT_SURVEY_REPORT_EC_ENCLOSURES',
  REPLENISHMENT_STUDY_REPORT_EC_ENCLOSURES:
    'REPLENISHMENT_STUDY_REPORT_EC_ENCLOSURES',
  EMP_REPORT_EC_ENCLOSURES: 'EMP_REPORT_EC_ENCLOSURES',
  CONCEPTUAL_PLAN: 'CONCEPTUAL_PLAN',
  SCHEMATIC_REPRESENTATION: 'SCHEMATIC_REPRESENTATION',
  TOR_LETTER: 'TOR_LETTER',
  AGENDA_PROPOSAL_DOCUMENT: 'AGENDA_PROPOSAL_DOCUMENT',
  AGENDA_ADDITIONAL_DOCUMENT: 'AGENDA_ADDITIONAL_DOCUMENT',
  AGENDA_DOCUMENT_ADDITIONAL: 'AGENDA_DOCUMENT_ADDITIONAL',
  LETTER_OF_AMENDMENT_TOR: 'LETTER_OF_AMENDMENT_TOR',
  RECOMMENDATIONS_OF_EAC: 'RECOMMENDATIONS_OF_EAC',
  SIGNED_PROCEEDINGS: 'SIGNED_PROCEEDINGS',
  ACTION_PLAN_ON_ISSUE: 'ACTION_PLAN_ON_ISSUE',
  EC_C_ADDITIONAL_DOCUMENT: 'EC_C_ADDITIONAL_DOCUMENT',
  GROUND_WATER_AUTHORITY: 'GROUND_WATER_AUTHORITY',
  CONSERVATION_PLAN: 'CONSERVATION_PLAN',
  APPROVAL_COPY: 'APPROVAL_COPY',
  FINAL_EIA_EMP_REPORT: 'FINAL_EIA_EMP_REPORT',
  EXECUTIVE_SUMMARY: 'EXECUTIVE_SUMMARY',
  APPROVED_MINING_PLAN: 'APPROVED_MINING_PLAN',
  AUTHORITY_LETTER: 'AUTHORITY_LETTER',
  EC_ATR_NON_COMPLIANCE: 'EC_ATR_NON_COMPLIANCE',

  FC_APPROVED_MINERAL_OIL_PLAN: 'FC_APPROVED_MINERAL_OIL_PLAN',
  FC_COPY_MAP_OUTER_BOUNDARY_MINERAL_OIL:
    'FC_COPY_MAP_OUTER_BOUNDARY_MINERAL_OIL',
  FC_copy_detailed_land_use_plan_mineral_oil:
    'FC_copy_detailed_land_use_plan_mineral_oil',
  FC_WLC_ROFR_CERTIFICATION: 'FC_WLC_ROFR_CERTIFICATION',
  FC_COPY_MINERAL_OIL_EXPLORATION_LICENCE:
    'FC_COPY_MINERAL_OIL_EXPLORATION_LICENCE',
  FC_COPY_MINERAL_OIL_TRANSPORTATION_NOTE:
    'FC_COPY_MINERAL_OIL_TRANSPORTATION_NOTE',
  FC_COPY_MINERAL_OIL_RESERVOIR_LIFE: 'FC_COPY_MINERAL_OIL_RESERVOIR_LIFE',
  FC_COPY_DEVELOPMENT_PLAN_MINERAL_OIL_EXTRACTION:
    'FC_COPY_DEVELOPMENT_PLAN_MINERAL_OIL_EXTRACTION',

  FINAL_LAYOUT_PLAN: 'FINAL_LAYOUT_PLAN',
  DISTRICT_SURVEY_REPORT: 'DISTRICT_SURVEY_REPORT',
  REPLENISHMENT_STUDY_REPORT: 'REPLENISHMENT_STUDY_REPORT',
  ADDITIONAL_FILE: 'ADDITIONAL_FILE',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
  GEO_REFERENCED_MAP: 'GEO_REFERENCED_MAP',
  COPY_OF_COST_BENEFIT_ANALYSIS: 'WL_COST_BENEFIT_ANALYSIS',
  COPY_OF_PROSPECTING_LICENCE: 'COPY_OF_PROSPECTING_LICENCE',
  COPY_OF_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION:
    'COPY_OF_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION',
  FC_copy_detailed_land_use_plan: 'FC_copy_detailed_land_use_plan',
  JUSTIFICATION_FOR_LOCATING_PROJECT: 'JUSTIFICATION_FOR_LOCATING_PROJECT',
  WL_ANIMAL_PASSAGE_PLAN: 'WL_ANIMAL_PASSAGE_PLAN',
  WL_TRANSMISSION_LINES: 'WL_TRANSMISSION_LINES',
  WL_GEO_REFERENCED_MAP: 'WL_GEO_REFERENCED_MAP',
  WL_KML: 'WL_KML',
  WL_LOCATION_AND_MAP: 'WL_LOCATION_AND_MAP',
  WL_EC_LETTER: 'WL_EC_LETTER',
  WL_ADDITIONAL_INFORMATION: 'WL_ADDITIONAL_INFORMATION',
  WL_APPROVAL_CAT_PLAN: 'WL_APPROVAL_CAT_PLAN',
  WL_APPROVED_CAT_PLAN: 'WL_APPROVED_CAT_PLAN',
  WL_BIODIVERSITY_IMPACT_ASSESSMENT: 'WL_BIODIVERSITY_IMPACT_ASSESSMENT',
  WL_PREVIOUS_PROJECTS: 'WL_PREVIOUS_PROJECTS',
  WL_APPROVED_MINING_PLAN: 'WL_APPROVED_MINING_PLAN',
  WL_OUTER_BOUNDARY_MAP: 'WL_OUTER_BOUNDARY_MAP',
  WL_PROSPECTING_LICENCE: 'WL_PROSPECTING_LICENCE',
  WL_ACCORDED_APPROVAL_COPY: 'WL_ACCORDED_APPROVAL_COPY',
  WL_TRANSPORTATION_COPY: 'WL_TRANSPORTATION_COPY',
  WL_ALT_KML: 'WL_ALT_KML',
  MUCK_RESTORATION_PLAN: 'MUCK_RESTORATION_PLAN',
  JUSTIFICATION_FOR_PROJECT: 'JUSTIFICATION_FOR_PROJECT',
  FC_REMOVE_DOCUMENT: 'FC_REMOVE_DOCUMENT',
  COPY_OF_MOU: 'COPY_OF_MOU',
  FC_GEO_REFERENCED_MAP: 'FC_GEO_REFERENCED_MAP',
  FC_KML: 'FC_KML',
  FC_B_PARTB_WORKING_PLAN_PRESCRIPTION: 'FC_B_PARTB_WORKING_PLAN_PRESCRIPTION',
  FC_B_PARTB_SITE_INSPECTION_COPY: 'FC_B_PARTB_SITE_INSPECTION_COPY',
  FC_B_PART_B_COPY_OF_NOC: 'FC_B_PART_B_COPY_OF_NOC',
  FC_B_PART_B_GEO_REFERENCED_MAP: 'FC_B_PART_B_GEO_REFERENCED_MAP',
  FC_B_PART_B_CA_SCHEME_COPY: 'FC_B_PART_B_CA_SCHEME_COPY',
  FC_B_PART_B_AFF_KML: 'FC_B_PART_B_AFF_KML',
  FC_B_PART_B_KML: 'FC_B_PART_B_KML',
  FC_C_PART_B_KML: 'FC_C_PART_B_KML',
  FC_C_PART_B_AFF_KML: 'FC_C_PART_B_AFF_KML',
  FC_FACTSHEET_CERTIFICATE: 'FC_FACTSHEET_CERTIFICATE',
  FC_FACTSHEET_PLAN_COPY: 'FC_FACTSHEET_PLAN_COPY',
  MEETING_NOTICE: 'MEETING_NOTICE',

  //  FOrm Form D part B

  FC_D_PARTB_WORKING_PLAN_PRESCRIPTION: 'FC_D_PARTB_WORKING_PLAN_PRESCRIPTION',
  FC_D_PART_B_KML: 'FC_D_PART_B_KML',
  FC_D_PART_B_COPY_OF_NOC: 'FC_D_PART_B_COPY_OF_NOC',
  FC_D_PARTB_SITE_INSPECTION_COPY: 'FC_D_PARTB_SITE_INSPECTION_COPY',
  FC_D_PART_B_LOCATED_NEAR_BOUNDARY: 'FC_D_PART_B_LOCATED_NEAR_BOUNDARY',
  FC_A_PART_B_REVISED_KML: 'FC_A_PART_B_REVISED_KML',
  FC_ALT_KML: 'FC_ALT_KML',
  //new kml added for fc-inspection report for project-details
  FC_ALT_ADDITIONAL_KML: 'FC_ALT_ADDITIONAL_KML',
  FC_ALT_PDF_IMG: 'FC_ALT_PDF_IMG',
  FC_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION:
    'FC_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION',
  FC_COST_BENEFIT_ANALYSIS: 'FC_COST_BENEFIT_ANALYSIS',
  FC_EC_LETTER: 'FC_EC_LETTER',
  FC_COPY_OF_APPROVAL: 'FC_COPY_OF_APPROVAL',
  FC_COPY_OF_APPLICATION: 'FC_COPY_OF_APPLICATION',
  FC_APPROVED_MINING_PLAN: 'FC_APPROVED_MINING_PLAN',
  FC_COPY_MAP_OUTER_BOUNDARY: 'FC_COPY_MAP_OUTER_BOUNDARY',
  FC_COPY_PERSPECTING_LICENCE: 'FC_COPY_PERSPECTING_LICENCE',
  FC_copy_approval_accorded_moefcc: 'FC_copy_approval_accorded_moefcc',
  FC_copy_transportation_minerals_proposed:
    'FC_copy_transportation_minerals_proposed',
  FC_A_PART_B_KML: 'FC_A_PART_B_KML',
  FC_F_PART_B_KML: 'FC_F_PART_B_KML',
  FC_A_PART_B_GEO_REFERENCED_MAP: 'FC_A_PART_B_GEO_REFERENCED_MAP',
  FC_A_PART_B_SURVEY_INDIA: 'FC_A_PART_B_SURVEY_INDIA',
  FC_A_PART_B_COMPETENT_ATHORITY_CERTIFICATE:
    'FC_A_PART_B_COMPETENT_ATHORITY_CERTIFICATE',
  FC_A_PART_B_CA_SCHEME_COPY: 'FC_A_PART_B_CA_SCHEME_COPY',
  CRZ_AGENCY_UPLOAD: 'CRZ_AGENCY_UPLOAD',
  CRZ_DETAILS_OF_TREE: 'CRZ_DETAILS_OF_TREE',
  CRZ_COMPLIANCE_OF_THE_CONDITIONS: 'CRZ_COMPLIANCE_OF_THE_CONDITIONS',
  CRZ_ACTION_PLAN_BY_SCZMA: 'CRZ_ACTION_PLAN_BY_SCZMA',
  Compensatory_afforestation_plan: 'Compensatory_afforestation_plan',
  Copy_Of_NOC: 'Copy_Of_NOC',
  Upload_Disaster_Management_Plan: 'Upload_Disaster_Management_Plan',
  Copy_Central_Ground_Water: 'Copy_Central_Ground_Water',
  Recommendation_Copy_Of_SCZMA: 'Recommendation_Copy_Of _SCZMA',
  Social_And_Environmental_Issues: 'Social_And_Environmental_Issues',
  Additional_Information_If: 'Additional_Information_If',
  Upload_Recommendation_EIAs_Terrestrial:
    'Upload_Recommendation_EIAs_Terrestrial',
  Upload_Recommendation_EIAs_Marine: 'Upload_Recommendation_EIAs_Marine',
  FC_ADDITIONAL_INFORMATION: 'FC_ADDITIONAL_INFORMATION',
  FC_ADDITIONAL_INFORMATION_ADDITIONAL_DFA:
    'FC_ADDITIONAL_INFORMATION_ADDITIONAL_DFA',
  WITHDRAW_FORM: 'WITHDRAW_FORM',
  UPLOAD_CRZ_MAP_HTL: 'UPLOAD_CRZ_MAP_HTL',
  DELAY_IN_IMPLEMENTATION: 'DELAY_IN_IMPLEMENTATION',
  COPY_OF_CRZ_LETTER: 'COPY_OF_CRZ_LETTER',
  SIX_MONTH_MONITORING_COMPLIANCE_REPORT:
    'SIX_MONTH_MONITORING_COMPLIANCE_REPORT',
  UPLOAD_PROJECT_SUPERIMPOSED_CRZ: 'UPLOAD_PROJECT_SUPERIMPOSED_CRZ',
  CRZ_MAP_SITE_PROJECT: 'CRZ_MAP_SITE_PROJECT',
  UPLOAD_CRZ_MAP_NOTIFIED_ESA: 'UPLOAD_CRZ_MAP_NOTIFIED_ESA',
  FC_A_PART_B_COPY_OF_NOC: 'FC_A_PART_B_COPY_OF_NOC',
  SITE_INSPECTION_COPY: 'SITE_INSPECTION_COPY',
  FC_C_PARTB_SITE_INSPECTION_COPY: 'FC_C_PARTB_SITE_INSPECTION_COPY',
  WORKING_PLAN_PRESCRIPTION: 'WORKING_PLAN_PRESCRIPTION',
  FC_C_PARTB_WORKING_PLAN_PRESCRIPTION: 'FC_C_PARTB_WORKING_PLAN_PRESCRIPTION',
  FC_C_PART_B_COPY_OF_NOC: 'FC_C_PART_B_COPY_OF_NOC',
  FC_C_PART_B_GEO_REFERENCED_MAP: 'FC_C_PART_B_GEO_REFERENCED_MAP',
  FC_C_PART_B_CA_SCHEME_COPY: 'FC_C_PART_B_CA_SCHEME_COPY',
  FC_F_PART_B_GEO_REFERENCED_MAP: 'FC_F_PART_B_GEO_REFERENCED_MAP',
  FC_F_PART_B_CA_SCHEME_COPY: 'FC_F_PART_B_CA_SCHEME_COPY',
  FC_F_PART_B_CERTIFICATE: 'FC_F_PART_B_CERTIFICATE',
  FC_PARTB_RECOMMEND_FILE_COPY: 'FC_PARTB_RECOMMEND_FILE_COPY',
  USER_REGISTRATION_VOTER_CARD: 'USER_REGISTRATION_VOTER_CARD',
  USER_AUTHORITY_LETTER: 'USER_AUTHORITY_LETTER',
  FC_FORM_B_COPY_OF_MAP_OF_PATCH_PREPARED:
    'FC_FORM_B_COPY_OF_MAP_OF_PATCH_PREPARED',
  FC_FORM_B_COPY_OF_DOC_PROOF: 'FC_FORM_B_COPY_OF_DOC_PROOF',
  FC_FORM_B_COPY_OF_TRANSFER_DEED: 'FC_FORM_B_COPY_OF_TRANSFER_DEED',
  FC_FORM_B_COPY_OF_MOEF_APPROVAL: 'FC_FORM_B_COPY_OF_MOEF_APPROVAL',
  FC_FORM_B_MAP_OF_PATCH_KML: 'FC_FORM_B_MAP_OF_PATCH_KML',
  FC_FORM_B_ALL_PATCH_KML: 'FC_FORM_B_ALL_PATCH_KML',
  FC_FORM_B_GEO_REFERENCED_MAP: 'FC_FORM_B_GEO_REFERENCED_MAP',
  FC_FORM_B_FORESTLAND_PROPOSED_DIVERSION_KML:
    'FC_FORM_B_FORESTLAND_PROPOSED_DIVERSION_KML',
  FC_FORM_B_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION:
    'FC_FORM_B_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION',
  FC_FORM_B_COPY_OF_MOU: 'FC_FORM_B_COPY_OF_MOU',
  FC_FORM_B_ALT_KML: 'FC_FORM_B_ALT_KML',
  FC_FORM_B_COST_BENEFIT_ANALYSIS: 'FC_FORM_B_COST_BENEFIT_ANALYSIS',
  FC_FORM_B_EC_LETTER: 'FC_FORM_B_EC_LETTER',
  FC_FORM_B_COPY_OF_APPROVAL: 'FC_FORM_B_COPY_OF_APPROVAL',
  FC_FORM_B_COPY_OF_APPLICATION: 'FC_FORM_B_COPY_OF_APPLICATION',
  FC_FORM_B_APPROVED_MINING_PLAN: 'FC_FORM_B_APPROVED_MINING_PLAN',
  FC_FORM_B_COPY_MAP_OUTER_BOUNDARY: 'FC_FORM_B_COPY_MAP_OUTER_BOUNDARY',
  FC_FORM_B_COPY_PERSPECTING_LICENCE: 'FC_FORM_B_COPY_PERSPECTING_LICENCE',
  FC_FORM_B_COPY_APPROVAL_ACCORDED_MOEFCC:
    'FC_FORM_B_COPY_APPROVAL_ACCORDED_MOEFCC',
  FC_FORM_B_COPY_TRANSPORTATION_MINERALS_PROPOSED:
    'FC_FORM_B_COPY_TRANSPORTATION_MINERALS_PROPOSED',
  FC_FORM_B_COPY_DETAILED_LAND_USE_PLAN:
    'FC_FORM_B_COPY_DETAILED_LAND_USE_PLAN',

  FC_FORM_C_SURVEY_OF_INDIA: 'FC_FORM_C_SURVEY_OF_INDIA',
  FC_FORM_C_LETTER_OF_INTENT: 'FC_FORM_C_LETTER_OF_INTENT',
  FC_FORM_C_GEO_REFERENCED_MAP: 'FC_FORM_C_GEO_REFERENCED_MAP',
  FC_FORM_C_FOREST_LAND_PROPOSED_DIVERSION_KML:
    'FC_FORM_C_FOREST_LAND_PROPOSED_DIVERSION_KML',
  FC_FORM_C_COST_BENEFIT_ANALYSIS: 'FC_FORM_C_COST_BENEFIT_ANALYSIS',
  FC_FORM_C_EC_LETTER: 'FC_FORM_C_EC_LETTER',
  FC_FORM_C_COPY_OF_APPROVAL: 'FC_FORM_C_COPY_OF_APPROVAL',
  FC_FORM_C_COPY_OF_APPLICATION: 'FC_FORM_C_COPY_OF_APPLICATION',

  FC_FORM_C_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION:
    'FC_FORM_C_MAP_OF_LAND_FOR_COMPENSATORY_AFFORESTATION',
  FC_FORM_C_COPY_OF_MOU: 'FC_FORM_C_COPY_OF_MOU',

  FC_FORM_C_ALT_KML: 'FC_FORM_C_ALT_KML',
  FC_FORM_C_DETAILS_OF_EXISTING_PATH_COPY:
    'FC_FORM_C_DETAILS_OF_EXISTING_PATH_COPY',
  FC_FORM_C_JUSTIFICATION_FOR_EXTENSION_COPY:
    'FC_FORM_C_JUSTIFICATION_FOR_EXTENSION_COPY',
  FC_FORM_C_NOTE_CONTSINING_DETAILS_COPY:
    'FC_FORM_C_NOTE_CONTSINING_DETAILS_COPY',

  FC_FORM_D_COPY_APPROVED_MINING_PLAN: 'FC_FORM_D_COPY_APPROVED_MINING_PLAN',
  FC_FORM_D_COPY_DPR: 'FC_FORM_D_COPY_DPR',
  FC_FORM_D_LETTER_OF_INTENT: 'FC_FORM_D_LETTER_OF_INTENT',
  FC_FORM_D_KML: 'FC_FORM_D_KML',
  FC_FORM_D_GEO_REFERENCED_MAP: 'FC_FORM_D_GEO_REFERENCED_MAP',
  FC_FORM_D_COPY_MAP_OUTER_BOUNDARY: 'FC_FORM_D_COPY_MAP_OUTER_BOUNDARY',
  FC_APPROVAL_CAT_PLAN: 'FC_APPROVAL_CAT_PLAN',
  FC_APPROVED_CAT_PLAN: 'FC_APPROVED_CAT_PLAN',
  EDS_DETAIL: 'EDS_DETAIL',
  EDS_APPROVAL_DOCUMENT: 'EDS_APPROVAL_DOCUMENT',

  EC_FORM_FIVE_TOR_LETTER: 'EC_FORM_FIVE_TOR_LETTER',
  EC_FORM_FIVE_LETTER_OF_AMENDMENT: 'EC_FORM_FIVE_LETTER_OF_AMENDMENT',
  EC_FORM_FIVE_EIA_EMP: 'EC_FORM_FIVE_EIA_EMP',
  EC_FORM_FIVE_EXECUTIVE_SUMMARY: 'EC_FORM_FIVE_EXECUTIVE_SUMMARY',
  EC_FORM_FIVE_EIA_REPORT_IN_VERNACULAR:
    'EC_FORM_FIVE_EIA_REPORT_IN_VERNACULAR',
  EC_FORM_FIVE_LOCATION_OF_MAP: 'EC_FORM_FIVE_LOCATION_OF_MAP',

  EC_FORM_SIX_COPY_OF_EC_LETTER: 'EC_FORM_SIX_COPY_OF_EC_LETTER',
  EC_FORM_SIX_EC_OBTAINED_DOC0UMENT: 'EC_FORM_SIX_EC_OBTAINED_DOC0UMENT',
  EC_FORM_SIX_CHRONOLOGY_OF_CLEARENCE: 'EC_FORM_SIX_CHRONOLOGY_OF_CLEARENCE',

  EC_FORM_SIX_LATEST_CONSENT_ORDER_COPY:
    'EC_FORM_SIX_LATEST_CONSENT_ORDER_COPY',
  EC_FORM_SIX_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL:
    'EC_FORM_SIX_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL',
  EC_FORM_SIX_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION:
    'EC_FORM_SIX_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION',
  EC_FORM_SIX_SUBSTANTIATING_REASONS_OF_DELAY:
    'EC_FORM_SIX_SUBSTANTIATING_REASONS_OF_DELAY',
  EC_FORM_SIX_BAR_CHART_OF_IMPLEMENTATION:
    'EC_FORM_SIX_BAR_CHART_OF_IMPLEMENTATION',
  EC_FORM_SIX_AMENDMENT_DETAILS_SUBSTANTIATING_REASONS_OF_DELAY:
    'EC_FORM_SIX_AMENDMENT_DETAILS_SUBSTANTIATING_REASONS_OF_DELAY',
  EC_FORM_SIX_AMENDMENT_DETAILS_NEW_SUBSTANTIATING_REASONS_OF_DELAY:
    'EC_FORM_SIX_AMENDMENT_DETAILS_NEW_SUBSTANTIATING_REASONS_OF_DELAY',
  EC_FORM_SIX_AMENDMENT_DETAILS_SUBSTANTIATING_NEW_REASONS_OF_DELAY:
    'EC_FORM_SIX_AMENDMENT_DETAILS_SUBSTANTIATING_NEW_REASONS_OF_DELAY',
  EC_FORM_SIX_AMENDMENT_DETAILS_BAR_CHART_OF_IMPLEMENTATION:
    'EC_FORM_SIX_AMENDMENT_DETAILS_BAR_CHART_OF_IMPLEMENTATION',
  EC_FORM_SEVEN_COPY_OF_EC_LETTER: 'EC_FORM_SEVEN_COPY_OF_EC_LETTER',
  EC_FORM_SEVEN_EC_OBTAINED_DOCUMENT: 'EC_FORM_SEVEN_EC_OBTAINED_DOCUMENT',
  EC_FORM_SEVEN_CHRONOLOGY_OF_CLEARENCE:
    'EC_FORM_SEVEN_CHRONOLOGY_OF_CLEARENCE',
  EC_FORM_SEVEN_LATEST_CONSENT_ORDER_COPY:
    'EC_FORM_SEVEN_LATEST_CONSENT_ORDER_COPY',
  EC_FORM_SEVEN_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL:
    'EC_FORM_SEVEN_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL',
  EC_FORM_SEVEN_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION:
    'EC_FORM_SEVEN_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION',

  EC_FORM_SEVEN_OBJECTION_FROM_TRANSFEROR:
    'EC_FORM_SEVEN_OBJECTION_FROM_TRANSFEROR',
  EC_FORM_SEVEN_UNDERTAKING_BY_TRANSFEREE:
    'EC_FORM_SEVEN_UNDERTAKING_BY_TRANSFEREE',
  EC_FORM_SEVEN_TRANFERE_COPY_FROM_COMPETENT:
    'EC_FORM_SEVEN_TRANFERE_COPY_FROM_COMPETENT',
  EC_FORM_SEVEN_PREVIOUS_COMPLIANCE_REPORT:
    'EC_FORM_SEVEN_PREVIOUS_COMPLIANCE_REPORT',
  EC_FORM_SEVEN_DETAILS_OF_ADDITIONAL_INFORMATION:
    'EC_FORM_SEVEN_DETAILS_OF_ADDITIONAL_INFORMATION',
  LETTER_OF_RECOMENDATION_FC_PART_4: 'LETTER_OF_RECOMENDATION_FC_PART_4',
  SIGNED_DOCUMENT_FC_PART_IV: 'SIGNED_DOCUMENT_FC_PART_IV',
  UPLOAD_NOTIFICATION_FC_PART_4: 'UPLOAD_NOTIFICATION_FC_PART_4',
  DATE_WITHDRAWN_FC_PART_4: 'DATE_WITHDRAWN_FC_PART_4',

  //Form C Exempted Category
  FORM_C_EXEMPTED_CATEGORY: 'FORM_C_EXEMPTED_CATEGORY',
  Details_of_Existing_Paths: 'Details_of_Existing_Paths',

  //crzTransferProposal
  UPLOAD_CRZ_CLEARANCE_LETTER: 'UPLOAD_CRZ_CLEARANCE_LETTER',
  COPY_OF_AFFIDAVIT_BY_TRANSFEROR: 'COPY_OF_AFFIDAVIT_BY_TRANSFEROR',
  COPY_OF_AFFIDAVIT_BY_TRANSFEREE: 'COPY_OF_AFFIDAVIT_BY_TRANSFEREE',
  REASON_FOR_TRANSFER: 'REASON_FOR_TRANSFER',
  REASON_FOR_TRANSFER2: 'REASON_FOR_TRANSFER2',
  UPLOAD_POLLUTION_CONTROL_BOARD_NOC: 'UPLOAD_POLLUTION_CONTROL_BOARD_NOC',
  UPLOAD_LATEST_CONSENT_LETTER: 'UPLOAD_LATEST_CONSENT_LETTER',
  UPLOAD_CRZ_TRANSFER_KML: 'UPLOAD_CRZ_TRANSFER_KML',
  UPLOAD_TRANSFER_OF_APPROVAL: 'UPLOAD_TRANSFER_OF_APPROVAL',
  UPLOAD_APPLICABLE_COMPLIANCES: 'UPLOAD_APPLICABLE_COMPLIANCES',

  // EC FORM EIGHT
  EC_FORM_EIGHT_COPY_OF_EC_LETTER: 'EC_FORM_EIGHT_COPY_OF_EC_LETTER',
  EC_FORM_EIGHT_EARLIER_TOR: 'EC_FORM_EIGHT_EARLIER_TOR',
  EC_FORM_EIGHT_UPLOAD_CHRONOLOGY_TOR: 'EC_FORM_EIGHT_UPLOAD_CHRONOLOGY_TOR',
  EC_FORM_EIGHT_NO_OBJECTION_FROM_TRANSFEROR:
    'EC_FORM_EIGHT_NO_OBJECTION_FROM_TRANSFEROR',
  EC_FORM_EIGHT_UNDERTAKING_BY_TRANSFEREE:
    'EC_FORM_EIGHT_UNDERTAKING_BY_TRANSFEREE',
  EC_FORM_EIGHT_TRANFEREE_COPY_FROM_COMPETENT:
    'EC_FORM_EIGHT_TRANFEREE_COPY_FROM_COMPETENT',
  EC_FORM_8_ADDITIONAL_INFORMATION: 'EC_FORM_8_ADDITIONAL_INFORMATION',
  EC_FORM_EIGHT_OBJECTION_FROM_TRANSFEROR:
    'EC_FORM_EIGHT_OBJECTION_FROM_TRANSFEROR',
  EC_FORM_EIGHT_TRANFERE_COPY_FROM_COMPETENT:
    'EC_FORM_EIGHT_TRANFERE_COPY_FROM_COMPETENT',

  EC_FORM_NINE_GOVT_ORDER: 'EC_FORM_NINE_GOVT_ORDER',
  EC_FORM_NINE_COURT_ORDER: 'EC_FORM_NINE_COURT_ORDER',
  EC_FORM_NINE_COPY_OF_DIRECTION: 'EC_FORM_NINE_COPY_OF_DIRECTION',
  EC_FORM_NINE_REPORT_ON_VIOLATION: 'EC_FORM_NINE_REPORT_ON_VIOLATION',
  EC_FORM_NINE_EC_LETTER_COPY: 'EC_FORM_NINE_EC_LETTER_COPY',
  EC_FORM_NINE_LETTER_OF_AMENDMENT: 'EC_FORM_NINE_LETTER_OF_AMENDMENT',
  EC_FORM_NINE_VESTING_ORDER_COPY: 'EC_FORM_NINE_VESTING_ORDER_COPY',
  EC_FORM_NINE_UPLOAD_KML: 'EC_FORM_NINE_UPLOAD_KML',

  EC_FORM_TEN_GOVT_ORDER: 'EC_FORM_TEN_GOVT_ORDER',
  EC_FORM_TEN_COURT_ORDER: 'EC_FORM_TEN_COURT_ORDER',
  EC_FORM_TEN_COPY_OF_DIRECTION: 'EC_FORM_TEN_COPY_OF_DIRECTION',
  EC_FORM_TEN_REPORT_ON_VIOLATION: 'EC_FORM_TEN_REPORT_ON_VIOLATION',
  EC_FORM_TEN_EC_LETTER: 'EC_FORM_TEN_EC_LETTER',
  EC_FORM_TEN_COPY_OF_EC_LETTER: 'EC_FORM_TEN_COPY_OF_EC_LETTER',
  EC_FORM_TEN_CONSENT_ORDER: 'EC_FORM_TEN_CONSENT_ORDER',
  EC_FORM_TEN_AUTHORIZATION_ORDER: 'EC_FORM_TEN_AUTHORIZATION_ORDER',
  EC_FORM_TEN_NOTIFICATION_COPY: 'EC_FORM_TEN_NOTIFICATION_COPY',
  EC_FORM_TEN_REPORT_ON_SEGREGATION: 'EC_FORM_TEN_REPORT_ON_SEGREGATION',
  EC_FORM_TEN_REPORT_ON_DETAILS_OF_R3: 'EC_FORM_TEN_REPORT_ON_DETAILS_OF_R3',
  EC_FORM_TEN_REPORT_ON_EFFLUENT_TREATMENT:
    'EC_FORM_TEN_REPORT_ON_EFFLUENT_TREATMENT',
  EC_FORM_TEN_REPORT_ON_PROPOSAL: 'EC_FORM_TEN_REPORT_ON_PROPOSAL',
  EC_FORM_TEN_REPORT_ON_MEMBERSHIP: 'EC_FORM_TEN_REPORT_ON_MEMBERSHIP',
  EC_FORM_TEN_ACHIEVE_ZERO_DISCHARGE: 'EC_FORM_TEN_ACHIEVE_ZERO_DISCHARGE',
  EC_FORM_TEN_MEMBERSHIP_OF_CETP: 'EC_FORM_TEN_MEMBERSHIP_OF_CETP',
  EC_FORM_TEN_REPORT_ON_PROPOSAL_FOR_R4:
    'EC_FORM_TEN_REPORT_ON_PROPOSAL_FOR_R4',
  EC_FORM_TEN_REPORT_ON_MEMBERSHIP_OF_CSLS:
    'EC_FORM_TEN_REPORT_ON_MEMBERSHIP_OF_CSLS',
  EC_FORM_TEN_REPORT_ON_MEMBERSHIP_OF_CHWI:
    'EC_FORM_TEN_REPORT_ON_MEMBERSHIP_OF_CHWI',
  EC_FORM_TEN_CERTIFICATE_OF_NO_INC_IN_POLLUTION:
    'EC_FORM_TEN_CERTIFICATE_OF_NO_INC_IN_POLLUTION',
  EC_FORM_TEN_APPROVAL_FROM_AUTHORITY: 'EC_FORM_TEN_APPROVAL_FROM_AUTHORITY',
  EC_FORM_TEN_PROPOSAL_FOR_SWITCHING: 'EC_FORM_TEN_PROPOSAL_FOR_SWITCHING',
  EC_FORM_TEN_PROPOSAL_FOR_UPGRADATION_OF_APCM:
    'EC_FORM_TEN_PROPOSAL_FOR_UPGRADATION_OF_APCM',
  EC_FORM_TEN_PROPOSAL_FOR_INSTALLATION_OF_APCM:
    'EC_FORM_TEN_PROPOSAL_FOR_INSTALLATION_OF_APCM',

  FC_FORM_A_PART_B_DC_VERIFICATION_REPORT:
    'FC_FORM_A_PART_B_DC_VERIFICATION_REPORT',
  FC_FORM_E_COPY_OF_NOC_PRIMARY: 'FC_FORM_E_COPY_OF_NOC_PRIMARY',
  FC_FORM_E_FC_ACT_COPY: 'FC_FORM_E_FC_ACT_COPY',
  FC_FORM_E_DOCUMENTARY_OF_PROOF: 'FC_FORM_E_DOCUMENTARY_OF_PROOF',
  FC_FORM_E_GPS_COPY: 'FC_FORM_E_GPS_COPY',
  FC_FORM_E_COPY_OF_APPLICATION: 'FC_FORM_E_COPY_OF_APPLICATION',
  FC_FORM_E_MAP_COPY: 'FC_FORM_E_MAP_COPY',
  FC_FORM_E_COPY_OF_APPROVAL: 'FC_FORM_E_COPY_OF_APPROVAL',
  FC_E_PART_B_KML: 'FC_E_PART_B_KML',
  FC_E_PART_B_WORKING_PLAN_PRESCRIPTION:
    'FC_E_PART_B_WORKING_PLAN_PRESCRIPTION',
  FC_FORM_E_PROPOSED_DIVERSION_KML: 'FC_FORM_E_PROPOSED_DIVERSION_KML',
  EC_FORM_FIVE_SIGNED_PROCEEDINGS: 'EC_FORM_FIVE_SIGNED_PROCEEDINGS',
  NOTING_DOCUMENT: 'NOTING_DOCUMENT',
  EC_FORM_FIVE_ADDITIONAL_DOCUMENT: 'EC_FORM_FIVE_ADDITIONAL_DOCUMENT',

  EC_MS_RECOMMENDATION_EAC_DOCUMENT: 'EC_MS_RECOMMENDATION_EAC_DOCUMENT',

  MOM_ADDTIONAL_DOUMENT: 'MOM_ADDTIONAL_DOUMENT',
  MOM_CREATION_ADDITIONAL_DOCUMENT: 'MOM_CREATION_ADDITIONAL_DOCUMENT',

  FC_COMPLAINCE_STATUS_ADDITIONAL_DOCUMENT:
    'FC_COMPLAINCE_STATUS_ADDITIONAL_DOCUMENT',
  COMPLAINCE_STATUS_KML: 'COMPLAINCE_STATUS_KML',



  // Proposal Withdrawal

  ADD_WITHDRAW_REQUEST_COPY: 'ADD_WITHDRAW_REQUEST_COPY',

  // NEW EC SIX FORM
  EC_FORM_SIX_EC_OBTAINED_DOCUMENT: 'EC_FORM_SIX_EC_OBTAINED_DOCUMENT',

  //  EC Form Twelve

  EC_FORM_TWELVE_COPY_OF_EC_LETTER: 'EC_FORM_TWELVE_COPY_OF_EC_LETTER',
  EC_FORM_TWELVE_EC_OBTAINED_DOCUMENT: 'EC_FORM_TWELVE_EC_OBTAINED_DOCUMENT',
  EC_FORM_TWELVE_CHRONOLOGY_OF_CLEARENCE:
    'EC_FORM_TWELVE_CHRONOLOGY_OF_CLEARENCE',
  EC_FORM_TWELVE_LATEST_CONSENT_ORDER_COPY:
    'EC_FORM_TWELVE_LATEST_CONSENT_ORDER_COPY',
  EC_FORM_TWELVE_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL:
    'EC_FORM_TWELVE_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL',
  EC_FORM_TWELVE_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION:
    'EC_FORM_TWELVE_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION',
  EC_FORM_TWELVE_UNDERTAKING_TRANSFEROR_STATING:
    'EC_FORM_TWELVE_UNDERTAKING_TRANSFEROR_STATING',
  EC_FORM_TWELVE_ADDENDUM_EIA_EMP: 'EC_FORM_TWELVE_ADDENDUM_EIA_EMP',
  EC_FORM_TWELVE_NO_OBJECTION_FROM_TRANSFEROR:
    'EC_FORM_TWELVE_NO_OBJECTION_FROM_TRANSFEROR',
  EC_FORM_TWELVE_CERTIFIED_COMPLIANCE_REPORT:
    'EC_FORM_TWELVE_CERTIFIED_COMPLIANCE_REPORT',
  EC_FORM_TWELVE_UNDERTAKING_TRANSFEREE_STATING:
    'EC_FORM_TWELVE_UNDERTAKING_TRANSFEREE_STATING',
  EC_FORM_TWELVE__ADDENDUM_EIA_EMP_TRANSFEREE:
    'EC_FORM_TWELVE__ADDENDUM_EIA_EMP_TRANSFEREE',

  //  FC Part 3

  FC_PART_3_IN_PRINCIPAL_LETTER: 'FC_PART_3_IN_PRINCIPAL_LETTER',
  FC_PART_3_RETURNING_LETTER: 'FC_PART_3_RETURNING_LETTER',
  FC_PART_3_WITHDRAWN_LETTER: 'FC_PART_3_WITHDRAWN_LETTER',
  FC_PART_3_REJECTION_LETTER: 'FC_PART_3_REJECTION_LETTER',
  FC_PART_3_CLOSED_LETTER: 'FC_PART_3_CLOSED_LETTER',
  WLC_REPLY_EDS: 'WLC_REPLY_EDS',

  // FC A Part 4

  LETTER_OF_RECOMENDATION_FC_FORM_A_PART_4:
    'LETTER_OF_RECOMENDATION_FC_FORM_A_PART_4',
  LETTER_OF_RECOMENDATION_FC_FORM_B_PART_4:
    'LETTER_OF_RECOMENDATION_FC_FORM_B_PART_4',
  SIGNED_DOCUMENT_FC_FORM_A_PART_4: 'SIGNED_DOCUMENT_FC_FORM_A_PART_4',
  SIGNED_DOCUMENT_FC_FORM_B_PART_4: 'SIGNED_DOCUMENT_FC_FORM_B_PART_4',
  CONFIRMATION_OF_MINUTES_MOM: 'CONFIRMATION_OF_MINUTES_MOM',

  //  Certificate

  CERTIFICATE_ADDITIONAL_DOCUMENT: 'CERTIFICATE_ADDITIONAL_DOCUMENT',

  // EC Form Eleven

  EC_FORM_ELEVEN_COPY_OF_EC_LETTER: 'EC_FORM_ELEVEN_COPY_OF_EC_LETTER',
  EC_FORM_ELEVEN_TIME_BOUND_ACTION_PLAN:
    'EC_FORM_ELEVEN_TIME_BOUND_ACTION_PLAN',
  EC_FORM_ELEVEN_NOT_IMPLEMENTATION: 'EC_FORM_ELEVEN_NOT_IMPLEMENTATION',
  EC_FORM_ELEVEN_SATELLITE_IMAGE: 'EC_FORM_ELEVEN_SATELLITE_IMAGE',
  EC_FORM_ELEVEN_IMPLEMENTATION_SPCB: 'EC_FORM_ELEVEN_IMPLEMENTATION_SPCB',
  EC_FORM_ELEVEN_FACTUAL_REPORT: 'EC_FORM_ELEVEN_FACTUAL_REPORT',
  EC_FORM_ELEVEN_UNDERTAKING_NON_JUDICIAL_STAMP_PAPER:
    'EC_FORM_ELEVEN_UNDERTAKING_NON_JUDICIAL_STAMP_PAPER',

  //  EC Form 2

  EC_FORM_TWO_LATEST_CONSENT_ORDER_COPY:
    'EC_FORM_TWO_LATEST_CONSENT_ORDER_COPY',
  EC_FORM_TWO_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL:
    'EC_FORM_TWO_LATEST_CONSENT_ORDER_COPY_INCLUDING_RENEWAL',
  EC_FORM_TWO_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION:
    'EC_FORM_TWO_BRIEF_NOTE_STATUS_OF_IMPLEMENTATION',
  EC_FORM_TWO_CORRIGENDUM_REFERENCE_LETTER:
    'EC_FORM_TWO_CORRIGENDUM_REFERENCE_LETTER',

  EC_FORM_TWO_CORRIGENDUM_SUPPORTING_FILE:
    'EC_FORM_TWO_CORRIGENDUM_SUPPORTING_FILE',
  //  Need to add these documents
  EC_FORM_TWO_COVER_LETTER: 'EC_FORM_TWO_COVER_LETTER',
  EC_FORM_TWO_LATES_COMPLIANCE_REPORT: 'EC_FORM_TWO_LATES_COMPLIANCE_REPORT',
  EC_FORM_TWO_PREVIOUS_CLREARANCES: 'EC_FORM_TWO_PREVIOUS_CLREARANCES',
  CAMPA_ACTIVITY_DETAILS_OTHER_DOC: 'CAMPA_ACTIVITY_DETAILS_OTHER_DOC',
  CAMPA_ADD_WORK_APPROVAL_CHANGE_DOC: 'CAMPA_ADD_WORK_APPROVAL_CHANGE_DOC',

  CAMPA_EXECUTIVE_AUTHORITY_APPROVAL_DOC:
    'CAMPA_EXECUTIVE_AUTHORITY_APPROVAL_DOC',
  UPLOAD_KML_DOC: 'UPLOAD_KML_DOC',
  CAMPA_STEERING_AUTHORITY_APPROVAL_DOC:
    'CAMPA_STEERING_AUTHORITY_APPROVAL_DOC',
  CIRCLE_OFFICE_APPROVAL_DOC: 'CIRCLE_OFFICE_APPROVAL_DOC',
  CAMPA_WORK_CERTIFICATE_COMPLETION_DOC:
    'CAMPA_WORK_CERTIFICATE_COMPLETION_DOC',

  CAMPA_WORK_INSPECTION_NOTE_DCF_DFO_DOC:
    'CAMPA_WORK_INSPECTION_NOTE_DCF_DFO_DOC',
  CAMPA_WORK_INSPECTION_NOTE_OTHER_OFFICER_DOC:
    'CAMPA_WORK_INSPECTION_NOTE_OTHER_OFFICER_DOC',
  CAMPA_WORK_INSPECTION_NOTE_THIRD_PARTY_DOC:
    'CAMPA_WORK_INSPECTION_NOTE_THIRD_PARTY_DOC',
  WLC_PAYMENT_ADDITIONAL_DOC: 'WLC_PAYMENT_ADDITIONAL_DOC',

  // EC-DEIAA
  DEIAA_EC_LETTER: 'DEIAA_EC_LETTER',
  DEIAA_SCANNED_COPY: 'DEIAA_SCANNED_COPY',

  // EC-SEIAA
  SEIAA_EC_LETTER: 'SEIAA_EC_LETTER',

  // EC-PRIOR FORM TWO
  //STEP -1
  DISTANCE_CERTIFICATE_AUTHENTICATED: 'DISTANCE_CERTIFICATE_AUTHENTICATED',
  DISTANCE_CERTIFICATE_AUTHENTICATED_ECO_SENSTIVE_DFO: 'DISTANCE_CERTIFICATE_AUTHENTICATED_ECO_SENSTIVE_DFO',
  DISTANCE_CERTIFICATE_AUTHENTICATED_ECO_SENSTIVE_WILDLIFE: 'DISTANCE_CERTIFICATE_AUTHENTICATED_ECO_SENSTIVE_WILDLIFE',
  DISTANCE_CERTIFICATE_AUTHENTICATED_FOREST_LAND_DFO: 'DISTANCE_CERTIFICATE_AUTHENTICATED_FOREST_LAND_DFO',
  EC_PRIOR_FORM_TWO_EC_LETTER: 'EC_PRIOR_FORM_TWO_EC_LETTER',
  EC_PRIOR_FORM_TWO_COPY_OF_APPROVAL: 'EC_PRIOR_FORM_TWO_COPY_OF_APPROVAL',
  EC_PRIOR_FORM_TWO_NBWL_RECOMMENDATIONS:
    'EC_PRIOR_FORM_TWO_NBWL_RECOMMENDATIONS',

  //STEP -13
  EC_PRIOR_FORM_TWO_APPROVED_MINING_PLAN_CONCERNED:
    'EC_PRIOR_FORM_TWO_APPROVED_MINING_PLAN_CONCERNED',
  EC_PRIOR_FORM_TWO_DISTRICT_SURVEY_REPORT_EC_ENCLOSURES:
    'EC_PRIOR_FORM_TWO_DISTRICT_SURVEY_REPORT_EC_ENCLOSURES',
  EC_PRIOR_FORM_TWO_REPLENISHMENT_STUDY_REPORT_EC_ENCLOSURES:
    'EC_PRIOR_FORM_TWO_REPLENISHMENT_STUDY_REPORT_EC_ENCLOSURES',
  EC_PRIOR_FORM_TWO_SUSTAINABLE_SAND_MINING:
    'EC_PRIOR_FORM_TWO_SUSTAINABLE_SAND_MINING',
  EC_PRIOR_FORM_TWO_COMPLAINCE_COURT: 'EC_PRIOR_FORM_TWO_COMPLAINCE_COURT',
  EC_PRIOR_FORM_TWO_CLUSTER_STATE_MINE: 'EC_PRIOR_FORM_TWO_CLUSTER_STATE_MINE',
  EC_PRIOR_FORM_TWO_EMP_REPORT_EC_ENCLOSURES:
    'EC_PRIOR_FORM_TWO_EMP_REPORT_EC_ENCLOSURES',
  EC_PRIOR_FORM_TWO_LAYOUT_PLAN_COMPONENT:
    'EC_PRIOR_FORM_TWO_LAYOUT_PLAN_COMPONENT',
  EC_PRIOR_FORM_TWO_ADDITIONAL: 'EC_PRIOR_FORM_TWO_ADDITIONAL',
  EC_PRIOR_FORM_TWO_LETTER_OF_INTENT: 'EC_PRIOR_FORM_TWO_LETTER_OF_INTENT',

  // STEP -10
  EC_PRIOR_FORM_TWO_CONSERVATION_PLAN: 'EC_PRIOR_FORM_TWO_CONSERVATION_PLAN',
  EC_PRIOR_FORM_TWO_APPROVAL_COPY: 'EC_PRIOR_FORM_TWO_APPROVAL_COPY',

  //STEP -2
  EC_PRIOR_FORM_TWO_COPY_OF_CONSENT_ORDER:
    'EC_PRIOR_FORM_TWO_COPY_OF_CONSENT_ORDER',
  EC_PRIOR_FORM_TWO_EC_LETTER_PROJECT: 'EC_PRIOR_FORM_TWO_EC_LETTER_PROJECT',
  EC_PRIOR_FORM_TWO_CORRIGENDUM_AMENDMENT_TRANSFER_TYPE:
    'EC_PRIOR_FORM_TWO_CORRIGENDUM_AMENDMENT_TRANSFER_TYPE',
  EC_PRIOR_FORM_TWO_DIRECTION_COPY: 'EC_PRIOR_FORM_TWO_DIRECTION_COPY',
  EC_PRIOR_FORM_TWO_NOTE_ON_CHRONOLOGY: 'EC_PRIOR_FORM_TWO_NOTE_ON_CHRONOLOGY',
  EC_PRIOR_FORM_TWO_CONSENT_ORDER: 'EC_PRIOR_FORM_TWO_CONSENT_ORDER',
  EC_PRIOR_FORM_TWO_NOTE_ON_STATUS_OF_IMPLEMENTATION:
    'EC_PRIOR_FORM_TWO_NOTE_ON_STATUS_OF_IMPLEMENTATION',
  EC_PRIOR_FORM_TWO_LATEST_CONSENT_ORDER:
    'EC_PRIOR_FORM_TWO_LATEST_CONSENT_ORDER',
  EC_PRIOR_FORM_TWO_RECOMMENDATION_LETTER:
    'EC_PRIOR_FORM_TWO_RECOMMENDATION_LETTER',
  GEO_CORDINATE_KML_DOC: 'GEO_CORDINATE_KML_DOC',
  APPROVAL_LETTER_CCF: 'APPROVAL_LETTER_CCF',
  UPLOAD_TRANSFER_LEASE: 'UPLOAD_TRANSFER_LEASE',
  UPLOAD_NO_OBJECTION_CERTIFICATE: 'UPLOAD_NO_OBJECTION_CERTIFICATE',

  // CRZ Validity Extension
  UPLOAD_CRZ_MAP_HTL_VALIDITY: 'UPLOAD_CRZ_MAP_HTL_VALIDITY',
  UPLOAD_PROJECT_SUPERIMPOSED_CRZ_VALIDITY:
    'UPLOAD_PROJECT_SUPERIMPOSED_CRZ_VALIDITY',
  CRZ_MAP_SITE_PROJECT_VALIDITY: 'CRZ_MAP_SITE_PROJECT_VALIDITY',
  UPLOAD_CRZ_MAP_NOTIFIED_ESA_VALIDITY: 'UPLOAD_CRZ_MAP_NOTIFIED_ESA_VALIDITY',
  CRZ_AGENCY_UPLOAD_VALIDITY: 'CRZ_AGENCY_UPLOAD_VALIDITY',
  CRZ_DETAILS_OF_TREE_VALIDITY: 'CRZ_DETAILS_OF_TREE_VALIDITY',
  Compensatory_afforestation_plan_VALIDITY:
    'Compensatory_afforestation_plan_VALIDITY',
  Copy_Of_NOC_VALIDITY: 'Copy_Of_NOC_VALIDITY',
  CRZ_COMPLIANCE_OF_THE_CONDITIONS_VALIDITY:
    'CRZ_COMPLIANCE_OF_THE_CONDITIONS_VALIDITY',
  Upload_Recommendation_EIAs_Terrestrial_VALIDITY:
    'Upload_Recommendation_EIAs_Terrestrial_VALIDITY',
  Upload_Recommendation_EIAs_EMP_Terrestrial_VALIDITY:
    'Upload_Recommendation_EIAs_EMP_Terrestrial_VALIDITY',
  Upload_Recommendation_EIAs_Marine_VALIDITY:
    'Upload_Recommendation_EIAs_Marine_VALIDITY',
  Upload_Disaster_Management_Plan_VALIDITY_CRZ:
    'Upload_Disaster_Management_Plan_VALIDITY_CRZ',
  Copy_Central_Ground_Water_VALIDITY_EXTENSION:
    'Copy_Central_Ground_Water_VALIDITY_EXTENSION',
  Recommendation_Copy_Of_SCZMA_VALIDITY_EXTENSION:
    'Recommendation_Copy_Of_SCZMA_VALIDITY_EXTENSION',
  CRZ_ACTION_PLAN_BY_SCZMA_VALIDITY_EXTENSION:
    'CRZ_ACTION_PLAN_BY_SCZMA_VALIDITY_EXTENSION',
  FORM_G_WORKING_PLAN_KML: 'FORM_G_WORKING_PLAN_KML',
  SIR_FORM_UPLOAD_MAP: 'SIR_FORM_UPLOAD_MAP',
  SIR_FORM_UPLOAD_HYDEL_DPR_DETAILS: 'SIR_FORM_UPLOAD_HYDEL_DPR_DETAILS',
  SIR_FORM_UPLOAD_TECHNO_ECONOMICAL: 'SIR_FORM_UPLOAD_TECHNO_ECONOMICAL',
  SIR_FORM_UPLOAD_CATCHMENT_AREA: 'SIR_FORM_UPLOAD_CATCHMENT_AREA',
  SIR_FORM_UPLOAD_TREATMENT_PLAN: 'SIR_FORM_UPLOAD_TREATMENT_PLAN',
  //new document
  FC_SIR_REPORT: 'FC_SIR_REPORT',
  SIR_FORM_UPLOAD_MUCK_PLAN: 'SIR_FORM_UPLOAD_MUCK_PLAN',
  DISTRIBUTION_AREA_OVERLAPPING_MAP_KML:
    'DISTRIBUTION_AREA_OVERLAPPING_MAP_KML',
  PRESCRIBED_YIELD_DOC: 'PRESCRIBED_YIELD_DOC',
  DWPR_MINUTES_DOC: 'DWPR_MINUTES_DOC',
  PWPR_MINUTES_DOC: 'PWPR_MINUTES_DOC',
  CHAPTER_WISE_DOCUMENT: 'CHAPTER_WISE_DOCUMENT',
  ANNEXURE_DOCUMENT: 'ANNEXURE_DOCUMENT',
  CHAPTER_WISE_KML: 'CHAPTER_WISE_KML',
  FUNCTIONAL_DWPR_DOC: 'FUNCTIONAL_DWPR_DOC',
  ANNUAL_FELLING_DOC: 'ANNUAL_FELLING_DOC',
  DISTRIBUTION_AREA_MAIN_MAP_KML: 'DISTRIBUTION_AREA_MAIN_MAP_KML',
  UPLOAD_RELEVANT_DOC: 'UPLOAD_RELEVANT_DOC',
  ADDITIONAL_NURSERY_PDF_DOC: 'ADDITIONAL_NURSERY_PDF_DOC',
  ADDITIONAL_NURSERY_KML_DOC: 'ADDITIONAL_NURSERY_KML_DOC',
  //wlc
  EXOTIC_SPECIES_DOC: 'EXOTIC_SPECIES_DOC',
  EXOTIC_APPLICANT_ADD_PROOF: 'EXOTIC_APPLICANT_ADD_PROOF',
  EXOTIC_ACQUISITION_PROOF: 'EXOTIC_ACQUISITION_PROOF',
  EXOTIC_SEA_CERT: 'EXOTIC_SEA_CERT',
  EXOTIC_DEATH_PM_CERT: 'EXOTIC_DEATH_PM_CERT',
  EXOTIC_IMPORT_LICENCE: 'EXOTIC_IMPORT_LICENCE',
  EXOTIC_IMP_CITES_EXPORT_CERT: 'EXOTIC_IMP_CITES_EXPORT_CERT',
  EXOTIC_ADD_INFO_DOCS: 'EXOTIC_ADD_INFO_DOCS',
  EXOTIC_SPECIES_IMG: 'EXOTIC_SPECIES_IMG',
  LEGACY_DOCUMENT_STATE: 'LEGACY_DOCUMENT_STATE',


  // Agenda MOM for CAMPA
  ADD_ANY_OTHER_DOC_CAMPA: 'ADD_ANY_OTHER_DOC_CAMPA',
  DOCUMENT_MERGED_PUBLISHED_AGENDA_CAMPA:
    'DOCUMENT_MERGED_PUBLISHED_AGENDA_CAMPA',
  ATTACHED_ADDITIONAL_DOCUMENT_CAMPA: 'ATTACHED_ADDITIONAL_DOCUMENT_CAMPA',
  UPLOAD_CONFIRMATION_MOM_AGENDA: 'UPLOAD_CONFIRMATION_MOM_AGENDA',
  MOM_CAMPA_ADDITIONAL_DOCUMENT: 'MOM_CAMPA_ADDITIONAL_DOCUMENT',
  GENERAL_DOCUMENT_CAMPA: 'GENERAL_DOCUMENT_CAMPA',
  DEFERED_DOCUMENT_CAMPA_AGENDA: 'DEFERED_DOCUMENT_CAMPA_AGENDA',
  GENERAL_DOCUMENT_CAMPA_MOM: 'GENERAL_DOCUMENT_CAMPA_MOM',
  UPLOAD_SCHEME_FILE_AGENDA: 'UPLOAD_SCHEME_FILE_AGENDA',

  // LEGAL
  PHOTOGRAPH_LEGAL: 'PHOTOGRAPH_LEGAL',
  SIGNATURE_LEGAL: 'SIGNATURE_LEGAL',
  ANNEXURE_TWO: 'ANNEXURE_TWO',
  ANNEXURE_THREE: 'ANNEXURE_THREE',
  //LEGALASSOCIATE
  PHOTOGRAPH_LEGAL_ASSOCIATE: 'PHOTOGRAPH_LEGAL_ASSOCIATE',
  PHOTOGRAPH_LEGAL_ASSOCIATE_SIGNATURE: 'PHOTOGRAPH_LEGAL_ASSOCIATE_SIGNATURE',
  LLB_DEGREE: 'LLB_DEGREE',
  HIGHER_SECONDARY_CERTIFICATE: 'HIGHER_SECONDARY_CERTIFICATE',
  SECONDARY_CERTIFCATE: 'SECONDARY_CERTIFCATE',
  OTHER_EDUCATIONAL_CERTIFICATE: 'OTHER_EDUCATIONAL_CERTIFICATE',
  DIPLOMA_CERTIFICATE: 'DIPLOMA_CERTIFICATE',
  BAR_COUNCIL_CERTIFICATE: 'BAR_COUNCIL_CERTIFICATE',
  RESEARCH_PAPER: 'RESEARCH_PAPER',
  AGE_PROOF: 'AGE_PROOF',
  EXPRIENCE_CERTIFICATE: 'EXPRIENCE_CERTIFICATE',
  OTHER_DOCUMENT: 'OTHER_DOCUMENT',
  WORKING_PERMISSION: 'WORKING_PERMISSION_LOG',

  //ACAFORM
  ACA_FORM_KML: 'ACA_FORM_KML',
  ACA_SIR_KML: 'ACA_SIR_KML',
  ACA_FORM_DOCUMENT: 'ACA_FORM_DOCUMENT',
  ACA_FORM_IMAGE: 'ACA_FORM_IMAGE',

  //FC-COMPLIANCE
  NOTING_FC_COMPLIANCE_DOC: 'NOTING_FC_COMPLIANCE_DOC',
  FC_COMPILNACE_PROPOSAL: 'FC_COMPILNACE_PROPOSAL',
  FC_COMPILNACE_SITE_REPORT: 'FC_COMPILNACE_SITE_REPORT',
  EDS_FC_COMPLIANCE_REPLY_DOC: 'EDS_FC_COMPLIANCE_REPLY_DOC',

  // GEAC
  GEAC_FORM_ONE_IBSC_MINUTES: 'GEAC_FORM_ONE_IBSC_MINUTES',
  GEAC_FORM2A_ACCOMPANIED_CERTIFICATE: 'GEAC_FORM2A_ACCOMPANIED_CERTIFICATE',
  GEAC_TRIAL_RCGM_RECOMMENDATION: 'GEAC_TRIAL_RCGM_RECOMMENDATION',
  GEAC_NOC: 'GEAC_NOC',
};

export const API_WORKFLOW_PROPOSAL = {
  TASK: 'process/getWorkgroupApplicationsList',
  ACA_TASK: 'process/getAcaApplicationsList',
};

export const DOCUMENT_TYPE_EDS_QUERY = {
  EDS_REQUEST: 'EDS_REQUEST',
  EDS_RESPONSE: 'EDS_RESPONSE',
};
export const CAF_FORM_STEPS = [
  { name: 'Project Details', route: '/project', id: 'caf_project' },
  {
    name: 'Location of Project',
    route: '/location',
    id: 'caf_locationOfProject',
  },
  {
    name: 'Project Activity Cost',
    route: '/activity-cost',
    id: 'caf_ProjectActivityCost',
  },
  { name: 'Others', route: '/other', id: 'caf_others' },
];

export const FC_FORM_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_project_details',
  },
  { name: 'Proposed Land', route: '/proposed-land', id: 'fc_proposed_land' },
  {
    name: 'Afforestation Details',
    route: '/afforestation-details',
    id: 'fc_afforestation_details',
  },
  { name: 'Others', route: '/other', id: 'fc_other' },
  {
    name: 'Category Specific Details',
    route: '/enclosures',
    id: 'fc_enclosures',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'fc_undertaking' },
];

export const FC_SIR_FORM_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_project_details',
  },
  { name: 'Others', route: '/other', id: 'fc_other' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'fc_undertaking' },
];

export const ACA_FORM_STEPS = [
  {
    name: 'Applicant Details',
    route: '/applicant-details',
    id: 'aca_applicant_details',
  },
  {
    name: 'Site Details',
    route: '/site-details',
    id: 'aca_site_details',
  },
  {
    name: 'Plantation Details',
    route: '/plantation-details',
    id: 'aca_plantation_details',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'aca_undertaking' },
];
export const ACA_FORM_STEPS_SITE_INSPECTION = [
  {
    name: 'General Information',
    route: '/general-information',
    id: 'aca_general_information',
  },
  {
    name: 'Site Inspection Information',
    route: '/site-inspection-information',
    id: 'aca_site_inspection_information',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'aca_additional_information',
  },
];
export const FC_FORM_B_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_form_b_project_details',
  },
  {
    name: 'Approval Details',
    route: '/approval-details',
    id: 'fc_form_b_approval_details',
  },
  {
    name: 'Proposed Land',
    route: '/proposed-land',
    id: 'fc_form_b_proposed_land',
  },
  {
    name: 'Afforestation land',
    route: '/afforestation-details',
    id: 'fc_form_b_afforestation_details',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_form_b_other_details',
  },
  {
    name: 'Category Specific Details',
    route: '/category-specific-details',
    id: 'fc_form_b_category_specific_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_form_b_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'fc_form_b_undertaking' },
];
export const FC_FORM_C_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_form_c_project_details',
  },
  {
    name: 'Proposed Land',
    route: '/proposed-land',
    id: 'fc_form_c_proposed_land',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_form_c_other_details',
  },
  {
    name: 'Activities Details',
    route: '/activity-details',
    id: 'fc_form_c_activity_details',
  },
  {
    name: 'Land Details',
    route: '/land-details',
    id: 'fc_form_c_land_details',
  },
  {
    name: 'Afforestation Details',
    route: '/afforestation-details',
    id: 'fc_form_c_afforestation_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_form_c_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'fc_form_c_undertaking' },
];

export const FC_FORMC_EXEMPTED_CATEGORY = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_form_c_exempted_category_basic_details',
  },
  {
    name: 'Proposed Land',
    route: '/proposed-land',
    id: 'fc_form_c_exempted_category_proposed_land',
  },
  {
    name: 'Activities Details',
    route: '/activity-details',
    id: 'fc_form_c_exempted_category_activities_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_form_c_exempted_category_additional_information',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'fc_form_c_exempted_category_undertaking',
  },
];
export const FC_FORMC_EXEMPTED_CATEGORY_DFO = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_form_c_exempted_category_dfo_basic_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_form_c_exempted_category_dfo_additional_information',
  },
];

export const FC_A_PART_B_DC_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_a_part_b_basic_details',
  },
  {
    name: 'Inspection Details',
    route: '/inspection-details',
    id: 'fc_a_part_b_inspection_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-documents',
    id: 'fc_a_part_b_additional_documents',
  },
];

export const FC_FORM_PART_D_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_form_part_d_project_details',
  },
  {
    name: 'Proposed Land',
    route: '/proposed-land',
    id: 'fc_form_part_d_proposed_land',
  },
  {
    name: 'Category Specific Details',
    route: '/enclosures',
    id: 'fc_form_part_d_enclosures',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_form_part_d_additional_information',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'fc_form_part_d_undertaking',
  },
];

export const FC_FORM_FORM_E_STEPS = [
  {
    name: 'Project Details',
    route: '/basic-details',
    id: 'fc_form_e_basic_details',
  },
  {
    name: 'Proposed Land',
    route: '/proposed-land',
    id: 'fc_form_e_proposed_land',
  },
  { name: 'Other Details', route: '/others', id: 'fc_form_e_others' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_form_e_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'fc_form_e_undertaking' },
];

export const FC_FORM_FORM_F_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_form_f_basic_details',
  },
  { name: 'Other Details', route: '/others', id: 'fc_form_f_others' },
  { name: 'Undertaking', route: '/undertaking', id: 'fc_form_f_undertaking' },
];

export const LEGAL_NGT_FORM_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'legal_ngt_form_basic_details',
  },
  {
    name: 'Education Details',
    route: '/education-details',
    id: 'legal_ngt_form_education_details',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'legal_ngt_form_other_details',
  },
  {
    name: 'Document Upload',
    route: '/document-upload',
    id: 'legal_ngt_form_document_upload',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'legal_ngt_form_undertaking',
  },
];

export const LEGAL_ASSOCIATE_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'legal_associate_basic_details',
  },
  {
    name: 'Education Details',
    route: '/education-details',
    id: 'legal_associate_education_details',
  },
  {
    name: 'Experience Details',
    route: '/experience-details',
    id: 'legal_associate_experience_details',
  },
  {
    name: 'Eligibility Criteria',
    route: '/eligibility-criteria',
    id: 'legal_associate_eligibility_details',
  },
  {
    name: 'Upload Document',
    route: '/upload-document',
    id: 'legal_associate_upload',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'legal_associate_undertaking_details',
  },
];

export const WL_FORM_STEPS = [
  {
    name: 'Project Details',
    route: '/project-detail',
    id: 'wl_project_detail',
  },
  { name: 'Proposed Land', route: '/proposed-land', id: 'wl_proposed_land' },
  {
    name: 'Category Specific Details',
    route: '/enclosure',
    id: 'wl_enclosure',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'wl_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'wl_undertaking' },
];

export const WL_FORM_STEPS2 = [
  {
    name: 'Project Details',
    route: '/project-detail',
    id: 'wl_project_detail',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'wl_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'wl_undertaking' },
];
export const WL_FORM_STEPS3 = [
  {
    name: 'Project Details',
    route: '/project-detail',
    id: 'wl_project_detail',
  },
  { name: 'Proposed Land', route: '/proposed-land', id: 'wl_proposed_land' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'wl_additional_information',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'wl_undertaking' },
];
export const EC_FORM_STEPS = [
  { name: 'Basic Information', route: '/basic-details', id: 'ec_basicDetails' },
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'ec_projectDetails',
  },
  {
    name: 'Product Details',
    route: '/product-details',
    id: 'ec_productDetails',
  },
  {
    name: 'Consultant Details',
    route: '/consultant',
    id: 'ec_consultantDetails',
  },
];

export const EC_FORM_TWO_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'ec_two_projectDetails',
  },
  {
    name: 'Implementation Details',
    route: '/implementation-details',
    id: 'ec_two_implementationDetails',
  },
  {
    name: 'Enclousers',
    route: '/enclousers',
    id: 'ec_two_enclousers',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_two_additional_info',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'ec_two_undertaking',
  },
];

export const EC_FORM_A_PART_B_STEPS = [
  {
    name: 'Project Details',
    route: '/activity-details',
    id: 'ec_b_projectDetails',
  },
  {
    name: 'Construction Details',
    route: '/construction-details',
    id: 'ec_b_constructionDetails',
  },
  {
    name: 'Physical Changes',
    route: '/physical-changes',
    id: 'ec_b_physicalDetails',
  },
  {
    name: 'Pollution Details',
    route: '/pollution-details',
    id: 'ec_b_pollutionDetails',
  },
  {
    name: 'Water Requirements',
    route: '/water-details',
    id: 'ec_b_waterRequirements',
  },
  { name: 'Green Belt', route: '/green-belt', id: 'ec_b_greenBelt' },
  {
    name: 'Waste Generation',
    route: '/waste-production',
    id: 'ec_b_wasteGeneration',
  },
  { name: 'Risk Assessment', route: '/risk-factor', id: 'ec_b_riskAssessment' },
  { name: 'Sector Specific', route: '', id: 'ec_b_sectorSpecific' },
  { name: 'Enclosures', route: '/enclosures', id: 'ec_b_enclosures' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_b_additionalInformation',
  },
  { name: 'Checklist', route: '/checklist', id: 'ec_b_checklist' },
  { name: 'Undertaking', route: '/other', id: 'ec_b_undertaking' },
];

export const EC_FORM_A_PART_C_STEPS = [
  { name: 'TOR Details', route: '/tor', id: 'ec_c_torDetails' },
  { name: 'Baseline Details', route: '/baseline', id: 'ec_c_baselineDetails' },
  { name: 'Other Details', route: '/other', id: 'ec_c_otherDetails' },
  { name: 'Enclosures', route: '/enclosure', id: 'ec_c_enclosures' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_c_additionalInformation',
  },
  { name: 'Checklist', route: '/checklist', id: 'ec_c_checklist' }
  ,
  { name: 'Undertaking', route: '/undertaking', id: 'ec_c_undertaking' },
];

export const EC_FORM_FIVE_STEPS = [
  {
    name: 'Basic Information',
    route: '/basic-details',
    id: 'ec_form_five_basicInformation',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_form_five_additionalInformation',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'ec_form_five_undertaking',
  },
];

export const EC_FORM_SIX_NEW_STEPS = [
  {
    name: 'Project Details',
    route: '/project',
    id: 'ec_form_six_projectDetails',
  },
  {
    name: 'Implementation Details',
    route: '/implementation-details',
    id: 'ec_form_six_implementationDetails',
  },
  {
    name: 'Amendment/Extension Details',
    route: '/amendment-details',
    id: 'ec_form_six_amendmentExtensionDetails',
  },
  {
    name: 'Consultant Details',
    route: '/consultant-details',
    id: 'ec_form_six_consultantDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_form_six_additionalInformation',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'ec_form_six_undertaking' },
];

export const EC_FORM_SEVEN_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'ec_seven_basicDetails',
  },
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'ec_seven_projectDetails',
  },
  {
    name: 'Activity Details',
    route: '/activity-details',
    id: 'ec_seven_activityDetails',
  },
  { name: 'Enclousers', route: '/enclousers', id: 'ec_seven_enclousers' },
  {
    name: 'Additional Information',
    route: '/additional-info',
    id: 'ec_seven_additionalInformation',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'ec_seven_undertaking' },
];
export const GEAC_FORM_ONE_STEPS = [
  {
    name: 'General Details',
    route: '/application-details',
    id: 'geac_one_applicationDetails',
  },
  {
    name: 'IBSC Documents',
    route: '/ibsc-details',
    id: 'geac_one_ibscDetails',
  },
  {
    name: 'Species Details',
    route: '/species-details',
    id: 'geac_one_speciesDetails',
  },
  {
    name: 'Genetic Details',
    route: '/genetic-details',
    id: 'geac_one_geneticDetails',
  },
  {
    name: 'Product Details',
    route: '/product-details',
    id: 'geac_one_productDetails',
  },
  {
    name: 'Trial Details',
    route: '/trial-details',
    id: 'geac_one_trialDetails',
  },
  {
    name: 'Protocol Details',
    route: '/protocol-details',
    id: 'geac_one_protocolDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-info',
    id: 'geac_one_additionInfo',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'geac_one__undertaking',
  },
];
export const GEAC_FORM_TWO_A_STEPS = [
  {
    name: 'Basic Details',
    route: 'basic-details',
    id: 'geac_two_a_basicDetails',
  },
  {
    name: 'Production Details',
    route: '/production-details',
    id: 'geac_two_a_productionDetails',
  },
  {
    name: 'Quality Details',
    route: '/quality-details',
    id: 'geac_two_a_qualityDetails',
  },
  {
    name: 'Trail Details',
    route: '/trial-details',
    id: 'geac_two_a_trialyDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-info',
    id: 'geac_two_a_additionInfo',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'geac_two_a__undertaking',
  },
];
export const GEAC_FORM_TWO_B_STEPS = [
  {
    name: 'Basic Details',
    route: 'basic-details',
    id: 'geac_two_b_basicDetails',
  },
  {
    name: 'Product Details',
    route: '/product-details',
    id: 'geac_two_b_productDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-info',
    id: 'geac_two_b_additionInfo',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'geac_two_b__undertaking',
  },
];
export const GEAC_FORM_THREE_STEPS = [
  {
    name: 'Basic Details',
    route: 'basic-details',
    id: 'geac_three_basicDetails',
  },
  {
    name: 'Product Details',
    route: '/product-details',
    id: 'geac_three_productDetails',
  },

  {
    name: 'Additional Information',
    route: '/additional-info',
    id: 'geac_three_additionInfo',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'geac_three__undertaking',
  },
];

export const EC_FORM_TWO_STEPS_PRIOR = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'ec_two_prior_basicDetails',
  },
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'ec_two_prior_projectDetails',
  },
  {
    name: 'Product Details',
    route: '/product-details',
    id: 'ec_two_prior_productDetails',
  },
  {
    name: 'Construction Details',
    route: '/construction-details',
    id: 'ec_two_prior_constructionDetails',
  },
  {
    name: 'Physical Changes',
    route: '/physical-changes',
    id: 'ec_two_prior_physicalchanges',
  },
  {
    name: 'Pollution Details',
    route: '/pollution-details',
    id: 'ec_two_prior_pollutiondetails',
  },
  {
    name: 'Water Details',
    route: '/water-details',
    id: 'ec_two_prior_waterRequirements',
  },
  {
    name: 'Green Belt',
    route: '/greenBelt',
    id: 'ec_two_prior_waterRequirements',
  },
  {
    name: 'Waste Production',
    route: '/waste-production',
    id: 'ec_two_prior_waterRequirements',
  },
  {
    name: 'Mining Proposals',
    route: '/mining-proposals',
    id: 'ec_two_prior_waterRequirements',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'ec_two_prior_otherDetails',
  },
  {
    name: 'Consultant',
    route: '/consultant',
    id: 'ec_two_prior_consultant',
  },
  { name: 'Enclousers', route: '/enclousers', id: 'ec_two_prior_enclousers' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_two_prior_additionalInformation',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'ec_two_prior_undertaking',
  },
];

export const EC_FORM_ELEVEN_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'ec_eleven_basicDetails',
  },
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'ec_eleven_projectDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-info',
    id: 'ec_eleven_additionalInformation',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'ec_eleven_undertaking' },
];

export const EC_FORM_EIGHT_STEPS = [
  {
    name: 'Project Details',
    route: '/project',
    id: 'ec_eight_projectDetails',
  },
  {
    name: 'Location of Project',
    route: '/location',
    id: 'ec_eight_projectLocation',
  },
  { name: 'Details of ToR', route: '/tor', id: 'ec_eight_torDetails' },
  { name: 'Documents', route: '/document', id: 'ec_eight_document' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_eight_additionalInformation',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'ec_eight_undertaking' },
];

export const CRZ_TRANSFER_PROPOSAL_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'crz_transfer_proposal_basicDetails',
  },
  {
    name: 'Proposal Details',
    route: '/proposal-details',
    id: 'crz_transfer_proposal_proposalDetails',
  },
  {
    name: 'Enclosures',
    route: '/enclosures',
    id: 'crz_transfer_proposal_enclosures',
  },
  {
    name: 'Additional Information',
    route: '/additional-details',
    id: 'crz_transfer_proposal_additional_details',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'crz_transfer_proposal_undertaking',
  },
];

export const CRZ_VALIDITY_EXTENSION_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'crz_validity_extension_basicDetails',
  },
  {
    name: 'Implementation Details',
    route: '/implementation-details',
    id: 'crz_validity_extension_proposalDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'crz_validity_extension_additional_details',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'crz_validity_extension_undertaking',
  },
];

export const EC_FORM_NINE_STEPS = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'ec_nine_projectDetails',
  },
  {
    name: 'Proposal Details',
    route: '/proposal-details',
    id: 'ec_nine_proposalDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_nine_additionalInformation',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'ec_nine_undertaking' },
];

export const EC_FORM_TEN_STEPS = [
  {
    name: 'Basic Information',
    route: '/basic-information',
    id: 'ec_ten_basicInformation',
  },
  {
    name: 'Product Details',
    route: '/product-details',
    id: 'ec_ten_productDetails',
  },
  {
    name: 'Emission Generation',
    route: '/emission-generation',
    id: 'ec_ten_emissionGeneration',
  },
  {
    name: 'Hazardous Waste Generation',
    route: '/waste-generation',
    id: 'ec_ten_wasteGeneration',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_ten_additionalInformation',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'ec_ten_undertaking',
  },
];

export const EC_FORM_TWELVE_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'ec_twelve_basicDetails',
  },
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'ec_twelve_projectDetails',
  },
  {
    name: 'Activity Details',
    route: '/activity-details',
    id: 'ec_twelve_activityDetails',
  },
  {
    name: 'Splitting Details',
    route: '/enclousers',
    id: 'ec_twelve_enclousers',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'ec_twelve_otherDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-info',
    id: 'ec_twelve_additionalInformation',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'ec_twelve_undertaking',
  },
];

export const EC_MS_RECOMMENDATION_STEPS = [
  { name: 'Recommendation', route: '', id: '' },
  { name: 'EAC', route: '', id: '' },
];

export const CRZ_FORM_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'crz_basic_details',
  },
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'crz_project_details',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'crz_other_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'crz_additional_information',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'crz_undertaking',
  },
];

export const ACA_FORM_STEP = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'basic_details',
  },
  {
    name: 'Others Details',
    route: 'others-details',
    id: 'other_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'aca_additional_information',
  },
  {
    name: 'Undertaking',
    route: '/undertaking',
    id: 'undertaking',
  },
];

export const FC_FORM_A_PART_B_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_a_part_b_basic_details',
  },
  {
    name: 'WL Specific Details',
    route: '/wl-specific-details',
    id: 'fc_a_part_b_wl_specific_details',
  },
  {
    name: 'Afforestation Details',
    route: '/aforestation-details',
    id: 'fc_a_part_b_aforestation_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_a_part_b_additional_information',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_a_part_b_other_details',
  },
];

export const FC_FORM_B_PART_B_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_b_part_b_basic_details',
  },
  {
    name: 'WL Specific Details',
    route: '/wl-specific-details',
    id: 'fc_b_part_b_wl_specific_details',
  },
  {
    name: 'Afforestation Details',
    route: '/afforestation-details',
    id: 'fc_b_part_b_afforestation_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_b_part_b_additional_information',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_b_part_b_other_details',
  },
];

export const FC_FORM_C_PART_B_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_c_part_b_basic_details',
  },
  {
    name: 'WL Specific Details',
    route: '/wl-specific-details',
    id: 'fc_c_part_b_wl_specific_details',
  },
  {
    name: 'Afforestation Details',
    route: '/afforestation-details',
    id: 'fc_c_part_b_afforestation_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_c_part_b_additional_information',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_c_part_b_other_details',
  },
];

export const FC_FORM_D_PART_B_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_d_part_b_basic_details',
  },
  {
    name: 'WL Specific Details',
    route: '/wl-specific-details',
    id: 'fc_d_part_b_wl_specific_details',
  },
  {
    name: 'Afforestation Details',
    route: '/afforestation-details',
    id: 'fc_d_part_b_afforestation_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_d_part_b_additional_information',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_d_part_b_other_details',
  },
];

export const FC_FORM_F_PART_B_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_f_part_b_basic_details',
  },
  {
    name: 'Afforestation Details',
    route: '/afforestation-details',
    id: 'fc_f_part_b_afforestation_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_f_part_b_additional_information',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_f_part_b_other_details',
  },
];

export const EDS_STEPS = [
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'eds_additional-information',
  },
  { name: 'Remarks', route: '/form', id: 'eds_form' },
];

export const CLEARANCE_PROCESS_FORM_STEPS = [
  {
    name: 'Application',
    route: '/process-definition',
    id: 'clearance_process_form_process-definition',
  },
  {
    name: 'Steps',
    route: '/process-steps',
    id: 'clearance_process_form_process-steps',
  },
  {
    name: 'Step Mapping',
    route: '/process-step-mapping',
    id: 'clearance_process_form_process-step-mapping',
  },
  {
    name: 'Office/Role Step Mapping',
    route: '/process-step-role-mapping',
    id: 'clearance_process_form_process-step-role-mapping',
  },
  {
    name: 'Action Step Mapping',
    route: '/process-actions',
    id: 'clearance_process_form_process-actions',
  },
];

export const DEPARTMENT_FORM_STEPS = ['EC Factsheet Details', 'Other Details'];

export const EC_FACT_SHEET_STEPS = [
  {
    name: 'EC Factsheet Details',
    route: '/details',
    id: 'ec_fact_sheet_details',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'ec_fact_sheet_otherDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_fact_sheet_additionalInformation',
  },
];

export const CRZ_FACT_SHEET_STEPS = [
  {
    name: 'CRZ Factsheet Details',
    route: '/details',
    id: 'crz_fact_sheet_details',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'crz_fact_sheet_additionalInformation',
  },
];

export const FC_C_PART_3 = [
  {
    name: 'Checklist',
    route: '/checklist',
    id: 'fc_c_part_3_checklist',
  },
  {
    name: 'Project Details',
    route: '/proposal-details',
    id: 'fc_c_part_3_proposalDetails',
  },
];

export const FC_A_PART_3 = [
  {
    name: 'Project Details',
    route: '/proposal-details',
    id: 'fc_a_part_3_proposalDetails',
  },
  {
    name: 'Checklist',
    route: '/checklist',
    id: 'fc_a_part_3_checklist',
  },
  {
    name: 'Clearence',
    route: '/clearence',
    id: 'fc_a_part_3_clearence',
  },
  {
    name: 'Additional-information',
    route: '/additional-information',
    id: 'fc_form_a_part_3_additional_information',
  },
];

export const FC_FORM_A_PART_3 = [
  {
    name: 'Checklist',
    route: '/checklist',
    id: 'fc_form_a_part_3_checklist',
  },
  {
    name: 'Recommendation',
    route: '/project-details',
    id: 'fc_form_a_part_3_project_details',
  },
  {
    name: 'Additional-information',
    route: '/additional-information',
    id: 'fc_form_a_part_3_additional_information',
  },
];
export const FC_FORM_F_PART_3 = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_form_f_part_3_project_details',
  },
  {
    name: 'Additional-information',
    route: '/additional-information',
    id: 'fc_form_f_part_3_additional_information',
  },
];

export const FC_A_PART_4 = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_a_part_4_projectDetails',
  },
  {
    name: 'Clearance Details',
    route: '/clearance-details',
    id: 'fc_a_part_4_clearanceDetails',
  },
];

export const FC_A_PART_4_STEP_1 = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_a_part_4_projectDetails',
  },
  {
    name: 'Additional-information',
    route: '/additional-information',
    id: 'fc_a_part_4_additional_information',
  },
];

export const FC_F_PART_4 = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_f_part_4_projectDetails',
  },
];

export const FC_F_PART_4_STEP_1 = [
  {
    name: 'Project Details',
    route: '/project-details',
    id: 'fc_f_part_4_projectDetails',
  },
  {
    name: 'Additional-information',
    route: '/additional-information',
    id: 'fc_f_part_4_additional_information',
  },
];

export const EC_FORM_FIVE_PART_B_STEPS = [
  {
    name: 'Details of Public Consultation',
    route: '/details',
    id: 'ec_five_a_detailsOfPublicConsultation',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'ec_five_a_additionalInformation',
  },
  { name: 'Undertaking', route: '/undertaking', id: 'ec_five_a_undertaking' },
];

export const REMARKS_FORM_STEPS = [
  { name: 'Remarks', route: '/remarks', id: 'remarks_with_document_remarks' },
  {
    name: 'Additional Documents',
    route: '/document',
    id: 'remarks_with_document_document',
  },
];

export const SITE_VISIT_REPORT = [
  { name: 'Details', route: '/details', id: 'site_visit_report_details' },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'site_visit_report_additionalInformation',
  },
];

export const PROJECT_CATEGORY = {
  B2: 'B2',
  AIRPORT: 'Airport',
  DRINKING_WATER: 'WATER',
  HYDEL: 'HYD/IRRIG',
  IRRIGATION: 'HYD/IRRIG',
  MINING: 'MIN/QRY',
  MINING_MINERAL_OIL: 'MINOIL',
};

export const FC_FORM_D_PROJECT_CATEGORY = {
  NON_MINING: 'NONMIND',
  MINING: 'MIND',
};

export const PAYMENT_CODE = {
  CLIENTCODE: 'CAM5064',
  COMPANYCODE: '4368',
};

export const CONSTRUCTION_DETAILS_TYPE = {
  WATER_CP_REQUIREMENT: 'water requirement Cp',
  WATER_OP_REQUIREMENT: 'water requirement Op',
  MINERALS_FUELS: 'minerals fuel',
  CONSTRUCTION_DETAIL: 'construction detail',
  RAW_MATERIAL: 'raw material',
  HAZARDOUS_MATERIAL: 'hazardous material',
};

export const DOCUMENT_REF_TYPES = {
  CAF: 'caf',
  FC: 'fc',
  WL: 'WL',
  EC: 'ec',
  EC_TOR: 'EC_TOR',
  EC_PRIOR_FORM_TWO: 'EC_PRIOR_FORM_TWO',
  ECPARTB: 'ecpartB',
  ECPARTC: 'ecpartC',
  CRZ: 'crz',
  FC_A_PART_B: 'FC_A_PART_B',
  FC_B_PART_B: 'FC_B_PART_B',
  FC_C_PART_B: 'FC_C_PART_B',
  FC_D_PART_B: 'FC_D_PART_B',
  FC_F_PART_B: 'FC_F_PART_B',
  FC_PART_4: 'FC_PART_4',
  CRZ_TRANSFER_PROPOSAL: 'CRZ_TRANSFER_PROPOSAL',
  USER_REGISTRATION: 'USER_REGISTRATION',
  FC_FORM_B: 'FC_FORM_B',
  FC_FORM_E: 'FC_FORM_E',
  FC_FORM_D: 'FC_FORM_D',
  FC_FORM_E_PART_B: 'FC_FORM_E_PART_B',
  FC_FORM_DC: 'FC_FORM_DC',
  FC_FORM_C: 'FC_FORM_C',
  EDS: 'EDS',
  EC_FORM_FIVE: 'EC_FORM_FIVE',
  EC_FORM_FIVE_PART_TWO: 'EC_FORM_FIVE_PART_TWO',
  EC_FORM_SEVEN: 'EC_FORM_SEVEN',
  EC_FORM_NINE: 'EC_FORM_NINE',
  EC_FORM_SIX: 'EC_FORM_SIX',
  EC_FORM_EIGHT: 'EC_FORM_EIGHT',
  EC_FORM_TEN: 'EC_FORM_TEN',
  EC_MS_RECOMMENDATION: 'EC_MS_RECOMMENDATION',
  NOTING: 'NOTING',
  AGENDA: 'AGENDA',
  MOM: 'MOM',
  COMPLAINCE_STATUS: 'COMPLAINCE_STATUS',
  COMPLAINCE_STATUS_KML: 'COMPLAINCE_STATUS_KML',
  EDS_QUERY: 'EDS_QUERY',
  EC_FORM_SIX_NEW: 'EC_FORM_SIX_NEW',
  WITHDRAWAL_PROPOSAL: 'WITHDRAWAL_PROPOSAL',
  FC_REMOVE: 'FC_REMOVE',
  FC_PART_3: 'FC_PART_3',
  FC_FORM_A_PART_4: 'FC_FORM_A_PART_4',
  FC_FORM_B_PART_4: 'FC_FORM_B_PART_4',
  CERTIFICATE: 'CERTIFICATE',
  EC_FORM_TWELVE: 'EC_FORM_TWELVE',
  EC_FORM_ELEVEN: 'EC_FORM_ELEVEN',
  EC_FORM_TWO: 'EC_FORM_TWO',
  CAMPA: 'CAMPA',
  EC_ADDITIONAL: 'EC_ADDITIONAL',
  WLC_PAYMENT_ADDITIONAL_DOC: 'WLC_PAYMENT_ADDITIONAL_DOC',
  EC_MS_ADDITIONAL_DOCUMENT: 'EC_MS_ADDITIONAL_DOCUMENT',
  CRZ_VALIDITY_EXTENSION: 'CRZ_VALIDITY_EXTENSION',
  FC_FORM_F: 'FC_FORM_F',
  EC_FORM_DEIAA: 'EC_FORM_DEIAA',
  EC_FORM_SEIAA: 'EC_FORM_SEIAA',
  FC_FORM_G: 'FC_FORM_G',
  FC_F_PART_B_CA_SCHEME_COPY: 'FC_F_PART_B_CA_SCHEME_COPY',
  WLC_Exotics: 'WLC_Exotics',
  LEGAL_REPO: 'LEGAL_REPO',
  FC_SIR_FORM: 'FC_SIR_FORM',
  //aca
  ACA: 'ACA',
  GEAC_FORM_ONE: 'GEAC_FORM_ONE',
  GEAC_FORM_TWO: 'GEAC_FORM_TWO',
  MEETING_NOTICE: 'MEETING_NOTICE',
  Details_of_Existing_Paths: 'Details_of_Existing_Paths',
  FORM_C_EXEMPTED_CATEGORY: 'FORM_C_EXEMPTED_CATEGORY',
  PAYMENT: 'PAYMENT'
};

export const ACTIVITY_TYPE = {
  MAJOR: 'Major / 2006 ',
  MINOR: 'Minor',
};

export const MAJOR_ACTIVITY = {
  METALLURGICAL_INDUSTRIES:
    'Metallurgical Industries (ferrous and non ferrous)',
  CEMENT_PLANTS: 'Cement plants',
  ASBESTOS_MILLING: 'Asbestos milling / asbestos-based products',
  PULP_PAPER_INDUSTRY: 'Pulp & Paper Industry',
  TANNING_INDUSTRY: 'Skin/hide processing including the tanning industry',
  CHLOR_ALKALI_INDUSTRY: 'Chlor-alkali industry',
  CHEMICAL_FERTILIZERS: 'Chemical fertilizers ',
  PESTICIDES_INDUSTRY:
    'Pesticides industry and pesticide specific intermediates (excluding formulations)',
  PETRO_CHEMICAL_COMPLEXES:
    'Petro-chemical complexes (industries based on processing of petroleum fractions ',
  PETROLEUM_PRODUCTS:
    'Petroleum products and petrochemical based processing such as production of carbon black and electrode grade graphite (processes other than cracking & reformation and not covered under the complexes).',
  SYNTHETIC_INDUSTRY: 'Synthetic organic chemicals industry ',
  DISTILLERIES: 'Distilleries',
  SUGER_INDUSTRY: 'Sugar Industry',
};

export const MAJOR_SUB_ACTIVITY = {
  NA: 'NA',

  INTEGRATED_STEEL_PLANTS: 'Integrated Steel Plants',

  SECONDARY_METALLURGICAL_PROCESSING_TOXIC:
    'Secondary metallurgical processing industry (Toxic and heavy metals)',
  SECONDARY_METALLURGICAL_PROCESSING_NON_TOXIC:
    'Secondary metallurgical processing industry (Non Toxic) - Process involving melting/heating based on gas/electric based furnaces',
  SECONDARY_METALLURGICAL_PROCESSING_NON_TOXIC_HOT_COLD:
    'Secondary metallurgical processing industry (Non Toxic) - Process involving hot/cold rolling mill/drawing/extrusion with pickling',
  SECONDARY_METALLURGICAL_PROCESSING_NON_TOXIC_HOT_ROLLING:
    'Secondary metallurgical processing industry (Non Toxic) - Process involving hot rolling mill without pickling',
  SECONDARY_METALLURGICAL_PROCESSING_NON_TOXIC_HOT_ROLLING_MILL:
    'Secondary metallurgical processing industry (Non Toxic) - Process involving cold rolling mill/drawing/extrusion without pickling',

  INTEGRATED_CEMENT_PLANTS_GRINDING:
    'Integrated Cement plants and Grinding units',
  STANDALONE_CEMENT_PLANTS: 'Standalone Cement plants',
  STANDALONE_GRINDING_UNITS: 'Standalone Grinding unit',

  NONFERROUS_SMELTING_REFINING: 'Nonferrous smelting/ refining',

  PULP_MANUFACTURING:
    'Integrated Pulp & Paper  manufacturing from virgin material',
  PULP_MANUFACTURING_WASTE:
    'Integrated Pulp & Paper  manufacturing from waste paper pulp and other ready pulp',
  PULP_STANDALONE: 'Standalone Pulp manufacturing from virgin material',
  PULP_STANDALONE_MANUFACTURING:
    'Standalone Pulp manufacturing from waste paper',
  PULP_STANDALONE_MANUFACTURING_FROM_WASTE:
    'Standalone Paper manufacturing from waste paper pulp or other ready pulp with deinking/bleaching/colouring',
  PULP_STANDALONE_MANUFACTURING_FROM_WASTE_PAPER:
    'Standalone Paper manufacturing from waste paper pulp or other ready pulp without deinking/bleaching/colouring',

  TANNING_LOCATION_OUTSIDE:
    'Tanning (Located outside the notified Industrial Area)',
  TANNING_LOCATION_WITHIN:
    'Tanning (Located Within the notified Industrial Area)',
  TANNING_PROCESSING_OUTSIDE:
    'Processing of Skin/Hide (Located outside the notified Industrial Area)',
  TANNING_PROCESSING_WITHIN:
    'Processing of Skin/Hide (Located Within the notified Industrial Area)',

  CHLOR_OUTSIDE_NOTIFIED: 'Loacted outside the notified Industrial area/Estate',
  CHLOR_WITHIN_NOTIFIED: 'Loacted Within the notified Industrial area/Estate',
  CHLOR_EXISTING_UNITS:
    'Existing units converting to membrane cell technology ',

  CHEMICAL_FERTILIZERS_ALL_PROJECTS:
    'All projects including Single Super Phosphate with H2SO4 production',
  CHEMICAL_FERTILIZERS_ALL_SINGLE_SUPER:
    'All Single Super Phosphate without H2SO4 production',
  CHEMICAL_FERTILIZERS_UREA: 'Urea',
  CHEMICAL_FERTILIZERS_NANO_FERTILIZERS: 'Nano Fertilizers',
  CHEMICAL_FERTILIZERS_GRANULATION:
    'Granulation of single super phosphate powder',
  CHEMICAL_FERTILIZERS_NEEM_COATED: 'Neem coating of fertilizer',
  CHEMICAL_FERTILIZERS_FORTIFICATION: 'Fortification of fertilizer',

  PESTICIDES_INDUSTRY_TECHNICAL_GRADE: 'Technical grade pesticides',
  PESTICIDES_INDUSTRY_PESTICIDE: 'Pesticide intermediates',

  PETRO_CHEMICAL_COMPLEXES_INDUSTRIES:
    'Industries based on the processing of petroleum fractions & natural gas and/or reforming to aromatics',

  PETROLEUM_PRODUCTS_LOCATED: 'Located in a notified industrial area',

  SYNTHETIC_INDUSTRY_LOCATED_OUTSISE:
    'Dyes & dye intermediates (loacted outside the notified industrial area)',
  SYNTHETIC_INDUSTRY_LOCATED_WITHIN:
    'Dyes & dye intermediates (loacted within the notified industrial area)',
  SYNTHETIC_INDUSTRY_LOCATED_OUT_SIDE_NOTIFIED:
    'Bulk drugs and intermediates (located outside the notified industrial area)',
  SYNTHETIC_INDUSTRY_BULK_DRUGS:
    'Bulk drugs and intermediates  (located within notified industrial area)',
  SYNTHETIC_INDUSTRY_ACTIVE_PHARMACEUTICALS_WITHIN:
    'Active Pharmaceuticals Ingredients (located within the notified industrial area)',
  SYNTHETIC_INDUSTRY_ACTIVE_PHARMACEUTICALS_OUTSIDE:
    'Active Pharmaceuticals Ingredients (located outside the notified industrial area)',
  SYNTHETIC_INDUSTRY_SYNTHETIC_OUTSIDE:
    'Synthetic rubbers (Located outside the notified industrial area)',
  SYNTHETIC_INDUSTRY_SYNTHETIC_WITHIN:
    'Synthetic rubbers (Located within the notified industrial area)',
  SYNTHETIC_INDUSTRY_BASIC_ORGANIC_OUTSIDE:
    'Basic organic chemicals (located outside notified industrial area)',
  SYNTHETIC_INDUSTRY_BASIC_ORGANIC_WITHIN:
    'Basic organic chemicals (located within the notified industrial area)',
  SYNTHETIC_INDUSTRY_OTHER_OUTSIDE:
    'Other synthetic organic chemicals and chemical intermediates (located outside notified industrial area)',
  SYNTHETIC_INDUSTRY_OTHER_WITHIN:
    'Other synthetic organic chemicals and chemical intermediates (located within notified industrial area)',

  DISTILLERIES_MOLASSES: 'Molasses based ',
  DISTILLERIES_MOLASSES_EXPANSION:
    'Expansion of Molasses based plant for non-EBP',
  DISTILLERIES_MOLASSES_EXPANSION_PLANT:
    'Expansion of Molasses based plant for EBP',
  DISTILLERIES_MOLASSES_GRAIN: 'Grain based / Non-molasess based for Non-EBP',
  DISTILLERIES_MAHUVA: 'Mahuva / Cashew Based (Country Liquor)',
  DISTILLERIES_EBP_WITH_ZLD: 'Grain based for EBP with ZLD',
  DISTILLERIES_EBP_WITOUT_ZLD: 'Grain based for EBP without ZLD',
};

export const USER_INFO_KEY = 'SpringBoot_token';

export const REFRESH_TOKEN_KEY = 'SpringBoot_Refresh_token';

export const TOKEN_EXPIRY_KEY = 'SpringBoot_Refresh_token';

export const WSO2_TOKEN_KEY = 'wso2_token';

export const WSO2_TOKEN_WAITING_SINCE_KEY = 'wsO2ExpriryWaitingSince';

export const WSO2_TOKEN_EXPIRY_TIME_KEY = 'wsO2ExpriryTime';

export const WSO2_TOKEN_EXPIRY_DATETIME_KEY = 'wsO2ExpriryDateTime';

export const USER_EMAIL_KEY = 'loggedIn_userName';

export const ROLES_KEY = 'role';
export const STATE_KEY = 'stateid';
export const ROLES_KEY_USER = 'roleNameAbbreviationDtos';
export const USER_PROFILE = 'USER_PROFILE';
export const STATE_ID = 'stateid';
export const EDS_RAISED = 'EDS RAISED';
export const EDS_REPLIED = 'EDS Replied';
export const SELECTED_ROLE_KEY = 'SELECTED_ROLE_KEY';
export const NSWS_USER = 'nsws_user';
export const NSWS_APPROVED_ID = 'nsws_approved_id';
export const NSWS_REQUEST_ID = 'nsws_req_id';
export const NSWS_FORM_DATA = 'nsws_form_data';

export const NSWS_SWS_ID = 'nsws_sws_id';
export const IS_NSWS_POPUP_OPENED = 'IS_NSWS_POPUP_OPENED';
export const PROPONENT_APPLICATION_ID = 'PROPONENT_APPLICATION_ID';
export const EDS_PROPOSAL = 'edsProposal';

export const DISABLE_MODEL_CLOSE = {
  backdrop: 'static',
  keyboard: false,
};

export const MY_TASK_COUNT_DATA = 'my_task_count_data';
export const MY_TASK_COUNT_DATA_HISTORY = 'my_task_count_data_history';
// 5 , 12 , 2 , 478480 , 13
export const ROLES_ID = {
  AS: 8,
  JS: 7,
  MEF: 45,
  SEC: 43,
  SS: environment.dev ? 561317 : 4530821,
};
export const ROLES = {
  EMPLOYEE: 'Employee',
  CONSULTANT: 'Accredited Consultant',
  PROJECT_PROPONENT: 'Project Proponent',
  PROPONENT_EMPLOYEE: 'Proponent Employee',
  BRO_PROPONENT_EMPLOYEE: 'BRO Proponent Employee',
  DFO: 'Divisional Forest Officer (DFO)',
  DC: 'District Collector (DC)',
  CF: 'Conservator of Forest (CF)',
  PCCF: 'Principal Chief Conservator of Forests',
  SS: 'State Secretary (SS)',
  MS1: 'Member Secretary, MoEFCC (EC)',
  MS: 'Member Secretary, MoEFCC (EC)', //5
  MS_CRZ: 'MEMBER SECRETARY OF THE STATE(CRZ)',
  MS_FC: 'Member Secretary of the State',
  MS_CRZ_MOEFCC: 'MEMBER SECRETARY, MOEFCC (CRZ)',
  SO_CRZ_MOEFCC: 'SECTION OFFICER, MOEFCC(CRZ)',
  DD_CRZ_MOEFCC: 'DEPUTY DIRECTOR, MOEFCC(CRZ)',
  CHAIRMAN_CRZ: 'Chairman, SCZMA',
  CHAIRMAN_CRZ_MOEFCC: 'Chairman CRZ MOEFCC',
  OE_CRZ: 'OFFICE EXECUTIVE OF THE STATE(CRZ)',
  JS: 'Joint Secretary, MoEFCC (EC)',
  AS: 'Additional Secretary, MoEFCC (EC)',
  IG: 'Inspector General of Forest, MEF',
  IRO_HEAD: 'IRO Head',
  SO: 'Section Officer, MoEFCC (EC)',
  DD: 'Deputy Director, MoEFCC (EC)',
  IRO_DIGF: 'Deputy Inspector General of Forest, IRO',
  IRO_AIGF: 'Assistant Inspector General of Forest,IRO',
  MEF_AIGF: 'Assistant Inspector General of Forest, MEF',
  MEF_TO: 'Technical Officer, MEF',
  MEF_DIGF: 'Deputy Inspector General of Forest, MEF',
  MEF_AIGF_NEW: 'Assistant Inspector General/Scientist, MEF',
  MEF_DGF: 'Director General of Forest, MoEFCC',
  MOEF_DGF: 'Director General of Forest, MEF',
  IRO_IGF: 'Inspector General of Forest,IRO',
  IRO_TO: 'Technical Officer, IRO',
  EC_USS: 'Support staff, SEAC', //12
  EC_MS_SEAC: 'Member Secretary, SEAC', //13
  EC_MS_SEIAA: 'Member Secretary, SEIAA', //2
  SUPP_STAFF_OTHERS: 'Support staff others',
  UPPER_SUPP_STAFF_SEIAA: 'Upper support staff, SEIAA',
  SUPP_STAFF_SEIAA: 'Support staff, SEIAA', // 478480
  NODAL: 'Nodal Officer',
  DUMMY_TO: 'Dummy Technical Officer, MEF',
  PROPONENT_CENTRAL_PSU: 'Central PSU (eg. NHAI, AAI, NTP, CIL, SAIL)',
  SEACC_MEMBER: 'SEAC Member',
  LEGAL_NO: 'LEGAL_NO',
  EC_SECRETARY: 'Secretary, MoEFCC',
  NGT: 'NGT',
  SPECIAL_SECRETARY: 'Special Secretary',
  MEF: 'Union Minister, MoEFCC',
  MS_GEAC: 'GEAC MEMBER SECRETARY',
  IRO_HEAD_EC: 'IRO Head',
  MS_SPCB: 'Member Secretary,SPCB'
};

export const TYPINVALIDUSER = [
  'Employee',
  'Accredited Consultant',
  'Project Proponent',
  'Proponent Employee',
  'BRO Proponent Employee',
];

export const COMMITTEE_TYPE = {
  EAC: 'EAC',
  SCZMA: 'SCZMA',
};

export const USER_TYPE = {
  INDIVIDUAL: 'Individual',
  OTHER: 'Others',
};

export const DATE_FORMATS = {
  GENERIC: 'YYYY-MM-DD',
  DISPLAY_FORMAT: 'DD/MM/YYYY',
  DD_MM_YYYY: 'DD-MM-YYYY',
  DD_MM_YYYY_HH_MM_A: 'DD-MM-YYYY hh:mm A',
  HH_MM_A: 'hh:mm A',
};

export const EXPANSION_MADE_UNDER_SEVENT_2_A = {
  MORE_THAN_40_UP_TO_50: 'More than 40% but up to 50%',
  UP_TO_20: 'Up to 20%',
  UP_TO_40: 'Up to 40%',
  WITHOUT_INCREASE_IN_PRODUCTION_CAPACITY:
    'Without increase in production capacity but with increase in pollution load',
};

export const CAF_PROPOSAL_FOR = {
  NEW: 'New',
  EXPANSION: 'Expansion',
  RENEWAL: 'Renewal(in case of FC)',
  AMENDMENT: 'Amendment',
  CORRIGENDUM: 'Corrigendum',
  EXTENSION: 'Validity Extension',
  TRANSFER: 'Transfer',
};

export const CLEARENCE_STATS_NAME_MAPPING = [
  {
    value: 'environmentClearanceCount',
    label: 'Environment',
  },
  {
    value: 'forestClearanceCount',
    label: 'Forest',
  },
  {
    value: 'wildLifeClearanceCount',
    label: 'Wild Life',
  },
  {
    value: 'zcRZClearanceCount',
    label: 'Coastal Regulatory Zone',
  },
];

export const PROJECT_SHAPE = {
  LINEAR: 'Linear',
  NON_LINEAR: 'Non - Linear',
  HYBRID: 'Hybrid',
};

export const FC_COMPENSATOR_AFFORESTATION = {
  NON_FOREST_LAND: 'Non-forest Land',
  DEGRADED_FOREST_LAND: 'Degraded Forest Land',
  ONLY_COMPENSATORY_TREES: 'Only Compensatory Trees',
};

export const ROLE_ABBR = {
  CONSULTANT: 'Accredited_Consultant',
  PROJECT_PROPONENT: 'PP',
  PROJECT_PROPONENT_EMPLOYEE: 'PP_EMPLOYEE',
};

export const CRZ_PROCESS_FLOW_ABBR = {
  PROCESS_FLOW_EDIT_AGENDA: 'PROCESS_FLOW_EDIT_AGENDA',
};

export const BUFFER_CORE = {
  BUFFER_ZONE: 'Buffer Zone',
  CORE_ZONE: 'Core Zone',
};

export const ROLE_IDS = {
  CONSULTANT: 4453,
  PROJECT_PROPONENT: 222,
  PROJECT_PROPONENT_EMPLOYEE: 40539,
};

export const SECTOR_DETAIL = {
  AIRPORT: 'Airport',
  CONSTRUCTION: 'Construction',
  MINING: 'Mining',
  INDUSTRY: 'Industry',
  TDSF: 'TDSF',
  CBWTF: 'CBWTF',
  CEPT: 'CEPT',
  CMSWMF: 'CMSWMF',
  BUILDING: 'Building',
  BUILDINGANDCONSTRUCTION: 'Building / Construction ',
  RIVER: 'River',
  IRRIGATION: 'Irrigation',
  HYDEL: 'Hydel',
  DRINKING_WATER: 'Drinking Water',
};

export const DATE = {
  TOR_DATE: '2022-09-05',
};
export const DATE_FORM_1 = {
  TOR_DATE: '2022-09-05',
};

export const DATE_CALENDER = {
  DAYS: 'DAYS',
};

export const BASELINE_DATA_COLLECTION = {
  ALREADY_COLLECTED: 'Already collected',
};

export const AUTH_TOKEN_EXPIRED = 'AUTH_TOKEN_EXPIRED';

export const DEPARTMENT_PROPOSAL_DETAIL = 'DEPARTMENT_PROPOSAL_DETAIL';

export const PROPOSAL_DETAIL = 'PROPOSAL_DETAIL';

export const WORKGROUP_CLEARANCE = {
  ENVIRONMENTAL_CLEARANCE: 'Environmental Clearance',
  FOREST_CLEARANCE: 'Forest Clearance',
  WILD_LIFE_CLEARANCE: 'Wild Life Clearance',
  COSTAL_REGULATION_ZONE_CLEARANCE: 'Coastal Regulation Zone',
};

export const FORM_CLEARANCE_ID = {
  TOR: 5,
  ENVIRONMENTAL_CLEARANCE: 2,
  FC_FORM_A: 1,
  FC_FORM_B: 8,
  EC_FORM_3_PART_A: 36,
  EC_FORM_4_PART_A: 37,
};

export const CLEARANCE_TYPE_KEY = {
  INFRA2: 'INFRA2',
  IND1: 'IND1',
  IND3: 'IND3',
  THE: 'THE',
  MIN: 'MIN',
  INFRA1: 'INFRA1',
  RIV: 'RIV',
  CMIN: 'CMIN',
};

export const WORKGROUP_ID = {
  ENVIRONMENTAL_CLEARANCE: 1,
  FOREST_CLEARANCE: 2,
  WILD_LIFE_CLEARANCE: 3,
  COSTAL_REGULATION_ZONE_CLEARANCE: 4,
};

export const CLEARANCE_FORMS = {
  EC_TOR: 'EC_FORM_1',
  EC_TOR_B2: 'EC_FORM_1_B2',
  EC_TOR_PART_C: 'EC_FORM_2',
  EC_FORM_FIVE: 'EC_FORM_5',
  EC_FORM_SEVEN: 'EC_FORM_7',
  //EC_FORM_NINE:'EC_FORM_NINE',
  EC_FORM_TEN: 'EC_FORM_10',
  EC_FORM_SIX: 'EC_FORM_6',
  EC_FORM_EIGHT: 'EC_FORM_8',
  EC_FORM_NINE: 'EC_FORM_9',
  CRZ_FORM_I: 'CRZ_FORM_1',
  CRZ_FORM_II: 'CRZ_FORM_2',
  FC_FORM_A: 'FC_FORM_A',
  FC_FORM_A_AMEND: 'FC_FORM_A_AMEND',
  FC_FORM_B: 'FC_FORM_B',
  FC_FORM_B_AMEND: 'FC_FORM_B_AMEND',
  FC_FORM_C: 'FC_FORM_C',
  FC_FORM_C_AMEND: 'FC_FORM_C_AMEND',
  FC_FORM_D: 'FC_FORM_D',
  FC_FORM_D_AMEND: 'FC_FORM_D_AMEND',
  FC_FORM_E: 'FC_FORM_E',
  FC_FORM_E_AMEND: 'FC_FORM_E_AMEND',
  EC_FORM_3_PART_A: 'EC_FORM_3_PART_A',
  EC_FORM_4_PART_A: 'EC_FORM_4_PART_A',
  EC_FORM_ELEVEN: 'EC_FORM_11',
  EC_FORM_TWELVE: 'EC_FORM_12',
  EC_FORM_TWO: 'EC_FORM_2_2',
  FC_FORM_F: 'FC_FORM_F',
  EC_FORM_2_MINING: 'EC_FORM_2_MINING',
  CRZ_TRANSFER_FORM: 'CRZ_TRANSFER_FORM',
  CRZ_VALIDITY_EXTENSION: 'CRZ_VALIDITY_EXTENSION',
  GEAC_FORM_1: 'GEAC_FORM_1',
  GEAC_FORM_2A: 'GEAC_FORM_2A',
};

export const EC_AMENDMENT_FORMS = {
  EC_FORM_3_PART_A: 'EC_FORM_3_PART_A',
  EC_FORM_4_PART_A: 'EC_FORM_4_PART_A',
  CRZ_FORM_II: 'CRZ_FORM_2',
};
export const ACA_AMENDMENT_FORMS = {
  ACA_FORM_PART_1: 'ACA_FORM_PART_1',
};
export const EXTRA_STEPS = {
  EDS_QUERY_RESPONSE: 'EDS Response',
  AMENDMENT_LOGS: 'Amendment Logs',
};

export const AMENDMENT_FORMS = [
  EC_AMENDMENT_FORMS.EC_FORM_3_PART_A,
  EC_AMENDMENT_FORMS.EC_FORM_4_PART_A,
  CLEARANCE_FORMS.CRZ_FORM_II,
  CLEARANCE_FORMS.FC_FORM_A_AMEND,
  CLEARANCE_FORMS.FC_FORM_B_AMEND,
  CLEARANCE_FORMS.FC_FORM_C_AMEND,
  CLEARANCE_FORMS.FC_FORM_D_AMEND,
  CLEARANCE_FORMS.FC_FORM_E_AMEND,
];

export const DROPDOWN_FORMS = [
  CLEARANCE_FORMS.EC_TOR_PART_C,
  CLEARANCE_FORMS.EC_FORM_FIVE,
  EC_AMENDMENT_FORMS.EC_FORM_3_PART_A,
  EC_AMENDMENT_FORMS.EC_FORM_4_PART_A,
  CLEARANCE_FORMS.EC_FORM_SEVEN,
  CLEARANCE_FORMS.EC_FORM_NINE,
  CLEARANCE_FORMS.EC_FORM_TEN,
  CLEARANCE_FORMS.EC_FORM_EIGHT,
  CLEARANCE_FORMS.EC_FORM_SIX,
  CLEARANCE_FORMS.CRZ_FORM_II,
  CLEARANCE_FORMS.FC_FORM_A_AMEND,
  CLEARANCE_FORMS.FC_FORM_B_AMEND,
  CLEARANCE_FORMS.FC_FORM_C_AMEND,
  CLEARANCE_FORMS.FC_FORM_D_AMEND,
  CLEARANCE_FORMS.FC_FORM_E_AMEND,
  CLEARANCE_FORMS.EC_FORM_ELEVEN,
  CLEARANCE_FORMS.EC_FORM_TWELVE,
  CLEARANCE_FORMS.EC_FORM_TWO,
  CLEARANCE_FORMS.FC_FORM_F,
];

export const SECTOR_SPECIFIC_ROUTES = [
  'Sector Specific',
  'Category Specific Details',
];

export const TRACK_YOUR_PROPOSAL_MENU = [
  {
    name: 'Search by SingleWindow Proposal no.',
    url: 'https://cpc.parivesh.nic.in/PV_Search_Proposals.aspx',
    abbr: 'search_by_singlewindow_proposal_no',
    route: 'searchBySingleWindow',
  },
  {
    name: 'Environment Clearance',
    url: 'http://164.100.213.73/EC/searchproposal1.aspx',
    abbr: 'environment_clearance',
    route: 'environmentClearance',
  },
  {
    name: 'Forest Clearance',
    url: 'https://cpc.parivesh.nic.in/search_proposals.aspx',
    abbr: 'forest_clearance',
    route: 'forestClearance',
  },
  {
    name: 'Wildlife Clearance',
    url: 'http://forestsclearance.nic.in/Wildnew_Online_Status_New.aspx',
    abbr: 'wildlife_clearance',
    route: 'wildlifeClearance',
  },
  {
    name: 'Exotic Live Species',
    url: 'https://cpc.parivesh.nic.in/loginnew.aspx',
    abbr: 'exotic_live_species',
    route: 'exoticLiveSpecies',
  },
  {
    name: 'CRZ',
    url: 'http://environmentclearance.nic.in/CRZ_Proposal_Status.aspx',
    abbr: 'crz',
    route: 'crz',
  },
];

export const FC_DEFAULT_SCENARIO_DATA = {
  variables: {
    threshold: {
      value: 0,
      type: 'double',
    },
    pptype: {
      value: '',
      type: 'string',
    },
    formtype: {
      value: 'Form A',
      type: 'string',
    },
    projectcategory: {
      value: '',
      type: 'string',
    },
    violation: {
      value: 'No',
      type: 'string',
    },
    canopy: {
      value: '',
      type: 'double',
    },
    linearity: {
      value: 'Yes',
      type: 'string',
    },
  },
};

export const FC_ACA_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'ACA',
    id: 1301,
  },
];

export const FC_NPV_PAYMENT_SUBMISSION_STEPS = [
  {
    name: 'FC_PAYMENT_SCENARIO',
    id: 27001,
  },
];

export const FC_EXEMPTED_SUBMISSION_STEPS = [
  {
    name: 'FC_FORM_C_EXEMPTED_SCENARIO',
    id: 32001,
  },
];

export const FC_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 4',
    id: 401,
  },
  {
    name: 'Scenario 1',
    id: 401,
  },
  {
    name: 'Scenario 2',
    id: 201,
  },
  {
    name: 'Scenario 3',
    id: 301,
  },
  {
    name: 'Scenario 5',
    id: 501,
  },
  {
    name: 'Scenario 6',
    id: 601,
  },
  {
    name: 'Scenario 7',
    id: 701,
  },
  {
    name: 'Scenario 8',
    id: 801,
  },
  {
    name: 'Scenario 9',
    id: 901,
  },
  {
    name: 'Scenario 1 C',
    id: 18001,
  },
  {
    name: 'Scenario 1 A',
    id: 19001,
  },
  {
    name: 'Scenario 1 B',
    id: 20001,
  },
  {
    name: 'Scenario 1 D',
    id: 19001,
  },
  {
    name: 'DEF_SCENARIO',
    id: 31001,
  },
];

export const SCENARIO_CONSTANT = {
  SCENARIO_1_C: 'Scenario 1 C',
  SCENARIO_1_B: 'Scenario 1 B',
  SCENARIO_1_D: 'Scenario 1 D',
};

export const FC_AMENDMENT_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 1',
    id: 6001,
  },
  {
    name: 'Scenario 2',
    id: 6201,
  },
  {
    name: 'Scenario 4',
    id: 6401,
  },
  {
    name: 'Scenario 5',
    id: 6501,
  },
  {
    name: 'Scenario 7',
    id: 6701,
  },
];

export const FC_FORM_B_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 4',
    id: 8401,
  },
  {
    name: 'Scenario 1',
    id: 8101,
  },
  {
    name: 'Scenario 2',
    id: 8201,
  },
  {
    name: 'Scenario 3',
    id: 8301,
  },
  {
    name: 'Scenario 5',
    id: 8501,
  },
  {
    name: 'Scenario 6',
    id: 8601,
  },
  {
    name: 'Scenario 7',
    id: 8701,
  },
  {
    name: 'Scenario 8',
    id: 8801,
  },
  {
    name: 'Scenario 9',
    id: 8901,
  },
];

export const FC_FORM_C_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 2',
    id: 7201,
  },
  {
    name: 'Scenario 3',
    id: 7301,
  },
  {
    name: 'Scenario 6',
    id: 7601,
  },
  {
    name: 'Scenario 8',
    id: 7801,
  },
  {
    name: 'Scenario 1 E',
    id: 34001,
  },
  {
    name: 'Scenario 1 A',
    id: 52001,
  },
  {
    name: 'FORM_C_DEF_SCENARIO',
    id: 32001,
  },
];

export const FC_FORM_E_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 1 A',
    id: 51,
  },
  {
    name: 'Scenario 2',
    id: 2201,
  },
  {
    name: 'Scenario 4',
    id: 2401,
  },
  {
    name: 'Scenario 5',
    id: 2501,
  },
  {
    name: 'Scenario 7',
    id: 2701,
  },
];

export const FC_FORM_D_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 4',
    id: 9401,
  },
  {
    name: 'Scenario 1',
    id: 9101,
  },
  {
    name: 'Scenario 2',
    id: 9201,
  },
  {
    name: 'Scenario 5',
    id: 9501,
  },
  {
    name: 'Scenario 7',
    id: 9701,
  },
];

export const EC_FORM_SIX_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'EC Form6 Category A',
    id: 892528,
  },
  {
    name: 'EC Form6 Category B',
    id: 892574,
  },
];

export const TOR_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'TOR Category A',
    id: 5000001,
  },
  {
    name: 'TOR Category B',
    id: 5100001,
  },
];

export const EC_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'EC GNT Category A',
    id: 4000001,
  },
  {
    name: 'EC GNT Category B',
    id: 4100001,
  },
];

export const EC_FORM_PRIOR_TWO_SUBMISSION_STEPS = [
  {
    name: 'EC GNT Category A',
    id: 4000001,
  },
  {
    name: 'EC GNT Category B',
    id: 4100001,
  },
];

export const CRZ_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'CRZ SCENARIO 1',
    id: 25001,
  },
  {
    name: 'CRZ SCENARIO 2',
    id: 26001,
  },
];

export const EC_AMENDMENT_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'EC AMD Category A',
    id: 4300001,
  },
  {
    name: 'EC AMD Category B',
    id: 4400001,
  },
];

export const EC_FORM_FIVE_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 1',
    id: 858684,
  },
];

export const EC_FORM_SEVEN_PROCESS_SUBISSION_STEPS = [
  {
    name: 'EC TRF Category A',
    id: 4200001,
  },
  {
    name: 'EC TRF Category B',
    id: 4100001,
  },
];

export const EC_FORM_TWO_PROCESS_SUBISSION_STEPS = [
  {
    name: 'EC SPT Category A',
    id: 1200001,
  },
  {
    name: 'EC SPT Category B',
    id: 4100001,
  },
];

export const EC_FORM_EIGHT_PROCESS_SUBISSION_STEPS = [
  {
    name: 'TOR TRF Category A',
    id: 4200001,
  },
  {
    name: 'TOR TRF Category B',
    id: 4200001,
  },
];

export const TOR_AMENDMENT_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'TOR Amd Category A',
    id: 3000001,
  },
  {
    name: 'TOR Amd Category B',
    id: 3100001,
  },
];

export const EC_FORM_EIGHT_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'TOR TRF Category A',
    id: 8000001,
  },
  {
    name: 'TOR TRF Category B',
    id: 5100001,
  },
];

export const EC_FORM_NINE_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'S1',
    id: 864561,
  },
  {
    name: 'S1B',
    id: 9000001,
  },
];

export const EC_FORM_TEN_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'S1',
    id: 876308,
  },
];

export const CRZ_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'S1',
    id: 77000002,
  },
];

export const AGENDA_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'EAC AGENDA SCENARIO',
    id: 10001,
  },
];

export const WITHDRAW_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'FC_SCENARIO',
    id: 15001,
    processId: 10,
  },
  {
    name: 'EC_SCENARIO',
    id: 16001,
    processId: 11,
  },
  {
    name: 'SIEAA_SCENARIO',
    id: 17001,
    processId: 12,
  },
  {
    name: 'CRZ WITHDRAW',
    id: 24001,
    processId: 42,
  },
];

export const CRZ_TRANSFER_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'CRZ TRANSFER',
    id: 270001,
  },
];

export const CRZ_VALIDITY_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'CRZ VALIDITY',
    id: 300001,
  },
];

export const CRZ_AMENDMENT_FORM_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'CRZ AMENDMENT',
    id: 280001,
  },
];

export const GEAC_FORM_ONE_PROCESS_SUBMISSION_STEP = [
  {
    name: 'GEAC_CONFINED_FIELD_TRIALS',
    id: 77000100,
  },
];

export const FC_FORM_E_PART_B_STEPS = [
  {
    name: 'Basic Details',
    route: '/basic-details',
    id: 'fc_form_E_part_b_basicDetails',
  },
  {
    name: 'WL Specific Details',
    route: '/wl-specific-details',
    id: 'fc_form_E_part_b_wl_Specific_Details',
  },
  {
    name: 'Afforestation Details',
    route: '/afforestation-details',
    id: 'fc_form_E_part_b_afforestationDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_form_E_part_b_additionalInformation',
  },
  {
    name: 'Other Details',
    route: '/other-details',
    id: 'fc_form_E_part_b_otherDetails',
  },
];
export const PAYMENT_DETAIL_DFO = [
  {
    name: 'Project Detail',
    route: '/project-detail',
    id: 'payment_detail_dfo_projectDetail',
  },
  {
    name: 'Compensatory Detail',
    route: '/compensatory-detail',
    id: 'payment_detail_dfo_compensatoryDetail',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'payment_detail_dfo_additionalInformation',
  },
];

export const NPV_PAYMENT_DETAIL_DFO = [
  {
    name: 'Project Detail',
    route: '/project-detail',
    id: 'payment_detail_dfo_projectDetail',
  },
  {
    name: 'Compensatory Detail',
    route: '/compensatory-detail',
    id: 'payment_detail_dfo_compensatoryDetail',
  },
];

export const MEETING_NOTICE = [
  {
    name: 'Meeting Notice',
    route: '/department/meeting-notice',
    id: 'meeting_notice_detail',
  },
];

export const PAYMENT_COMPLETION_DETAILS = [
  {
    name: 'Proponent Details',
    route: '/pp-details',
    id: 'payment_completion_details_proponentDetails',
  },
  {
    name: 'Proposal Details',
    route: '/proposal-details',
    id: 'payment_completion_details_proposalDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'payment_completion_details_additionalInformation',
  },
];

export const NPV_PAYMENT_COMPLETION_DETAILS = [
  {
    name: 'Proponent Details',
    route: '/pp-details',
    id: 'payment_completion_details_proponentDetails',
  },
  {
    name: 'Payment Details',
    route: '/payment-details',
    id: 'payment_completion_details_proposalDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'payment_completion_details_additionalInformation',
  },
];

export const PAYMENT_COMPLETION_DETAILS_WLC = [
  {
    name: 'Proponent Details',
    route: '/pp-details',
    id: 'payment_completion_details_proponentDetails',
  },
  {
    name: 'Transaction Details',
    route: '/proposal-details',
    id: 'payment_completion_details_proposalDetails',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'payment_completion_details_additionalInformation',
  },
];
export const MOM_DETAILS = ['Basic Details', 'Project Details'];

export const FORM_ABBR_TYPES = {
  FC_FORM_A: 'FC_FORM_A',
  FC_FORM_A_AMEND: 'FC_FORM_A_AMEND',
  FC_FORM_B: 'FC_FORM_B',
  FC_FORM_C: 'FC_FORM_C',
  FC_FORM_D: 'FC_FORM_D',
  FC_FORM_E: 'FC_FORM_E',
  EC_FORM_1: 'EC_FORM_1',
  EC_FORM_2: 'EC_FORM_2_2',
  EC_FORM_5: 'EC_FORM_5',
  EC_FORM_6: 'EC_FORM_6',
  EC_FORM_7: 'EC_FORM_7',
  EC_FORM_8: 'EC_FORM_8',
  EC_FORM_9: 'EC_FORM_9',
  EC_FORM_10: 'EC_FORM_10',
  WL_FORM_1: 'WL_FORM_1',
  CRZ_FORM_1: 'CRZ_FORM_1',
  CRZ_TRANSFER_FORM: 'CRZ_TRANSFER_FORM',
  CRZ_VALIDITY_EXTENSION: 'CRZ_VALIDITY_EXTENSION',
  EC_FORM_3_PART_A: 'EC_FORM_3_PART_A',
  EC_FORM_4_PART_A: 'EC_FORM_4_PART_A',
  EC_FORM_12: 'EC_FORM_12',
  EC_FORM_1_B2: 'EC_FORM_1_B2',
  EC_FORM_11: 'EC_FORM_11',
  MEETING_NOTICE: 'MEETING_NOTICE',
  FC_FORM_C_EXEMPTED: 'FormC_Exempted_Category_Part_I'
};

export const CERTIFICATES_ROUTES = {
  GET_DATE_TIME: 'api/datetime',
  GET_APPROVING_AUTHORITY: 'approvingAuthority/getApprovingAuthority',
  GET_APPROVING_AUTHORITY_V2: 'approvingAuthority/getApprovingAuthority/V2',
  GET_GENERAL_CONDITION: 'conditionMaster/getHeadings',
  GENERATE_QR_CODE: 'genrateAndDownloadQRCode',
  GENERATE_QR_CODE_STANDARD: 'qr-code/build-qr',
  GET_FRESH_LETTER_TOR: 'freshLetterCertificate/getFreshLetterTorCertByPropId',
  SAVE_FRESH_LETTER_TOR: 'freshLetterCertificate/saveFreshLetterCertificate',
  GET_STANDARD_TOR: 'standardCertificate/getStandTorCertByPropId',
  GET_STANDARD_TOR_ID: 'standardCertificate/getStandTorCertById',
  SAVE_STANDARD_TOR_CERTIFICATE: 'standardCertificate/ecCertificate',
  GET_REJECTION_TOR: 'rejectionCertificate/getRejectionTorCertByPropId',
  SAVE_REJECTION_TOR: 'rejectionCertificate/saveRejectionCertificate',
  GET_EC_MINING_ACKNOWLEDGMENT:
    'ecTransferOfMiningLeaseAcknowledgmentCertificate/getEcTransferOfMiningLeaseAcknowledgmentCertByPropId',
  SAVE_EC_MINING_ACKNOWLEDGMENT_CERTIFICATE:
    'ecTransferOfMiningLeaseAcknowledgmentCertificate/saveEcTransferOfMiningLeaseAcknowledgmentCertificate',
  GET_SPECIFIC_TOR: 'specificCertificate/getSpecificTorCertByPropId',
  SAVE_SPECIFIC_TOR: 'specificCertificate/saveSpecificCertificate',
  GET_AMENDMENT_TOR: 'amendmentCertificate/getEcTorAmendmentCertByPropId',
  SAVE_AMENDMENT_TOR: 'amendmentCertificate/saveAmendmentCertificate',
  GET_FRESH_LETTER: 'freshLetterCertificate/getFreshLetterTemplateCertByPropId',
  SAVE_FRESH_LETTER: 'freshLetterCertificate/saveFreshLetterCertificate',
  GET_EC_SPLIT: 'EcForm12Certificate/get',
  SAVE_EC_SPLIT: 'EcForm12Certificate/saveChildren',
  SAVE_EC_PARENT_SPLIT: 'EcForm12Certificate/save',
  GET_SURRENDER_LETTER: 'Ecform11Certificate/get',
  GET_CORRINGENDUM_LETTER: 'ecCorrigendumCertificate/get',
  SAVE_SURRENDER_LETTER: 'Ecform11Certificate/save',
  SAVE_CORRINGENDUM_LETTER: 'ecCorrigendumCertificate/save',

  GET_TRANSFER_TOR:
    'ecTorTransferTemplate/getEcTorTransferTemplateCertByPropId',
  SAVE_TRANSFER_TOR_CERTIFICATE:
    'ecTorTransferTemplate/saveEcTorTransferTemplate',

  GET_EC_TRANSFER: 'transferTemplate/getEcTransferTemplateCertByPropId',
  SAVE_EC_TRANSFER_CERTIFICATE: 'transferTemplate/saveTransferTemplate',

  GET_EC_ALL_SECTORS_REJECTION_CERTIFICATE:
    'ecAllSectorRejectionLetterCertificate/getEcAllSectorRejectionLetterCertByPropId',
  SAVE_EC_ALL_SECTORS_REJECTION_CERTIFICATE:
    'ecAllSectorRejectionLetterCertificate/saveEcAllSectorRejectionLetterCertificate',

  GET_EC_AMENDMENT: 'ecAmendmentCertificate/getEcAmendmentCertByPropId',
  SAVE_EC_AMENDMENT_CERTIFICATE:
    'ecAmendmentCertificate/saveEcAmendmentCertificate',

  GET_EC_MINING_AMENDMENT_CERTIFICATE:
    'ecMiningAmendmentCertificate/getEcMiningAmendmentCertByPropId',
  SAVE_EC_MINING_AMENDMENT:
    'ecMiningAmendmentCertificate/saveEcMiningAmendmentCertificate',

  GET_EC_MINING_EXPANSION:
    'ecMiningExpansionTemplate/getEcMiningExpansionTemplateCertByPropId',
  SAVE_EC_MINING_EXPANSION:
    'ecMiningExpansionTemplate/saveEcMiningExpansionTemplate',

  GET_EC_Mining_EXTENSION_OF_VALIDITY:
    'ecMiningExtensionofValidityTemplateCertificate/getEcMiningExtensionOfValidityTemplateCertByPropId',
  SAVE_EC_MINING_EXTENSION_OF_VALIDITY:
    'ecMiningExtensionofValidityTemplateCertificate/saveEcMiningExtensionofValidityTemplateCertificate',

  GET_EC_EXPANSION_NIPL:
    'ecExpansionNIPLCertificate/getEcExpansionNIPLCertByPropId',
  SAVE_EC_EXPANSION_NIPL:
    'ecExpansionNIPLCertificate/saveEcExpansionNIPLCertificate',

  GET_EC_EXPANSION: 'expansionFormData/get',
  SAVE_EC_EXPANSION: 'expansionFormData/save',
  GET_EC_EXTENSION_OF_VALIDITY:
    'ecExtensionOfValidityCertificate/getEcExtensionOfValidityCertByPropId',
  SAVE_EC_EXTENSION_OF_VALIDITY:
    'ecExtensionOfValidityCertificate/saveEcExtensionOfValidityCertificate',

  GET_MINING_EC_TRANSFER:
    'ecMiningTransferCertificateController/getEcMiningTransferCertByPropId',
  SAVE_MINING_EC_TRANSFER_CERTIFICATE:
    'ecMiningTransferCertificateController/saveEcMiningTransferCertificate',

  GET_MINISTRY_STAGE_II_CLEARANCE_LETTER_CERTIFICATE:
    'fcMinistryStageClearanceCertificate/getFcMinistryStageIIClearanceCertByPropId',
  GET_MINISTRY_STAGE_II_FORM_TYPE_CLEARANCE_LETTER_CERTIFICATE:
    'fcMinistryStageClearanceCertificate/getFcMinistryStageClearanceCertFormTypeByPropId',
  SAVE_MINISTRY_STAGE_II_CLEARANCE_LETTER_CERTIFICATE:
    'fcMinistryStageClearanceCertificate/saveFcMinistryStageIIClearanceCertificate',

  GET_MINISTRY_STAGE_I_CLEARANCE_LETTER_CERTIFICATE:
    'fcMinistryStageClearanceCertificate/getFcMinistryStageIClearanceCertByPropId',
  SAVE_MINISTRY_STAGE_I_CLEARANCE_LETTER_CERTIFICATE:
    'fcMinistryStageClearanceCertificate/saveFcMinistryStageIClearanceCertificate',

  GET_STAGE_II_CLEARANCE_LETTER_CERTIFICATE:
    'fcStateStageClearanceCertificate/getFcStateStageIIClearanceCertByPropId',
  GET_STAGE_II_FORM_TYPE_CLEARANCE_LETTER_CERTIFICATE:
    'fcStateStageClearanceCertificate/getFcStateStageClearanceCertFormTypeByPropId',
  SAVE_STAGE_II_CLEARANCE_LETTER_CERTIFICATE:
    'fcStateStageClearanceCertificate/saveFcStateStageIIClearanceCertificate',

  GET_STAGE_I_CLEARANCE_LETTER_CERTIFICATE:
    'fcStateStageClearanceCertificate/getFcStateStageIClearanceCertByPropId',
  SAVE_STAGE_I_CLEARANCE_LETTER_CERTIFICATE:
    'fcStateStageClearanceCertificate/saveFcStateStageIClearanceCertificate',
  GET_STAGE_FC_CONDITIONS: 'proponentApplicant/getFcCertificateConditions',
  GET_IRO_STAGE_II_CLEARANCE_LETTER_CERTIFICATE:
    'fcIROStageClearanceCertificate/getFcIROStageIIClearanceCertByPropId',
  GET_IRO_STAGE_II_FORM_TYPE_CLEARANCE_LETTER_CERTIFICATE:
    'fcIROStageClearanceCertificate/getFcIROStageClearanceCertFormTypeByPropId',
  SAVE_IRO_STAGE_II_CLEARANCE_LETTER_CERTIFICATE:
    'fcIROStageClearanceCertificate/saveFcIROStageIIClearanceCertificate',
  GET_IRO_STAGE_II_OFFICE_NAME: 'process/getUserOffice',

  GET_IRO_STAGE_I_CLEARANCE_LETTER_CERTIFICATE:
    'fcIROStageClearanceCertificate/getFcIROStageIClearanceCertByPropId',
  GET_FORM_G_IRO_STAGE_I_CLEARANCE_LETTER_CERTIFICATE:
    'fcIROStageClearanceCertificate/getFormGCertByPropId',
  SAVE_IRO_STAGE_I_CLEARANCE_LETTER_CERTIFICATE:
    'fcIROStageClearanceCertificate/saveFcIROStageIClearanceCertificate',

  GET_TOR_CERTIFICATE: 'standardCertificate/getTORformData',

  GET_EC_SURRENDER_LETTER: '',

  GET_MINING_FRESH_LETTER:
    'ecMiningFreshLetterCertificate/getEcMiningFreshLetterCertByPropId',

  GET_INDUS_PROPOSAL_BY_PROP_ID:
    'standardCertificate/getIndustryProposalDetailByEcId',
  GET_TOR_IDENTIFICATION_NUMBER: 'standardCertificate/genIdentificationNo',
  GET_TOR_IDENTIFICATION_NUMBER_FOR:
    'ecTorTransferTemplate/getIdentificationNo',
  GET_TOR_IDENTIFICATION_NUMBER_TRANSFER:
    'transferTemplate/getIdentificationNo',
  GET_TOR_IDENTIFICATION_NUMBER_MINING:
    'ecTransferOfMiningLeaseAcknowledgmentCertificate/getIdentificationNo',

  SAVE_EC_SURRENDER_LETTER_CERTIFICATE: '',

  SAVE_MINING_FRESH_LETTER:
    'ecMiningFreshLetterCertificate/saveEcMiningFreshLetterCertificate',
  GET_FRESH_LETTER_IDENTIFICATION_NUMBER_FOR:
    'freshLetterCertificate/getIdentificationNo',
  GET_IDENTIFICATION_NUMBER_FORM_11: 'ecForm11/getIdentificationNo',
  GET_IDENTIFICATION_NUMBER_FORM_13: 'ecForm13/getIdentificationNo',
  GET_IDENTIFICATION_NUMBER_FORM_12: 'ecForm12/getIdentificationNo',
  GET_EC_MINING_EXPANSION_IDENTIFICATION_NUMBER_FOR:
    'ecMiningExpansionTemplate/getIdentificationNo',
  GET_EC_AMENDMENT_IDENTIFICATION_NUMBER_FOR:
    'ecAmendmentCertificate/getIdentificationNo',
  GET_EC_MINING_FRESH_LETTER_IDENTIFICATION_NUMBER_FOR:
    'ecMiningFreshLetterCertificate/getIdentificationNo',
  GET_EC_EXTENSION_VALIDITY_IDENTIFICATION_NUMBER_FOR:
    'ecExtensionOfValidityCertificate/getIdentificationNo',
  SAVE_CRZ_FRESH_LETTER: 'crzcertificate/crzCertificate',
  GET_CRZ_FRESH_LETTER: 'crzcertificate/getCrzGrantCertByPropId',
  SAVE_CRZ_REJECTION_LETTER: 'crzRejectionCertificate/crzRejectionCertificate',
  GET_CRZ_REJECTION_LETTER:
    'crzRejectionCertificate/getCrzRejectionCertByPropId',

  SAVE_CRZ_AMENDMENT_LETTER: 'crzamendmentcertificate/crzAmendmentCertificate',
  GET_CRZ_AMENDMENT_LETTER:
    'crzamendmentcertificate/getCrzAmendmentCertByPropId',
  SAVE_CRZ_TRANSFER_LETTER: 'crztransfercertificate/crzTransferCertificate',
  GET_CRZ_TRANSFER_LETTER: 'crztransfercertificate/getCrzTransferCertByPropId',
  SAVE_CRZ_VALIDITY_LETTER: 'crzvaliditycertificate/crzValidityCertificate',
  GET_CRZ_VALIDITY_LETTER: 'crzvaliditycertificate/getCrzValidityCertByPropId',
};

export const AMENDMENT_COMPARISON = {
  CAF: 'CAF',
  EC_A: 'EC-A',
  EC_B: 'EC-B',
  EC_C: 'EC-C',
  CRZ: 'CRZ',
  CRZ_I: 'CRZ_FORM_2',
  FC_A: 'FC-A',
  FC_B: 'FC-B',
  FC_C: 'FC-C',
  FC_D: 'FC-D',
  FC_E: 'FC-E',
};

export const MAX_ADD_ROW_LIMIT = {
  PAYMENT_COMPENSATORY_DETAILS: 4,
};

export const GENRAL_COND_ROUTES = {
  GET_GEN_COND: 'conditionMaster/getConditionsByActivityId',
};

export const REASON_OF_EXTENSION_ROUTES = {
  REASON_OF_EXTENSION_: 'ECMoM/getMomByAppHistoryIdAndProposalNumber',
};

export const SPEC_COND_ROUTES = {
  GET_SPEC_COND: 'ECMoM/getMomByAppHistoryIdAndProposalNumber',
};
export const REASON_FOR_AMENDMENT_ROUTES = {
  GET_REASON_FOR_AMENDMENT: 'ECMoM/getMomByAppHistoryIdAndProposalNumber',
};

export const STANDARD_EC_CONDITION_ROUTES = {
  GET_STANDARD_EC_COND: 'ECMoM/getMomByAppHistoryIdAndProposalNumber',
};

export const SPECIFIC_EC_CONDITION_ROUTES = {
  GET_SPECIFIC_EC_COND: 'ECMoM/getMomByAppHistoryIdAndProposalNumber',
};

export const STORAGE_KEY = {
  AMEND_PROPOSAL_DATA: 'amendment_proposal_data',
};

export const FC_CERTIFICATES_ROUTES = {
  GET_CLEARANCE_LETTER_COND:
    'clearanceLetterController/getClearanceLetterbyproposalno',
  GET_CLEARANCE_CERTIFICATES_DETAILS:
    'clearance/letter/getClearanceCertificateDetails',
  FC_CORRIGENDUM_GET: 'fcCorrigendumCertificate/getAllDataByPropId',
  FC_CORRIGENDUM_SAVE: 'fcCorrigendumCertificate/saveFcCorrigendumCertificate',
};

export const AGENDA_STATUS = {
  CONSIDERED: 'Considered',
  HOLD: 'Hold',
  NEED_CONSIDERATION: 'To be considered',
};

export const MOM_PROCESS_FLOW_STATUS = {
  PROCESS_FLOW_MOM_FINAL_SUBMIT: 'PROCESS_FLOW_MOM_FINAL_SUBMIT',
  MOM_SUBMITTED_BY_MS: 'MOM_SUBMITTED_BY_MS',
};

export const AGENDA_PROCESS_FLOW_STATUS = [
  'MS_APPROVED',
  'MINISTER_APPROVED_REORDER_REQUEST',
];

export const BANK_DETAILS = {
  BRANCH: 'Union Bank Of India, FCS Centre (996335)',
  NAME: 'Union Bank Of India',
  IFSC_CODE: 'UBIN0996335',
  BANK_ADDRESS:
    'FCS Centre, 21/1, III Floor, Jelitta Towers, MissionRoad, Bengaluru-560027',
};

export const AGENDA_MEETING_TYPES = {
  HYBRID: 'Hybrid',
  PHYSICAL: 'Physical',
  VIRTUAL: 'Virtual',
};

export const MOM_FORM_HEADING = {
  MOM_COMMON_FEILD: 'Create MOM',
  INDIVIDUAL_MOM: 'Proposal Deliberations',
  MINUTES_MEETING: 'Minutes of Meeting',
};

export const MOM_STATUS = {
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  FIANL_SUBMIT: [
    'Pending at MS for Final Submit',
    'Approved & Pending at MS for MoM Publish',
  ],
};

export const WITHDRAW_STATUS = {
  REQUESTED: 'WITHDRAW_REQUESTED',
  ACCEPTED: 'WITHDRAW_ACCEPTED',
  REJECTED: 'WITHDRAW_REJECTED',
};

export const STATUS_FOR_PROPOSALS = ['DRAFT', 'GRANTED', 'REJECTED'];

export const RANGE_FORMAT = {
  YEAR: '1000-2023',
};

export const ACTIVITES_FOR_EC_FORM_10 = [
  1, 2, 3, 5, 6, 7, 29, 30, 31, 32, 33, 34, 35, 36, 37, 39, 40, 97,
];

export const SECTOR_CODES_FOR_EC_FORM_10 = ['CMIN', 'IND', 'IND2', 'IND3'];

export const SECTOR_CODE_FOR_EC_FORM_9 = ['MIN', 'CMIN'];
export const SUB_ACTIVITY_FOR_EC_FORM_9 = [182, 171, 170];

export const AGENDA_OTP_POPUP_STATUS = [
  'PROCESS_FLOW_AGENDA_APPROVE',
  'PROCESS_FLOW_AGENDA_ACCEPT',
  'PROCESS_FLOW_AGENDA_REJECT',
  'PROCESS_FLOW_MOM_RAISE_QUERY',
  'PROCESS_FLOW_MOM_EDIT_MOM',
  'PROCESS_FLOW_AGENDA_APPROVE',
];

export const ORGANISATION_DASHBOARD_ROLE = [222, 4453, 40536];

export const FC_2_ABBR: string[] = [
  'FC_FORM_A_PART_II',
  'FC_FORM_B_PART_II',
  'FC_FORM_C_PART_II',
  'FC_FORM_E_PART_II',
  'FC_FORM_D_PART_II',
  'FC_FORM_F_PART_II',
  'FormC_Exempted_Category_Part_II'
];

export const FC_3_ABBR: string[] = [
  'FC Form-A (Part III)',
  'FC_FORM_C_PART_III',
  'FC_FORM_B_PART_III',
  'FC_FORM_E_PART_III',
  'FC Form-D (Part III)',
  'FC_FORM_A_PART_III_NEW',
  'FC_FORM_C_PART_III_NEW',
  'FC_FORM_B_PART_III_NEW',
  'FC_FORM_D_PART_III_NEW',
  'FC_FORM_F_PART_III',
];
export const FC_4_ABBR: string[] = [
  'FC Form-A (Part IV)',
  'FC_FORM_C_PART_IV',
  'FC_FORM_B_PART_IV',
  'FC Form-D (Part IV)',
  'FC_FORM_E_PART_IV',
  'FC_FORM_A_PART_IV_NEW',
  'FC_FORM_C_PART_IV_NEW',
  'FC_FORM_B_PART_IV_NEW',
  'FC_FORM_D_PART_IV_NEW',
  'FC_FORM_F_PART_IV',
];

export const SIR_ABBR: string[] = ['PROCESS_FLOW_FC_REQUEST_SIR_FORM'];

export const COMPLIANCE_ABBR: string[] = ['PROCESS_FLOW_FC_COMPLAINCE_FORM'];

export const WORKING_PERMISSION_ABBR: string[] = ['UPLOAD_WORKING_PERMISSION'];

export const FC_FACTSHEET_ABBR: string[] = ['UPLOAD_FACTSHEET_FC'];

export const STIPULATED_CA_ABBR: string[] = ['CA_STIPULATED_ACTION_1'];

export const FC_FORM_NAMES: string[] = [
  'Part I',
  'Part II',
  'Part III',
  'Part IV',
  'SIR',
  'Factsheet',
  'Compliance',
  'Working Permission',
  'Stipulated CA',
];

export const SCENARIO_FOR_NOTE: string[] = ['Scenario 1', 'Scenario 1 A'];
export const EXCEPTIONAL_SCENARIO: string[] = [
  'Scenario 1 C',
  'Scenario 1 A',
  'Scenario 1 E',
  'DEF_SCENARIO',
];
export const SEND_TO_GRANT_AUTHORITY: { label: string; value: any }[] = [
  { label: 'Principal Chief Conservator of Forests', value: true },
  { label: 'State Secretary (SS)', value: false },
];

export const PROPOSAL_HISTORY_STATUS = {
  IN_PRINCIPLE_APPROVAL_GRANTED_STAGE_1:
    'In-Principle Approval Granted (Stage-I)',
  STAGE_2_ACCORDED: 'Stage-II Accorded',
  STAGE_II_ACCORDED: 'STAGE II ACCORDED',
  STAGE_II_ACCORDED_FINAL: 'final diversion order issued',
};

export const MIS_DASHBOARD_KEYS = {
  IRO: '81b15fb8-a576-49d7-b9a3-f7828d2efac2',
  MEF: 'a2367d1b-4c87-4836-a0df-4e6993e2e69e',
  NODAL: 'e97f0fe2-fa52-4fc3-bfbb-28699985b2d8',
  EC_JS: '835fe867-71de-463b-be66-56bd7c9eed9c',
  EC_AS: '835fe867-71de-463b-be66-56bd7c9eed9c',
  EC_MS: 'b9f05c15-6571-433d-8fe5-02855e0e99a3',
  PUBLIC_EC: 'c1c71a91-efee-46e1-9af3-4df76fad8e38',
  PUBLIC_FC: '72063a0f-561f-4c98-ac61-2e26ada9fb29',
  PUBLIC_WL: '584b6a1a-ade3-4251-8c94-2b4742ef7c64',
  PUBLIC_CRZ: '14433d71-5fa2-43dc-8950-31a5968a04d4',
};

export const MIS_PUBLIC_DASHBOARD_KEYS = {
  EC: 'c1c71a91-efee-46e1-9af3-4df76fad8e38',
  FC: '72063a0f-561f-4c98-ac61-2e26ada9fb29',
  WL: '584b6a1a-ade3-4251-8c94-2b4742ef7c64',
  CRZ: '14433d71-5fa2-43dc-8950-31a5968a04d4',
};

export const PROJECT_ACTIVITY_PROVISION_UNDER = {
  '7(ii)(b)': '7(ii) (b)',
  '7(ii)(c)': '7(ii) (c)',
};

export const SEAA_INACTIVE_FOR: any[] = []; // State Code

export const CAMPA_USER_ROLE = {
  DFO: 'DFO',
  CF: 'CF',
  CCF: 'CCF',
  PCCF: 'PCCF',
};

export const CAMPA_ADD_WORK_STEPS = [
  {
    name: 'Activity Details',
    route: '/campa/work/activity-details',
    id: 'campa_add_work_activity_details',
  },
  {
    name: 'Proposal Details',
    route: '/campa/fc-proposal/view-purposal',
    id: 'campa_add_work_purposal_details',
  },
  {
    name: 'Document Details',
    route: '/campa/work/view-document',
    id: 'campa_add_work_document_details',
  },
  {
    name: 'Add Work',
    route: '/campa/work/add-work',
    id: 'campa_add_work_details',
  },
];

export const ACTION_ID = {
  PROCESS_FLOW_MOM_FINAL_SUBMIT: ['14014', '13006'],
  AGENDA_EDIT_REQUEST: ['11020', '11007', '14024', '10043'],
  REORDER_FLOW: ['10052'],
};

export const CLEARANCE_FOR_REFERMEETING = [
  '153',
  '50',
  '125',
  '191',
  '239',
  '237',
  '187',
  '158',
];

export const STEP_PARAMETER = {
  DFA_CERTIFICATE_ESIGN: 'dfa_certificate_esign',
};

export const SIR = {
  UPDATE_SIR: 'workgroupmaster/process/requestSIR',
};

export const GRANTED_STATUS = [
  'crz granted',
  'CRZ_Granted',
  'crz_granted',
  'granted',
];

export const EXEMPTED_PARAMS = {
  DEFENSE_CATEGORY: 'DEF',
  EXEMPTED_CATEGORY: {
    INFRA: 'Defence- Related Infrastructure (Up to 10 Hectare)',
    SECURITY_RELATED_INFRA:
      'Security- Defence Related Strategic Linear Infrastructure (Within 100 KM)',
    DEFENCE_RELATED_INFRA_LWE_PUBLIC:
      'Upto 5 hectare for public utility in LWE district',
    DEFENCE_RELATED_INFRA_LWE_DEFENCE:
      'Upto 5 hectare for Defence related infrastructure in LWE district',
    ROAL_RAIL_AMINITIES:
      'Road Linking Railside/Road Side Amenities (Up to 0.1 Hectare)',
    NA: 'NA',
  },

  // category Removed on 06-08-2024
  //DEFENCE_RELATED_INFRA_LWE: 'Defence- Related Infrastructure and Public Utility in LWE (Up to 5 Hectare)'
  SCENARIOS: {
    SCENARIO_1_A: 'Scenario 1 A',
    SCENARIO_1_B: 'Scenario 1 B',
    SCENARIO_1_C: 'Scenario 1 C',
    SCENARIO_1_D: 'Scenario 1 D',
    SCENARIO_1_E: 'Scenario 1 E',
    FORM_C_DEF_SCENARIO: 'FORM_C_DEF_SCENARIO',
    DEF_SCENARIO: 'DEF_SCENARIO',
  },
};

export const FC_AGENDA_MOM_SCENARIO = {
  AC_AGENDA_SCENARIO: 'AC_AGENDA_SCENARIO',
  REC_AGENDA_SCENARIO: 'REC_AGENDA_SCENARIO',
  PSC_AGENDA_SCENARIO: 'PSC_AGENDA_SCENARIO',
};

export const FC_CORRIGENDUM_SCENARIO = {
  FC_CORRIGENDUM_INCLUSION: 'FC_CORRIGENDUM_INCLUSION',
  FC_CORRIGENDUM_IRO_INCLUSION: 'FC_CORRIGENDUM_IRO_INCLUSION',
  FC_CORRIGENDUM_STATE_INCLUSION: 'FC_CORRIGENDUM_STATE_INCLUSION',
};

export const INTEGRATED_STATE_APO_STEPS = [
  {
    name: 'Integrated Step 1',
    route: '/campa/campa-apo/state-integrated-apo',
    id: 'campa_integrated_step_1',
  },
  {
    name: 'Integrated Step 2',
    route: '/campa/campa-apo/state-integrated-step-one-part',
    id: 'campa_integrated_step_2',
  },
  {
    name: 'Integrated Step 3',
    route: '/campa/campa-apo/state-integrated-step-two',
    id: 'campa_integrated_step_3',
  },
  {
    name: 'Integrated Step 4',
    route: '/campa/campa-apo/state-integrated-step-three',
    id: 'campa_integrated_step_4',
  },
  {
    name: 'Integrated Step 5',
    route: '/campa/campa-apo/state-integrated-step-four',
    id: 'campa_integrated_step_5',
  },
  {
    name: 'Integrated Step 6',
    route: '/campa/campa-apo/state-integrated-step-five',
    id: 'campa_integrated_step_6',
  },
];

export const FC_FORM_G_STEP = [
  {
    name: 'Basic Details',
    route: '/form-g-part-one',
    id: 'form_g_part_one',
  },
  {
    name: 'Other Details',
    route: '/form-g-part-two',
    id: 'form_g_part_two',
  },
  {
    name: 'Additional Information',
    route: '/additional-information',
    id: 'fc_additional_information',
  },
];

export const FC_FORM_F_PROCESS_SUBMISSION_STEPS = [
  {
    name: 'Scenario 1 F',
    id: environment.production ? 38001 : 21001,
  },
  {
    name: 'Scenario 1',
    id: environment.production ? 38001 : 21001,
  },
  {
    name: 'Scenario 1 C',
    id: environment.production ? 38001 : 21001,
  },
  {
    name: 'Scenario 1 A',
    id: environment.production ? 38001 : 21001,
  },
  {
    name: 'Scenario 1 B',
    id: environment.production ? 38001 : 21001,
  },
  {
    name: 'Scenario 1 D',
    id: environment.production ? 38001 : 21001,
  },
  {
    name: 'Scenario 2 F',
    id: 36001,
  },
  {
    name: 'Scenario 3 F',
    id: 37001,
  },
  {
    name: 'Scenario 4',
    id: 36001,
  },
  {
    name: 'Scenario 2',
    id: 36001,
  },
  {
    name: 'Scenario 3',
    id: 36001,
  },
  {
    name: 'Scenario 6',
    id: 36001,
  },
];

export const PAGINATION = {
  PER_PAGE: 25,
  PER_PAGE_COUNT: [25, 50, 100, 250, 500],
};

export const OZONE_FORM_NINE = [
  {
    name: 'Form-9 Part 1',
    route: '/campa/campa-apo/form-nine-part-1',
    id: 'ozone_form_nine_one',
  },
  {
    name: 'Form-9 Part 2',
    route: '/campa/campa-apo/form-nine-part-2',
    id: 'ozone_form_nine_two',
  },
];

export const OZONE_FORM_TEN = [
  {
    name: 'Form-10 Part 1',
    route: '/campa/campa-apo/form-ten-part-one',
    id: 'ozone_form_ten_one',
  },
  {
    name: 'Form-10 Part 2',
    route: '/campa/campa-apo/form-ten-part-two',
    id: 'ozone_form_ten_two',
  },
];

export const OZONE_FORM_TEN_A = [
  {
    name: 'Form-10-A Part 1',
    route: '/campa/campa-apo/form-ten-a-part-one',
    id: 'ozone_form_ten_a_one',
  },
  {
    name: 'Form-10-A Part 2',
    route: '/campa/campa-apo/form-ten-a-part-two',
    id: 'ozone_form_ten_a_two',
  },
];

export const OZONE_FORM_THIRTEEN = [
  {
    name: 'Form-13 Part 1',
    route: '/campa/campa-apo/form-thirteen-part-one',
    id: 'ozone_form_thirteen_one',
  },
  {
    name: 'Form-13 Part 2',
    route: '/campa/campa-apo/form-thirteen-part-two',
    id: 'ozone_form_thirteen_two',
  },
];

export const PROPOSAL_DATA = {
  HISTORY_SAVE: 'proposalDataHistory/save',
  HISTORY_GET: 'proposalDataHistory/getList',
};
export const JS_WLC = {
  GET_ALL_COUNT: 'parivesh/wlc-workflow/dashboard-count?bucketNames=',
  GET_TAB_LIST: 'parivesh/wlc-workflow/dashboard-record?bucketName=',
  GET_PROPOSAL: 'parivesh/wlc-workflow/dashboard-record-new?bucketName=',
  GET_DOWNLOAD: 'parivesh/WildLife/get-combined-form',
  WLC_PDF_GENERATION: 'parivesh/WildLife/get-wlc-Pdf'
}
export const JS_WLC_ABBR = {
  AGENDA_NBWL_APPROVAL: 'AGENDANBWLAPPROVAL',
  AGENDA_NBWL: 'AGENDANBWLHISTORY',
  MOM_NBWL_APPROVAL: 'MOMNBWLAPPROVAL',
  MOM_NBWL: 'MOMNBWLHISTORY',
  AGENDA_NBWL_DOWNLOAD: 'AGENDA_NBWL',
  MOM_NBWL_DOWNLOAD: 'MOM_NBWL'
}

export const SPCB_BUCKETS_NAME = {
  PP: 'PP',
  TOR: 'TOR',
  EC: 'EC',
  COMMENTS: 'COMMENTS',
  ALL: 'ALL'
}