import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message?: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'Cancel',
    dialogSize: 'md' | 'lg' = 'md',
    isDisableBackdropClose?: boolean,
    htmlElement?: any,
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: dialogSize,
      centered: true,
      ...isDisableBackdropClose ? { backdrop: 'static', keyboard: false } : {}
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.htmlElement = htmlElement;

    return modalRef.result;
  }

  public esignModal(
    title: string,
    //message?: string,
    btnOkText: string = 'OK',
    btnCancelText: string = 'not',
    dialogSize: 'md' | 'lg' = 'md',
    isDisableBackdropClose?: boolean,
    htmlElement?: any,
    eSignModal?:boolean
  ): Promise<boolean>{
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {
      size: dialogSize,
      centered: true,

      ...isDisableBackdropClose ? { backdrop: 'static', keyboard: false } : {}
    });
    modalRef.componentInstance.title = title;
    //modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.htmlElement = htmlElement;
    modalRef.componentInstance.esignModal = eSignModal;

    return modalRef.result;
  }
}
