import { Component, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/services/message.service';
import { Router } from '@angular/router';
import { SELECTED_ROLE_KEY, USER_PROFILE } from 'src/app/shared/app-constant';

@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
})
export class HeaderLogoComponent {
  @Input() fluid: boolean = false;
  userRole$!: Observable<any>;
  userName$!: Observable<any>;
  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {
    this.userRole$ = this.userService.getLoggedInUserRoleName();
    this.userName$ = this.userService.getLoggedInUserAPI();
    //console.log("UserName",this.userName$);
    
    this.userName$.subscribe(res => {
      //console.log("res",res);
      
      let data: any = res;
      this.sessionStorageService.set(USER_PROFILE, JSON.stringify(data));
      this.sessionStorageService.set(SELECTED_ROLE_KEY, data?.selectedRoleDto?.role)
      this.userService.userLoginDetails$.next(data);
    })
    this.userRole$.subscribe(res => {
      let data: any = res;
      this.userService.userRoleDetails$.next(data);
    })
  }

  formatJurisdiction(jurisdiction: string) {
    return jurisdiction?.includes(',')
      ? jurisdiction?.split(',')[0]
      : jurisdiction;
  }

  logout() {
    this.userService.logout().subscribe((res) => { });
    this.sessionStorageService.removeAll();
    this.messageService.showSuccess(
      this.translateService.instant('registration.logout')
    );
    this.router.navigate(['/home']);
  }
}
