import { ROLES, USER_TYPE } from '../shared/app-constant';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';

@Injectable()
export class MenuService {
  user: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService
  ) {
    this.userService
      .userLoginDetails$
      .subscribe((res) => this.user = res);
  }

  getMenuItems(): Observable<any> {
    if (this.user?.is_three_tier_hierarcy && this.authService.ROLES?.includes(ROLES.PROJECT_PROPONENT)) return this.http.get('assets/json/menues/super-user.json');
    else if (
      (this.authService.ROLES?.includes(ROLES.PROJECT_PROPONENT) &&
        this.user?.user_type == USER_TYPE.INDIVIDUAL) ||
      this.user?.user_type == USER_TYPE.OTHER
    )
      return this.http.get('assets/json/menues/employee.json');
    if (this.authService.ROLES?.includes(ROLES.PROJECT_PROPONENT))
      return this.http.get('assets/json/menues/proponent.json');
    else if (this.authService.ROLES?.includes(ROLES.PROPONENT_CENTRAL_PSU))
      return this.http.get('assets/json/menues/proponent_central_psu.json');
    else if (this.authService.ROLES?.includes(ROLES.PROPONENT_EMPLOYEE))
      return this.http.get('assets/json/menues/employee.json');
    if (this.authService.ROLES?.includes(ROLES.CONSULTANT))
      return this.http.get('assets/json/menues/consultant.json');
    if (this.authService.ROLES?.includes(ROLES.AS))
      return this.http.get('assets/json/menues/as.json');
    if (this.authService.ROLES?.includes(ROLES.JS))
      return this.http.get('assets/json/menues/js-above.json');
    if (this.authService.ROLES?.includes(ROLES.EC_MS_SEIAA))
      return this.http.get('assets/json/menues/ms-above-sieaa.json');
    if (this.authService.ROLES?.includes(ROLES.MS_CRZ_MOEFCC))
      return this.http.get('assets/json/menues/ms-crz-moefcc-above.json');
    if (this.authService.ROLES?.includes(ROLES.MS))
      return this.http.get('assets/json/menues/ms-above.json');
    if (this.authService.ROLES?.includes(ROLES.EC_MS_SEAC))
      return this.http.get('assets/json/menues/ms-state-above.json');
    if (this.authService.ROLES?.includes(ROLES.MS_CRZ))
      return this.http.get('assets/json/menues/ms-crz-above.json');
    if (this.authService.ROLES?.includes(ROLES.MS_CRZ_MOEFCC))
      return this.http.get('assets/json/menues/ms-crz-moefcc-above.json');
    if (
      this.authService.ROLES?.includes(ROLES.SO_CRZ_MOEFCC) ||
      this.authService.ROLES?.includes(ROLES.DD_CRZ_MOEFCC)
    )
      return this.http.get('assets/json/menues/so-crz-moefcc-above.json');
    if (this.authService.ROLES?.includes(ROLES.DD))
      return this.http.get('assets/json/menues/dd-above.json');
    if (this.authService.ROLES?.includes(ROLES.EC_USS))
      return this.http.get('assets/json/menues/uss-seac-above.json');
    if (this.authService.ROLES?.includes(ROLES.SO))
      // for uss_sieaa
      return this.http.get('assets/json/menues/so-above.json');
    if (
      this.authService.ROLES?.includes(ROLES.SUPP_STAFF_OTHERS) ||
      this.authService.ROLES?.includes(ROLES.UPPER_SUPP_STAFF_SEIAA) ||
      this.authService.ROLES?.includes(ROLES.SUPP_STAFF_SEIAA)
    )
      return this.http.get('assets/json/menues/supp-staff-above.json');
    if (
      this.authService.ROLES?.includes(ROLES.CHAIRMAN_CRZ) ||
      this.authService.ROLES?.includes(ROLES.CHAIRMAN_CRZ_MOEFCC)
    )
      return this.http.get('assets/json/menues/chairman-crz-above.json');
    if (this.authService.ROLES?.includes(ROLES.LEGAL_NO))
      return this.http.get('assets/json/menues/legal.json');
    if (this.authService.ROLES?.includes(ROLES.DC))
      return this.http.get('assets/json/menues/dc-above.json');
    if (this.authService.ROLES?.includes(ROLES.EC_SECRETARY) || this.authService.ROLES?.includes(ROLES.SPECIAL_SECRETARY))
      return this.http.get('assets/json/menues/ec-secretary.json');
    if (this.authService.ROLES?.includes(ROLES.NGT))
      return this.http.get('assets/json/menues/legal-ngt.json');
    if (this.authService.ROLES?.includes(ROLES.MS_GEAC))
      return this.http.get('assets/json/menues/ms-geac-above.json');
    if (this.authService.ROLES?.includes(ROLES.IRO_HEAD_EC))
      return this.http.get('assets/json/menues/ec_status.json');
    if (this.authService.ROLES?.includes(ROLES.MS_SPCB))
      return this.http.get('assets/json/menues/spcb-above.json');
    else return this.http.get('assets/json/menues/department.json');
  }
}
